import styled from 'styled-components'

import { Color } from 'root-constants/common'

type TTextProps = {
  marginBottom?: number
}

export const StyledSubscriptionDescription = {
  Text: styled.p<TTextProps>`
    margin-bottom: ${({ marginBottom }) => `${marginBottom || 48}px`};
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: ${Color.LIGHT_TEXT};

    & a {
      color: ${Color.LIGHT_TEXT};
      text-decoration: underline;
    }
  `,
}
