import React, { createContext, useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { selectCurrentVariantSteps } from 'root-redux/selects/common'

import { IStep } from 'models/variant.model'

import { PageId } from 'root-constants/pages'

type TPageInfoContextProps = {
  steps: IStep[]
  currentPageId: PageId
  currentPageIndex: number
  hasHeader: boolean
  hasProgressbar: boolean
  currentSubscriptionPageId: PageId
  currentPaymentPageId: PageId
  isPaymentPage: boolean
}

const PageInfoContext = createContext<TPageInfoContextProps | null>(null)

export const PageInfoProvider = ({ children }) => {
  const { pathname } = useLocation()
  const steps = useSelector(selectCurrentVariantSteps)

  const currentPageId = pathname.slice(1) as PageId

  const currentPageIndex = steps.findIndex(({ id }) => id === currentPageId)

  const isPostPaymentPage = [
    PageId.UPSELL_1,
    PageId.ACCOUNT,
    PageId.DOWNLOAD,
  ].includes(currentPageId)

  const { hasHeader, hasProgressbar } = {
    hasHeader: steps[currentPageIndex]?.hasHeader || isPostPaymentPage,
    hasProgressbar: steps[currentPageIndex]?.hasProgressBar,
  }

  let currentSubscriptionPageId = '' as PageId
  let currentPaymentPageId = '' as PageId

  steps.forEach(({ isSubscriptions, isPayment, id }) => {
    if (isSubscriptions && !currentSubscriptionPageId) {
      currentSubscriptionPageId = id
    }

    if (isPayment && !currentPaymentPageId) currentPaymentPageId = id
  })

  const isPaymentPage = currentPaymentPageId === currentPageId

  const value = useMemo(
    () => ({
      currentPageId,
      currentPageIndex,
      hasHeader,
      hasProgressbar,
      currentSubscriptionPageId,
      currentPaymentPageId,
      isPaymentPage,
      steps,
    }),
    [
      currentPageId,
      currentPageIndex,
      currentPaymentPageId,
      currentSubscriptionPageId,
      hasHeader,
      hasProgressbar,
      isPaymentPage,
      steps,
    ],
  )

  return (
    <PageInfoContext.Provider value={value}>
      {children}
    </PageInfoContext.Provider>
  )
}

export const usePageInfo = () => {
  const context = useContext(PageInfoContext)
  if (!context) {
    throw new Error('usePageInfo must be used within a PageInfoProvider')
  }
  return context
}
