import React from 'react'

import { PlanItemLong } from 'modules/subscriptions/components/common/PlanItemLong'
import {
  PlanItemVariant1,
  VatPlanItemVariant1,
} from 'modules/subscriptions/components/common/PlanItemVariant1'
import { PlanItemVariant2 } from 'modules/subscriptions/components/common/PlanItemVariant2'
import { PaidTrialItemVariant1 } from 'modules/subscriptions/components/trial/PaidTrialItemVariant1'
import { PaidTrialItemVariant2 } from 'modules/subscriptions/components/trial/PaidTrialItemVariant2'
import { ISelectPlanItemProps } from 'modules/subscriptions/types'

import { Currency, TrialPeriod } from 'root-constants/common'

import { UpsellPlanItemVariant1 } from './components/upsell/UpsellPlanItemVariant1'

export const COMMON_BENEFITS = [
  'subscriptions.commonBenefits.trackingSystem',
  'subscriptions.commonBenefits.playlists',
  'subscriptions.commonBenefits.reports',
]

export const REDUCE_STRESS_BENEFITS = [
  'subscriptions.reduceStressBenefits.heartRate',
  'subscriptions.reduceStressBenefits.insights',
  'subscriptions.reduceStressBenefits.trackProgress',
  'subscriptions.reduceStressBenefits.playlists',
]

export const INITIAL_FORMATTED_TIME = {
  minutes: '',
  seconds: '',
}

export const PLAN_ITEMS: Record<string, React.FC<ISelectPlanItemProps>> = {
  plan_item_variant_1: PlanItemVariant1,
  plan_item_variant_2: PlanItemVariant2,
  plan_item_long_variant_1: PlanItemLong,
  vat_plan_item_variant_1: VatPlanItemVariant1,
  trial_plan_item_variant_1: PaidTrialItemVariant1,
  trial_plan_item_variant_2: PaidTrialItemVariant2,
  upsell_plan_item_variant1: UpsellPlanItemVariant1,
}

export const TRIAL_PLAN_ITEMS = [
  'trial_plan_item_variant_1',
  'trial_plan_item_variant_2',
]

export const enum ProductKey {
  ONE_WEEK = 'oneWeek',
  THREE_MONTHS = 'threeMonths',
  SIX_MONTHS = 'sixMonths',
  ONE_YEAR = 'oneYear',
  ONE_WEEK_SOULDOUT = 'oneWeek_soldout',
  UPSELL_BUNDLE = 'upsellBundle',
  AI_HELPER = 'aiHelper',
  WEEKLY_REPORTS = 'weeklyReports',
}

export const TRIAL_PERIOD_NAME_TO_MARKUP_MAP = {
  [TrialPeriod.ONE_WEEK]: 'payment.planBlock.day',
  [TrialPeriod.ONE_MONTH]: 'payment.planBlock.month',
  [TrialPeriod.THREE_MONTH]: 'payment.planBlock.month',
}
export const OVERSIZE_CURRENCIES: string[] = [
  Currency.CAD,
  Currency.AUD,
  Currency.NZD,
  Currency.MXN,
  Currency.BRL,
]
