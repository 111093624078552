import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { setAnswersAction } from 'root-redux/actions/common'
import { sendUserAnswersAction } from 'root-redux/actions/user'

import { useIsCurrentCohort } from 'hooks/useIsCurrentCohort'
import { useUserData } from 'hooks/useUserData'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import {
  DYNAMIC_TITLE_OPTIONS,
  OPTION_VALUES,
} from 'pages/consumption/constants'

import { Button } from 'components/Button'
import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { goTo } from 'browser-history'
import { Cohort, Language } from 'root-constants/common'

import { StyledConsumptionVariant1 as S } from './ConsumptionVariant1.styles'

export const ConsumptionVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
  alternativePagePath,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [answers, setAnswers] = useState<string[]>([])

  const { activity } = useUserData()
  const isRendered = useIsCurrentCohort(Cohort.CARDIMATE_19_FEED)

  const handleContinue = (answer) => {
    dispatch(
      setAnswersAction({
        answers: answer,
        pageId,
      }),
    )

    dispatch(sendUserAnswersAction())

    eventLogger.logQuestion({
      question: t('onboarding.consumption.title', { lng: Language.EN }),
      answers: answer,
      pageName: pageId,
    })

    if (answer.includes(OPTION_VALUES.NONE_ABOVE)) {
      goTo(alternativePagePath)
      return
    }
    goTo(nextPagePath)
  }

  const optionProps = {
    name: pageId,
    type: OptionType.CHECKBOX,
    onChange: (value: string, isChecked: boolean) => {
      if (isChecked && value === OPTION_VALUES.NONE_ABOVE) {
        setAnswers([OPTION_VALUES.NONE_ABOVE])
        return
      }

      if (isChecked) {
        const newValues = [...answers, value].filter(
          (item) => item !== OPTION_VALUES.NONE_ABOVE,
        )
        setAnswers(newValues)
        return
      }

      const newValues = answers.filter((item) => item !== value)
      setAnswers(newValues)
    },
  }

  return (
    <S.Wrapper>
      <S.Column>
        {isRendered && <S.Title>{t(DYNAMIC_TITLE_OPTIONS[activity])}</S.Title>}
        <S.Title>{t`onboarding.consumption.title`}</S.Title>
        <S.Text>{t`onboarding.consumption.subtitle`}</S.Text>
        <StyledOption.OptionsContainer paddingBottom={4}>
          <Option
            {...optionProps}
            value={OPTION_VALUES.SALTY_FOODS}
            checked={answers.includes(OPTION_VALUES.SALTY_FOODS)}
          >
            <QuestionButton data-has-check-icon>
              {t`onboarding.consumption.saltyFood`}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.PROCESSED_FOODS}
            checked={answers.includes(OPTION_VALUES.PROCESSED_FOODS)}
          >
            <QuestionButton data-has-check-icon>
              {t`onboarding.consumption.processedFood`}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.BEVERAGES}
            checked={answers.includes(OPTION_VALUES.BEVERAGES)}
          >
            <QuestionButton data-has-check-icon>
              {t`onboarding.consumption.beverages`}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.DONT_EAT}
            checked={answers.includes(OPTION_VALUES.DONT_EAT)}
          >
            <QuestionButton data-has-check-icon>
              {t`onboarding.consumption.dontEat`}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.NONE_ABOVE}
            checked={answers.includes(OPTION_VALUES.NONE_ABOVE)}
          >
            <QuestionButton data-has-none-button-check-icon>
              {t`onboarding.consumption.none`}
            </QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
        <StickyButtonContainer>
          <Button
            onClick={() => handleContinue(answers)}
            disabled={!answers.length}
          >
            {t`actions.continue`}
          </Button>
        </StickyButtonContainer>
      </S.Column>
    </S.Wrapper>
  )
}
