import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'
import { usePurchaseStore } from 'hooks/usePurchaseStore'
import { useUserData } from 'hooks/useUserData'

import { createIntroOfferProductId } from 'helpers/createIntroOfferProductId'

import { Events, eventLogger } from 'services/eventLogger.service'

import { PaymentMethod, PaymentSystem } from 'root-constants/common'

export const usePrimerAnalytics = (): {
  logPaypalPaymentStarted: (eventName?: Events) => void
} => {
  const {
    screenName,
    email,
    currentPrice,
    trialPriceId,
    trialPrice,
    trialPeriodDays,
    currency,
    priceId,
    stripeAccountName,
    stripeAccountId,
  } = usePurchaseStore()

  const { goal } = useUserData()
  const { isPersonalDataAllowed } = useCookieConsentAnswer()

  const productId = createIntroOfferProductId({
    priceId,
    trialPriceId,
    trialPeriodQuantity: trialPeriodDays,
  })

  const logPaypalPaymentStarted = (eventName = Events.PURCHASE_STARTED) => {
    eventLogger.logPurchaseStarted({
      eventName,
      email,
      screenName,
      productId: trialPriceId ? productId : priceId,
      priceDetails: {
        price: currentPrice,
        trial: !!trialPrice,
        currency,
      },
      paymentMethod: PaymentMethod.PAYPAL,
      paymentSystem: PaymentSystem.PRIMER,
      goal,
      isPersonalDataAllowed,
      stripeAccountName,
      stripeAccountId,
    })
  }

  return {
    logPaypalPaymentStarted,
  }
}
