import { IAppState } from 'models/store.model'
import { ISubscription, TOldPrice } from 'models/subscriptions.model'

import {
  DEFAULT_CURRENCY,
  EMPTY_OLD_PRICE,
  PaymentMethod,
  PlanAddition,
} from 'root-constants/common'

export const selectSubscription = (state: IAppState): ISubscription | null =>
  state.payment.selectedSubscription || null
export const selectSubscriptionId = (state: IAppState): string =>
  state.payment.selectedSubscription?.id || ''
export const selectPlanId = (state: IAppState): number =>
  state.payment.selectedSubscription?.planId || 0
export const selectPayPalPlanId = (state: IAppState): string =>
  state.payment.selectedSubscription?.paypalPlanId || ''
export const selectSubscriptionLookupKey = (state: IAppState): string =>
  state.payment.selectedSubscription?.lookupKey || ''
export const selectSubscriptionTrialLookupKey = (state: IAppState): string =>
  state.payment.selectedSubscription?.trialLookupKey || ''
export const selectSubscriptionPeriodQuantity = (state: IAppState): number =>
  state.payment.selectedSubscription?.mainPrices.periodQuantity || 0
export const selectSubscriptionPeriodName = (state: IAppState): string =>
  state.payment.selectedSubscription?.mainPrices.periodName || ''
export const selectSubscriptionFullPrice = (state: IAppState): number =>
  state.payment.selectedSubscription?.mainPrices.fullPrice || 0
export const selectSubscriptionDailyPrice = (state: IAppState): number =>
  state.payment.selectedSubscription?.mainPrices.daily || 0
export const selectSubscriptionTrialDailyPrice = (state: IAppState): number =>
  state.payment.selectedSubscription?.trialPrices.daily || 0
export const selectSubscriptionOldPriceBeforeCustomDiscount = (
  state: IAppState,
): TOldPrice =>
  state.payment.selectedSubscription?.mainPrices.oldPrices
    .beforeCustomDiscount || EMPTY_OLD_PRICE
export const selectSubscriptionTrialOldPriceBeforeCustomDiscount = (
  state: IAppState,
): TOldPrice =>
  state.payment.selectedSubscription?.trialPrices.oldPrices
    .beforeCustomDiscount || EMPTY_OLD_PRICE
export const selectCurrency = (state: IAppState): string =>
  state.payment.selectedSubscription?.currency || DEFAULT_CURRENCY
export const selectProductId = (state: IAppState): string =>
  state.payment.selectedSubscription?.product || ''
export const selectSubscriptionTrialPeriodPrice = (state: IAppState): number =>
  state.payment.selectedSubscription?.trialPrices.fullPrice || 0
export const selectSubscriptionTrialPeriodPriceTax = (
  state: IAppState,
): number => state.payment.selectedSubscription?.trialPrices.fullPriceTax || 0
export const selectSubscriptionTrialPeriodDays = (state: IAppState): number =>
  state.payment.selectedSubscription?.trialPrices.durationDays || 0
export const select3DSecureIframeUrl = (state: IAppState): string =>
  state.payment.threeDSecureIframeURL
export const selectPaymentClientSecret = (state: IAppState): string =>
  state.payment.paymentClientSecret
export const selectCreatedSubscriptionId = (state: IAppState): string =>
  state.payment.subscriptionId
export const selectIsPaymentFlowsShown = (state: IAppState): boolean =>
  state.payment.isPaymentFlowsShown
export const selectPaymentMethod = (state: IAppState): PaymentMethod =>
  state.payment.paymentMethod
export const selectPlanAdditions = (state: IAppState): PlanAddition[] =>
  state.payment.planAdditions
export const selectPaymentRequestButtonType = (
  state: IAppState,
): PaymentMethod | null => state.payment.paymentRequestButtonType

export const selectPrimerClientSessionToken = (state: IAppState): string =>
  state.payment.primerClientSessionToken
export const selectIsFirstPaymentRetryPassed = (state: IAppState): boolean =>
  state.payment.isFirstPaymentRetryPassed
export const selectIsPrimerRetryProcessing = (state: IAppState): boolean =>
  state.payment.isPrimerRetryProcessing || false
export const selectIsPaymentPopupActive = (state: IAppState): boolean =>
  state.payment.isPaymentPopupActive
export const selectIsPaymentProcessingPopupActive = (
  state: IAppState,
): boolean => state.payment.isPaymentProcessingPopupActive
