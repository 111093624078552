import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectLanguage } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'
import { useUserAdditionalGoals } from 'hooks/useUserAdditionalGoals'
import { useUserSymptoms } from 'hooks/useUserSymptoms'

import { TPageProps } from 'models/common.model'

import {
  MAX_ADDITIONAL_GOALS_SIZE,
  MAX_SYMPTOMS_SIZE,
} from 'pages/better-heart-life/constants'

import { Button } from 'components/Button'

import { CDN_FOLDER_LINK, Images, Language } from 'root-constants/common'

import { StyledBetterHeartLife as S } from './BetterHeartLife.styles'

export const BetterHeartLifeVariant1: React.FC<TPageProps> = ({
  nextPagePath,
  pageId,
}) => {
  const { t } = useTranslation()
  const language = useSelector(selectLanguage)

  const userSymptoms = useUserSymptoms(MAX_SYMPTOMS_SIZE)
  const userAdditionalGoals = useUserAdditionalGoals(MAX_ADDITIONAL_GOALS_SIZE)

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: t('onboarding.betterHeartLife.title', { lng: Language.EN }),
    nextPagePath,
  })

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.betterHeartLife.title`}</S.Title>
        <S.BenefitsWrapper>
          <S.BenefitsList>
            <li>{t`onboarding.betterHeartLife.advancedTracking`}</li>
            <li>
              <Trans>{userAdditionalGoals.join(', ')}</Trans>
            </li>

            {!!userSymptoms.length && (
              <li>
                {t('onboarding.betterHeartLife.minimize', {
                  symptoms: userSymptoms,
                  style: 'long',
                  type: 'conjunction',
                })}
              </li>
            )}
          </S.BenefitsList>
        </S.BenefitsWrapper>
        <S.Image
          src={`${CDN_FOLDER_LINK}${Images.NUMBER_ONE}_${language}.png`}
          alt="number_one_app"
        />
        <S.StickyButtonContainer>
          <Button type="button" onClick={() => handleContinue('')}>
            {t`actions.continue`}
          </Button>
        </S.StickyButtonContainer>
      </S.Column>
    </S.Wrapper>
  )
}
