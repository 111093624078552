import { useState } from 'react'

type TInputFieldHook = {
  value: string
  isValid: boolean
  handleInputChange: (inputValue: string, isValidInput: boolean) => void
}

export const useInputValidation = (): TInputFieldHook => {
  const [value, setValue] = useState('')
  const [isValid, setIsValid] = useState(true)

  const handleInputChange = (inputValue: string, isValidInput: boolean) => {
    setValue(inputValue)
    setIsValid(inputValue ? isValidInput : true)
  }

  return { value, isValid, handleInputChange }
}
