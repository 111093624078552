import React, { useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { useDynamicPaywallConfig } from 'hooks/common/useDynamicPaywallConfig'
import { useGetPrices } from 'hooks/common/useGetPrices'
import { usePurchaseStore } from 'hooks/common/usePurchaseStore'
import { useVatInfo } from 'hooks/common/useVatInfo'
import { useHeartMonitorDomain } from 'hooks/ui/useHeartMonitorDomain'

import { createProductId } from 'helpers/createProductId'

import { TPageProps } from 'models/common.model'

import { ScreenName, eventLogger } from 'services/eventLogger.service'

import { MoneyBackGuarantee } from 'modules/subscriptions/components/common/MoneyBackGuarantee'
import { VatPlanItemVariant1 } from 'modules/subscriptions/components/common/PlanItemVariant1'
import { SubheaderWithTimer } from 'modules/subscriptions/components/common/SubheaderWithTimer'
import { SubscriptionDescription } from 'modules/subscriptions/components/common/SubscriptionDescription'
import { PaidTrialItemVariant1 } from 'modules/subscriptions/components/trial/PaidTrialItemVariant1'
import {
  COMMON_BENEFITS,
  PLAN_ITEMS,
  TRIAL_PLAN_ITEMS,
} from 'modules/subscriptions/constants'
import { usePaywall } from 'modules/subscriptions/hooks/usePaywall'

import { HeaderWithButton } from 'components/HeaderWithButton'
import { ReviewsSlider } from 'components/ReviewSlider'
import { ReviewsSliderDynamic } from 'components/ReviewSlider/ReviewsSliderDynamic'
import { Spinner } from 'components/Spinner'

import heartRateImg from 'assets/images/subscriptions-heart-rate.jpg'

import { Cohort, SubscriptionTag } from 'root-constants/common'

import { StyledSubscriptionsVariant1 as S } from './SubscriptionsVariant1.styles'

export const SubscriptionsVariant1: React.FC<TPageProps> = ({
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const selectPlanBlockRef = useRef<HTMLDivElement>(null)

  const { subscriptions, cohortToUse } = usePurchaseStore()
  const hasIncludedVAT = useVatInfo()
  const { hasPrices, handleSelectSubscription, handleShowPayment } =
    usePaywall(nextPagePath)

  useGetPrices({
    tags: hasIncludedVAT ? SubscriptionTag.TAX : SubscriptionTag.NO_TAX,
  })

  const {
    title,
    subtitle,
    buttonText,
    benefits,
    subscriptionBlockType,
    customerReviews,
  } = useDynamicPaywallConfig()

  const { productName } = useHeartMonitorDomain()

  const planItem = useMemo(() => {
    if (hasIncludedVAT) {
      return VatPlanItemVariant1
    }
    if (cohortToUse === Cohort.CARDIMATE_14) {
      return PaidTrialItemVariant1
    }
    return PLAN_ITEMS[subscriptionBlockType]
  }, [hasIncludedVAT, cohortToUse, subscriptionBlockType])

  const isTrialPlan = TRIAL_PLAN_ITEMS.includes(subscriptionBlockType)

  useEffect(() => {
    if (!subscriptions.length) return

    eventLogger.logSalePageShown({
      productIds: subscriptions.map(
        ({ mainPrices: { periodName, periodQuantity, fullPrice } }) =>
          createProductId({
            periodName,
            periodQuantity,
            price: fullPrice,
          }),
      ),
      screenName: ScreenName.ONBOARDING,
    })
  }, [subscriptions])

  const handleScroll = () => {
    selectPlanBlockRef.current?.scrollIntoView({
      block: 'center',
    })
  }

  return !hasPrices ? (
    <Spinner />
  ) : (
    <S.Wrapper>
      <HeaderWithButton onClick={handleScroll} />

      <S.Content>
        <SubheaderWithTimer discount={isTrialPlan ? 50 : 75} />
        <S.Congratulations>{t`subscriptions.congrats`}</S.Congratulations>
        <S.Title>{title || t`subscriptions.rightTrack`}</S.Title>
        <S.Disclaimer>
          {subtitle || t`subscriptions.trackingTools`}
        </S.Disclaimer>

        <S.Benefits>
          {benefits?.length ? (
            <ul>
              {benefits.map(({ id, text }) => (
                <S.ListItem key={id}>{text}</S.ListItem>
              ))}
            </ul>
          ) : (
            <ul>
              {COMMON_BENEFITS.map((path) => (
                <S.ListItem key={path}>{t(path)}</S.ListItem>
              ))}
            </ul>
          )}
          <S.ImageContainer>
            <img src={heartRateImg} alt="scores on mobile phone" />
          </S.ImageContainer>
        </S.Benefits>

        <S.SelectPlanBlock
          ref={selectPlanBlockRef}
          onSelect={handleSelectSubscription}
          SelectPlanItem={planItem}
        />
        <S.Button onClick={handleShowPayment}>
          {buttonText || t('actions.getCardi', { productName })}
        </S.Button>
        {isTrialPlan ? (
          <SubscriptionDescription />
        ) : (
          <S.CancelInfo>{t`subscriptions.disclaimer`}</S.CancelInfo>
        )}
        {customerReviews?.length ? (
          <ReviewsSliderDynamic />
        ) : (
          <ReviewsSlider
            titlePath="subscriptions.customersLove"
            titleBottomMargin={24}
            marginBottom={32}
          />
        )}
        <MoneyBackGuarantee />
      </S.Content>
    </S.Wrapper>
  )
}
