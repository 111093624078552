import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  selectDynamicDiscount,
  selectLanguage,
} from 'root-redux/selects/common'

import { getPriceBeforeDiscount } from 'helpers/getPriceBeforeDiscount'

import {
  selectCurrency,
  selectSubscriptionTrialPeriodDays,
  selectSubscriptionTrialPeriodPrice,
} from 'modules/payment/redux/selects'

import {
  CURRENCY_SYMBOLS,
  DEFAULT_DYNAMIC_DISCOUNT_AMOUNT,
  Language,
  TRIAL_DESCRIPTION_CONTEXT,
} from 'root-constants/common'

import { StyledTrialAmountWithDynamicDiscount as S } from './TrialAmountWithDynamicDiscount.styles'

export const TrialAmountWithDynamicDiscount: React.FC = () => {
  const { t } = useTranslation()
  const currency = useSelector(selectCurrency)
  const trialPrice = useSelector(selectSubscriptionTrialPeriodPrice)
  const trialPeriodDays = useSelector(selectSubscriptionTrialPeriodDays)
  const dynamicDiscount = useSelector(selectDynamicDiscount)
  const language = useSelector(selectLanguage)

  const oldPrice = useMemo(
    () =>
      getPriceBeforeDiscount(
        dynamicDiscount?.amount || DEFAULT_DYNAMIC_DISCOUNT_AMOUNT,
        trialPrice,
      ),
    [trialPrice, dynamicDiscount?.amount],
  )

  const priceWithDiscount = useMemo(
    () => Number(oldPrice) - trialPrice,
    [oldPrice, trialPrice],
  )

  return (
    <S.IntroOfferContainer>
      <S.Title fontSize={language === Language.ES ? 22 : 24}>
        {t`payment.paymentFlow.getPlan`}
      </S.Title>
      <S.DescriptionWrapper>
        <S.Description>
          {t('payment.trialWithoutDiscountAmount.amount', {
            context: TRIAL_DESCRIPTION_CONTEXT[trialPeriodDays],
          })}
        </S.Description>
        <S.Price>
          {t('payment.planBlock.price', {
            price: oldPrice,
            currencySymbol: CURRENCY_SYMBOLS[currency],
            minimumFractionDigits: 2,
          })}
        </S.Price>
      </S.DescriptionWrapper>
      <S.DescriptionWrapper>
        <S.Description>
          {dynamicDiscount?.amount}% {dynamicDiscount?.checkout}
        </S.Description>
        <S.Price color="#FD417F">
          -
          {t('payment.planBlock.price', {
            price: priceWithDiscount,
            currencySymbol: CURRENCY_SYMBOLS[currency],
            minimumFractionDigits: 2,
          })}
        </S.Price>
      </S.DescriptionWrapper>
      <S.DescriptionWrapper>
        <S.Description fontWeight={900}>
          {t`payment.paymentSummary.total`}
        </S.Description>
        <S.Price fontWeight={900}>
          {t('payment.planBlock.price', {
            price: trialPrice,
            currencySymbol: CURRENCY_SYMBOLS[currency],
            minimumFractionDigits: 2,
          })}
        </S.Price>
      </S.DescriptionWrapper>
    </S.IntroOfferContainer>
  )
}
