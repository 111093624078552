import styled from 'styled-components'

import { Color } from 'root-constants/common'

type TPropsDescription = {
  color?: string
  fontWeight?: number
}
type TTitleProps = {
  fontSize: number
}
export const StyledTrialAmountWithDynamicDiscount = {
  IntroOfferContainer: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0;

    strong {
      font-weight: 800;
    }
  `,
  Title: styled.h2<TTitleProps>`
    font-size: ${({ fontSize }) => fontSize || 24}px;
    font-weight: 900;
    line-height: 32px;
    color: ${({ theme }) => theme.colors.secondaryText};
    margin-bottom: 16px;
  `,
  DescriptionWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    width: 100%;
  `,
  Description: styled.p<TPropsDescription>`
    font-size: 13px;
    font-weight: ${({ fontWeight }) => fontWeight || 500};
    line-height: 18px;
    color: ${Color.LIGHT_TEXT};
  `,
  Price: styled.p<TPropsDescription>`
    font-size: 13px;
    font-weight: ${({ fontWeight }) => fontWeight || 500};
    line-height: 18px;
    color: ${({ color }) => color || Color.LIGHT_TEXT};
    min-width: 60px;
    text-align: end;
  `,
}
