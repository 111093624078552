import React from 'react'
import { useTranslation } from 'react-i18next'

import { usePurchaseStore } from 'hooks/usePurchaseStore'

import {
  CURRENCY_SYMBOLS,
  TRIAL_DESCRIPTION_CONTEXT,
} from 'root-constants/common'

import { StyledDescription as S } from './Description.styles'

export const TrialDescription: React.FC = () => {
  const { t } = useTranslation()

  const { currentPrice, trialPrice, trialPeriodDays, currency } =
    usePurchaseStore()

  return (
    <ul>
      <S.DescriptionItem>
        {t`payment.description.followInstructions`}
      </S.DescriptionItem>
      <S.DescriptionItem>
        {t('payment.description.trialPeriodV2', {
          trialPrice,
          fullPrice: currentPrice,
          currencySymbol: CURRENCY_SYMBOLS[currency],
          context: TRIAL_DESCRIPTION_CONTEXT[trialPeriodDays],
          minimumFractionDigits: 2,
        })}
      </S.DescriptionItem>
    </ul>
  )
}
