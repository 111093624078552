import styled from 'styled-components'

import { SelectPlanBlockVariant1 } from 'modules/subscriptions/components/common/SelectPlanBlockVariant1'

import { Button } from 'components/Button'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color, HEADER_HEIGHT } from 'root-constants/common'

export const StyledUpsellVariant2 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    max-width: 343px;
    width: 100%;
    padding: 28px 0 38px;
    align-items: center;
  `,
  Title: styled.h1`
    font-size: 22px;
    font-weight: 900;
    line-height: 30px;
    text-align: center;
    margin-bottom: 24px;
    max-width: 327px;

    strong {
      font-size: 22px;
      font-weight: 900;
      line-height: 30px;
      color: ${Color.DANGER};
    }
  `,
  SelectPlanBlock: styled(SelectPlanBlockVariant1)`
    margin-bottom: 14px;
  `,
  Button: styled(Button)`
    line-height: 22px;
    font-size: 16px;
    font-weight: 900;
  `,
  SkipButton: styled(Button)`
    color: ${Color.LIGHT_TEXT};
    text-align: center;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    text-decoration: underline;
    margin-top: 6px;
    background-color: transparent;
    cursor: pointer;
    max-height: 20px;
  `,
  Disclaimer: styled.div`
    font-size: 11px;
    font-weight: 400;
    line-height: 14px;
    color: ${Color.LIGHT_TEXT};
    margin-top: 16px;
    opacity: 0.8;

    a {
      font-size: 11px;
      font-weight: 400;
      line-height: 14px;
      color: ${Color.LIGHT_TEXT};
      text-decoration: underline;
    }
  `,
  StickyButtonContainer: styled(StickyButtonContainer)`
    padding: 10px 0;
    background-image: none;
  `,
}
