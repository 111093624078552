import React from 'react'
import { useTranslation } from 'react-i18next'

import { ISelectPlanItemProps } from 'modules/subscriptions/types'

import {
  CURRENCY_SYMBOLS,
  PERIOD_NAME_TO_MARKUP_MAP,
} from 'root-constants/common'

import { StyledPlanItemVariant2 as S } from './PlanItemVariant2.styles'

export const PlanItemVariant2: React.FC<ISelectPlanItemProps> = ({
  subscription: {
    isDefault,
    currency,
    mainPrices: { periodName, periodQuantity, weekly },
  },
  isSelected,
}) => {
  const { t } = useTranslation()

  return (
    <S.PlanItem
      data-is-selected={isSelected}
      data-is-default={isDefault}
      data-text={t`subscriptions.mostPopular`}
    >
      <S.Container>
        <S.PlanTitle>
          {t('subscriptions.planPeriodV2', {
            periodQuantity,
            periodName: t(PERIOD_NAME_TO_MARKUP_MAP[periodName], {
              count: periodQuantity,
            }),
          })}
        </S.PlanTitle>
        <S.PaymentInformation>
          {t('subscriptions.billingCycle', {
            periodName: t(PERIOD_NAME_TO_MARKUP_MAP[periodName], {
              count: periodQuantity,
            }),
            count: periodQuantity,
          })}
        </S.PaymentInformation>
      </S.Container>
      {!!weekly && (
        <S.Container>
          <S.PlanPrice>
            {CURRENCY_SYMBOLS[currency]}
            {weekly.toFixed(2)}
          </S.PlanPrice>
          <S.PricePeriod>{t`subscriptions.perWeek`}</S.PricePeriod>
        </S.Container>
      )}
    </S.PlanItem>
  )
}
