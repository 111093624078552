/* eslint-disable react/no-unescaped-entities */

/* eslint-disable max-lines */
import React, { useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { Trans, useTranslation } from 'react-i18next'

import { getIsHeartMonitorDomain } from 'helpers/getIsHeartMonitorDomain'
import { getIsMyCardimateDomain } from 'helpers/getIsMyCardimateDomain'

import { SupportLink } from 'components/SupportLink'

import heartMonitorLogo from 'assets/images/heart-monitor-logo.webp'
import logo from 'assets/images/logo.webp'

import { CONTACT_FORM_LINK, SUPPORT_EMAIL_LINK } from 'root-constants/common'

import { GlobalStyleForTermsOfUse } from './TermsOfUse.styles'

export const TermsOfUse: React.FC = () => {
  const { t } = useTranslation()
  const isMyCardimateDomain = getIsMyCardimateDomain()
  const isHeartMonitorDomain = getIsHeartMonitorDomain()

  const { appLink } = useMemo(() => {
    if (isMyCardimateDomain) {
      return { appLink: 'https://mycardimate.com/' }
    }
    if (isHeartMonitorDomain) {
      return { appLink: 'https://heartmonitor.app/' }
    }
    return { appLink: 'https://cardimate.com/' }
  }, [isMyCardimateDomain, isHeartMonitorDomain])

  const contactLink = (
    <Trans
      i18nKey="commonComponents.commonContactLink"
      values={{
        contactUs: isHeartMonitorDomain
          ? t('commonComponents.contactUsVia')
          : t('commonComponents.contactUsAt'),
      }}
      components={{
        supportLink: (
          <SupportLink
            text={
              isHeartMonitorDomain
                ? 'commonComponents.contactUsLink'
                : 'commonComponents.contactFormLink'
            }
          />
        ),
      }}
    />
  )

  return (
    <>
      <Helmet>
        <title>
          {isHeartMonitorDomain ? 'HeartMonitor' : 'CardiMate'} | Terms Of Use
        </title>
      </Helmet>
      <GlobalStyleForTermsOfUse />
      <header className="header">
        <section className="header__container container">
          <div className="logo">
            <img
              className="logo__img"
              src={isHeartMonitorDomain ? heartMonitorLogo : logo}
              alt="logo"
            />
          </div>
          <h3 className="header-title">
            {isHeartMonitorDomain ? 'HeartMonitor' : 'CardiMate'}
          </h3>
        </section>
      </header>
      <main>
        <article className="article container">
          <h1 className="article__title">TERMS OF USE</h1>
          <h4 className="article__date">
            {isHeartMonitorDomain
              ? 'Effective Date: February 11, 2025'
              : 'Effective Date: January 30, 2025'}
          </h4>

          <section>
            <h2>INTRODUCTION</h2>
            <p className="mb-10">
              These Terms of Use (the “<strong>Terms</strong>”) apply to your
              access and use of our mobile application “
              <strong>Cardi Mate</strong>” (the “<strong>App</strong>”), our
              websites (including but not limited to{' '}
              <a href={appLink}>{appLink}</a>), our blog, and all related
              services, features, materials, and content available for your use
              (collectively the “<strong>Services</strong>”) provided by{' '}
              <strong>Gismart Limited</strong>, a private limited company,
              incorporated and registered in England and Wales with company
              number 10152488 whose registered office is at 151 Wardour Street,
              London, England, W1F 8WE (“<strong>we</strong>”, “
              <strong>us</strong>”, “<strong>our</strong>” or the “
              <strong>Company</strong>”).
            </p>
            <p className="mb-10">
              If you have any questions about the Terms or our Services, please{' '}
              {contactLink}
            </p>
            {isHeartMonitorDomain && (
              <p className="mb-10">
                Depending on your location, payment method, or other relevant
                factors, your access to the Service, including through sales or
                payment processing, may be facilitated by{' '}
                <strong>Gismart Limited</strong> or its authorized partners,{' '}
                <strong>Extramile Limited</strong>, a company incorporated in
                Cyprus, company number ΗΕ 445953, whose registered office is
                located at Prodromou, 75, Oneworld Parkview House, Floor 4,
                2063, Nicosia, Cyprus; or <strong>Fulfilling Inc.</strong>, a
                Delaware corporation with its registered address at 1007 N
                Orange St. 4th Floor Site 1382, Wilmington, New Castle, 19801.
                These partners <strong>do not provide the Service</strong>,
                manage subscriptions, or assume any obligations related to the
                operation, support, or performance of the Service. Your
                contractual relationship is solely with Gismart Limited. Your
                rights and obligations under these Terms remain the same,
                regardless of the designated partner, and neither Gismart
                Limited nor its partners shall be jointly liable for each
                other’s obligations unless expressly stated in these Terms.
              </p>
            )}
            {!isHeartMonitorDomain && (
              <>
                <p className="mb-10">
                  The Services may also be provided to you by our partners:
                </p>
                <p className="mb-10">
                  <strong>Extramile Limited</strong>, a company incorporated in
                  Cyprus, company number ΗΕ 445953, whose registered office is
                  located at Prodromou, 75, ONEWORLD PARKVIEW HOUSE, Floor 4,
                  2063, Nicosia, Cyprus; or
                </p>
                <p className="mb-10">
                  <strong>Fulfilling Inc.</strong>, a Delaware corporation with
                  its registered address at 1007 N Orange St. 4th Floor Site
                  1382, Wilmington, New Castle, 19801.
                </p>
              </>
            )}
          </section>
          <section>
            <h2>BINDING ARBITRATION AND DISPUTE RESOLUTION NOTICE</h2>
            <p className="mb-10">
              These Terms include a Binding Arbitration Provision in Section 18
              that significantly impacts your legal rights. The arbitration
              provision mandates that any disputes or claims arising from or
              related to these Terms or our Services will be resolved
              exclusively through arbitration on an individual basis.
            </p>
            <p className="mb-10">Additional Key Provisions:</p>
            <p className="mb-10">
              <strong>CLASS ACTION WAIVER</strong>: Section 18 includes a waiver
              of your right to participate in class actions or other collective
              proceedings.
            </p>
            <p className="mb-10">
              <strong>DISCLAIMERS OF WARRANTIES</strong>: Section 10 contains
              disclaimers regarding warranties related to our Services.
            </p>
            <p className="mb-10">
              <strong>LIMITATION OF LIABILITY</strong>: Section 11 outlines the
              limitations on our liability for damages or losses.
            </p>
            <p className="mb-10">
              It is essential that you carefully review all sections of these
              Terms to fully understand your rights and obligations. By
              accessing, downloading, installing, or using any part of our
              Services, you confirm that you have read, understood, and agreed
              to be bound by these Terms.
            </p>
            <p className="mb-10">
              <strong>
                IF YOU DO NOT AGREE TO THESE TERMS (INCLUDING BUT NOT LIMITED TO
                BINDING ARBITRATION PROVISION), YOU MUST NOT ACCESS OR USE OUR
                SERVICES. CONTINUED USE OF THE SERVICES CONSTITUTES YOUR ONGOING
                AGREEMENT TO THESE TERMS.
              </strong>
            </p>
          </section>
          <section>
            <h2>CHANGES TO THESE TERMS</h2>
            <p className="mb-10">
              We may change, modify, or remove portions of these Terms at our
              sole discretion. If we make changes to these Terms, we may post
              the updated version within our Services and revise the "Effective
              Date" accordingly. At our discretion, we may also notify you of
              significant changes.
            </p>
            <p className="mb-10">
              If you do not agree with any updated Terms, you must stop using
              the Services, delete your account, or cancel your subscription
              before the Effective Date. Your continued use after changes are
              implemented constitutes your acceptance of the updated Terms. We
              encourage you to regularly review these Terms for updates.
            </p>
          </section>
          <section>
            <h2>IMPORTANT DISCLAIMERS</h2>
            <p className="mb-10">
              Our App is designed to provide tools, resources, and personalized
              guidance to support general wellness, healthy lifestyle choices,
              and self-care practices. By using our Services, you acknowledge
              and agree to the following disclaimers:
            </p>
            <p className="mb-10">
              <strong>For Informational Purposes Only</strong>. Our Services,
              including all content, recommendations, evaluations, and wellness
              plans, are provided solely for educational and general
              informational purposes. We are not licensed medical professionals
              and do not diagnose, treat, or prevent any medical,
              cardiovascular, or health conditions. Any insights, assessments,
              or lifestyle recommendations offered through the Services should
              not be considered professional medical advice, nor should they
              replace consultation with a qualified healthcare provider. Always
              seek professional guidance before making any health-related
              decisions.
            </p>
            <p className="mb-10">
              <strong>Personalized Recommendations and Results</strong>. Our
              Services use the data you provide to generate personalized
              insights and recommendations intended to support general wellness.
              While we strive for accuracy, these outputs are for informational
              purposes only and should not be relied upon as medical advice,
              diagnosis, or treatment. The App does not provide clinically
              validated measurements, nor does it replace professional medical
              assessments or expert guidance. Any trends, insights, or
              recommendations generated by the App are general in nature and may
              not account for your specific medical history, conditions, or risk
              factors.
            </p>
            <p className="mb-10">
              <strong>Accuracy</strong>. We do not guarantee the accuracy,
              completeness, or medical applicability of any information or
              features provided through our Services. While designed to support
              general wellness, our Services are not substitutes for clinically
              validated monitoring devices, certified medical instruments, or
              professional healthcare assessments. The accuracy of data may be
              affected by device and sensor limitations, user input errors,
              environmental factors, and physiological fluctuations. Users
              should not rely on our Services for medical decisions and should
              verify any health-related data using certified medical devices or
              consult a qualified healthcare professional.
            </p>
            <p className="mb-10">
              <strong>No Professional Medical or Healthcare Advice</strong>. Our
              App is not a medical device and does not provide medical advice,
              diagnosis, or treatment. The Services are intended solely for
              informational and general wellness purposes and should never be
              used as a substitute for professional medical assessments or
              prescribed treatments. Any measurements, insights, or
              recommendations within the App are not medical guidance and should
              not be relied upon for healthcare decisions.
            </p>
            <p className="mb-10">
              <strong>Contact a Healthcare Professional</strong>. Never rely
              solely on the insights or readings provided by our App when making
              medical decisions. If you experience irregular heart activity,
              symptoms of a medical condition, or a health emergency, seek
              immediate medical attention from a qualified healthcare provider.
              The App does not replace routine check-ups, prescribed treatments,
              or emergency interventions.
            </p>
            <p className="mb-10">
              <strong>No Physician-Patient Relationship</strong>. Your use of
              the App does not establish a physician-patient relationship, nor
              does it constitute medical supervision, diagnosis, or treatment.
              The Services are not a replacement for in-person medical
              consultations, professional medical care, or prescribed treatments
              and may not be appropriate for individuals with specific medical
              conditions or risks. Always consult a licensed healthcare
              professional before making any health-related decisions based on
              the information provided by the App.
            </p>
            <p className="mb-10">
              <strong>Third-Party Integrations</strong>. We do not assume
              liability for the accuracy, reliability, or performance of
              third-party integrations, including but not limited to wearable
              devices (e.g., smartwatches, fitness trackers), third-party health
              applications, or external software that sync with our Services.
              Any inaccuracies, malfunctions, or discrepancies in third-party
              data that impact App calculations or insights are beyond our
              control, and we disclaim any responsibility for errors, omissions,
              or inconsistencies arising from such integrations. Users should
              independently verify any health-related data obtained through
              third-party services.
            </p>
            {isHeartMonitorDomain && (
              <>
                <p className="mb-10">
                  <strong>Conversations with an AI Assistant</strong>. Our
                  Service includes an AI Assistant, a chatbot utilizing OpenAI
                  technology (e.g., GPT-4 API), designed to provide only general
                  guidance and informational support. All outputs generated are
                  fully automated and may occasionally contain inaccuracies,
                  hallucinations, or misleading content. The AI Assistant does
                  not have human oversight in real-time, and users should
                  exercise caution when relying on its responses.
                </p>
                <p className="mb-10">
                  Please note that we may process your interactions with the AI
                  Assistant, including conversations, to enhance and maintain
                  our Service, improve functionality, and develop new features.
                  This may also involve ensuring compliance with applicable
                  laws, enforcing our Terms and policies, and protecting the
                  safety and security of our App. You can read more about it in
                  our Privacy Policy.
                </p>
              </>
            )}
            <p className="mb-10">
              <strong>Your responsibility</strong>. You acknowledge and agree
              that we bear no liability for any claims, losses, liabilities,
              injuries, health complications, or damages, whether actual or
              alleged, resulting from your use, reliance on, or interpretation
              of any information or Content provided through our Services. Your
              decision to use the Services is entirely voluntary, and you assume
              full responsibility and all associated risks. If you have any
              concerns regarding your health or well-being, consult a qualified
              professional before relying on any information obtained through
              the Services.
            </p>
          </section>
          <section>
            <h2>REGISTRATION AND ELIGIBILITY</h2>
            <p className="mb-10">
              <strong>Age Restrictions</strong>. Our Services are intended for
              individuals aged 18 or older, or the minimum age required by
              applicable law. We do not knowingly allow children to register for
              or use our Services. If you become aware that a child is using our
              Services in violation of these Terms, please {contactLink}, and we
              will take necessary actions to address the issue.
            </p>
            <p className="mb-10">
              <strong>Access to the Services</strong>. To use and fully access
              our Services, you may be required to create an account (“
              <strong>Account</strong>”). By doing so, you agree to provide
              accurate, current, and complete information and to promptly update
              any changes to your information. You are responsible for ensuring
              that all activities performed through your Account represent your
              own authentic use of the Services.
            </p>
            <p className="mb-10">
              You accept full responsibility for any actions or activities
              conducted under your Account, whether through your computer,
              mobile device, or other means. Your login credentials, including
              your username and password, are strictly for personal use and must
              be kept secure and confidential. Sharing your login credentials
              with others is expressly prohibited, and any breach of this
              obligation may result in suspension or termination of your
              Account.
            </p>
            <p className="mb-10">
              If you suspect unauthorized access to your Account or any security
              breach, you must notify us immediately{' '}
              {isHeartMonitorDomain ? 'via' : 'at'}{' '}
              <a
                href={
                  isHeartMonitorDomain
                    ? `mailto:${SUPPORT_EMAIL_LINK}`
                    : CONTACT_FORM_LINK
                }
              >
                {isHeartMonitorDomain ? SUPPORT_EMAIL_LINK : CONTACT_FORM_LINK}
              </a>
              .
            </p>
            <p className="mb-10">
              If you create an Account or use our Services on behalf of another
              person or entity, you confirm that you have the authority to
              accept these Terms on their behalf.
            </p>
          </section>
          <section>
            <h2>SUBSCRIPTIONS</h2>
            <h3 className="mb-10">General Information</h3>
            <p className="mb-10">
              Certain features of the App are accessible exclusively through a
              subscription. We offer various subscription options that may
              include but are not limited to weekly, monthly, and annual
              subscription options. Subscriptions can be purchased via the App
              Store, Google Play, or directly from our website using a
              credit/debit card or PayPal. We may also provide different offers
              and challenges at a discounted rate.
            </p>
            <h2 className="mb-10">Trial Subscriptions</h2>
            <p className="mb-10">
              We may provide trial subscriptions, which can be either free or
              paid, allowing temporary access to our App. If a trial is
              available, this will be explicitly stated during the checkout
              process. If no such notice is provided, your subscription purchase
              will proceed without any trial period. We reserve the right to
              restrict your ability to participate in multiple trial offers.
            </p>
            <p className="mb-10">
              <strong>Automatic Conversion to Paid Subscription</strong>: Unless
              you cancel at least 24 hours before the trial ends, your payment
              method will be charged the subscription fee displayed during
              purchase for the selected subscription plan.
            </p>
            <h3 className="mb-10">Subscription Terms and Renewals</h3>
            <p className="mb-10">
              When you subscribe, your plan will renew automatically at the end
              of each billing cycle (weekly, monthly, annually, or as selected).
            </p>
            <p className="mb-10">
              <strong>Renewal Charges</strong>. Charges for renewal will be
              processed within 24 hours before the subscription term ends.
              Cancellations made less than 24 hours before renewal will take
              effect only after the subsequent term.
            </p>
            <p className="mb-10">
              <strong>Pricing Changes</strong>. We reserve the right to modify
              subscription fees at any time, to the fullest extent allowed by
              applicable laws. Advance notice of subscription fees changes may
              be sent to you, and the updated subscription fee will apply to the
              next billing cycle. Updated subscription fees will take effect at
              the start of the next subscription term after the change is
              implemented. By continuing to use the subscription after the
              updated pricing takes effect, you agree to the new subscription
              fees. If you do not accept the subscription fees change, you can
              cancel your subscription at least 24 hours before the end of the
              current subscription term to avoid further charges.
            </p>
            <p className="mb-10">
              <strong>Promotions</strong>. We may offer promotional discounts.
              These discounts apply only to the initial payment and do not
              affect future renewal fees.
            </p>
            <p className="mb-10">
              <strong>No Future Commitments</strong>. By subscribing, you agree
              that your purchase is not contingent on any future features,
              updates, or functionality, nor on any representations made by us.
            </p>
            <h3 className="mb-10">Billing</h3>
            <p className="mb-10">
              <strong>Payment Authorization</strong>. You authorize us to charge
              the payment method provided at checkout or at the end of any trial
              period. Payment options include credit/debit cards, PayPal, Apple
              Pay, Google Pay etc. If you purchase a subscription through the
              App Store or Google Play, the payment will be charged to your
              credit/debit card via your respective account upon confirmation of
              your subscription. Alternatively, if you subscribe through our
              websites (including but not limited to{' '}
              <a href={appLink}>{appLink}</a>
              ), the payment will be processed using your credit/debit card or
              PayPal account once you select a subscription and confirm your
              purchase.
            </p>
            <p className="mb-10">
              <strong>Non-Refundable Transactions</strong>. Except where
              applicable law provides otherwise or as outlined in these Terms,
              all payments are final, non-refundable, and non-transferable.
            </p>
            <p className="mb-10">
              <strong>Validation Fee</strong>. A small temporary validation
              charge may be applied to verify the validity of your payment
              method. This charge will be refunded immediately.
            </p>
            {isHeartMonitorDomain ? (
              <>
                <h3 className="mb-10">Refunds</h3>
                <p className="mb-10">
                  Refund eligibility depends on the platform used for the
                  purchase and is subject to specific conditions, as outlined
                  below. Refunds are not guaranteed and are assessed on a
                  case-by-case basis in accordance with applicable laws and
                  these Terms.
                </p>
                <p className="mb-10">
                  <strong>Website Purchases</strong>: Refunds for subscriptions
                  purchased directly through our websites are limited and may
                  only be granted in specific cases, such as where required by
                  law (e.g., statutory cooling-off periods in certain
                  jurisdictions) or confirmed technical bugs.
                </p>
                <p className="mb-10">
                  <strong>Confirmed Technical Bugs</strong>: In cases where a
                  verifiable and confirmed technical bug prevents the proper use
                  of the Service, a refund request may be considered. To
                  determine whether an issue qualifies as a bug, you must
                  provide us with all necessary technical details. Only after we
                  review the information and confirm that the issue is a bug
                  that materially affects the functionality of the Service may a
                  refund be considered.
                </p>
                <p className="mb-10">
                  <strong>App Store or Google Play Purchases</strong>: If you
                  purchased your subscription through the Apple App Store or
                  Google Play, refund requests must be directed to the
                  respective platform, as they manage all billing and refund
                  decisions independently.
                </p>
                <p className="mb-10">
                  <strong>U.S. Purchases</strong>: Residents of California or
                  Connecticut may cancel a subscription up until midnight of the
                  third business day following the date of purchase and receive
                  a full refund, provided there is no breach of these Terms.
                </p>
              </>
            ) : (
              <>
                <h3 className="mb-10">Refund Eligibility</h3>
                <p className="mb-10">
                  Refund eligibility depends on the platform used for the
                  purchase:
                </p>
                <p className="mb-10">
                  <strong>Website Purchases</strong>: Except where applicable
                  law provides otherwise or as outlined in these Terms, all
                  payments are final, non-refundable, and non-transferable.
                </p>
                <p className="mb-10">
                  <strong>App Store or Google Play Purchases</strong>:
                  Subscriptions purchased through the App Store or Google Play
                  are subject to their respective refund policies, and requests
                  should be directed to the appropriate support channels.
                </p>
                <p className="mb-10">
                  <strong>U.S. Purchases</strong>: Residents of California or
                  Connecticut may cancel a subscription purchase up until
                  midnight of the third business day following the date of
                  purchase and receive a full refund, provided there is no
                  breach of these Terms.
                </p>
              </>
            )}
            <h3 className="mb-10">Cancellation Policy</h3>
            <p className="mb-10">
              To prevent auto-renewal and avoid the billing of the subscription
              fees for the next subscription term, you must cancel your
              subscription before the current subscription term ends.{' '}
              <strong>Important Note</strong>: Uninstalling the App does not
              automatically cancel your subscription. To stop recurring charges,
              you must actively cancel the auto-renewal through your account
              settings.
            </p>
            <h3 className="mb-10">For Website Purchases:</h3>
            <p className="mb-10">
              a) cancel your subscription purchased on our website in your
              account:
            </p>
            <ul className="mb-10">
              <li className="mb-10">
                Open the “
                <a href="https://account.cardimate.com/login">Web Profile</a>”;
              </li>
              <li className="mb-10">Log into your Account;</li>
              <li className="mb-10">Navigate to the Subscriptions tab;</li>
              <li className="mb-10">Tap “Turn off auto-renewal”.</li>
            </ul>
            <p className="mb-10">
              b) cancel your subscription purchased on our website via sending a
              cancellation request via{' '}
              <a
                href={
                  isHeartMonitorDomain
                    ? `mailto:${SUPPORT_EMAIL_LINK}`
                    : CONTACT_FORM_LINK
                }
              >
                {isHeartMonitorDomain ? SUPPORT_EMAIL_LINK : 'Contact form'}
              </a>
            </p>
            <p className="mb-10">
              <strong>For App Store Purchases</strong>: If you subscribed or
              started a trial through the App Store, manage your subscription
              settings within your Apple Account. Ensure cancellation is
              completed at least 24 hours before the trial or current
              subscription term ends. For more information, visit{' '}
              <a href="https://support.apple.com/en-ca/118428">
                Apple’s support page on managing subscriptions
              </a>
              .
            </p>
            <p className="mb-10">
              <strong>For Google Play Purchases</strong>: If you subscribed or
              started a trial through Google Play, manage your subscription
              settings within your Google Account. Cancellation must be done at
              least 24 hours before the trial or current subscription term ends.
              Learn more about managing subscriptions on{' '}
              <a href="https://support.google.com/googleplay/answer/7018481?co=GENIE.Platform%3DAndroid&hl=en">
                Google’s support page
              </a>
              .
            </p>
            {!isHeartMonitorDomain && (
              <>
                <h3 className="mb-10">Refund conditions</h3>
                <p className="mb-10">
                  From time to time, we may offer special promotional offers
                  associated with your subscription. If you purchased a
                  subscription through our website (including but not limited to{' '}
                  <a href={appLink}>{appLink}</a>), you may qualify for a refund
                  by meeting all the conditions outlined below.
                </p>
                <p className="mb-10">
                  <strong>Eligibility and Completion Requirements</strong>. To
                  qualify for a refund, you must:
                </p>
                <p className="mb-10">
                  - Perform Heart Rate measurements daily for 30 consecutive
                  days within 30 days from the date of subscription purchase.
                </p>
                <p className="mb-10">
                  - Submit a screenshot of the "Measurements History - Last 30
                  Days" section in the App, clearly showing all logged daily
                  data.
                </p>
                <p className="mb-10">Here are a few illustrative cases:</p>
                <p className="mb-10">
                  <strong>Eligible</strong>: Subscription purchased on December
                  15; Heart Rate measurements performed daily from December 15
                  to January 13; screenshot of "Measurements History – Last 30
                  Days" submitted. Refund applicable.
                </p>
                <p className="mb-10">
                  <strong>Ineligible</strong>: Subscription purchased on
                  December 15; Heart Rate measurements performed for 15
                  consecutive days, missed one day, then continued for 15 more
                  days. Refund not applicable due to non-continuous completion.
                </p>
                <p className="mb-10">
                  <strong>Ineligible</strong>: Subscription purchased on
                  December 15; first measurement taken on January 5; completed
                  daily measurements for 30 days from that date. Refund not
                  applicable due to exceeding the 30-day period from the date of
                  purchase.
                </p>
                <p className="mb-10">
                  <strong>Submission of Refund Request</strong>. After
                  completing the required Heart Rate measurements, you must:
                </p>
                <p className="mb-10">
                  - Contact us within thirty (30) calendar days after the end of
                  the measurement period through the designated form at{' '}
                  {contactLink}.
                </p>
                <p className="mb-10">
                  - Provide the email address used for the subscription
                  purchase.
                </p>
                <p className="mb-10">
                  - Briefly describe the reason for your refund request.
                </p>
                <p className="mb-10">
                  - Attach a screenshot from the App showing your measurement
                  history for the last 30 days, as follows:
                </p>
                <p className="mb-10">
                  1) Open the "Measurements History" section in the App.
                </p>
                <p className="mb-10">
                  2) Select "Heart Rate" as the measurement type.
                </p>
                <p className="mb-10">3) Choose the "Last 30 Days" view.</p>
                <p className="mb-10">
                  4) Take a screenshot clearly displaying your logged daily
                  heart rate measurements for the full 30-day period.
                </p>
                <p className="mb-10">Here are a few illustrative cases:</p>
                <p className="mb-10">
                  <strong>Eligible</strong>: Subscription purchased on December
                  15; user starts taking daily Heart Rate measurements on
                  December 15 and logs data every day for 30 consecutive days.
                  On January 14, the user submits a refund request via{' '}
                  {contactLink}, providing the email used for purchase, a valid
                  refund description, and a screenshot of "Measurements History"
                  showing logged data for all 30 days. Refund applicable.
                </p>
                <p className="mb-10">
                  <strong>Ineligible</strong>: Subscription purchased on Dec 15;
                  Challenge started on Dec 25; completed for 28 consecutive
                  days. Contacted us via {contactLink}, but failed to attach
                  required screenshots or submitted incorrect screenshots.
                  Refund is not applicable as the required visual proof of
                  Challenge completion was not submitted correctly.
                </p>
                <p className="mb-10">
                  <strong>Ineligible</strong>: Subscription purchased on
                  December 15; user completes all 30 days of measurements but
                  submits a refund request 40 days after completion instead of
                  within 30 days. Refund is not applicable due to late
                  submission.
                </p>
                <p className="mb-10">
                  <strong>Ineligible</strong>: Subscription purchased on
                  December 15; user submits a refund request via an email
                  instead of {contactLink}, or fails to provide the required
                  screenshot. Refund is not is not applicable due to improper
                  submission.
                </p>
                <h3 className="mb-10">Additional Provisions:</h3>
                <p className="mb-10">
                  Refund amount is capped at USD 60.00. Refunds are processed to
                  the original payment method.
                </p>
                <p className="mb-10">
                  REFUND APPLIES ONLY TO THE FIRST SUBSCRIPTION PAYMENT.
                  SUBSEQUENT PAYMENTS, INCLUDING RENEWALS OR ADDITIONAL OFFERS,
                  ARE NON-REFUNDABLE.
                </p>
                <p className="mb-10">
                  AUTO-RENEWAL REMAINS ACTIVE UNLESS EXPLICITLY CANCELED BEFORE
                  THE RENEWAL DATE SPECIFIED IN YOUR CONFIRMATION EMAIL.
                </p>
                <p className="mb-10">
                  YOU ARE ELIGIBLE FOR THE REFUND ONLY ONCE. RE-PURCHASING A
                  SUBSCRIPTION AFTER RECEIVING A REFUND DISQUALIFIES YOU FROM
                  FURTHER PARTICIPATION.
                </p>
                <p className="mb-10">
                  VIOLATION OF ANY TERMS OR RULES ASSOCIATED WITH THE OFFER OR
                  THESE TERMS MAY RESULT IN DISQUALIFICATION FROM A REFUND.
                </p>
              </>
            )}
          </section>
          <section>
            <h2>YOUR USE OF THE SERVICES</h2>
            <p className="mb-10">
              <strong>Your Representations and Warranties</strong>. By accessing
              or using the Service, you represent, warrant and agree that:
            </p>
            <p className="mb-10">
              a) You have the legal capacity to enter into these Terms and agree
              to comply with them fully.
            </p>
            <p className="mb-10">
              b) You are at least 18 years of age or the age of majority in your
              jurisdiction, if higher.
            </p>
            <p className="mb-10">
              c) All information you provide to us in connection with your use
              of the Service is true, accurate, and complete.
            </p>
            <p className="mb-10">
              d) You will not use the Service for any illegal or unauthorized
              purposes and will ensure your use complies with all applicable
              laws and regulations, including those of your jurisdiction.
            </p>
            <p className="mb-10">
              e) You will not misuse the Service or any Content published by us
              or third parties.
            </p>
            <p className="mb-10">
              f) You are not listed on any U.S. Government list of prohibited or
              restricted parties, including but not limited to the U.S.
              Department of the Treasury’s Specially Designated Nationals and
              Blocked Persons List or the U.S. Department of Commerce Denied
              Persons List or Entity List.
            </p>
            <p className="mb-10">
              g) You are not an individual or associated with any entity
              designated under the UK’s Terrorist Asset-Freezing etc. Act 2010
              (TAFA 2010) or any equivalent legislation.
            </p>
            <p className="mb-10">
              h) You agree to comply with all applicable export and re-export
              control laws and regulations, including those of the United
              States, the United Kingdom, and any other relevant jurisdictions.
              This includes restrictions on the transfer of goods, software
              (including the Services), technology, and services.
            </p>
            <p className="mb-10">
              i) The availability and functionality of the App depend on the
              third-party platform from which you downloaded it (e.g., the Apple
              App Store or Google Play Store, collectively, "
              <strong>App Stores</strong>"). You agree to pay any applicable
              fees required by the App Store and to comply with its terms,
              conditions, and policies. The App Stores and their subsidiaries
              are third-party beneficiaries of these Terms and may enforce them
              to the extent necessary to protect their rights.
            </p>
            <p className="mb-10">
              <strong>Your Permitted Use of the Services</strong>. We allow you
              to use the Service exclusively for your personal, non-commercial
              purposes, as intended and defined by us. Any use of the Service
              for commercial activities, or any purpose not expressly authorized
              or approved by us, is strictly prohibited.
            </p>
            <p className="mb-10">
              <strong>Your Prohibited Use of Services</strong>. You agree not to
              use the Service, directly or indirectly, for any unlawful or
              unauthorized purposes, or in a manner that violates these Terms,
              including but not limited to:
            </p>
            <p className="mb-10">
              i. developing, operating, or assisting in the development or
              operation of a product, service, or software that competes with or
              serves as a substitute for the Service;
            </p>
            <p className="mb-10">
              ii. engaging in activities that could damage, disable, overburden,
              or impair the functionality of the Service or its underlying
              systems, or interfere with another user’s access to or enjoyment
              of the Service;
            </p>
            <p className="mb-10">
              iii. conducting any fraudulent, deceptive, or harmful activities,
              including uploading or distributing viruses, worms, trojan horses,
              or other malicious code designed to disrupt or harm the Service,
              users, or third parties;
            </p>
            <p className="mb-10">
              iv. copying, adapting, altering, modifying, translating, or
              creating derivative works based on the Service or any part
              thereof, without prior written permission from us;
            </p>
            <p className="mb-10">
              v. engaging in unauthorized framing of or linking to the Service,
              or embedding it within another service without our permission;
            </p>
            <p className="mb-10">
              vi. reverse engineering, decompiling, disassembling, or otherwise
              attempting to extract source code or underlying ideas from the
              Service, except as explicitly permitted by applicable law;
            </p>
            <p className="mb-10">
              vii. attempting to bypass, disable, or interfere with security
              features or technological protections of the Service;
            </p>
            <p className="mb-10">
              viii. removing, obscuring, or altering any copyright, trademark,
              or other proprietary notices contained within the Service;
            </p>
            <p className="mb-10">
              ix. using the Service for any revenue-generating, commercial, or
              promotional purpose without our express written approval;
            </p>
            <p className="mb-10">
              x. using the Service or any data, Content, or features from the
              Services to diagnose, treat, cure, or mitigate any health
              conditions or medical issues without our express written approval;
            </p>
            <p className="mb-10">
              xi. sharing, sublicensing, assigning, selling, renting, leasing,
              or otherwise transferring your access to the Service or its
              Content to others without authorization;
            </p>
            <p className="mb-10">
              xii. conducting, facilitating, authorizing, or permitting any text
              or data mining or web scraping activities in connection with our
              Services. This prohibition includes, but is not limited to, the
              use of any automated tools, including “robots”, “bots”, “spiders”,
              “scrapers”, or similar programs, algorithms, or processes to: (i)
              access, obtain, copy, monitor, or republish any portion of the
              website, its content, data, or Services; or (ii) analyze text or
              data in digital form to derive patterns, trends, correlations, or
              other insights. This clause constitutes an express reservation of
              our rights concerning text and data mining or web scraping. Where
              applicable law prevents us from restricting or excluding such
              activities by contract, this clause shall only apply to the extent
              permitted by law.
            </p>
            <p className="mb-10">
              <strong>Right to Refuse or Terminate Access</strong>. If any
              information you provide is found to be untrue, inaccurate, not
              current, or incomplete, or if you violate these Terms, we reserve
              the right to immediately suspend or terminate your access to the
              Service, including any current or future subscriptions, without
              prior notice or liability.
            </p>
          </section>
          <section>
            <h2>LIMITED LICENSE TO THE SERVICE</h2>
            <p className="mb-10">
              <strong>Content</strong>. All text, images, graphics, designs,
              icons, marks, logos, data, software, and other information
              displayed on or used to operate the Service (collectively, the "
              <strong>Content</strong>"), are proprietary to us or third
              parties. All rights in and to the Content are reserved by us,
              except where expressly granted to you under these Terms. No
              additional rights, whether express or implied, are conferred.
              Except as expressly permitted in these Terms, you may not copy,
              modify, store, distribute, reproduce, create derivative works
              based on, reverse engineer, decompile, attempt to discover the
              source code of any part of the Service or Content, or otherwise
              exploit the Content or any part of the Service. The provision of
              the Service does not transfer or assign any rights, title, or
              interest in the Content to you or any third party. Any use of our
              software, trademarks, service marks, trade names, logos, domain
              names, or other proprietary brand features or Content requires our
              prior written consent.
            </p>
            <p className="mb-10">
              <strong>License</strong>. We grant you a limited, revocable,
              non-transferable, non-sublicensable, and non-exclusive license to
              access and use the Service solely for your personal,
              non-commercial purposes.
            </p>
          </section>
          <section>
            <h2>LICENSE TO USER-GENERATED CONTENT</h2>
            <p className="mb-10">
              <strong>Ownership of User-Generated Content</strong>. You retain
              all rights, title, and interest in any User-Generated Content you
              post, share, or log through the Service. User-Generated Content
              includes, but is not limited to, text, graphics, messages,
              comments, suggestions, photos, videos, reviews, or other materials
              you upload, submit, or transmit via the Service. We are not
              responsible for the accuracy, quality, or legality of
              User-Generated Content posted by users. Interactions between you
              and other users are solely your responsibility. We accept no
              liability for any disputes, damages, or losses arising from these
              interactions. We may, at our discretion, remove User-Generated
              Content without notice and are not obligated to retain or restore
              any removed User-Generated Content, nor are we liable for any loss
              caused by its removal.
            </p>
            <p className="mb-10">
              <strong>
                Your Acknowledgement in relation to User-Generated Content
              </strong>
              . You acknowledge that your relationship with us is not
              confidential, fiduciary, or otherwise special, and submitting
              User-Generated Content does not create any obligations for us
              beyond those outlined in these Terms, Privacy Policy or your
              selected privacy settings. User-Generated Content you provide is
              not subject to any duty of confidentiality on our part, and we are
              not responsible for any use or disclosure of such User-Generated
              Content, except as specified in our Terms, Privacy Policy or your
              privacy preferences.
            </p>
            <p className="mb-10">
              <strong>License Grant</strong>. By providing User-Generated
              Content through the Service, you grant us a non-exclusive,
              worldwide, royalty-free, sublicensable, and transferable license
              to use, copy, modify, adapt, translate, reformat, display,
              distribute, create derivative works from, and otherwise exploit
              your User-Generated Content and your name, username and likeness
              in connection with the operation, improvement, and promotion of
              the Service. Personal data, as defined by applicable privacy laws
              and regulations, is explicitly excluded from the scope of the
              license granted by you under these Terms.
            </p>
            <p className="mb-10">
              <strong>Revocation of License</strong>. If you wish to revoke the
              license for specific User-Generated Content, you may contact us.
              Upon receipt of your request, we will take reasonable steps to
              remove and cease using the specified User-Generated Content,
              subject to applicable laws and technical limitations. The Company
              may retain copies of your User-Generated Content for purposes
              reasonably necessary to operate the Service, as permitted under
              these Terms and our Privacy Policy.
            </p>
          </section>
          <section>
            <h2>WARRANTY DISCLAIMER</h2>
            <p className="mb-10">
              <strong>General Disclaimer</strong>. The Service, including the
              App and all related Content, features, and functionality, is
              provided on an "as is" and "as available" basis, without any
              representations, warranties, or guarantees of any kind, express or
              implied. To the fullest extent permitted by law, we disclaim all
              warranties, including but not limited to:
            </p>
            <p className="mb-10">
              a) Implied warranties of merchantability, fitness for a particular
              purpose, and non-infringement;
            </p>
            <p className="mb-10">
              b) Warranties arising from course of performance, trade, or usage;
              and
            </p>
            <p className="mb-10">
              c) Warranties regarding the accuracy, reliability, or availability
              of the Service.
            </p>
            <p className="mb-10">
              We and our directors, employees, agents, representatives, and
              suppliers, do not guarantee that:
            </p>
            <p className="mb-10">
              i. The Service will be secure, uninterrupted, error-free, or
              available at any specific time or location;
            </p>
            <p className="mb-10">
              ii. Any defects or errors will be corrected;
            </p>
            <p className="mb-10">
              iii. The Service or its content will be free of viruses, malware,
              or other harmful components;
            </p>
            <p className="mb-10">
              iv. The results obtained from using the Service will meet your
              expectations; or
            </p>
            <p className="mb-10">
              v. Content or communications provided by third parties or users
              through the Service will be accurate, reliable, or complete.
            </p>
            <p className="mb-10">
              Your use of the Service is entirely at your own risk.
            </p>
            <h3 className="mb-10">Additional Disclaimers.</h3>
            <p className="mb-10">
              We reserve the right, at our sole discretion, to modify, update,
              suspend, or discontinue the Service or any part thereof at any
              time without prior notice. We are not liable for any disruptions
              or losses resulting from such actions. Furthermore, we are under
              no obligation to screen or monitor any Content, and do not
              guarantee that any Content available on the Service will be
              suitable for all users or remain available for any specific
              duration. Certain features or Content may not be available in your
              region and may vary by jurisdiction.
            </p>
            <p className="mb-10">
              The Service is not intended to provide financial, medical, legal,
              or other professional advice. Any information provided through the
              Service is for informational purposes only and should not be
              relied upon as a substitute for professional consultation.
            </p>
            <p className="mb-10">
              You are solely responsible for your interactions with other users
              and for any reliance on Content accessed through the Service. We
              disclaim all liability for disputes, damages, or losses arising
              from such interactions.
            </p>
            <p className="mb-10">
              <strong>Consumer Rights</strong>. For consumers in the{' '}
              <strong>
                European Union, United Kingdom, Liechtenstein, Switzerland,
                Norway, or Iceland,
              </strong>{' '}
              the Service will conform to the agreed description, functionality,
              and performance at the time of purchase or download, including
              compatibility with your device. We will provide necessary updates,
              including security updates, as required by EU laws, and you are
              responsible for installing these updates promptly. Failure to
              install updates may impair the Service’s performance, and we are
              not liable for resulting issues. If the Service does not conform
              to these Terms, you have the right to request defect correction
              within a reasonable time or, if the issue persists, to receive a
              price reduction or refund. You agree to cooperate by providing
              necessary information about your digital environment to resolve
              issues. Liability for defects is valid for the legally required
              duration under EU consumer protection laws.
            </p>
            <p className="mb-10">
              <strong>Limitations by Jurisdiction</strong>. Some jurisdictions
              do not allow the exclusion or limitation of implied warranties or
              certain damages. In such cases, the disclaimers and limitations in
              these Terms will apply to the fullest extent permitted by law.
            </p>
          </section>
          <section>
            <h2>LIMITATION OF LIABILITY</h2>
            <p className="mb-10">
              TO THE FULLEST EXTENT PERMITTED BY LAW, WE, INCLUDING OUR
              OFFICERS, DIRECTORS, AGENTS, AFFILIATES, EMPLOYEES,
              REPRESENTATIVES, SUPPLIERS, PARTNERS, ADVERTISERS, AND DATA
              PROVIDERS, SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL,
              SPECIAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES, OR FOR ANY
              LOSS OF PROFITS, REVENUE, BUSINESS, USE, DATA, OR GOODWILL. SUCH
              EXCLUSIONS APPLY REGARDLESS OF WHETHER THE LIABILITY ARISES FROM
              CONTRACT, TORT (INCLUDING NEGLIGENCE), EQUITY, STATUTE, OR ANY
              OTHER LEGAL THEORY, EVEN IF WE HAVE BEEN ADVISED OF THE
              POSSIBILITY OF SUCH DAMAGES. THE TOTAL AGGREGATE LIABILITY OF US
              TO YOU FOR ALL CLAIMS ARISING UNDER OR RELATED TO THESE TERMS OR
              YOUR USE OF THE SERVICES SHALL NOT EXCEED THE TOTAL AMOUNT OF FEES
              YOU HAVE ACTUALLY PAID TO US FOR USE OF THE SERVICES DURING THE
              TWELVE (12) MONTHS IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO
              THE CLAIM.
            </p>
            <p className="mb-10">
              NOTHING IN THESE TERMS SHALL EXCLUDE OR LIMIT OUR LIABILITY FOR
              DEATH OR PERSONAL INJURY CAUSED BY NEGLIGENCE, FRAUD OR FRAUDULENT
              MISREPRESENTATION, OR ANY OTHER LIABILITY THAT CANNOT BE EXCLUDED
              OR LIMITED UNDER APPLICABLE LAW. THESE LIMITATIONS ARE SUBJECT TO
              JURISDICTIONAL VARIATIONS, AND SOME JURISDICTIONS MAY NOT ALLOW
              CERTAIN EXCLUSIONS OR LIMITATIONS OF LIABILITY. IN SUCH CASES, THE
              EXCLUSIONS AND LIMITATIONS SET OUT IN THESE TERMS SHALL APPLY TO
              THE FULLEST EXTENT PERMITTED BY LAW.
            </p>
          </section>
          <section>
            <h2>INDEMNITY</h2>
            <p className="mb-10">
              To the fullest extent permitted by applicable law, you agree to
              indemnify, defend, and hold harmless us, including our
              subsidiaries, affiliates, successors, officers, directors,
              employees, agents, contractors, partners, licensors, and
              representatives (collectively, the “
              <strong>Indemnified Parties</strong>”), from and against any
              claims, liabilities, damages, losses, costs, and expenses
              (including reasonable attorneys’ fees) arising out of or related
              to:
            </p>
            <p className="mb-10">
              a) Your use or misuse of the Services or User-Generated Content;
            </p>
            <p className="mb-10">
              b) Your violation of these Terms or any applicable policies;
            </p>
            <p className="mb-10">
              c) Your violation of the rights of any third party, including
              intellectual property rights, privacy rights, or other proprietary
              rights;
            </p>
            <p className="mb-10">
              We reserve the right, at your expense, to assume the exclusive
              defense and control of any matter subject to indemnification under
              these Terms. You agree to cooperate fully with our defense of such
              claims. You may not settle any claim without our prior written
              consent, and we will make reasonable efforts to notify you of any
              claim, action, or proceeding as soon as we become aware of it.
            </p>
          </section>
          <section>
            <h2>THIRD-PARTY SERVICES</h2>
            <p className="mb-10">
              The Services may include links to third-party websites,
              applications, advertisements, or other resources ("
              <strong>Third-Party Services</strong>"). These Third-Party
              Services are provided for your convenience, but we do not control,
              endorse, review, or assume any responsibility for their content,
              accuracy, or suitability. Your access to and use of any
              Third-Party Services is at your own risk and subject to the terms
              and privacy policies of the respective third parties.
            </p>
            <p className="mb-10">
              We disclaim all liability for any damages, losses, or issues
              arising from your use of or reliance on Third-Party Services,
              including but not limited to:
            </p>
            <p className="mb-10">
              a) Any transactions, payments, or delivery of goods or services
              made between you and the third party;
            </p>
            <p className="mb-10">
              b) Any inaccuracies or inappropriate material in the content,
              products, or advertisements offered by the third party;
            </p>
            <p className="mb-10">
              c) Any sharing of links by you or others that direct to
              Third-Party Services.
            </p>
            <p className="mb-10">
              You further acknowledge that our Services may include
              advertisements, promotions, or sponsored content, which may appear
              in various formats and locations. We may not always explicitly
              identify such content as advertising. The manner and extent of
              advertisements are subject to change without notice.
            </p>
            <p className="mb-10">
              You are solely responsible for assessing whether accessing or
              engaging with Third-Party Services is appropriate. This includes
              taking steps to protect your personal data and complying with any
              applicable third-party agreements.
            </p>
          </section>
          <section>
            <h2>YOUR PRIVACY</h2>
            <p className="mb-10">
              Your personal data is processed by us in connection with your use
              of the Services in compliance with our Privacy Policy. This policy
              provides detailed information on how we collect, handle, store,
              and disclose your data and your rights under data protection laws.
            </p>
            <p className="mb-10">
              Information collected through cookies and similar technologies is
              addressed in our Cookie Policy, which explains their purpose, how
              third parties may use them, and how you can manage your cookie
              preferences.
            </p>
            <p className="mb-10">
              The Privacy and Cookie Policies are incorporated by reference into
              these Terms. Should any inconsistencies arise between these Terms
              and the Privacy or Cookie Policy, the provisions of these Terms
              shall govern.
            </p>
          </section>
          <section>
            <h2>COMMUNICATION AND CUSTOMER SUPPORT</h2>
            <p className="mb-10">
              <strong>Support</strong>. If you have any questions, or concerns,
              or require assistance regarding our Services, you may reach out to
              us using the contact information provided in the “
              <strong>INTRODUCTION</strong>” section of these Terms. While we
              are not legally obligated to provide customer support, we may, at
              our sole discretion, choose to offer support services to users
              when necessary. Such support, if provided, is offered as a
              courtesy and does not create any ongoing obligation or entitlement
              to future support. We will make reasonable efforts to respond
              promptly to your inquiries; however, we do not guarantee a
              specific response time.
            </p>
            <p className="mb-10">
              <strong>Feedback</strong>. We welcome your feedback. You will be
              prompted to submit comments, suggestions, ideas, feedback,
              ratings, materials, or other information ("
              <strong>Feedback</strong>") to us through the App. You hereby
              agree that all Feedback that you disclose or submit to us through
              the App is voluntary, non-confidential, and becomes and remains
              our property. Your disclosure or submission of Feedback to us
              constitutes an assignment to us of all rights, title, and interest
              in and to the Feedback. We may disclose or use any Feedback as we
              see fit without any compensation to you.
            </p>
          </section>
          <section>
            <h2>REPORTING COPYRIGHT INFRINGEMENT</h2>
            <p className="mb-10">
              We are committed to responding to any alleged copyright
              violations, should they occur.
            </p>
            <p className="mb-10">
              If you believe that your content has been copied in a way that
              constitutes copyright infringement, you or your agent may submit a
              Digital Millennium Copyright Act («<strong>DMCA</strong>»)
              notification to us. If we find that there has been an alleged or
              actual infringement, we will take appropriate action under the
              DMCA and other intellectual property laws.
            </p>
            <p className="mb-10">
              Alternatively, you can send the notice to us via{' '}
              <a
                href={
                  isHeartMonitorDomain
                    ? `mailto:${SUPPORT_EMAIL_LINK}`
                    : CONTACT_FORM_LINK
                }
              >
                {isHeartMonitorDomain ? SUPPORT_EMAIL_LINK : CONTACT_FORM_LINK}
              </a>{' '}
              or contact us by mail or post.
            </p>
            <p className="mb-10">
              Such notice must be in writing and contain the following
              information:
            </p>
            <p className="mb-10">
              a) your address, telephone number, and e-mail address;
            </p>
            <p className="mb-10">
              b) a description of the copyrighted work or other intellectual
              property that you claim has been infringed (including infringing
              and source URL);
            </p>
            <p className="mb-10">
              c) information sufficient to permit us to locate the alleged
              infringing work. If possible, please provide links to the exact
              location so that we may quickly and more easily locate the
              material or link;
            </p>
            <p className="mb-10">
              d) include the following statement: «I have a good faith belief
              that use of the copyrighted materials described above as allegedly
              infringing is not authorized by the copyright owner, its agent, or
              the law»;
            </p>
            <p className="mb-10">
              e) include the following statement: «I swear, under penalty of
              perjury, that the information in the notification is accurate and
              that I am the copyright owner or am authorized to act on behalf of
              the owner of an exclusive right that is allegedly infringed»;
            </p>
            <p className="mb-10">
              f) an electronic or physical signature of the person authorized to
              act on behalf of the owner of the copyright.
            </p>
          </section>
          <section>
            <h2>TERMINATION</h2>
            <p className="mb-10">
              <strong>Term</strong>. These Terms take effect from the date you
              first access or use our Services and will remain in force until
              terminated in accordance with this Section.
            </p>
            <p className="mb-10">
              <strong>Termination by Us</strong>. We may terminate these Terms,
              suspend or restrict your access to the Services, or delete your
              Account immediately upon providing notice if:
            </p>
            <p className="mb-10">
              a) You have used the Services in violation of these Terms;
            </p>
            <p className="mb-10">
              b) Unauthorized access or activity is detected on your Account or
              related to your use of the Services;
            </p>
            <p className="mb-10">
              c) We decide, at our sole discretion, to withdraw the Services (in
              whole or in part);
            </p>
            <p className="mb-10">
              d) We are required to do so due to a legal or regulatory
              obligation;
            </p>
            <p className="mb-10">
              e) You request the deletion of your Account or personal data; or
            </p>
            <p className="mb-10">
              f) For any other lawful reason, provided we have given you
              reasonable advance notice via email, or any electronic
              communication within the Services.
            </p>
            <p className="mb-10">
              We reserve the right to block your access to the Services to
              prevent re-registration or unauthorized access if your account has
              been suspended, terminated, or deleted
            </p>
            <p className="mb-10">
              <strong>Termination by You</strong>. You may terminate these Terms
              at any time by ceasing to use the Services. If you have purchased
              a subscription, please refer to Section 6 of these Terms for
              detailed instructions on how to cancel your subscription properly
              to avoid future billing.
            </p>
            <p className="mb-10">
              <strong>Effect of Termination</strong>. Upon termination of these
              Terms:
            </p>
            <p className="mb-10">
              i. All licenses granted to you under these Terms will immediately
              terminate;
            </p>
            <p className="mb-10">
              Upon termination of these Terms, all provisions that by their
              nature are intended to survive termination shall remain in effect.
              This includes, but is not limited to, provisions relating to
              Sections 8, 9, 10, 11, 12, 14, 18, 20 and any other provisions
              necessary to enforce rights or obligations that accrued prior to
              termination.
            </p>
          </section>
          <section>
            <h2>DISPUTE RESOLUTION BY BINDING ARBITRATION</h2>
            <p className="mb-10">
              THIS SECTION OUTLINES YOUR RIGHTS AND OBLIGATIONS REGARDING
              DISPUTE RESOLUTION. PLEASE READ IT CAREFULLY, AS IT INCLUDES
              MANDATORY BINDING ARBITRATION. THIS SECTION SHALL BE REFERRED TO
              AS THE "ARBITRATION AGREEMENT"
            </p>
            <p className="mb-10">
              <strong>
                For Users in the European Union, United Kingdom, Liechtenstein,
                Switzerland, Norway, or Iceland
              </strong>
              . If you reside in the European Union, United Kingdom,
              Liechtenstein, Switzerland, Norway, or Iceland, any Disputes
              arising out of or relating to these Terms, including their
              interpretation, enforcement, or validity, shall be governed by and
              construed in accordance with the laws of your country of
              residence. The courts of your usual place of residence shall have
              exclusive jurisdiction over any Disputes (as defined herein) that
              are not resolved through the arbitration agreement outlined in
              these Terms.
            </p>
            <p className="mb-10">
              <strong>
                For Users in the United States or Other Jurisdictions
              </strong>
              . If you reside in the United States or any other jurisdiction not
              listed above, any Disputes arising out of or relating to these
              Terms shall be resolved exclusively through the arbitration
              agreement provided herein, and the governing law shall be
              determined as specified in the arbitration clause.
            </p>
            <p className="mb-10">
              By accessing or using our Services, you agree that any disputes,
              claims, or controversies arising out of or relating to these
              Terms, the breach, termination, enforcement, interpretation, or
              validity thereof, or your use of the Services (collectively, "
              <strong>Disputes</strong>"), will be resolved solely by binding,
              individual arbitration (i.e. not in a class, representative or
              consolidated action or proceeding), unless expressly provided
              otherwise in this Section or to the extent that the applicable law
              prohibits the exclusive use of arbitration for dispute resolution.
              This includes any claims against our affiliates, subsidiaries,
              employees, officers, agents, or licensors.
            </p>
            <p className="mb-10">
              Notwithstanding the above, the following exceptions apply:
            </p>
            <p className="mb-10">
              <strong>Small Claims Court</strong>: Either party retains the
              right to bring an individual claim in small claims court for
              disputes or claims within the jurisdictional limits of such a
              court.
            </p>
            <p className="mb-10">
              <strong>Injunctive or Equitable Relief</strong>: Either party may
              seek injunctive or equitable relief in a court of competent
              jurisdiction to protect or enforce intellectual property rights,
              including copyrights, trademarks, trade secrets, patents, or
              similar proprietary rights.
            </p>
            <h3 className="mb-10">
              AGREEMENT TO WAIVE JURY TRIAL AND CLASS ACTION
            </h3>
            <p className="mb-10">
              BY ACCEPTING THESE TERMS, YOU EXPRESSLY AGREE, AND WE AGREE, TO
              WAIVE THE RIGHT TO A TRIAL BY JURY AND THE RIGHT TO PARTICIPATE IN
              ANY CLASS ACTION, COLLECTIVE, OR REPRESENTATIVE PROCEEDING. THE
              WAIVER OF CLASS ACTIONS AND COLLECTIVE RELIEF IS A FUNDAMENTAL
              COMPONENT OF THIS ARBITRATION AGREEMENT. ALL CLAIMS MUST BE
              BROUGHT ON AN INDIVIDUAL BASIS. YOU ACKNOWLEDGE AND AGREE THAT
              THIS ARBITRATION AGREEMENT LIMITS YOUR RIGHT TO APPEAL AND OTHER
              RESOLUTION PROCESSES.
            </p>
            <h3 className="mb-10">
              Arbitration Procedure and Applicable Rules
            </h3>
            <p className="mb-10">
              You further agree that all disputes between you and us will be
              resolved exclusively through binding arbitration before a single,
              neutral arbitrator, administered by the American Arbitration
              Association (“<strong>AAA</strong>”) under its Commercial
              Arbitration Rules and the Supplementary Procedures for
              Consumer-Related Disputes (the “<strong>AAA Rules</strong>”) then
              in effect, except as modified herein.
            </p>
            <p className="mb-10">
              <strong>Place of Arbitration</strong>. The arbitration proceedings
              shall be held in Delaware, United States, unless the parties agree
              to an alternative location.
            </p>
            <p className="mb-10">
              <strong>Governing Law</strong>. The arbitration shall be conducted
              in accordance with the laws of the State of Delaware, consistent
              with the Federal Arbitration Act, including any applicable
              statutes of limitations and recognized legal privileges.
            </p>
            <p className="mb-10">
              <strong>Language of Arbitration</strong>. The arbitration will be
              conducted in the English language.
            </p>
            <p className="mb-10">
              <strong>Commencing Arbitration</strong>. To initiate arbitration,
              you must adhere to the procedures outlined by the AAA Rules, which
              are accessible on this{' '}
              <a href="https://www.adr.org/Support">page</a>.
            </p>
            <p className="mb-10">
              <strong>Filing Deadline</strong>. By accessing or using our
              Services, you agree that any Dispute (as defined herein) must be
              initiated by filing a demand for arbitration or commencing an
              individual legal action within one (1) year from the date the
              party asserting the Dispute first becomes aware, or reasonably
              should have become aware, of the act, omission, or event giving
              rise to the Dispute. Except where prohibited by applicable law,
              any Dispute not initiated within this one-year period will be
              permanently barred, and no remedy shall be available for such
              untimely Disputes.
            </p>
            <p className="mb-10">
              <strong>Selection of the Arbitrator</strong>. The arbitrator
              responsible for adjudicating the dispute will be selected in
              accordance with the procedures specified in the AAA Rules.
            </p>
            <p className="mb-10">
              <strong>Hearing Requirements</strong>. For claims of $10,000 or
              less, the arbitration will generally proceed on the basis of
              written submissions from the parties, unless a hearing is
              requested by you or deemed necessary by the arbitrator. For claims
              exceeding $10,000, the need for a hearing will be determined by
              the AAA Rules. The arbitrator shall have the discretion to oversee
              and regulate the exchange of information between the parties. No
              party or representative shall engage in ex-parte communications
              with the arbitrator. All communications must be made in the
              presence of, or with notice to, all parties involved in the
              arbitration.
            </p>
            <p className="mb-10">
              <strong>Judgment and Finality of Award</strong>. The arbitrator
              shall deliver the award within the timeline prescribed by the AAA
              Rules. The award may be enforced in any court with appropriate
              jurisdiction. The arbitration award shall be final and binding,
              and there shall be no appeal. Any damages awarded by the
              arbitrator must conform to the limitations specified in the
              applicable “<strong>Limitation of Liability</strong>” section,
              including restrictions on the types and amounts of damages
              recoverable.
            </p>
            <p className="mb-10">
              <strong>Remedies</strong>. The arbitrator shall have no authority
              to award punitive or other damages not measured by the prevailing
              party's actual damages, except as may be required by statute.
            </p>
            <p className="mb-10">
              <strong>Individual Relief Only</strong>. The arbitrator may award
              relief solely in favor of the individual party seeking relief and
              only to the extent necessary to resolve that party's individual
              claim. The arbitrator shall have no authority to award relief to
              or against anyone who is not a party to the arbitration.
              Furthermore, the arbitrator may not consolidate multiple parties'
              claims or preside over any form of class, joint, or representative
              proceeding.
            </p>
            <p className="mb-10">
              <strong>Fees</strong>. Your obligation to cover any filing,
              administrative, or arbitrator fees shall be governed exclusively
              by the fee schedule outlined in the AAA Rules.
            </p>
            <p className="mb-10">
              <strong>Assessment of Fees</strong>. The prevailing party shall be
              entitled to an award of reasonable attorney fees. The allocation
              of forum fees and expenses shall be determined by the arbitrator.
            </p>
            <p className="mb-10">
              <strong>Confidentiality</strong>. Except as may be required by
              law, neither a party nor an arbitrator may disclose the existence,
              content, or results of any arbitration hereunder without the prior
              written consent of both parties.
            </p>
            <p className="mb-10">
              <strong>Opt-Out Option</strong>. You may opt out of this
              arbitration agreement within 30 days of accepting these Terms by
              submitting your notice{' '}
              {isHeartMonitorDomain ? 'via' : 'via our online contact form at'}{' '}
              <a
                href={
                  isHeartMonitorDomain
                    ? `mailto:${SUPPORT_EMAIL_LINK}`
                    : CONTACT_FORM_LINK
                }
              >
                {isHeartMonitorDomain ? SUPPORT_EMAIL_LINK : CONTACT_FORM_LINK}
              </a>
              , stating your intention to opt out. Opting out will not affect
              other provisions of these Terms.
            </p>
            <p className="mb-10">
              {isHeartMonitorDomain ? (
                <>
                  <strong>Severability</strong>. If any portion of this
                  arbitration agreement is deemed invalid or unenforceable, that
                  portion shall be severed, and the remaining provisions shall
                  remain in full force and effect. However, if the waiver of
                  class actions and collective relief is found unenforceable,
                  the arbitration shall proceed on an individual basis only to
                  the fullest extent permitted by law.
                </>
              ) : (
                <>
                  <strong>Severability</strong>. If any portion of this
                  arbitration agreement is deemed invalid or unenforceable, it
                  shall be severed, and the remaining provisions shall remain in
                  full effect, except that if the waiver of class actions and
                  collective relief is found unenforceable, this arbitration
                  agreement shall be null and void.
                </>
              )}
            </p>
            <p className="mb-10">
              <strong>Survival</strong>. Except stated otherwise, this
              arbitration agreement shall survive termination of these Terms.
            </p>
          </section>
          <section>
            <h2>WITHDRAWAL AND ALTERNATIVE DISPUTE RESOLUTION</h2>
            <p className="mb-10">
              <strong>Your Right to Withdraw</strong>. If you are a consumer
              based in the European Union, the United Kingdom, Liechtenstein,
              Switzerland, Norway, or Iceland, you have a statutory right to
              withdraw from contracts for online purchases of services within 14
              days of entering into the contract without providing a reason,
              unless specific conditions apply as detailed below.
            </p>
            <p className="mb-10">
              <strong>Exceptions to the Right of Withdrawal</strong>. When
              purchasing digital content online, including single-use items, you
              agree that the content will be available to you immediately. By
              consenting to immediate access, you acknowledge that you waive
              your right of withdrawal and are not eligible for a refund. When
              you purchase a subscription to access our Services, which are
              provided on a continuous basis, your access to the Services begins
              immediately upon completing the purchase. By consenting to the
              immediate commencement of your Subscription, you acknowledge and
              agree to waive your statutory right of withdrawal.
            </p>
            <p className="mb-10">
              <strong>Exercising Your Right of Withdrawal</strong>. To exercise
              your right of withdrawal (where applicable), you must send an
              unequivocal statement of your intention to withdraw from the
              contract (i.e., these Terms). This can be done by:
            </p>
            <p className="mb-10">
              Sending a written notice by post to one of the following
              addresses:
            </p>
            <p className="mb-10">
              Gismart Limited, 151 Wardour Street, London, England, W1F 8WE
            </p>
            <p className="mb-10">
              Extramile Limited, Prodromou, 75, ONEWORLD PARKVIEW HOUSE, Floor
              4, 2063, Nicosia, Cyprus
            </p>
            <p className="mb-10">
              Fulfilling Inc., 1007 N Orange St., 4th Floor, Ste 1382,
              Wilmington, New Castle, DE 19801, USA
            </p>
            <p className="mb-10">
              Submitting your notice{' '}
              {isHeartMonitorDomain ? 'via' : 'via our online contact form at'}{' '}
              <a
                href={
                  isHeartMonitorDomain
                    ? `mailto:${SUPPORT_EMAIL_LINK}`
                    : CONTACT_FORM_LINK
                }
              >
                {isHeartMonitorDomain ? SUPPORT_EMAIL_LINK : CONTACT_FORM_LINK}
              </a>
              .
            </p>
            <p className="mb-10">
              Your withdrawal notice must be sent no later than 24 hours before
              the expiration of the withdrawal period. Unless otherwise waived,
              the withdrawal period expires 14 days from the day you entered
              into the contract. While not mandatory, you may use the following
              template to exercise your right of withdrawal:
            </p>
            <p className="mb-10">
              I/we* hereby withdraw from the contract concluded by me/us* for
              the purchase of the following goods*/the provision of the
              following service*:
            </p>
            <p className="mb-10">Ordered on*/received on*:</p>
            <p className="mb-10">Name of the consumer(s):</p>
            <p className="mb-10">Address of the consumer(s):</p>
            <p className="mb-10">Date:</p>
            <p className="mb-10">*Delete as applicable.</p>
            <p className="mb-10">
              <strong>Refunds</strong>. If you exercise your right of
              withdrawal, we will deduct a proportionate amount corresponding to
              the Service you have used up to the time of notification. Refunds
              will be processed using the same payment method used for the
              original transaction, unless agreed otherwise.
            </p>
            <h3 className="mb-10">Alternative Dispute Resolution (ADR)</h3>
            <p className="mb-10">
              In the event of a dispute arising from or relating to these Terms,
              you and the Company will first attempt to resolve the matter
              through negotiations. If no resolution is reached within 30 days
              of written notice to negotiate, the parties may voluntarily
              explore a good-faith resolution through an agreed alternative
              dispute resolution (ADR) procedure. Participation in ADR is not
              mandatory and is entirely at the discretion of each party. If ADR
              is unsuccessful within 60 days, or if a party refuses to
              participate, the dispute may proceed to arbitration. European
              consumers (including those in Norway, Switzerland, Iceland, or
              Liechtenstein) may use the European Commission’s Online Dispute
              Resolution (ODR) platform for disputes related to online
              contracts. The platform is accessible at{' '}
              <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage">
                ODR Platform Link
              </a>
              .
            </p>
          </section>
          <section>
            <h2>MISCELLANEOUS</h2>
            <p className="mb-10">
              <strong>Governing Law</strong>. These Terms shall be governed by
              and construed in accordance with the laws of the State of
              Delaware, U.S. without regard to its conflict of laws principles,
              and excluding the 1980 UN Convention on Contracts for the
              International Sale of Goods.
            </p>
            <p className="mb-10">
              <strong>Entire Agreement</strong>. These Terms constitute the
              entire agreement between you and us regarding your access to and
              use of the Services and supersede all prior agreements,
              understandings, or representations, whether written or oral.
            </p>
            <p className="mb-10">
              <strong>No Waiver</strong>. Failure or delay by us to exercise any
              right or enforce any provision under these Terms shall not operate
              as a waiver of such right or provision. A waiver will only be
              effective if explicitly stated in writing and signed by an
              authorized representative.
            </p>
            <p className="mb-10">
              <strong>Severability</strong>. If any provision of these Terms is
              found to be invalid or unenforceable by a court of competent
              jurisdiction, that provision shall be modified or severed to the
              minimum extent necessary to comply with the law, without affecting
              the enforceability of the remaining provisions.
            </p>
            <p className="mb-10">
              <strong>Assignment</strong>. You may not assign or transfer your
              rights or obligations under these Terms without our prior written
              consent. We may assign or transfer our rights and obligations
              under these Terms at any time and without prior notice, including
              via novation. Posting an updated version of these Terms
              identifying the new party shall serve as valid notice of such
              transfer. These Terms are binding and will inure to the benefit of
              both parties and their respective successors, assigns, and legal
              representatives.
            </p>
            <p className="mb-10">
              <strong>Electronic Communications</strong>. By interacting with
              the Services or communicating with us electronically, you consent
              to receive communications electronically and agree that such
              communications, including notices, disclosures, and agreements,
              have the same legal effect as those provided in writing. You
              acknowledge that your electronic submissions constitute a legally
              binding agreement.
            </p>
            <p className="mb-10">
              <strong>Force Majeure</strong>. We shall not be held liable for
              any delay or failure to perform our obligations under these Terms
              due to causes beyond our reasonable control, including but not
              limited to natural disasters, governmental actions, labor
              disputes, or disruptions in telecommunications.
            </p>
            <p className="mb-10">
              <strong>Third-Party Rights</strong>. Except as expressly stated
              herein, these Terms are intended solely for the benefit of the
              parties and do not create any rights for third-party
              beneficiaries.
            </p>
            <p className="mb-10">
              <strong>Headings and Construction</strong>. Headings in these
              Terms are provided for convenience only and shall not affect their
              interpretation.
            </p>
          </section>
        </article>
      </main>
      <footer className="footer">
        <p className="footer__content">© 2025 All Rights Reserved.</p>
      </footer>
    </>
  )
}
