import styled from 'styled-components'

import { SelectPlanBlockVariant1 } from 'modules/subscriptions/components/common/SelectPlanBlockVariant1'

import { Button } from 'components/Button'

import checkIcon from 'assets/images/check-icon-wavy-borders-green.svg'
import middleRefundBg from 'assets/images/middle-refund-bg.png'
import topRefundBg from 'assets/images/top-refund-bg.png'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color, MediaBreakpoint } from 'root-constants/common'

type TContentProps = {
  paddingTop?: number
  paddingBottom?: number
}
type TRefundTextProps = {
  fontWeight?: number
}
type TButtonProps = {
  marginBottom: number
}
export const StyledSubscriptionsVariant6 = {
  Wrapper: styled.div`
    ${basePageStyles};
  `,
  Content: styled.div<TContentProps>`
    ${baseColumnStyles};
    padding-bottom: ${({ paddingBottom }) => paddingBottom}px;
    padding-top: ${({ paddingTop }) => paddingTop}px;
  `,
  Congratulations: styled.p`
    margin-bottom: 12px;
    color: ${Color.DANGER};
    text-align: center;
    font-size: 14px;
    font-weight: 800;
    line-height: 20px;
  `,
  Title: styled.h3`
    margin-bottom: 12px;
    text-align: center;
    font-size: 22px;
    font-weight: 900;
    line-height: 30px;
  `,
  Disclaimer: styled.p`
    margin-bottom: 24px;
    color: ${Color.LIGHT_TEXT};
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
  `,
  Benefits: styled.div`
    display: flex;
    margin-bottom: 45px;
    padding: 24px 16px 16px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 16px;
    background-color: ${Color.WHITE};
    box-shadow: 0 8px 20px 0 #dfe3f3;
  `,
  ImageContainer: styled.div`
    aspect-ratio: 295/256;
    width: 100%;

    img {
      border-radius: 8px;
    }
  `,
  ListItem: styled.li`
    margin-bottom: 16px;
    padding-left: 32px;
    background-image: url(${checkIcon});
    background-repeat: no-repeat;
    background-position: 0 2px;
    color: ${Color.LIGHT_TEXT};
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
  `,
  SelectPlanBlock: styled(SelectPlanBlockVariant1)`
    margin-bottom: 12px;
  `,
  Button: styled(Button)<TButtonProps>`
    margin-bottom: ${({ marginBottom }) => marginBottom}px;
  `,
  CancelInfo: styled.p`
    margin-bottom: 32px;
    color: ${Color.LIGHT_TEXT};
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  `,
  TopRefundBannerWrapper: styled.div`
    max-width: 327px;
    width: 100%;
    border-radius: 12px;
    background: url(${topRefundBg}) no-repeat center;
    background-size: contain;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 26px 16px 26px 10px;
    margin-bottom: 32px;

    img {
      height: 60px;
    }
  `,
  RefundText: styled.p<TRefundTextProps>`
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: ${Color.WHITE};

    strong {
      font-size: 14px;
      font-weight: ${({ fontWeight }) => fontWeight || 800};
      line-height: 20px;
      color: ${Color.WHITE};
    }
  `,
  MiddleRefundBanner: styled.div`
    max-width: 327px;
    width: 100%;
    border-radius: 12px;
    background: url(${middleRefundBg}) no-repeat center;
    background-size: contain;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 24px 12px 12px;
    margin-bottom: 32px;

    img {
      width: 60px;
      height: 60px;
    }
  `,
  RefundContentContainer: styled.div`
    display: flex;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    width: 100%;
    padding: 40px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 24px;
    background: #d8f0e4;
    margin-bottom: 32px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  Header: styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    width: 100%;
  `,
  PlanProgram: styled.div`
    padding: 6px 16px;
    border-radius: 16px;
    background: linear-gradient(273deg, #01cda1 33.19%, #02ac63 94.54%);
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-transform: uppercase;
    color: ${Color.WHITE};
  `,
  RefundTitle: styled.h3`
    font-size: 22px;
    font-weight: 900;
    line-height: 30px;
  `,
  HeaderText: styled.p`
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: ${Color.LIGHT_TEXT};
    text-align: center;

    strong {
      font-size: 16px;
      font-weight: 800;
      line-height: 24px;
      color: ${Color.DEFAULT_TEXT};
    }
  `,
  RefundConditionsContainer: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
  `,
  RefundConditionsWrapper: styled.div`
    width: 100%;
    padding: 16px;
    border-radius: 16px;
    background: ${Color.WHITE};
    box-shadow: 0 8px 20px 0 #d4ebe0;
    display: flex;
    gap: 16px;

    img {
      width: 40px;
      height: 40px;
    }
  `,
  ConditionTextWrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
  `,
  ConditionsTitle: styled.h3`
    font-size: 16px;
    font-weight: 800;
    line-height: 24px;
  `,
  ConditionsText: styled.p`
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: ${Color.LIGHT_TEXT};

    strong {
      font-size: 14px;
      font-weight: 800;
      line-height: 20px;
      color: ${Color.DEFAULT_TEXT};
    }

    a {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: ${Color.DANGER};
      text-decoration: none;
    }
  `,
  PromoText: styled.p`
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    color: ${Color.LIGHT_TEXT};
    text-align: center;

    a {
      font-size: 13px;
      font-weight: 500;
      line-height: 16px;
      color: ${Color.LIGHT_TEXT};
      text-decoration: underline;
    }
  `,
}
