import styled, { css } from 'styled-components'

import { commonPlanItemStyles } from 'modules/subscriptions/styles'

import { Color } from 'root-constants/common'

export const commonTextStyles = css`
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: ${Color.LIGHT_TEXT};
`
export const StyledPlanItemLong = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
  `,
  PlanItem: styled.div`
    ${commonPlanItemStyles};
    border-radius: 12px;
    border: 1px solid #dce0ee;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: start;

    ::before {
      top: 16px;
      right: 16px;
      left: auto;
      bottom: 0;
      margin: 0;
    }

    &[data-is-default='true'] {
      ::after {
        border-radius: 4px;
        background: ${({ theme }) => theme.colors.discountBadge};
        line-height: 18px;
        padding: 4px 8px;
      }
    }
  `,
  Title: styled.div`
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 8px;
  `,
  Subtitle: styled.div`
    ${commonTextStyles};
    line-height: 18px;
    margin-bottom: 16px;
  `,
  OldPriceDaily: styled.div`
    ${commonTextStyles};
    color: ${({ theme }) => theme.colors.oldPrice};
    text-decoration: line-through;
    margin-bottom: 8px;
  `,
  PlanPriceDaily: styled.div`
    font-size: 24px;
    font-weight: 800;
    line-height: 22px;
    margin-bottom: 12px;

    span {
      ${commonTextStyles};
    }
  `,
  TotalPriceWrapper: styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 12px;
    margin-bottom: 8px;
  `,
  OldPriceMonthly: styled.div`
    ${commonTextStyles};
    color: ${({ theme }) => theme.colors.oldPrice};
    text-decoration: line-through;
  `,
  PlanPriceMonthly: styled.div`
    ${commonTextStyles};
  `,
  CommonText: styled.div`
    ${commonTextStyles};
  `,
}
