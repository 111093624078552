import styled from 'styled-components'

import discountBadge from 'assets/images/red-discount-badge.png'

import { Color } from 'root-constants/common'

type TRadioButtonProps = {
  checkIcon: string
}
type TTitleProps = {
  fontWeight: number
  hasOverSizeFont: boolean
}
type TPriceProps = {
  hasOverSizeFont: boolean
}

export const StyledUpsellPlanItemVariant1 = {
  Wrapper: styled.div`
    border-radius: 16px;
    border: 2px solid #e9e9e9;
    background: ${Color.WHITE};
    box-shadow: 0 8px 20px 0 #e5e5ec;
    padding: 16px;
    position: relative;

    &[data-is-selected='true'] {
      border: 2px solid ${Color.DANGER};
      background: rgba(248, 59, 0, 0.1);
    }
  `,
  TitleWrapper: styled.div`
    display: flex;
    align-items: center;
  `,
  Title: styled.h3<TTitleProps>`
    font-size: ${({ hasOverSizeFont }) => (hasOverSizeFont ? 14 : 16)}px;
    font-weight: ${({ fontWeight }) => fontWeight || 800};
    line-height: 24px;
    letter-spacing: 0.5px;
    margin-left: 12px;
  `,
  PriceWrapper: styled.div`
    display: flex;
    flex-direction: column;
    margin-left: auto;
    align-items: end;
  `,
  Price: styled.p<TPriceProps>`
    font-size: ${({ hasOverSizeFont }) => (hasOverSizeFont ? 12 : 16)}px;
    font-weight: 800;
    line-height: 24px;
    margin-left: auto;
  `,
  RadioButton: styled.div<TRadioButtonProps>`
    width: 24px;
    height: 24px;
    background-image: url(${({ checkIcon }) => checkIcon});
    background-repeat: no-repeat;
    border: none;
    background-color: transparent;

    &[data-is-selected='true'] {
      background-image: url(${({ checkIcon }) => checkIcon});
    }
  `,
  DiscountBadge: styled.div`
    background-image: url(${discountBadge});
    background-size: contain;
    width: 90px;
    height: 90px;
    align-self: center;
    position: absolute;
    bottom: 10px;
    right: 90px;
    background-repeat: no-repeat;
    text-align: center;
    text-shadow: 0 3px 5px rgba(27, 28, 44, 0.15);
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    color: ${Color.WHITE};
    padding-right: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
  `,
  OldPrice: styled.p<TPriceProps>`
    color: ${Color.LIGHT_TEXT};
    font-size: ${({ hasOverSizeFont }) => (hasOverSizeFont ? 10 : 14)}px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    text-decoration: line-through;
    text-decoration-color: ${Color.LIGHT_TEXT};
  `,
  DescriptionWrapper: styled.div`
    display: flex;
    gap: 12px;
    align-items: start;
    margin-top: 16px;

    img {
      width: 72px;
      height: 72px;
    }
  `,
  DescriptionText: styled.p`
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: ${Color.LIGHT_TEXT};
  `,
}
