import React, { InputHTMLAttributes, useState } from 'react'

import { StyledInput as S } from './Input.styles'

type TProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'required'> & {
  hasVisibilityControl?: boolean
  labelName?: string
  isValid?: boolean
  validationText?: string
  type?: string
  inputRef?: React.Ref<HTMLInputElement>
}

export const Input: React.FC<TProps> = ({
  labelName = '',
  hasVisibilityControl = false,
  isValid = true,
  validationText = '',
  type = 'text',
  inputRef,
  ...props
}) => {
  const [isVisible, setIsVisible] = useState(false)

  const getInputType = () => {
    if (!hasVisibilityControl) return type

    return isVisible ? 'text' : 'password'
  }

  return (
    <S.Wrapper
      data-valid={isValid}
      data-validation-text={validationText}
      data-has-visibility-control={hasVisibilityControl}
      data-has-label={!!labelName}
    >
      {labelName && <S.Label>{labelName}</S.Label>}
      <S.Input required type={getInputType()} {...props} ref={inputRef} />
      {hasVisibilityControl && (
        <S.VisibilityButton
          type="button"
          onClick={() => setIsVisible((prevValue) => !prevValue)}
          data-visible={isVisible}
        />
      )}
    </S.Wrapper>
  )
}
