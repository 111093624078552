import styled, { css } from 'styled-components'

import { Color } from 'root-constants/common'

type TMainPriceValueProps = {
  isDiscount?: boolean
}

const commonTotalTextStyles = css`
  font-size: 14px;
  font-weight: 800;
  line-height: 20px;
`

export const StyledPriceInfoWithDynamicDiscount = {
  Wrapper: styled.div`
    margin-bottom: 32px;
  `,
  MainPriceContainer: styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 16px;
    padding: 16px 0;
    border-top: 1px solid #c6c6c8;
    border-bottom: 1px solid #c6c6c8;
  `,
  MainPriceText: styled.p`
    flex: 50%;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
  `,
  MainPriceValue: styled.p<TMainPriceValueProps>`
    font-size: 13px;
    line-height: 18px;
    color: ${({ isDiscount }) =>
      isDiscount ? Color.DANGER : Color.DEFAULT_TEXT};
    font-weight: ${({ isDiscount }) => (isDiscount ? 800 : 500)};
  `,
  TotalPerDayContainer: styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
    padding: 8px 12px;
    border-radius: 8px;
    background-color: rgba(1, 205, 161, 0.08);
  `,
  TotalPerDayText: styled.p`
    ${commonTotalTextStyles};
  `,
  TotalPerDayPrice: styled.p`
    ${commonTotalTextStyles};
    color: #0fc398;
    text-align: right;
    font-weight: 800;
  `,
  TotalContainer: styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 32px;
  `,
  TotalText: styled.p`
    margin-bottom: 8px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 800;
  `,
  TotalPrice: styled.p`
    flex: 75%;
    margin-bottom: 8px;
    color: ${Color.LIGHT_TEXT};
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: right;

    strong {
      color: ${Color.DEFAULT_TEXT};
      font-weight: 800;
    }
  `,
  TotalDiscount: styled.p`
    flex: 1;
    color: ${Color.DANGER};
    font-size: 13px;
    font-weight: 700;
    line-height: 18px;
    text-align: right;
  `,
}
