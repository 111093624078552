import React, { useLayoutEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useIsHealthConditionSkippablePage } from 'hooks/useIsHealthConditionSkippablePage'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'

import { replaceHistory } from 'browser-history'
import {
  BLOOD_PRESSURE_OPTION_VALUES,
  HEALTH_CONDITION_OPTION_VALUES,
} from 'root-constants/common'
import { CustomPageId } from 'root-constants/pages'

import { StyledBloodPressure as S } from './BloodPressure.styles'
import { PAGE_NAME } from './constants'

const QUESTION = `What's your average blood pressure (mmHg)?`
export const BloodPressureVariant1: React.FC<TPageProps> = ({
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId: CustomPageId.BLOOD_PRESSURE,
    pageName: PAGE_NAME,
    question: QUESTION,
    nextPagePath,
  })

  const isPageSkippable = useIsHealthConditionSkippablePage(
    HEALTH_CONDITION_OPTION_VALUES.HIGH_BLOOD_PRESSURE,
  )

  const optionProps = {
    name: CustomPageId.BLOOD_PRESSURE,
    type: OptionType.RADIO,
    onChange: (value: string) => {
      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
    },
  }

  useLayoutEffect(() => {
    if (isPageSkippable) {
      replaceHistory(nextPagePath)
    }
  }, [isPageSkippable, nextPagePath])

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>
          <Trans i18nKey="onboarding.bloodPressure.question" />
        </S.Title>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={BLOOD_PRESSURE_OPTION_VALUES.DONT_KNOW}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`actions.doNotKnow`}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={BLOOD_PRESSURE_OPTION_VALUES.LESS_120_LESS_80}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>
              {BLOOD_PRESSURE_OPTION_VALUES.LESS_120_LESS_80}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={BLOOD_PRESSURE_OPTION_VALUES.BETWEEN_120_129_AND_LESS_80}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>
              {BLOOD_PRESSURE_OPTION_VALUES.BETWEEN_120_129_AND_LESS_80}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={BLOOD_PRESSURE_OPTION_VALUES.BETWEEN_130_139_AND_80_89}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>
              {BLOOD_PRESSURE_OPTION_VALUES.BETWEEN_130_139_AND_80_89}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={BLOOD_PRESSURE_OPTION_VALUES.MORE_140_MORE_90}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>
              {BLOOD_PRESSURE_OPTION_VALUES.MORE_140_MORE_90}
            </QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </S.Wrapper>
  )
}
