/* eslint-disable max-lines */
import { PageId } from 'root-constants/pages'

export const enum CurrentEnvironment {
  DEV = 'dev',
  PROD = 'prod',
  STAGE = 'stage',
}

export const enum RequestMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  HEAD = 'HEAD',
  OPTIONS = 'OPTIONS',
  PATCH = 'PATCH',
}

export const enum StripeFieldName {
  NAME = 'name',
  NUMBER = 'cardNumber',
  EXPIRY = 'cardExpiry',
  CVC = 'cardCvc',
}

export const enum PaymentSystem {
  STRIPE = 'stripe',
  PAYPAL = 'paypal',
  PRIMER = 'primer',
}

export const enum PaymentMethod {
  CREDIT_CARD = 'credit_card',
  APPLE_PAY = 'apple_pay',
  GOOGLE_PAY = 'google_pay',
  PAYPAL = 'pay_pal',
}

export const BASE_COLUMN_WIDTH = 328
export const HEADER_HEIGHT = 48
export const SENIOR_FLOW_HEADER_HEIGHT = 52

export const CONTACT_FORM_LINKS = {
  [CurrentEnvironment.DEV]: 'https://account-cardimate.gidev.xyz/contact-form',
  [CurrentEnvironment.PROD]: 'https://account.cardimate.com/contact-form',
  [CurrentEnvironment.STAGE]:
    'https://account-cardimate.gistage.com/contact-form',
}

export const EMAIL_REGEXP =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const enum LoginMethod {
  EMAIL = 'email',
}

export const enum LoginMethodRaw {
  EMAIL = 'password',
}

export const LOGIN_METHOD_RAW_MAP = {
  [LoginMethodRaw.EMAIL]: LoginMethod.EMAIL,
}

export const enum ProductKey {
  ONE_WEEK = 'oneWeek',
  THREE_MONTHS = 'threeMonths',
  SIX_MONTHS = 'sixMonths',
  ONE_YEAR = 'oneYear',
  ONE_WEEK_SOULDOUT = 'oneWeek_soldout',
}

export const enum FirebaseSignUpErrorCode {
  EMAIL_EXISTS = 'auth/email-already-in-use',
}

export const enum Color {
  BLUE = '#5f96f7',
  DEFAULT_TEXT = '#2c2f54',
  DARK_TEXT = '#0a324d',
  BLUE_DARK = '#17202a',
  LIGHT_GRAY = '#b2B8cd',
  MIDDLE_GRAY = '#8188A3',
  LIGHT_TEXT = '#565976',
  GRAY_80 = '#626262',
  ERROR = '#fd417f',
  WHITE = '#fff',
  DANGER = '#ff0000',
  RED = '#e74c3c',
  GRAY_100 = '#2C3131',
}

export const enum MediaBreakpoint {
  MIN_PHONE = 321,
  MAX_PHONE = 431,
}

export const enum TimeInterval {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export const enum EventLoggerInstanceName {
  GIA = 'gia-amplitude',
  AMPLITUDE = 'amplitude',
  USER_FLOW_TRACKER = 'user-flow-tracker',
}

export const enum PlatformOS {
  ANDROID = 'Android',
  IOS = 'iOS',
  UNKNOWN = 'unknown',
}

export const enum AppWithInAppBrowser {
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
}

export const enum PagePath {
  LOCALHOST = 'localhost',
  SUBSCRIPTIONS = 'subscriptions',
}

export const enum Gender {
  MALE = 'male',
  FEMALE = 'female',
  OTHER = 'other',
}

export const enum SubscriptionListType {
  PURCHASE = 'purchase',
  UPSELL = 'upsell',
}

export const enum SubscriptionTag {
  TAX = 'tax',
  NO_TAX = 'no_tax',
  CANCEL_OFFER = 'cancel',
  NO_TAX_SOLD_OUT = 'no_tax_sold_out',
  SIX_MONTHS = 'six_months',
  ONE_YEAR = 'one_year',
}

export const enum Currency {
  USD = 'usd',
  EUR = 'eur',
  AUD = 'aud',
  CAD = 'cad',
  GBP = 'gbp',
  MXN = 'mxn',
  NZD = 'nzd',
}

export const CURRENCY_SYMBOLS = {
  [Currency.USD]: '$',
  [Currency.EUR]: '€',
  [Currency.AUD]: 'AU$',
  [Currency.CAD]: 'C$',
  [Currency.GBP]: '£',
  [Currency.MXN]: 'MXN',
  [Currency.NZD]: 'NZ$',
}

export const COUNTRIES_WITH_IMPERIAL_MEASUREMENT_SYSTEM: string[] = [
  'AU',
  'CA',
  'GB',
  'NZ',
  'US',
  'ZA',
]

export const enum Cohort {
  CARDIMATE_2 = 'cardimate2',
  CARDIMATE_9 = 'cardimate9',
  CARDIMATE_11 = 'cardimate11',
  CARDIMATE_14 = 'cardimate14',
  CARDIMATE_15 = 'cardimate15',
  CARDIMATE_16 = 'cardimate16',
  CARDIMATE_16_PROMO = 'cardimate16_promo',
  CARDIMATE_16_CANCEL = 'cardimate16_cancel',
  CARDIMATE_17 = 'cardimate17',
  CARDIMATE_18 = 'cardimate18',
  CARDIMATE_19 = 'cardimate19',
  CARDIMATE_19_FEED = 'cardimate19_feed',
  CARDIMATE_19_UPDATE = 'cardimate19_update',
  CARDIMATE_20 = 'cardimate20',
  CARDIMATE_21 = 'cardimate21',
}

export const TRIAL_COHORTS_WITH_TOTAL_AMOUNT: string[] = [
  Cohort.CARDIMATE_9,
  Cohort.CARDIMATE_14,
  Cohort.CARDIMATE_15,
  Cohort.CARDIMATE_17,
  Cohort.CARDIMATE_20,
  Cohort.CARDIMATE_21,
]

export const COHORTS_WITH_SENIOR_FLOW: string[] = [
  Cohort.CARDIMATE_11,
  Cohort.CARDIMATE_14,
]

export const COHORTS_WITH_BURGER_MENU: string[] = [
  Cohort.CARDIMATE_2,
  Cohort.CARDIMATE_11,
  Cohort.CARDIMATE_14,
  Cohort.CARDIMATE_15,
  Cohort.CARDIMATE_16,
  Cohort.CARDIMATE_16_PROMO,
  Cohort.CARDIMATE_17,
  Cohort.CARDIMATE_19,
  Cohort.CARDIMATE_20,
  Cohort.CARDIMATE_21,
]

export const PAGES_WITH_BURGER_MENU: PageId[] = [
  PageId.INTRO_4,
  PageId.INTRO_5,
  PageId.AGE_RANGE_1,
  PageId.AGE_RANGE_3,
  PageId.AGE_RANGE_4,
]

export const enum Language {
  EN = 'en',
  ES = 'es',
  IN_CONTEXT = 'af',
}

export const INITIAL_LANGUAGE = Language.EN
export const SUPPORTED_LOCALES: string[] = [Language.EN, Language.ES]

export const TEST_ENV_QUERY_PARAM = 'is_test_environment'
export const HEADLINE_QUERY_PARAM = 'headline'
export const LANG_QUERY_PARAM = 'lang'
export const APP_NAME = 'cardimate'
export const PAGE_TRANSITION_DELAY = 500
export const CENTS_IN_DOLLAR = 100
export const INITIAL_PRICE = 0
export const CUSTOM_TOKEN_LOCAL_STORAGE_KEY = 'CUSTOM_TOKEN'
export const DEFAULT_CURRENCY = Currency.USD
export const NO_NAME_AB_SEGMENT = 'no_name'
export const NO_GROWTH_BOOK_TEST = 'no_test'
export const EXPERIMENT_ID_NOT_SET = 'experiment_id_not_set'
export const STATIC_HEART_AGE = 13

export const enum Goal {
  HEART_ATTACK_PREVENTION = 'heart_attack_prevention',
  BLOOD_PRESSURE = 'blood_pressure',
  LOSE_WEIGHT = 'lose_weight',
  LOWER_CHOLESTEROL = 'lower_cholesterol',
  HEART_HEALTH = 'heart_health',
  HEART_RATE = 'heart_rate',
  REDUCE_STRESS = 'reduce_stress',
}

export const enum BMICategory {
  UNDERWEIGHT = 'underweight',
  NORMAL = 'normal',
  OVERWEIGHT = 'overweight',
  OBESE = 'obese',
}

export const PUBLIC_PAGES: PageId[] = [
  PageId.EMAIL_1,
  PageId.EMAIL_2,
  PageId.SUMMARY_2,
  PageId.SUMMARY_HEART_PLAN,
  PageId.SUMMARY_BLOOD_PRESSURE_PLAN,
  PageId.SUMMARY_TEST_RESULTS,
  PageId.SUMMARY_HEALTH_PROFILE,
  PageId.SUMMARY_PRESSURE_ANALYSES,
  PageId.SUMMARY_HEART_SCORE,
  PageId.SUMMARY_HEALTH_METRICS,
]

export const NO_TRANSITION_PAGES: PageId[] = [PageId.EMAIL_1, PageId.EMAIL_2]

export const GENDER_PAGES: PageId[] = [
  PageId.INTRO_4,
  PageId.GENDER_1,
  PageId.GENDER_3,
  PageId.KIT_GENDER_1,
]
export const COMMON_OPTION_VALUES = {
  YES: 'yes',
  NO: 'no',
  DONT_KNOW: 'dont_know',
  OFTEN: 'quite_often',
  SOMETIMES: 'sometimes',
  RARELY: 'rarely',
  NEVER: 'never',
  MAYBE: 'maybe',
  OCCASIONALLY: 'occasionally',
  FREQUENTLY: 'frequently',
  ALWAYS: 'always',
  PREFER_NOT_TO_ANSWER: 'prefer_not_to_answer',
  NOT_SURE: 'not_sure',
}
export const HEALTH_CONDITION_OPTION_VALUES = {
  HIGH_BLOOD_PRESSURE: 'high_blood_pressure',
  CHOLESTEROL: 'high_cholesterol',
  HIGH_HEART_RATE: 'high_heart_rate',
  NONE: 'none_of_the_above',
}

export const SLEEP_OPTION_VALUES = {
  LESS_THAN_6: 'less_than_6_hours',
  AVERAGE: '6_8_hours',
  MORE_THAN_8: 'more_than_8_hours',
  VARIES: 'varies',
}

export const BLOOD_PRESSURE_OPTION_VALUES = {
  DONT_KNOW: 'dont_know',
  LESS_120_LESS_80: '<120 / <80',
  BETWEEN_120_129_AND_LESS_80: '120–129 / <80',
  BETWEEN_120_129_AND_80: '120–129/80',
  BETWEEN_130_139_AND_80_89: '130–139 / 80–89',
  MORE_140_MORE_90: '>140 / >90',
}

export const VAT_INCLUDED_COUNTRIES: string[] = [
  'ca',
  'us',
  'au',
  'ch',
  'be',
  'el',
  'lt',
  'pt',
  'bg',
  'es',
  'lu',
  'ro',
  'cz',
  'fr',
  'hu',
  'si',
  'dk',
  'hr',
  'mt',
  'sk',
  'de',
  'it',
  'nl',
  'fi',
  'ee',
  'cy',
  'at',
  'se',
  'ie',
  'lv',
  'pl',
  'gb',
  'mx',
]

export const VAT_INCLUDED_COHORTS: string[] = [
  Cohort.CARDIMATE_2,
  Cohort.CARDIMATE_11,
]

export const EMPTY_OLD_PRICE = {
  fullPrice: 0,
  amountOfDiscount: 0,
  weekly: 0,
  daily: 0,
  monthly: 0,
  yearly: 0,
  percentOfDiscount: 0,
}
export const PERIOD_NAMES = {
  day: 'payment.planBlock.day',
  week: 'payment.planBlock.week',
  month: 'payment.planBlock.month',
  year: 'payment.planBlock.year',
}
export const enum TrialPeriod {
  ONE_WEEK = 7,
  ONE_MONTH = 30,
  THREE_MONTH = 90,
}

export const TRIAL_DESCRIPTION_CONTEXT = {
  [TrialPeriod.ONE_WEEK]: 'week',
  [TrialPeriod.ONE_MONTH]: 'month',
  [TrialPeriod.THREE_MONTH]: 'quarter',
}

export const TRIAL_PERIOD_NAME_TO_MARKUP_MAP = {
  [TrialPeriod.ONE_WEEK]: 'payment.planBlock.day',
  [TrialPeriod.ONE_MONTH]: 'payment.planBlock.month',
  [TrialPeriod.THREE_MONTH]: 'payment.planBlock.month',
}

export const PERIOD_NAME_TO_MARKUP_MAP = {
  week: 'payment.planBlock.week',
  month: 'payment.planBlock.month',
  day: 'payment.planBlock.day',
}

export const INTRO_OFFER_PERIODS = {
  [TrialPeriod.ONE_WEEK]: 7,
  [TrialPeriod.ONE_MONTH]: 1,
  [TrialPeriod.THREE_MONTH]: 3,
}

export const enum BillingCycle {
  ONE_MONTH = 1,
  THREE_MONTHS = 3,
  SIX_MONTHS = 6,
  ONE_YEAR = 12,
}

export const MAIN_GOAL_OPTION_VALUES = {
  CONTROL_HEART_HEALTH: 'heart_health',
  CONTROL_BLOOD_PRESSURE: 'blood_pressure',
  REDUCE_STRESS: 'reduce_stress',
  SLEEP_BETTER: 'sleep_better',
  REDUCE_MEDICATION_INTAKE: 'medication_intake',
  OTHER: 'other',
}
export const enum AdditionalGoal {
  BLOOD_PRESSURE = 'blood_pressure',
  REDUCE_STRESS = 'reduce_stress',
  SLEEP_BETTER = 'sleep_better',
  HEART_HEALTHY_DIET = 'heart_healthy_diet',
  MONITOR_BLOOD_PRESSURE = 'monitor_blood_pressure',
  PHYSICAL_ACTIVITY = 'physical_activity',
}

const enum Symptom {
  HEADACHES = 'headaches',
  NOSEBLEEDS = 'nosebleeds',
  FATIGUE = 'fatigue',
  VISION_PROBLEMS = 'vision_problems',
  CHEST_PAIN = 'chest_pain',
  DIFFICULTY_BREATHING = 'difficulty_breathing',
  DIFFICULTY_CONCENTRATING = 'difficulty_concentrating',
  FACIAL_REDNESS = 'facial_redness',
  DIZZINESS = 'dizziness',
  NONE = 'none_of_the_above',
}

export const ADDITIONAL_GOALS_TO_MARKUP = {
  [AdditionalGoal.BLOOD_PRESSURE]:
    'onboarding.additionalGoals.healthyBloodPressure',
  [AdditionalGoal.REDUCE_STRESS]: 'onboarding.additionalGoals.reduceStress',
  [AdditionalGoal.SLEEP_BETTER]: 'onboarding.additionalGoals.sleepBetter',
  [AdditionalGoal.HEART_HEALTHY_DIET]: 'onboarding.additionalGoals.healthyDiet',
  [AdditionalGoal.MONITOR_BLOOD_PRESSURE]:
    'onboarding.betterHeartLife.monitorPressure',
  [AdditionalGoal.PHYSICAL_ACTIVITY]:
    'onboarding.betterHeartLife.physicalActivity',
}
export const SYMPTOMS_TO_MARKUP = {
  [Symptom.HEADACHES]: 'onboarding.symptoms.headaches',
  [Symptom.NOSEBLEEDS]: 'onboarding.symptoms.nosebleeds',
  [Symptom.FATIGUE]: 'onboarding.symptoms.fatigue',
  [Symptom.VISION_PROBLEMS]: 'onboarding.symptoms.visionProblems',
  [Symptom.CHEST_PAIN]: 'onboarding.symptoms.chestPain',
  [Symptom.DIFFICULTY_BREATHING]: 'onboarding.symptoms.difficultyBreathing',
  [Symptom.DIFFICULTY_CONCENTRATING]:
    'onboarding.symptoms.difficultyConcentrating',
  [Symptom.FACIAL_REDNESS]: 'onboarding.symptoms.facialRedness',
  [Symptom.DIZZINESS]: 'onboarding.symptoms.dizziness',
  [Symptom.NONE]: 'actions.none',
}

export const DYNAMIC_PAGE_ID_PREFIX = 'dynamic'

export const enum CheckboxCustomValue {
  NONE_OF_THE_ABOVE = 'none_of_the_above',
}
export const CHECKBOX_CUSTOM_VALUES: string[] = [
  CheckboxCustomValue.NONE_OF_THE_ABOVE,
]

export const enum PlanAddition {
  UPSELL = 'Upsell',
  FREE_GUIDES = 'FreeGuides',
}

export const PLAN_ADDITIONS: Record<string, PlanAddition[]> = {
  [PageId.UPSELL_1]: [PlanAddition.UPSELL],
}

export const enum CookieConsentOption {
  STRICTLY_NECESSARY = 'necessary',
  ANALYTICS_AND_PERFORMANCE = 'analytics',
  ADVERTISING_AND_TARGETING = 'advertising',
  FUNCTIONAL_COOKIES = 'functional_cookies',
  REJECT_ALL = 'reject_all',
}

export const enum GAConsent {
  GRANTED = 'granted',
  DENIED = 'denied',
}

export const enum FBConsent {
  REVOKE = 'revoke',
  GRANT = 'grant',
}
export const enum AppLinks {
  TOU_LINK = `/${PageId.TERMS_OF_USE}`,
  PP_LINK = `/${PageId.PRIVACY_POLICY}`,
  COOKIE_LINK = `/${PageId.COOKIE_POLICY}`,
}

export const enum HotJarClientId {
  PROD = '4996762',
  DEV = '4996764',
}

export const TOU_PP_COOKIE_LINKS: string[] = [
  AppLinks.TOU_LINK,
  AppLinks.PP_LINK,
  AppLinks.COOKIE_LINK,
]

export const CDN_FOLDER_LINK =
  'https://cdn-prod-unified-content.gismart.xyz/03df34be4965496c6da922b4a6ec6b446fcb268aea40296954b01bd8af25b272'

export const enum Images {
  LOW_BLOOD_PRESSURE_GRAPH_V2 = '/images/low-blood-pressure-graph-v2',
  HIGH_BLOOD_PRESSURE_GRAPH_V2 = '/images/high-blood-pressure-graph-v2',
  MEDIUM_HEART_ATTACK_RISK = '/images/medium-heart-attack-risk',
  HIGH_HEART_ATTACK_RISK = '/images/high-heart-attack-risk',
  TRACKING_SYSTEM_PHONE = '/images/tracking-system-phone',
  PULSE_HRV_PHONE = '/images/pulse-hrv-phone',
  CUSTOMIZED_REPORTS_PHONE = '/images/customized-reports-phone',
  NUMBER_ONE = '/images/number-one',
  USERS_AROUND_THE_WORLD = '/images/users-around-the-world',
  LOW_BLOOD_PRESSURE_GRAPH = '/images/low-blood-pressure-graph',
  HIGH_BLOOD_PRESSURE_GRAPH = '/images/high-blood-pressure-graph',
}
export const DEFAULT_DYNAMIC_DISCOUNT_AMOUNT = 50
export const DYNAMIC_DISCOUNT_THEME = {
  red: 'linear-gradient(87deg, #C40202 -4.33%, #FF5659 111.17%)',
  blue: 'linear-gradient(87deg, #7B00FF -3.7%, #1BE4FF 119.47%)',
  orange: 'linear-gradient(88deg, #FF0031 -0.53%, #FFBE1B 108.08%)',
}
export const DYNAMIC_DISCOUNT_CONTAINER_THEME = {
  red: 'linear-gradient(87deg, rgba(196, 2, 2, 0.15) -4.33%, rgba(255, 86, 89, 0.15) 111.17%)',
  blue: 'linear-gradient(87deg, rgba(123, 0, 255, 0.15) -3.7%, rgba(27, 228, 255, 0.15) 119.47%)',
  orange:
    'linear-gradient(88deg, rgba(255, 0, 49, 0.15) -0.53%, rgba(255, 190, 27, 0.15) 108.08%)',
}
export const DYNAMIC_DISCOUNT_TITLE_COLORS = {
  red: '#E74C3C',
  blue: '#0091FF',
  orange: '#F83B00',
}
export const DEFAULT_TRIAL_DISCOUNT_AMOUNT = 50
export const DEFAULT_NONE_TRIAL_DISCOUNT_AMOUNT = 75

export const enum CountryCode {
  ARGENTINA = 'ar',
  COLOMBIA = 'co',
  PERU = 'pe',
  CHILE = 'cl',
  DOMINICAN_REPUBLIC = 'do',
  URUGUAY = 'uy',
  ECUADOR = 'ec',
  GUATEMALA = 'gt',
  VENEZUELA = 've',
  PARAGUAY = 'py',
  BOLIVIA = 'bo',
  NICARAGUA = 'ni',
  HONDURAS = 'hn',
  COSTA_RICA = 'cr',
  PANAMA = 'pa',
  EL_SALVADOR = 'sv',
  BRAZIL = 'br',
  INDIA = 'in',
  PHILIPPINES = 'ph',
  SOUTH_AFRICA = 'za',
  NIGERIA = 'ng',
  KENYA = 'ke',
  ZAMBIA = 'zm',
  TANZANIA = 'tz',
  ETHIOPIA = 'et',
  ZIMBABWE = 'zw',
  SENEGAL = 'sn',
  NAMIBIA = 'na',
}
export const COUNTRIES_WITHOUT_PAYPAL: string[] = [
  CountryCode.ARGENTINA,
  CountryCode.COLOMBIA,
  CountryCode.PERU,
  CountryCode.CHILE,
  CountryCode.DOMINICAN_REPUBLIC,
  CountryCode.URUGUAY,
  CountryCode.ECUADOR,
  CountryCode.GUATEMALA,
  CountryCode.VENEZUELA,
  CountryCode.PARAGUAY,
  CountryCode.BOLIVIA,
  CountryCode.NICARAGUA,
  CountryCode.HONDURAS,
  CountryCode.COSTA_RICA,
  CountryCode.PANAMA,
  CountryCode.EL_SALVADOR,
  CountryCode.BRAZIL,
  CountryCode.INDIA,
  CountryCode.PHILIPPINES,
  CountryCode.SOUTH_AFRICA,
  CountryCode.NIGERIA,
  CountryCode.KENYA,
  CountryCode.ZAMBIA,
  CountryCode.TANZANIA,
  CountryCode.ETHIOPIA,
  CountryCode.ZIMBABWE,
  CountryCode.SENEGAL,
  CountryCode.NAMIBIA,
]
