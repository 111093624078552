import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { usePurchaseStore } from 'hooks/common/usePurchaseStore'

import { getIsHeartMonitorDomain } from 'helpers/getIsHeartMonitorDomain'

import { SupportLink } from 'components/SupportLink'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import {
  CURRENCY_SYMBOLS,
  TRIAL_DESCRIPTION_CONTEXT,
} from 'root-constants/common'

import { StyledSubscriptionDescription as S } from './SubscriptionDescription.styles'

type TProps = {
  marginBottom?: number
}

export const SubscriptionDescription: React.FC<TProps> = (props) => {
  const { t } = useTranslation()
  const { currency, trialPeriodDays, trialPrice, currentPrice } =
    usePurchaseStore()
  const isHeartMonitorDomain = getIsHeartMonitorDomain()

  return (
    <S.Text {...props}>
      <Trans
        i18nKey="subscriptions.paidTrial.subscriptionDescription"
        values={{
          context: TRIAL_DESCRIPTION_CONTEXT[trialPeriodDays],
          currencySymbol: CURRENCY_SYMBOLS[currency],
          currencyCode: currency.toUpperCase(),
          trialPrice,
          price: currentPrice,
          minimumFractionDigits: 2,
          contactUs: isHeartMonitorDomain
            ? t('commonComponents.contactUsVia')
            : '',
        }}
        components={{
          termsOfUse: <TermsOfUseLink />,
          supportLink: (
            <SupportLink
              text={
                isHeartMonitorDomain
                  ? 'commonComponents.contactUsLink'
                  : 'commonComponents.contactSupport'
              }
            />
          ),
        }}
      />
    </S.Text>
  )
}
