import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { SupportLink } from 'components/SupportLink'

import { PrivacyPolicyLink } from '../PrivacyPolicyLink'
import { TermsOfUseLink } from '../TermsOfUseLink'
import { StyledBurgerMenu as S } from './BurgerMenu.styles'

export const BurgerMenu: React.FC = (props) => {
  const { t } = useTranslation()
  const [isMenuVisible, setIsMenuVisible] = useState(false)

  const toggleMenuVisibility = () => {
    setIsMenuVisible(!isMenuVisible)
  }

  return (
    <S.Wrapper {...props}>
      <S.BurgerButton type="button" onClick={toggleMenuVisibility} />
      <S.Backdrop
        isMenuVisible={isMenuVisible}
        onClick={toggleMenuVisibility}
      />
      <S.Menu isMenuVisible={isMenuVisible}>
        <S.MenuCloseButton type="button" onClick={toggleMenuVisibility} />
        <TermsOfUseLink />
        <PrivacyPolicyLink />
        <SupportLink>{t`actions.needHelp`}</SupportLink>
      </S.Menu>
    </S.Wrapper>
  )
}
