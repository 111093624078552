import { usePurchaseStore } from 'hooks/usePurchaseStore'

import { SET_PRIMER_PAYMENT_FORM_IS_LOADING } from 'modules/payment/redux/actions/primer'

export const useIsCheckoutReady = () => {
  const { isPaymentFlowsShown, fetchingActionsList } = usePurchaseStore()

  return (
    isPaymentFlowsShown &&
    !fetchingActionsList.includes(SET_PRIMER_PAYMENT_FORM_IS_LOADING)
  )
}
