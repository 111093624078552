import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Autoplay, Pagination } from 'swiper'
import { Swiper as Carousel, SwiperSlide } from 'swiper/react'

import { useDynamicOBConfig } from 'hooks/common/useDynamicOBConfig'
import { useNextStep } from 'hooks/common/useNextStep'
import { useHeartMonitorDomain } from 'hooks/ui/useHeartMonitorDomain'

import { TPageProps } from 'models/common.model'

import { Button } from 'components/Button'

import { StyledHeartCompanionVariant1 as S } from './HeartCompanionVariant1.styles'
import { USER_DATA } from './constants'

export const HeartCompanionVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const { title } = useDynamicOBConfig()
  const { productName } = useHeartMonitorDomain()

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: '',
    nextPagePath,
  })

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>
          {title || t('onboarding.heartCompanion.title', { productName })}
        </S.Title>
      </S.Column>

      <S.SwiperContainer>
        <Carousel
          slidesPerView="auto"
          spaceBetween={40}
          centeredSlides
          modules={[Pagination, Autoplay]}
          loop
          pagination={{ clickable: true }}
          autoplay={{ delay: 5000, disableOnInteraction: false }}
        >
          {USER_DATA.map(({ avatar, name, review }) => (
            <SwiperSlide key={name}>
              <S.AvatarContainer>
                <img src={avatar} alt="user" />
              </S.AvatarContainer>

              <S.ReviewContainer>
                <S.Author>{t(name)}</S.Author>
                <S.Review>
                  <Trans i18nKey={review} values={{ productName }} />
                </S.Review>
              </S.ReviewContainer>
            </SwiperSlide>
          ))}
        </Carousel>
      </S.SwiperContainer>
      <Button type="button" onClick={() => handleContinue('')}>
        {t`actions.continue`}
      </Button>
    </S.Wrapper>
  )
}
