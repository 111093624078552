export const OPTION_VALUES = {
  LESS_THAN_4: 'less_than_4',
  FROM_4_TO_8: 'from_4_to_8',
  MORE_THAN_8: 'more_than_8',
  DONT_KNOW: 'dont_know',
}
export const OPTION_VALUES_DAILY_WATER_2 = [
  { value: OPTION_VALUES.LESS_THAN_4, text: 'onboarding.dailyWater.lessThan4' },
  { value: OPTION_VALUES.FROM_4_TO_8, text: 'onboarding.dailyWater.from4to8' },
  { value: OPTION_VALUES.MORE_THAN_8, text: 'onboarding.dailyWater.moreThan8' },
  { value: OPTION_VALUES.DONT_KNOW, text: 'onboarding.dailyWater.dontKnow' },
]
export const TITLE_PATH = 'onboarding.dailyWater.title'
export const DYNAMIC_TITLE_OPTIONS = {
  few_kilos_to_lose: 'onboarding.dailyWater.fewKilos',
  slightly_overweight: 'onboarding.dailyWater.slightlyOverweight',
  heavily_overweight: 'onboarding.dailyWater.heavilyOverweight',
  healthy_weight: 'onboarding.dailyWater.healthyWeight',
  not_to_answer: 'onboarding.dailyWater.preferNotTo',
}
