import styled from 'styled-components'

import { commonPlanItemStyles } from 'modules/subscriptions/styles'

import soldOutPriceBgImg from 'assets/images/price-bg-grey.svg'
import priceRedBgImg from 'assets/images/price-bg-red.svg'

import { BASE_COLUMN_WIDTH, Color } from 'root-constants/common'

export const StyledPlanItemVariant2 = {
  PlanItem: styled.div`
    ${commonPlanItemStyles};

    &::after {
      display: block;
      padding: 2px 0;
      position: absolute;
      top: -1px;
      left: -1px;
      font-weight: 900;
      font-size: 10px;
      line-height: 13px;
      text-align: center;
      width: ${BASE_COLUMN_WIDTH}px;
      border-radius: 12px 12px 0 0;
      color: ${Color.WHITE};
    }

    &[data-is-default='true'] {
      &::after {
        left: 35%;
      }
    }
  `,
  Container: styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
  `,
  PricesWrapper: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
  `,
  PlanTitle: styled.div`
    flex-grow: 1;
    font-weight: 700;
  `,
  CustomPrice: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 21px;
    width: 81px;
    height: 40px;
    color: #b2b8cd;
    background-image: url(${soldOutPriceBgImg});
    background-repeat: no-repeat;
    background-size: contain;

    &[data-is-selected='true'] {
      background-image: url(${priceRedBgImg});
    }

    &[data-currency-symbol-length='3'] {
      width: 90px;
      height: 45px;
      padding-left: 15px;
    }
  `,
  Price: styled.strong`
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    color: #2c2f54;

    &[data-is-selected='true'] {
      color: ${Color.WHITE};
    }

    &[data-currency='mxn'] {
      font-size: 14px;
    }
  `,
  Period: styled.span`
    font-weight: 600;
    font-size: 10px;
    line-height: 10px;
    color: ${Color.LIGHT_TEXT};

    &[data-is-selected='true'] {
      color: ${Color.WHITE};
    }
  `,
  OldPrice: styled.span`
    font-size: 14px;
    line-height: 13px;
    font-weight: 500;
    text-decoration-line: line-through;
    text-decoration-color: #fd417f;
    color: #81848c;

    &[data-currency='mxn'] {
      font-size: 11px;
    }
  `,
  NewPrice: styled.span`
    font-size: 14px;
    line-height: 13px;
    font-weight: 500;
    color: #81848c;

    &[data-currency='mxn'] {
      font-size: 11px;
    }
  `,
}
