import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { usePurchaseStore } from 'hooks/common/usePurchaseStore'

import { OVERSIZE_CURRENCIES } from 'modules/subscriptions/constants'
import { ISelectPlanItemProps } from 'modules/subscriptions/types'

import { PriceValue } from 'components/PriceValue'

import checkIconGrey from 'assets/images/check-icon-grey.svg'
import checkIconRed from 'assets/images/check-icon-red.svg'

import { StyledUpsellPlanItemVariant1 as S } from './UpsellPlanItemVariant1.styles'
import {
  DESCRIPTION_DATA,
  PLAN_ITEM_FONT_WEIGHT,
  PLAN_ITEM_TITLES,
} from './constants'

export const UpsellPlanItemVariant1: React.FC<ISelectPlanItemProps> = ({
  subscription: {
    isDefault,
    discountPercent,
    product,
    mainPrices: { fullPrice },
  },
  isSelected,
}) => {
  const { t } = useTranslation()

  const { currency } = usePurchaseStore()

  const oldPrice = (fullPrice / (discountPercent / 100)).toFixed(2)

  const hasOverSizeFont = OVERSIZE_CURRENCIES.includes(currency)

  return (
    <S.Wrapper data-is-selected={isSelected} data-is-default={isDefault}>
      <S.TitleWrapper>
        <S.RadioButton checkIcon={isSelected ? checkIconRed : checkIconGrey} />
        <S.Title
          fontWeight={PLAN_ITEM_FONT_WEIGHT[product]}
          hasOverSizeFont={hasOverSizeFont}
        >
          <Trans i18nKey={t(PLAN_ITEM_TITLES[product])} />
        </S.Title>
        <S.PriceWrapper>
          {isDefault && (
            <S.DiscountBadge>
              <Trans i18nKey="upsell.discount" />
            </S.DiscountBadge>
          )}
          <S.Price hasOverSizeFont={hasOverSizeFont}>
            <PriceValue value={fullPrice} currency={currency} />
          </S.Price>
          {!!discountPercent && (
            <S.OldPrice hasOverSizeFont={hasOverSizeFont}>
              <PriceValue value={oldPrice} currency={currency} />
            </S.OldPrice>
          )}
        </S.PriceWrapper>
      </S.TitleWrapper>
      {!!DESCRIPTION_DATA[product] && (
        <S.DescriptionWrapper>
          <img src={DESCRIPTION_DATA[product].img} alt="description" />
          <S.DescriptionText>
            {t(DESCRIPTION_DATA[product].text)}
          </S.DescriptionText>
        </S.DescriptionWrapper>
      )}
    </S.Wrapper>
  )
}
