import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  selectAnswers,
  selectCurrentVariantCohortToUse,
  selectLanguage,
} from 'root-redux/selects/common'

import { useNextStep } from 'hooks/common/useNextStep'
import { useHeartMonitorDomain } from 'hooks/ui/useHeartMonitorDomain'

import { TPageProps } from 'models/common.model'

import {
  CDN_FOLDER_LINK,
  Cohort,
  HEART_MONITOR_IMG_PREFIX,
  Images,
} from 'root-constants/common'
import { CustomPageId } from 'root-constants/pages'

import { StyledBloodPressureGraphVariant2 as S } from './BloodPressureGraphVariant2.styles'

const LOW_BLOOD_PRESSURE = '<120 / <80'

export const BloodPressureGraphVariant2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const userAnswers = useSelector(selectAnswers)
  const cohort = useSelector(selectCurrentVariantCohortToUse)
  const language = useSelector(selectLanguage)
  const { isHeartMonitorDomain, productName } = useHeartMonitorDomain()

  const hasLowBloodPressure =
    userAnswers?.[CustomPageId.BLOOD_PRESSURE] === LOW_BLOOD_PRESSURE

  const isNineteenCohort = cohort === Cohort.CARDIMATE_19

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: '',
    nextPagePath,
  })

  const imgSrc = (() => {
    const hmPrefix = isHeartMonitorDomain ? HEART_MONITOR_IMG_PREFIX : ''

    if (hasLowBloodPressure) {
      return `${CDN_FOLDER_LINK}${Images.LOW_BLOOD_PRESSURE_GRAPH_V2}${hmPrefix}_${language}.png`
    }

    return `${CDN_FOLDER_LINK}${Images.HIGH_BLOOD_PRESSURE_GRAPH_V2}${hmPrefix}_${language}.png`
  })()

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>
          {t('onboarding.bloodPressureGraph.title', { productName })}
        </S.Title>
        <S.GraphContainer>
          <S.GraphTitle isNineteenCohort={isNineteenCohort}>
            <Trans
              i18nKey={
                hasLowBloodPressure
                  ? 'onboarding.bloodPressureGraph.hypotensionRisk'
                  : 'onboarding.bloodPressureGraph.hypertensionRisk'
              }
            />
          </S.GraphTitle>
          <img src={imgSrc} alt="graph" />
        </S.GraphContainer>
        <S.Disclaimer>
          <Trans
            i18nKey="onboarding.bloodPressureGraph.disclaimer"
            values={{ productName }}
          />
        </S.Disclaimer>
        <S.Button type="button" onClick={() => handleContinue('')}>
          {t`actions.continue`}
        </S.Button>
      </S.Column>
    </S.Wrapper>
  )
}
