import { TAnswer } from 'models/common.model'
import { TProductId } from 'models/subscriptions.model'

import { ScreenName, eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { PaymentMethod, PaymentSystem } from 'root-constants/common'

export const logSuccessfulPayment = ({
  email,
  productId,
  price,
  currency,
  trialPrice,
  predictedLtv,
  trialPeriodDays,
  subscriptionId,
  discountApplied,
  uuid,
  paymentMethod,
  screenName,
  paymentSystem,
  stripeAccountId,
  stripeAccountName,
  goal,
  isPersonalDataAllowed,
}: {
  email: string
  productId: TProductId
  price: number
  currency?: string
  trialPrice?: number
  predictedLtv?: number
  trialPeriodDays?: number
  subscriptionId: string
  discountApplied?: string
  uuid: string
  paymentMethod?: PaymentMethod
  screenName: ScreenName
  paymentSystem: PaymentSystem
  stripeAccountId?: string
  stripeAccountName?: string
  goal: TAnswer
  isPersonalDataAllowed: boolean
}): void => {
  if (trialPeriodDays && screenName !== ScreenName.UPSELL_OFFER) {
    window.fbq(
      'track',
      'StartTrial',
      {
        value: trialPrice,
        currency,
        subscription_id: subscriptionId,
        // This field and similar ones below have been added to avoid FB pixel issue. https://github.com/facebook/facebook-nodejs-business-sdk/issues/164
        subscription_sid: subscriptionId,
      },
      { eventID: uuid },
    )
  }

  if (screenName !== ScreenName.UPSELL_OFFER) {
    window.fbq(
      'track',
      'Subscribe',
      {
        value: trialPrice || price,
        currency,
        subscription_id: subscriptionId,
        subscription_sid: subscriptionId,
      },
      { eventID: uuid },
    )
    window.fbq(
      'track',
      'Purchase',
      {
        value: predictedLtv || trialPrice || price,
        currency,
        subscription_id: subscriptionId,
        subscription_sid: subscriptionId,
      },
      { eventID: uuid },
    )
  }

  eventLogger.logPurchaseCompleted({
    email,
    productId,
    priceDetails: {
      price,
      trial: !!trialPrice,
      currency,
    },
    paymentMethod,
    transactionId: subscriptionId,
    screenName,
    goal,
    paymentSystem,
    stripeAccountId,
    stripeAccountName,
    discountApplied,
    isPersonalDataAllowed,
  })

  if (screenName !== ScreenName.UPSELL_OFFER) {
    googleAnalyticsLogger.logPurchaseCompleted({
      subscriptionId,
      price: trialPrice || price,
      productId,
      currency,
    })

    !!window.hj && window.hj('event', 'purchase')
  }
}
