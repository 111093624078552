import styled from 'styled-components'

import { SelectPlanBlockVariant1 } from 'modules/subscriptions/components/common/SelectPlanBlockVariant1'

import { Button } from 'components/Button'

import checkIcon from 'assets/images/check-icon-wavy-borders-green.svg'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color } from 'root-constants/common'

export const StyledSubscriptionsVariant1 = {
  Wrapper: styled.div`
    ${basePageStyles};
  `,
  Content: styled.div`
    ${baseColumnStyles};
    padding: 80px 0 100px;
  `,
  Congratulations: styled.p`
    margin-bottom: 12px;
    color: ${Color.DANGER};
    text-align: center;
    font-size: 14px;
    font-weight: 800;
    line-height: 20px;
  `,
  Title: styled.h3`
    margin-bottom: 12px;
    text-align: center;
    font-size: 22px;
    font-weight: 900;
    line-height: 30px;
  `,
  Disclaimer: styled.p`
    margin-bottom: 24px;
    color: ${Color.LIGHT_TEXT};
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
  `,
  Benefits: styled.div`
    display: flex;
    margin-bottom: 45px;
    padding: 24px 16px 16px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 16px;
    background-color: ${Color.WHITE};
    box-shadow: 0 8px 20px 0 #dfe3f3;
  `,
  ImageContainer: styled.div`
    aspect-ratio: 295/256;
    width: 100%;

    img {
      border-radius: 8px;
    }
  `,
  ListItem: styled.li`
    margin-bottom: 16px;
    padding-left: 32px;
    background-image: url(${checkIcon});
    background-repeat: no-repeat;
    background-position: 0 2px;
    color: ${Color.LIGHT_TEXT};
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
  `,
  SelectPlanBlock: styled(SelectPlanBlockVariant1)`
    margin-bottom: 12px;
  `,
  Button: styled(Button)`
    margin-bottom: 24px;
  `,
  CancelInfo: styled.p`
    margin-bottom: 32px;
    color: ${Color.LIGHT_TEXT};
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  `,
}
