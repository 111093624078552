import styled, { css } from 'styled-components'

import { commonPlanItemStyles } from 'modules/subscriptions/styles'

import { Color } from 'root-constants/common'

const textStyles = css`
  font-weight: 500;
  font-size: ${({ theme }) => theme.common?.planItemPeriodText};
  line-height: 16px;
  color: ${Color.LIGHT_TEXT};
`

export const StyledPlanItemVariant1 = {
  PlanItem: styled.div`
    ${commonPlanItemStyles};
  `,
  Container: styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
  `,
  PlanTitle: styled.div`
    flex-grow: 1;
    font-weight: 700;
  `,
  PaymentInformation: styled.div`
    ${textStyles};
  `,
  PlanPrice: styled.div`
    font-weight: 900;
  `,
  PricePeriod: styled.div`
    ${textStyles};
  `,
}
