import React, { useEffect, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button, buttonTheme } from 'storybook-ui'

import { useDynamicPaywallConfig } from 'hooks/common/useDynamicPaywallConfig'
import { useGetPrices } from 'hooks/common/useGetPrices'
import { usePurchaseStore } from 'hooks/common/usePurchaseStore'
import { useHeartMonitorDomain } from 'hooks/ui/useHeartMonitorDomain'

import { createProductId } from 'helpers/createProductId'

import { TPageProps } from 'models/common.model'

import { ScreenName, eventLogger } from 'services/eventLogger.service'

import { ACCESS_DATA_V2 } from 'modules/subscriptions/components/common/Access/constants'
import { CustomerRatingVariant2 } from 'modules/subscriptions/components/common/CustomerRating'
import { PLAN_ITEMS, TRIAL_PLAN_ITEMS } from 'modules/subscriptions/constants'
import { usePaywall } from 'modules/subscriptions/hooks/usePaywall'

import { Spinner } from 'components/Spinner'

import appNameLogo from 'assets/images/app-name-logo-2.png'
import clockImage from 'assets/images/sprite/clock-big.svg'
import guaranteeImg from 'assets/images/sprite/guarantee-stamp-gray.svg'

import { SubscriptionTag } from 'root-constants/common'

import { StyledSubscriptionsVariant10 as S } from './SubscriptionsVariant10.styles'

export const SubscriptionsVariant10: React.FC<TPageProps> = ({
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const selectPlanBlockRef = useRef<HTMLDivElement>(null)

  const { subscriptions } = usePurchaseStore()
  const { hasPrices, handleSelectSubscription, handleShowPayment } =
    usePaywall(nextPagePath)

  useGetPrices({
    tags: SubscriptionTag.NO_TAX,
  })

  const { subscriptionBlockType } = useDynamicPaywallConfig()

  const { productName } = useHeartMonitorDomain()

  const planItem = PLAN_ITEMS[subscriptionBlockType]

  const isTrialPlan = TRIAL_PLAN_ITEMS.includes(subscriptionBlockType)

  useEffect(() => {
    if (!subscriptions.length) return

    eventLogger.logSalePageShown({
      productIds: subscriptions.map(
        ({ mainPrices: { periodName, periodQuantity, fullPrice } }) =>
          createProductId({
            periodName,
            periodQuantity,
            price: fullPrice,
          }),
      ),
      screenName: ScreenName.ONBOARDING,
    })
  }, [subscriptions])

  const handleScroll = () => {
    selectPlanBlockRef.current?.scrollIntoView({
      block: 'center',
    })
  }

  return !hasPrices ? (
    <Spinner />
  ) : (
    <S.Wrapper>
      <S.HeaderWithButton
        onClick={handleScroll}
        logo={appNameLogo}
        height={36}
      />
      <S.Content>
        <S.SubheaderWithTimer
          discount={isTrialPlan ? 50 : 75}
          clockImg={clockImage}
          clockheight={24}
        />
        <S.Title>
          <Trans i18nKey="subscriptions.achieveHealthyHeart" />
        </S.Title>
        <S.Disclaimer>{t`subscriptions.heartHealthProgram`}</S.Disclaimer>
        <S.HeartAge />
        <S.SelectPlanBlock
          ref={selectPlanBlockRef}
          onSelect={handleSelectSubscription}
          SelectPlanItem={planItem}
        />
        <Button onClick={handleShowPayment} theme={buttonTheme.CARDIMATE}>
          {t('actions.getCardi', { productName })}
        </Button>
        {isTrialPlan ? (
          <S.SubscriptionDescription />
        ) : (
          <S.CancelInfo>{t`subscriptions.disclaimer`}</S.CancelInfo>
        )}
        <S.AccessVariant accessData={ACCESS_DATA_V2} />
        <CustomerRatingVariant2 />
        <S.ReviewsSlider
          titlePath="subscriptions.customersLove"
          titleBottomMargin={24}
          marginBottom={32}
          textAlign="start"
        />
        <S.MoneyBackGuarantee
          logo={appNameLogo}
          width={124}
          height={36}
          guaranteeImg={guaranteeImg}
        />
      </S.Content>
    </S.Wrapper>
  )
}
