import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import {
  PaymentRequestButtonElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { PaymentRequest } from '@stripe/stripe-js/types/stripe-js/payment-request'

import { resetErrorAction } from 'root-redux/actions/common'

import { useGetPageInfo } from 'hooks/useGetPageInfo'
import { useUserData } from 'hooks/useUserData'

import { eventLogger } from 'services/eventLogger.service'

import {
  CENTS_IN_DOLLAR,
  INTRO_OFFER_PERIODS,
  PaymentMethod,
  TRIAL_PERIOD_NAME_TO_MARKUP_MAP,
  TrialPeriod,
} from 'root-constants/common'

import {
  purchaseAction,
  setIsPaymentFlowsShownAction,
  setPaymentMethodAction,
  setPaymentRequestButtonTypeAction,
} from '../../redux/actions/common'
import {
  selectCurrency,
  selectSubscriptionFullPrice,
  selectSubscriptionTrialPeriodDays,
  selectSubscriptionTrialPeriodPrice,
} from '../../redux/selects'
import { StyledPaymentRequestButton as S } from './PaymentRequestButton.styles'

export const PaymentRequestButton: React.FC = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const stripe = useStripe()
  const elements = useElements()
  const trialCurrentPrice = useSelector(selectSubscriptionTrialPeriodPrice)
  const trialPeriodDays = useSelector(selectSubscriptionTrialPeriodDays)
  const selectedPrice = useSelector(selectSubscriptionFullPrice)
  const currency = useSelector(selectCurrency)
  const durationDays = useSelector(selectSubscriptionTrialPeriodDays)

  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(
    null,
  )
  const [buttonType, setButtonType] = useState<PaymentMethod | null>(null)

  const { currentPageId } = useGetPageInfo()
  const { goal } = useUserData()

  const calculatedPrice = useMemo(
    () => +((trialCurrentPrice || selectedPrice) * CENTS_IN_DOLLAR).toFixed(),
    [trialCurrentPrice, selectedPrice],
  )

  const planPeriodDescription = useMemo(
    () =>
      !!trialPeriodDays && trialPeriodDays <= TrialPeriod.ONE_WEEK
        ? t('payment.appleAndGooglePayPopupTrialText', { trialPeriodDays })
        : t('payment.appleAndGooglePayPopupText', {
            periodName: t(TRIAL_PERIOD_NAME_TO_MARKUP_MAP[durationDays], {
              count: INTRO_OFFER_PERIODS[durationDays],
            }),
            periodQuantity: INTRO_OFFER_PERIODS[durationDays],
          }),
    [t, trialPeriodDays, durationDays],
  )

  useEffect(() => {
    if (!stripe || !elements) {
      return
    }

    const pr = stripe.paymentRequest({
      currency,
      country: 'GB',
      requestPayerEmail: true,
      requestPayerName: true,
      total: {
        label: planPeriodDescription,
        amount: calculatedPrice,
      },
    })

    pr.canMakePayment().then((result) => {
      if (result) {
        const shownButtonType = result?.applePay
          ? PaymentMethod.APPLE_PAY
          : PaymentMethod.GOOGLE_PAY

        setPaymentRequest(pr)
        setButtonType(shownButtonType)
        dispatch(setPaymentRequestButtonTypeAction(shownButtonType))
      }
      dispatch(setIsPaymentFlowsShownAction(true))
    })

    pr.on('paymentmethod', (event) => {
      dispatch(resetErrorAction())
      dispatch(
        purchaseAction({
          stripe,
          paymentPageId: currentPageId,
          createPaymentResFromDigitalWallet: event,
          goal,
        }),
      )
    })
  }, [
    calculatedPrice,
    currency,
    currentPageId,
    dispatch,
    elements,
    goal,
    planPeriodDescription,
    stripe,
  ])

  const handleClick = useCallback(() => {
    if (!buttonType) return

    dispatch(setPaymentMethodAction(buttonType))
    eventLogger.logPaymentMethodSelected({ paymentMethod: buttonType })
  }, [buttonType, dispatch])

  return paymentRequest ? (
    <S.Wrapper {...props}>
      <PaymentRequestButtonElement
        onClick={handleClick}
        options={{
          paymentRequest,
          style: {
            paymentRequestButton: {
              height: '56px',
            },
          },
        }}
      />
    </S.Wrapper>
  ) : null
}
