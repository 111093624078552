import heartRateIcon from 'assets/images/heart-rate-icon.png'
import moneyBackIcon from 'assets/images/money-back-icon.png'
import shareResultsIcon from 'assets/images/share-results-icon.png'

export const REFUND_CONDITIONS_DATA = [
  {
    icon: heartRateIcon,
    title: 'subscriptions.conditionsData.heartRateMeasurement',
    text: 'subscriptions.conditionsData.heartRateUnderControl',
  },
  {
    icon: shareResultsIcon,
    title: 'subscriptions.conditionsData.shareResults',
    text: 'subscriptions.conditionsData.contactUs',
  },
  {
    icon: moneyBackIcon,
    title: 'subscriptions.conditionsData.moneyBack',
    text: 'subscriptions.conditionsData.refund',
  },
]
