import { useSelector } from 'react-redux'

import {
  selectActionList,
  selectCurrentVariantCohort,
  selectCurrentVariantCohortToUse,
  selectDynamicDiscount,
  selectError,
  selectHasCancelOffer,
  selectIsFetching,
  selectLanguage,
  selectPayPalClientId,
  selectScreenName,
  selectStripeAccountId,
  selectStripeAccountName,
  selectSubscriptionList,
} from 'root-redux/selects/common'
import {
  selectEmail,
  selectProductName,
  selectUUID,
  selectUserCountryCode,
} from 'root-redux/selects/user'

import { compareSubscriptionList } from 'helpers/compareSubscriptionList'

import {
  select3DSecureIframeUrl,
  selectCurrency,
  selectIsFirstPaymentRetryPassed,
  selectIsPaymentFlowsShown,
  selectIsPaymentPopupActive,
  selectIsPaymentProcessingPopupActive,
  selectPayPalPlanId,
  selectPaymentRequestButtonType,
  selectPrimerClientSessionToken,
  selectProductId,
  selectSubscription,
  selectSubscriptionDailyPrice,
  selectSubscriptionFullPrice,
  selectSubscriptionId,
  selectSubscriptionLookupKey,
  selectSubscriptionOldPriceBeforeCustomDiscount,
  selectSubscriptionPeriodName,
  selectSubscriptionPeriodQuantity,
  selectSubscriptionTrialDailyPrice,
  selectSubscriptionTrialLookupKey,
  selectSubscriptionTrialOldPriceBeforeCustomDiscount,
  selectSubscriptionTrialPeriodDays,
  selectSubscriptionTrialPeriodPrice,
} from 'modules/payment/redux/selects'

export const usePurchaseStore = () => {
  const email = useSelector(selectEmail)
  const cohort = useSelector(selectCurrentVariantCohort)
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)
  const uuid = useSelector(selectUUID)
  const countryCode = useSelector(selectUserCountryCode)
  const language = useSelector(selectLanguage)
  const subscriptions = useSelector(
    selectSubscriptionList,
    compareSubscriptionList,
  )
  const selectedSubscription = useSelector(selectSubscription)
  const selectedSubscriptionId = useSelector(selectSubscriptionId)
  const priceId = useSelector(selectSubscriptionLookupKey)
  const productId = useSelector(selectProductId)
  const productName = useSelector(selectProductName)
  const trialPriceId = useSelector(selectSubscriptionTrialLookupKey)
  const trialPrice = useSelector(selectSubscriptionTrialPeriodPrice)
  const dailyTrialPrice = useSelector(selectSubscriptionTrialDailyPrice)
  const trialPeriodDays = useSelector(selectSubscriptionTrialPeriodDays)
  const currentPrice = useSelector(selectSubscriptionFullPrice)
  const dailyPrice = useSelector(selectSubscriptionDailyPrice)
  const oldPrice = useSelector(selectSubscriptionOldPriceBeforeCustomDiscount)
  const oldTrialPrice = useSelector(
    selectSubscriptionTrialOldPriceBeforeCustomDiscount,
  )
  const currency = useSelector(selectCurrency)
  const periodName = useSelector(selectSubscriptionPeriodName)
  const periodQuantity = useSelector(selectSubscriptionPeriodQuantity)
  const isPaymentFlowsShown = useSelector(selectIsPaymentFlowsShown)
  const hasCancelOffer = useSelector(selectHasCancelOffer)
  const stripeAccountName = useSelector(selectStripeAccountName)
  const stripeAccountId = useSelector(selectStripeAccountId)
  const paypalPlanId = useSelector(selectPayPalPlanId)
  const paypalClientId = useSelector(selectPayPalClientId)
  const screenName = useSelector(selectScreenName)
  const isFetching = useSelector(selectIsFetching)
  const fetchingActionsList = useSelector(selectActionList)
  const threeDSecureIframeUrl = useSelector(select3DSecureIframeUrl)
  const dynamicDiscount = useSelector(selectDynamicDiscount)
  const paymentRequestButtonType = useSelector(selectPaymentRequestButtonType)
  const isFirstPaymentRetryPassed = useSelector(selectIsFirstPaymentRetryPassed)
  const isPaymentPopupActive = useSelector(selectIsPaymentPopupActive)
  const isPaymentProcessingPopupActive = useSelector(
    selectIsPaymentProcessingPopupActive,
  )
  const priceLookupKey = useSelector(selectSubscriptionLookupKey)
  const primerClientSessionToken = useSelector(selectPrimerClientSessionToken)
  const error = useSelector(selectError)

  return {
    email,
    cohort,
    cohortToUse,
    uuid,
    countryCode,
    language,
    subscriptions,
    selectedSubscription,
    selectedSubscriptionId,
    priceId,
    productId,
    productName,
    trialPriceId,
    trialPrice,
    dailyTrialPrice,
    oldTrialPrice,
    trialPeriodDays,
    currentPrice,
    dailyPrice,
    oldPrice,
    currency,
    periodName,
    periodQuantity,
    isPaymentFlowsShown,
    hasCancelOffer,
    stripeAccountName,
    stripeAccountId,
    paypalPlanId,
    paypalClientId,
    screenName,
    isFetching,
    threeDSecureIframeUrl,
    dynamicDiscount,
    fetchingActionsList,
    paymentRequestButtonType,
    isFirstPaymentRetryPassed,
    isPaymentPopupActive,
    isPaymentProcessingPopupActive,
    priceLookupKey,
    primerClientSessionToken,
    error,
  }
}
