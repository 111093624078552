import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectPaymentRequestButtonType } from 'modules/payment/redux/selects'

import { OptionType } from 'components/Option'

import applePayGray from 'assets/images/apple-pay-gray.png'
import applePay from 'assets/images/apple-pay.png'
import cardIconsBlackWhite from 'assets/images/card-icons-black-white.png'
import cardIcons from 'assets/images/card-icons.png'
import googlePayGray from 'assets/images/google-pay-gray.png'
import googlePay from 'assets/images/google-pay.png'

import { PaymentMethod } from 'root-constants/common'

import { StyledPaymentMethodSelector as S } from './PaymentMethodSelector.styles'

type TProps = {
  paymentMethod: PaymentMethod
  setPaymentMethod: (paymentMethod: PaymentMethod) => void
}

export const PaymentMethodSelector: React.FC<TProps> = ({
  paymentMethod,
  setPaymentMethod,
}) => {
  const { t } = useTranslation()
  const paymentRequestButtonType = useSelector(selectPaymentRequestButtonType)

  const handleChangePaymentMethod = ({ target: { value } }) => {
    setPaymentMethod(value)
  }

  if (!paymentRequestButtonType) return null

  return (
    <S.Wrapper>
      <S.InputLabel>
        <input
          type={OptionType.RADIO}
          value={PaymentMethod.CREDIT_CARD}
          checked={paymentMethod === PaymentMethod.CREDIT_CARD}
          onChange={handleChangePaymentMethod}
        />
        <S.CardPayment>
          <S.CardPaymentText>{t`payment.creditCard`}</S.CardPaymentText>
          <img
            src={
              paymentMethod === PaymentMethod.CREDIT_CARD
                ? cardIcons
                : cardIconsBlackWhite
            }
            alt="card-icons"
          />
        </S.CardPayment>
      </S.InputLabel>

      {paymentRequestButtonType === PaymentMethod.APPLE_PAY && (
        <S.InputLabel>
          <input
            type={OptionType.RADIO}
            value={PaymentMethod.APPLE_PAY}
            checked={paymentMethod === PaymentMethod.APPLE_PAY}
            onChange={handleChangePaymentMethod}
          />
          <S.ApplePay>
            <img
              src={
                paymentMethod === PaymentMethod.APPLE_PAY
                  ? applePay
                  : applePayGray
              }
              alt="apple-pay"
            />
          </S.ApplePay>
        </S.InputLabel>
      )}

      {paymentRequestButtonType === PaymentMethod.GOOGLE_PAY && (
        <S.InputLabel>
          <input
            type={OptionType.RADIO}
            value={PaymentMethod.GOOGLE_PAY}
            checked={paymentMethod === PaymentMethod.GOOGLE_PAY}
            onChange={handleChangePaymentMethod}
          />
          <S.GooglePay>
            <img
              src={
                paymentMethod === PaymentMethod.GOOGLE_PAY
                  ? googlePay
                  : googlePayGray
              }
              alt="google-pay"
            />
          </S.GooglePay>
        </S.InputLabel>
      )}
    </S.Wrapper>
  )
}
