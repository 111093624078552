import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectAnswers, selectLanguage } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/common/useNextStep'
import { useHeartMonitorDomain } from 'hooks/ui/useHeartMonitorDomain'

import { TPageProps } from 'models/common.model'

import { Button } from 'components/Button'

import {
  CDN_FOLDER_LINK,
  HEART_MONITOR_IMG_PREFIX,
  Images,
} from 'root-constants/common'
import { CustomPageId } from 'root-constants/pages'

import { StyledBloodPressureGraphVariant1 as S } from './BloodPressureGraphVariant1.styles'

const LOW_BLOOD_PRESSURE = '<120 / <80'

export const BloodPressureGraphVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const userAnswers = useSelector(selectAnswers)
  const language = useSelector(selectLanguage)
  const { isHeartMonitorDomain, productName } = useHeartMonitorDomain()

  const hasLowBloodPressure =
    userAnswers?.[CustomPageId.BLOOD_PRESSURE] === LOW_BLOOD_PRESSURE

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: '',
    nextPagePath,
  })

  const imgSrc = (() => {
    const hmPrefix = isHeartMonitorDomain ? HEART_MONITOR_IMG_PREFIX : ''

    if (hasLowBloodPressure) {
      return `${CDN_FOLDER_LINK}${Images.LOW_BLOOD_PRESSURE_GRAPH}${hmPrefix}_${language}.jpg`
    }

    return `${CDN_FOLDER_LINK}${Images.HIGH_BLOOD_PRESSURE_GRAPH}${hmPrefix}_${language}.jpg`
  })()

  return (
    <S.Wrapper>
      <S.Content>
        <S.Column>
          <S.Title>
            {t('onboarding.bloodPressureGraph.title', { productName })}
          </S.Title>
          <S.GraphContainer>
            <S.GraphTitle>{t`onboarding.bloodPressureGraph.yourPressure`}</S.GraphTitle>
            <img src={imgSrc} alt="graph" />
          </S.GraphContainer>
          <S.Disclaimer>
            <Trans
              i18nKey="onboarding.bloodPressureGraph.disclaimer"
              values={{ productName }}
            />
          </S.Disclaimer>
          <Button type="button" onClick={() => handleContinue('')}>
            {t`actions.continue`}
          </Button>
        </S.Column>
      </S.Content>
    </S.Wrapper>
  )
}
