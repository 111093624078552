import styled, { css, keyframes } from 'styled-components'

import { StickyButtonContainer } from 'components/StickyButtonContainer'

import checkIcon from 'assets/images/check-icon-green.svg'

import {
  baseColumnStyles,
  basePageStyles,
  baseTitleStyles,
} from 'common-styles'
import { Color, HEADER_HEIGHT } from 'root-constants/common'

const progressValueStyles = css`
  border-radius: 10px;
  background-color: ${Color.DANGER};
  transition: all 0.2s ease-out;
`
const itemVisibility = keyframes`
  0% {
   opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const animation = css`
  animation-name: ${itemVisibility};
  animation-duration: 6000ms;
  animation-fill-mode: backwards;

  [data-item='1']& {
    animation-delay: 1000ms;
  }

  [data-item='2']& {
    animation-delay: 2000ms;
  }

  [data-item='3']& {
    animation-delay: 3000ms;
  }

  [data-item='4']& {
    animation-delay: 4000ms;
  }
`

const smallTextCommonStyles = css`
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: ${Color.LIGHT_TEXT};
`

const mediumTextCommonStyles = css`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
`
export const StyledPlanProcessingVariant6 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    align-items: center;
    padding: 24px 0 0;
    min-height: calc(var(--full-height, 100vh) - ${HEADER_HEIGHT}px);
  `,
  Title: styled.h2`
    ${baseTitleStyles};
    margin-bottom: 40px;
    letter-spacing: -0.1px;
  `,
  ProgressBar: styled.progress`
    width: 100%;
    height: 6px;
    appearance: none;
    border: none;
    display: block;
    border-radius: 10px;
    overflow: hidden;
    background-color: ${Color.WHITE};
    margin-bottom: 40px;

    &::-webkit-progress-bar {
      background-color: ${Color.WHITE};
    }
    &::-webkit-progress-value {
      ${progressValueStyles};
    }
    &::-moz-progress-bar {
      ${progressValueStyles};
    }
    &::-ms-fill {
      ${progressValueStyles};
    }
  `,
  Block: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
  `,
  Item: styled.div`
    ${animation};
    position: relative;
    padding-left: 24px;
    ${smallTextCommonStyles};

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 18px;
      height: 18px;
      background-image: url(${checkIcon});
      background-repeat: no-repeat;
      background-position: center;
    }
  `,
  Disclaimer: styled.p`
    color: ${Color.LIGHT_TEXT};
    text-align: center;
    ${mediumTextCommonStyles};
    margin-bottom: 24px;
  `,
  ImageWrapper: styled.div`
    display: flex;
    padding-right: 16px;
    align-items: center;
    gap: 12px;
    border-radius: 16px;
    background: ${Color.WHITE};
    box-shadow: 0 8px 12px 0 rgba(152, 159, 187, 0.08);
    max-height: 146px;
    margin: 24px 0;

    img {
      max-height: 146px;
      border-bottom-left-radius: 16px;
      border-top-left-radius: 16px;
    }
  `,
  ImageWrapperTitle: styled.p`
    ${mediumTextCommonStyles};
    margin-bottom: 5px;
  `,
  Text: styled.p`
    ${smallTextCommonStyles};
  `,
  StickyButtonContainer: styled(StickyButtonContainer)`
    padding: 24px 0 40px;
    background-image: none;
  `,
}
