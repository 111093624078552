import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { usePageInfo } from 'providers/PageInfoProvider'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { Button } from 'components/Button'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import healthMetrics from 'assets/images/health-metrics.png'

import { goTo } from 'browser-history'

import { StyledSummaryHealthMetrics as S } from './SummaryHealthMetrics.styles'

export const SummaryHealthMetrics: React.FC<TPageProps> = ({ pageId }) => {
  const { t } = useTranslation()
  const { search } = useLocation()

  const { currentSubscriptionPageId } = usePageInfo()

  useEffect(() => {
    eventLogger.logPresalePageShown({
      pageName: pageId,
    })
  }, [pageId])

  const handleContinue = () => {
    eventLogger.logPresalePageCompleted({
      pageName: pageId,
    })
    goTo({ pathname: currentSubscriptionPageId, search })
  }

  return (
    <S.Wrapper>
      <S.Content>
        <S.Title>
          <Trans i18nKey="summary.summaryHealthMetrics.title" />
        </S.Title>
        <S.ImageContainer>
          <img src={healthMetrics} alt="healthMetrics" />
        </S.ImageContainer>
        <StickyButtonContainer>
          <Button type="button" onClick={handleContinue}>
            {t`actions.continue`}
          </Button>
        </StickyButtonContainer>
      </S.Content>
    </S.Wrapper>
  )
}
