import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js'
import { paymentApi, userApi } from 'api'

import { sendUserConfigAction } from 'root-redux/actions/user'
import {
  selectCurrentVariantCohort,
  selectPayPalClientId,
  selectScreenName,
} from 'root-redux/selects/common'
import { selectEmail, selectUUID } from 'root-redux/selects/user'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'
import { useUserData } from 'hooks/useUserData'

import { ISubscription } from 'models/subscriptions.model'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { setPaymentMethodAction } from 'modules/payment/redux/actions/common'

import { Modal } from 'components/Modal'
import { PaymentSuccess } from 'components/PaymentSuccess'

import { PaymentMethod, PaymentSystem } from 'root-constants/common'

import { logFailedPayment } from '../../helpers/logFailedPayment'
import { logSuccessfulPayment } from '../../helpers/logSuccessfulPayment'
import {
  selectCurrency,
  selectPayPalPlanId,
  selectSubscription,
  selectSubscriptionFullPrice,
  selectSubscriptionPeriodName,
  selectSubscriptionPeriodQuantity,
  selectSubscriptionTrialPeriodDays,
  selectSubscriptionTrialPeriodPrice,
} from '../../redux/selects'
import { StyledPayPalContainer as S } from './PayPalContainer.styles'

export const PayPalContainer: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const uuid = useSelector(selectUUID)
  const paypalPlanId = useSelector(selectPayPalPlanId)
  const paypalClientId = useSelector(selectPayPalClientId)
  const currentPrice = useSelector(selectSubscriptionFullPrice)
  const trialPeriodPrice = useSelector(selectSubscriptionTrialPeriodPrice)
  const trialPeriodDays = useSelector(selectSubscriptionTrialPeriodDays)
  const currency = useSelector(selectCurrency)
  const email = useSelector(selectEmail)
  const screenName = useSelector(selectScreenName)
  const periodName = useSelector(selectSubscriptionPeriodName)
  const periodQuantity = useSelector(selectSubscriptionPeriodQuantity)
  const cohort = useSelector(selectCurrentVariantCohort)
  const selectedSubscription = useSelector(selectSubscription)

  const [isPaymentStatusShown, setIsPaymentStatusShown] = useState(false)
  const [isErrorModalShown, setIsErrorModalShown] = useState(false)

  const { goal } = useUserData()
  const { isPersonalDataAllowed } = useCookieConsentAnswer()

  const handleResetError = useCallback(() => {
    setIsPaymentStatusShown(false)
    setIsErrorModalShown(false)
  }, [])

  const handlePaymentApprove = useCallback(
    async (data) => {
      const predictedLtvResponse = await userApi.getPredictedLtv(uuid)

      logSuccessfulPayment({
        email,
        subscriptionId: data.subscriptionID,
        productId: paypalPlanId,
        uuid,
        price: currentPrice,
        trialPrice: trialPeriodPrice,
        predictedLtv: predictedLtvResponse?.data?.predicted_ltv,
        trialPeriodDays,
        currency,
        paymentMethod: PaymentMethod.PAYPAL,
        paymentSystem: PaymentSystem.PAYPAL,
        goal,
        screenName,
        isPersonalDataAllowed,
      })

      const response = await paymentApi.createPaypalSubscription({
        uuid,
        paypalPlanId,
        cohort,
      })

      if (response.status) {
        dispatch(
          sendUserConfigAction({
            payment_currency: currency,
            payment_method: PaymentMethod.PAYPAL,
            subscription_price: currentPrice,
            subscription_duration: `${periodQuantity}${periodName}`,
            price_id: paypalPlanId,
            trial_price: trialPeriodPrice,
            trial_period: trialPeriodDays,
          }),
        )

        setIsPaymentStatusShown(true)
        setIsErrorModalShown(false)
      }
    },
    [
      email,
      paypalPlanId,
      uuid,
      currentPrice,
      trialPeriodPrice,
      trialPeriodDays,
      currency,
      goal,
      screenName,
      isPersonalDataAllowed,
      cohort,
      dispatch,
      periodQuantity,
      periodName,
    ],
  )

  const handlePaymentError = useCallback(
    (error) => {
      setIsErrorModalShown(true)
      logFailedPayment({
        email,
        productId: paypalPlanId,
        price: currentPrice,
        currency,
        paymentResponse: {
          type: error?.name || '',
          code: error?.debug_id,
          message: error?.message,
        },
        paymentMethod: PaymentMethod.PAYPAL,
        paymentSystem: PaymentSystem.PAYPAL,
        isTrialActive: !!trialPeriodPrice,
        goal,
        screenName,
        isPersonalDataAllowed,
      })
    },
    [
      email,
      paypalPlanId,
      currentPrice,
      currency,
      trialPeriodPrice,
      goal,
      screenName,
      isPersonalDataAllowed,
    ],
  )

  const handleButtonClick = useCallback(() => {
    dispatch(setPaymentMethodAction(PaymentMethod.PAYPAL))

    googleAnalyticsLogger.logAddingToCart(selectedSubscription as ISubscription)
    window.fbq('track', 'AddToCart', {}, { eventID: uuid })
    eventLogger.logPaymentMethodSelected({
      paymentMethod: PaymentMethod.PAYPAL,
    })
    eventLogger.logPurchaseStarted({
      email,
      productId: paypalPlanId,
      priceDetails: {
        price: currentPrice,
        trial: !!trialPeriodPrice,
        currency,
      },
      paymentMethod: PaymentMethod.PAYPAL,
      paymentSystem: PaymentSystem.PAYPAL,
      goal,
      screenName,
      isPersonalDataAllowed,
    })
  }, [
    dispatch,
    email,
    paypalPlanId,
    currentPrice,
    trialPeriodPrice,
    currency,
    goal,
    screenName,
    isPersonalDataAllowed,
    uuid,
    selectedSubscription,
  ])

  return (
    <>
      <S.ButtonsContainer>
        {paypalPlanId && paypalClientId && (
          <PayPalScriptProvider
            options={{
              'client-id': paypalClientId,
              vault: true,
              'disable-funding': 'credit',
            }}
          >
            <PayPalButtons
              style={{
                label: 'paypal',
                tagline: false,
                layout: 'horizontal',
                height: 55,
              }}
              forceReRender={[paypalPlanId]}
              createSubscription={(data, actions) => {
                return actions.subscription.create({
                  plan_id: paypalPlanId,
                  custom_id: uuid,
                })
              }}
              onApprove={(data) => handlePaymentApprove(data)}
              onError={(error) => handlePaymentError(error)}
              onClick={handleButtonClick}
            />
          </PayPalScriptProvider>
        )}
      </S.ButtonsContainer>
      {isPaymentStatusShown && <PaymentSuccess />}
      <Modal onClose={handleResetError} isShown={isErrorModalShown}>
        {t`payment.error`}
      </Modal>
    </>
  )
}
