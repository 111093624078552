import React from 'react'
import { useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/common/useNextStep'
import { useCounter } from 'hooks/ui/useCounter'
import { useHeartMonitorDomain } from 'hooks/ui/useHeartMonitorDomain'

import { TPageProps } from 'models/common.model'

import { Button } from 'components/Button'

import doctor from 'assets/images/plan-processing-doctor.png'

import { StyledPlanProcessingVariant6 as S } from './PlanProcessingVariant6.styles'
import { PAGE_NAME, PROCESSING_TEXT_FOUR_POINTS } from './constants'

export const PlanProcessingVariant6: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const { productName } = useHeartMonitorDomain()

  const { counter, isFinished } = useCounter(true, {
    duration: 6000,
  })

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAME,
    question: '',
    nextPagePath,
  })

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.processing.creatingProfile`}</S.Title>
        <S.ProgressBar max={100} value={counter} />
        <S.Block>
          {PROCESSING_TEXT_FOUR_POINTS.map((text, index) => (
            <S.Item key={text} data-item={index}>
              {t(text)}
            </S.Item>
          ))}
        </S.Block>
        <S.ImageWrapper>
          <img src={doctor} alt="doctor" />
          <div>
            <S.ImageWrapperTitle>{t`onboarding.processing.thanksForTrust`}</S.ImageWrapperTitle>
            <S.Text>
              {t('onboarding.processing.wellBeing', { productName })}
            </S.Text>
          </div>
        </S.ImageWrapper>
        <S.Disclaimer>{t`onboarding.processing.trustedByMillion`}</S.Disclaimer>
        <S.StickyButtonContainer>
          <Button disabled={!isFinished} onClick={() => handleContinue('')}>
            {t`actions.continue`}
          </Button>
        </S.StickyButtonContainer>
      </S.Column>
    </S.Wrapper>
  )
}
