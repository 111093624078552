import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectLanguage } from 'root-redux/selects/common'

import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Button } from 'components/Button'

import { CDN_FOLDER_LINK, Images } from 'root-constants/common'

import { StyledUsersAroundTheWorldVariant1 as S } from './UsersAroundTheWorldVariant1.styles'

export const UsersAroundTheWorldVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const { title, subtitle, buttonText } = useDynamicOBConfig()
  const language = useSelector(selectLanguage)

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: '',
    nextPagePath,
  })

  return (
    <S.Wrapper>
      <S.Content>
        <S.ImageContainer>
          <img
            src={`${CDN_FOLDER_LINK}${Images.USERS_AROUND_THE_WORLD}_${language}.png`}
            alt="users"
          />
        </S.ImageContainer>
        <S.Title>{title || t`onboarding.usersAroundTheWorld.title`}</S.Title>
        <S.Text>
          {subtitle || <Trans i18nKey="onboarding.usersAroundTheWorld.text" />}
        </S.Text>
        <Button type="button" onClick={() => handleContinue('')}>
          {buttonText || t`actions.continue`}
        </Button>
      </S.Content>
    </S.Wrapper>
  )
}
