import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  InputWithFloatPlaceholder,
  InputWithFloatPlaceholderTheme,
  buttonTheme,
} from 'storybook-ui'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { NAME_VALIDITY_REGEXP } from 'pages/name/constants'

import { Language } from 'root-constants/common'
import { CustomPageId } from 'root-constants/pages'

import { StyledNameVariant2 as S } from './NameVariant2.styles'

export const NameVariant2: React.FC<TPageProps> = ({
  nextPagePath,
  alternativePagePath,
}) => {
  const { t } = useTranslation()
  const [name, setName] = useState({ value: '', isValid: false })

  const handleChange = ({ target: { value, validity } }) => {
    const isNameValid = !value.match(NAME_VALIDITY_REGEXP)
    if (isNameValid) {
      setName({
        value,
        isValid: validity.valid && value.trim(),
      })
    }
  }

  const handleContinue = useNextStep({
    pageId: CustomPageId.NAME,
    pageName: CustomPageId.NAME,
    question: t('onboarding.name.title', { lng: Language.EN }),
    nextPagePath,
  })

  const handleSkip = useNextStep({
    pageId: CustomPageId.NAME,
    pageName: CustomPageId.NAME,
    question: t('onboarding.name.title', { lng: Language.EN }),
    nextPagePath: alternativePagePath,
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    handleContinue(name.value.trim())
  }

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.name.title`}</S.Title>
        <S.NameForm onSubmit={handleSubmit}>
          <InputWithFloatPlaceholder
            theme={InputWithFloatPlaceholderTheme.CARDIMATE}
            type="text"
            value={name.value}
            label={t`onboarding.name.yourName`}
            onChange={handleChange}
          />
          <Button
            type="submit"
            disabled={!name.isValid}
            theme={buttonTheme.CARDIMATE}
            margin="40px 0 0"
          >
            {t`actions.continue`}
          </Button>
          <S.SkipButton type="button" onClick={() => handleSkip('')}>
            {t`onboarding.name.skip`}
          </S.SkipButton>
        </S.NameForm>
      </S.Column>
    </S.Wrapper>
  )
}
