import React, { useEffect, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import lottie from 'lottie-web/build/player/lottie_light'

import { useGetPrices } from 'hooks/useGetPrices'
import { usePurchaseStore } from 'hooks/usePurchaseStore'

import { createProductId } from 'helpers/createProductId'

import { TPageProps } from 'models/common.model'

import { ScreenName, eventLogger } from 'services/eventLogger.service'

import { usePaywall } from 'modules/subscriptions/hooks/usePaywall'

import { Button } from 'components/Button'
import { Spinner } from 'components/Spinner'

import animationConfig from 'assets/animations/heart-animation.json'
import discountImage from 'assets/images/discount-fire.png'
import specialOfferImage from 'assets/images/special-offer-gift.png'

import { goTo } from 'browser-history'
import {
  CURRENCY_SYMBOLS,
  Cohort,
  ProductKey,
  SubscriptionListType,
  SubscriptionTag,
} from 'root-constants/common'

import { StyledCancelOfferDiscount as S } from './CancelOfferDiscount.styles'

export const CancelOfferDiscount: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()
  const animationContainerRef = useRef<HTMLDivElement>(null)

  const {
    cohort,
    subscriptions,
    productName,
    oldPrice,
    currentPrice,
    dailyPrice,
    currency,
  } = usePurchaseStore()

  const { hasPrices } = usePaywall(nextPagePath)

  const handleContinue = () => {
    goTo(nextPagePath)
  }

  useGetPrices({
    screenName: ScreenName.CANCEL_OFFER_DISCOUNT,
    tags:
      productName === ProductKey.THREE_MONTHS &&
      cohort !== Cohort.CARDIMATE_16_CANCEL
        ? `${SubscriptionTag.CANCEL_OFFER},${SubscriptionTag.NO_TAX},${SubscriptionTag.ONE_YEAR}`
        : `${SubscriptionTag.CANCEL_OFFER},${SubscriptionTag.NO_TAX},${SubscriptionTag.SIX_MONTHS}`,
    subscriptionType: SubscriptionListType.PURCHASE,
  })

  useEffect(() => {
    if (!subscriptions.length) return

    eventLogger.logSalePageShown({
      productIds: subscriptions.map(
        ({ mainPrices: { periodName, periodQuantity, fullPrice } }) =>
          createProductId({
            periodName,
            periodQuantity,
            price: fullPrice,
          }),
      ),
      screenName: ScreenName.CANCEL_OFFER_DISCOUNT,
    })
  }, [subscriptions])

  useEffect(() => {
    if (animationContainerRef.current && hasPrices) {
      lottie.loadAnimation({
        name: 'heartAnimation',
        container: animationContainerRef.current,
        animationData: animationConfig,
        loop: false,
      })
    }

    return () => lottie.destroy('heartAnimation')
  }, [hasPrices])

  return !hasPrices ? (
    <Spinner />
  ) : (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`subscriptions.cancelOffer.cancelOfferDiscount.title`}</S.Title>
        <S.AnimationContainer>
          <div ref={animationContainerRef} />
        </S.AnimationContainer>
        <S.DiscountContainer>
          <img src={discountImage} alt="discount" />
          <S.DiscountText>
            {t(`subscriptions.cancelOffer.cancelOfferDiscount.discountText`, {
              discount: oldPrice.percentOfDiscount,
            })}
          </S.DiscountText>
        </S.DiscountContainer>
        <S.SpecialOfferContainer>
          <img src={specialOfferImage} alt="specialOffer" />
          <S.SpecialOfferText>
            {t(
              `subscriptions.cancelOffer.cancelOfferDiscount.specialOfferText`,
              {
                price: dailyPrice,
                currencySymbol: CURRENCY_SYMBOLS[currency],
                minimumFractionDigits: 1,
              },
            )}
          </S.SpecialOfferText>
        </S.SpecialOfferContainer>
      </S.Column>
      <S.PriceContainer>
        <Trans
          i18nKey="subscriptions.cancelOffer.cancelOfferDiscount.priceText"
          values={{
            price: currentPrice,
            oldPrice: oldPrice.fullPrice,
            currencySymbol: CURRENCY_SYMBOLS[currency],
            minimumFractionDigits: 2,
          }}
        />
      </S.PriceContainer>
      <S.StickyButtonContainer>
        <Button onClick={handleContinue}>
          {t`subscriptions.cancelOffer.cancelOfferDiscount.buttonText`}
        </Button>
      </S.StickyButtonContainer>
    </S.Wrapper>
  )
}
