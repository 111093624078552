import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js'
import { StripeElementStyle } from '@stripe/stripe-js'
import styled, { css } from 'styled-components'

import { Button } from 'components/Button'

import cardIcon from 'assets/images/card.svg'
import warningIcon from 'assets/images/warning-outline.svg'

import { Color } from 'root-constants/common'

type TLabelProps = {
  gridArea: string
}

const INPUT_PLACEHOLDER_COLOR = '#b2b8cd'

export const stripeElementStyle: StripeElementStyle = {
  base: {
    color: Color.DEFAULT_TEXT,
    fontFamily: 'Mulish, sans-serif',
    fontSize: '14px',
    lineHeight: '44px',
    fontWeight: 600,
    fontSmoothing: 'antialiased',
    '::placeholder': {
      color: INPUT_PLACEHOLDER_COLOR,
    },
  },
  invalid: {
    color: Color.ERROR,
  },
}

const commonInputStyles = css`
  position: relative;
  height: 46px;
  color: ${Color.DEFAULT_TEXT};
  background-color: white;
  border-radius: 8px;
  border: 1px solid #b2b8cd;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 12px;
  padding-left: 16px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &[data-invalid='true'],
  &.StripeElement--invalid {
    border: 1px solid ${Color.ERROR};

    ::before {
      content: url(${warningIcon});
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
    }
  }
`
export const StyledStripePaymentForm = {
  Form: styled.form`
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      'cardNumberLabel cardNumberLabel'
      'cardNumber cardNumber'
      'cardExpiryLabel cardCvcLabel'
      'cardExpiry cardCvc'
      'cardholderLabel cardholderLabel'
      'cardholder cardholder'
      'button button';
    grid-gap: 0 16px;
    width: 100%;
  `,
  Label: styled.label<TLabelProps>`
    grid-area: ${({ gridArea }) => gridArea};
    padding-bottom: 8px;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    color: ${Color.DEFAULT_TEXT};
  `,
  CardNumberElement: styled(CardNumberElement)`
    ${commonInputStyles};
    grid-area: cardNumber;
  `,
  CardExpiryElement: styled(CardExpiryElement)`
    ${commonInputStyles};
    grid-area: cardExpiry;
  `,
  CardCvcElement: styled(CardCvcElement)`
    ${commonInputStyles};
    grid-area: cardCvc;

    &.StripeElement--invalid {
      &::before {
        right: 48px;
      }
    }

    ::after {
      content: url(${cardIcon});
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
      width: 24px;
      height: 16px;
    }
  `,
  CardholderInput: styled.input`
    ${commonInputStyles};
    grid-area: cardholder;
    width: 100%;
    margin-bottom: 24px;
    outline: none;
    box-shadow: none;

    ::placeholder {
      color: ${INPUT_PLACEHOLDER_COLOR};
    }
  `,
  SubmitButton: styled(Button)`
    grid-area: button;
    width: 100%;
  `,
}
