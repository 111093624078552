import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { usePurchaseStore } from 'hooks/common/usePurchaseStore'
import { useHeartMonitorDomain } from 'hooks/ui/useHeartMonitorDomain'

import {
  INTRO_OFFER_PERIODS,
  TimeInterval,
  TrialPeriod,
} from 'root-constants/common'

import { StyledPriceInfo as S } from './PriceInfo.styles'

export const PriceInfo: React.FC = () => {
  const { t } = useTranslation()
  const {
    trialPrice,
    trialPeriodDays,
    oldTrialPrice,
    dailyTrialPrice,
    currency,
  } = usePurchaseStore()
  const { productName } = useHeartMonitorDomain()

  const pricePeriodContext =
    trialPeriodDays > TrialPeriod.ONE_WEEK
      ? TimeInterval.MONTH
      : TimeInterval.WEEK

  return (
    <S.Wrapper>
      <S.MainPriceContainer>
        <S.MainPriceText>
          {t('payment.planDescription', {
            periodQuantity: INTRO_OFFER_PERIODS[trialPeriodDays],
            context: pricePeriodContext,
            productName,
          })}
        </S.MainPriceText>
        <S.MainPriceValue>
          {t('payment.price', {
            value: oldTrialPrice.fullPrice,
            currencyCode: currency.toUpperCase(),
            minimumFractionDigits: 2,
          })}
        </S.MainPriceValue>
        <S.MainPriceText>
          {t('payment.discount', {
            discountPercent: Math.floor(oldTrialPrice.percentOfDiscount),
          })}
        </S.MainPriceText>
        <S.MainPriceValue isDiscount>
          -
          {t('payment.price', {
            value: oldTrialPrice.amountOfDiscount,
            currencyCode: currency.toUpperCase(),
            minimumFractionDigits: 2,
          })}
        </S.MainPriceValue>
      </S.MainPriceContainer>

      <S.TotalPerDayContainer>
        <S.TotalPerDayText>{t`payment.totalPerDay`}</S.TotalPerDayText>
        <S.TotalPerDayPrice>
          {t('payment.price', {
            value: dailyTrialPrice,
            currencyCode: currency.toUpperCase(),
            minimumFractionDigits: 2,
          })}
        </S.TotalPerDayPrice>
      </S.TotalPerDayContainer>

      <S.TotalContainer>
        <S.TotalText>{t`payment.total`}</S.TotalText>
        <S.TotalPrice>
          <Trans
            i18nKey="payment.totalPerPeriod"
            values={{
              price: trialPrice,
              context: pricePeriodContext,
              periodQuantity: INTRO_OFFER_PERIODS[trialPeriodDays],
              currencyCode: currency.toUpperCase(),
              minimumFractionDigits: 2,
            }}
          />
        </S.TotalPrice>
        <S.TotalDiscount>
          {t('payment.saved', {
            discountAmount: oldTrialPrice.amountOfDiscount,
            discountPercent: Math.floor(oldTrialPrice.percentOfDiscount),
            currencyCode: currency.toUpperCase(),
          })}
        </S.TotalDiscount>
      </S.TotalContainer>
    </S.Wrapper>
  )
}
