import React, { useEffect, useMemo, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useDynamicPaywallConfig } from 'hooks/common/useDynamicPaywallConfig'
import { useGetPrices } from 'hooks/common/useGetPrices'
import { usePurchaseStore } from 'hooks/common/usePurchaseStore'

import { createProductId } from 'helpers/createProductId'

import { TPageProps } from 'models/common.model'

import { ScreenName, eventLogger } from 'services/eventLogger.service'

import { AccessVariant1 } from 'modules/subscriptions/components/common/Access'
import { CustomerRatingVariant1 } from 'modules/subscriptions/components/common/CustomerRating'
import { SubheaderWithTimer } from 'modules/subscriptions/components/common/SubheaderWithTimer'
import { SubscriptionDescription } from 'modules/subscriptions/components/common/SubscriptionDescription'
import { Discount } from 'modules/subscriptions/components/discount'
import { PaidTrialItemVariant3 } from 'modules/subscriptions/components/trial/PaidTrialItemVariant3'
import { PLAN_ITEMS, TRIAL_PLAN_ITEMS } from 'modules/subscriptions/constants'
import { usePaywall } from 'modules/subscriptions/hooks/usePaywall'

import { DynamicDiscountBanner } from 'components/DynamicDiscountBanner'
import { HeaderWithButton } from 'components/HeaderWithButton'
import { ReviewsSlider } from 'components/ReviewSlider'
import { Spinner } from 'components/Spinner'

import {
  Cohort,
  DEFAULT_NONE_TRIAL_DISCOUNT_AMOUNT,
  DEFAULT_TRIAL_DISCOUNT_AMOUNT,
  SubscriptionTag,
} from 'root-constants/common'

import { StyledSubscriptionsVariant9 as S } from './SubscriptionsVariant9.styles'

export const SubscriptionsVariant9: React.FC<TPageProps> = ({
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const selectPlanBlockRef = useRef<HTMLDivElement>(null)

  const { subscriptions, dynamicDiscount, cohortToUse } = usePurchaseStore()
  const { hasPrices, handleSelectSubscription, handleShowPayment } =
    usePaywall(nextPagePath)

  useGetPrices({
    tags: SubscriptionTag.NO_TAX,
  })

  const { subscriptionBlockType } = useDynamicPaywallConfig()

  const planItem = PLAN_ITEMS[subscriptionBlockType]

  const isTrialPlan = TRIAL_PLAN_ITEMS.includes(subscriptionBlockType)

  const discountAmount = useMemo(() => {
    if (dynamicDiscount) {
      return dynamicDiscount.amount
    }
    if (isTrialPlan) {
      return DEFAULT_TRIAL_DISCOUNT_AMOUNT
    }
    return DEFAULT_NONE_TRIAL_DISCOUNT_AMOUNT
  }, [isTrialPlan, dynamicDiscount])

  const isSeventeenCohort = cohortToUse === Cohort.CARDIMATE_17

  useEffect(() => {
    if (!subscriptions.length) return

    eventLogger.logSalePageShown({
      productIds: subscriptions.map(
        ({ mainPrices: { periodName, periodQuantity, fullPrice } }) =>
          createProductId({
            periodName,
            periodQuantity,
            price: fullPrice,
          }),
      ),
      screenName: ScreenName.ONBOARDING,
    })
  }, [subscriptions])

  const handleScroll = () => {
    selectPlanBlockRef.current?.scrollIntoView({
      block: 'center',
    })
  }

  return !hasPrices ? (
    <Spinner />
  ) : (
    <S.Wrapper>
      <HeaderWithButton onClick={handleScroll} />
      <DynamicDiscountBanner marginTop={48} />
      <S.Content paddingTop={dynamicDiscount ? 40 : 80}>
        <SubheaderWithTimer discount={discountAmount} />
        <S.Title>
          <Trans i18nKey="subscriptions.achieveBloodPressure" />
        </S.Title>
        <S.Disclaimer>{t`subscriptions.bloodPressureProgram`}</S.Disclaimer>
        <S.HeartAge />
        <Discount marginBottom={isSeventeenCohort ? 50 : 25} />
        <S.SelectPlanBlock
          ref={selectPlanBlockRef}
          onSelect={handleSelectSubscription}
          SelectPlanItem={dynamicDiscount ? PaidTrialItemVariant3 : planItem}
        />
        <S.Button onClick={handleShowPayment}>{t`actions.getCardi`}</S.Button>
        {isTrialPlan ? (
          <SubscriptionDescription marginBottom={32} />
        ) : (
          <S.CancelInfo>{t`subscriptions.disclaimer`}</S.CancelInfo>
        )}
        <AccessVariant1 />
        <CustomerRatingVariant1 />
        <ReviewsSlider
          titlePath="subscriptions.customersLove"
          titleBottomMargin={24}
          marginBottom={32}
          textAlign="start"
        />
        <S.MoneyBackGuarantee />
      </S.Content>
    </S.Wrapper>
  )
}
