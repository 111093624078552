import React from 'react'
import { useTranslation } from 'react-i18next'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { OPTION_VALUES } from 'pages/smoking/constants'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'

import { Language } from 'root-constants/common'

import { StyledSmokingVariant2 as S } from './SmokingVariant2.styles'

export const SmokingVariant2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: t('onboarding.smoking.title2', { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    onChange: (value: string) => {
      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
    },
  }

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.smoking.title2`}</S.Title>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.REGULARLY}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`onboarding.smoking.regularly`}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.SOCIALLY}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`onboarding.smoking.socially`}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.NO}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`actions.no`}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.NOT_ANSWER}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`onboarding.smoking.notAnswer`}</QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </S.Wrapper>
  )
}
