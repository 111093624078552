import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { ThemeProvider } from 'styled-components'

import { selectCurrentVariantCohortToUse } from 'root-redux/selects/common'

import { Cohort } from 'root-constants/common'

import {
  COMMON_THEME,
  SENIOR_THEME,
  SENIOR_THEME_V2,
  SENIOR_THEME_V3,
} from '../../styles/themes'

type TProps = {
  children: React.ReactNode
}

export const Theme: React.FC<TProps> = ({ children }) => {
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)

  const currentTheme = useMemo(() => {
    switch (cohortToUse) {
      case Cohort.CARDIMATE_11:
      case Cohort.CARDIMATE_14:
        return SENIOR_THEME
      case Cohort.CARDIMATE_15:
      case Cohort.CARDIMATE_17:
      case Cohort.CARDIMATE_19:
        return SENIOR_THEME_V2
      case Cohort.CARDIMATE_20:
      case Cohort.CARDIMATE_21:
        return SENIOR_THEME_V3
      default:
        return COMMON_THEME
    }
  }, [cohortToUse])

  return <ThemeProvider theme={currentTheme}>{children}</ThemeProvider>
}
