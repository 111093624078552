import React, { useEffect, useLayoutEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { FeaturesReady, useFeatureIsOn } from '@growthbook/growthbook-react'

import { selectCurrentVariantCohortToUse } from 'root-redux/selects/common'

import { useCounter } from 'hooks/useCounter'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { replaceHistory } from 'browser-history'
import { COHORTS_WITH_SENIOR_FLOW } from 'root-constants/common'

import { StyledPlanProcessingVariant1 as S } from './PlanProcessingVariant1.styles'
import { PAGE_NAME, PROCESSING_TEXTS } from './constants'

export const PlanProcessingVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
  alternativePagePath,
}) => {
  const { t } = useTranslation()
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)

  const hasAlternativeTitle = useFeatureIsOn('web_433_profile_screen_test')
  const hasAlternativeProcessingPage = useFeatureIsOn('car_185_loader_test')
  const { counter } = useCounter(true, {
    duration: 8000,
  })

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAME,
    question: '',
    nextPagePath: alternativePagePath,
  })

  const isSeniorFlow = COHORTS_WITH_SENIOR_FLOW.includes(cohortToUse)

  useLayoutEffect(() => {
    if (hasAlternativeProcessingPage) {
      replaceHistory(nextPagePath)
    }
  }, [hasAlternativeProcessingPage, nextPagePath])

  useEffect(() => {
    const nextStepId = setTimeout(() => {
      handleContinue('')
    }, 10000)

    return () => {
      clearTimeout(nextStepId)
    }
  }, [handleContinue])

  return (
    <S.Wrapper>
      <S.Column>
        <FeaturesReady timeout={0} fallback={<div />}>
          {hasAlternativeTitle ? (
            <S.Title2>
              <Trans i18nKey="onboarding.processing.title2" />
            </S.Title2>
          ) : (
            <>
              <S.Title>{t`onboarding.processing.title`}</S.Title>
              <S.Text isSeniorFlow={isSeniorFlow}>
                {t`onboarding.processing.subtitle`}
              </S.Text>
            </>
          )}
        </FeaturesReady>
        <S.ProgressBar max={100} value={counter} />
        <S.Block>
          {PROCESSING_TEXTS.map((text, index) => (
            <S.Item key={text} data-item={index} isSeniorFlow={isSeniorFlow}>
              {t(text)}
            </S.Item>
          ))}
        </S.Block>
        {isSeniorFlow && (
          <S.Disclaimer>{t`onboarding.processing.trustedBy`}</S.Disclaimer>
        )}
      </S.Column>
    </S.Wrapper>
  )
}
