import styled, { css } from 'styled-components'

import { Button } from 'components/Button'
import { Switch } from 'components/Switch'

import minusIcon from 'assets/images/minus-accordion-icon.svg'
import plusIcon from 'assets/images/plus-accordion-icon.svg'

import { Color } from 'root-constants/common'

type TTitleProps = {
  marginBottom?: number
}

export const StyledCookieConsent = {
  Wrapper: styled.div<{ fullHeight: boolean }>`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    box-shadow: 0 8px 20px 0 #cccfde;
    background-color: ${Color.WHITE};
    z-index: 3;
    ${({ fullHeight }) => {
      return (
        fullHeight &&
        css`
          height: 100dvh;
          overflow-y: auto;
        `
      )
    }};
  `,
  Header: styled.header`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 24px;
    align-items: center;
    justify-content: flex-end;
    border-bottom: 1px solid #dcdfec;
    padding: 10px 12px;

    img {
      justify-self: center;
      margin-left: 24px;
    }
  `,
  CloseButton: styled.button`
    all: unset;
    display: flex;
    align-items: center;
    cursor: pointer;
  `,
  Container: styled.div`
    max-width: 375px;
    margin: 0 auto;
    padding: 16px 24px;
  `,
  Title: styled.h2<TTitleProps>`
    margin-bottom: ${({ marginBottom }) => `${marginBottom || 8}px`};
    font-size: 16px;
    font-weight: 800;
    line-height: 24px;
  `,
  Description: styled.p`
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #565976;

    a {
      text-decoration: underline;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      color: #565976;
    }
  `,
  ButtonsContainer: styled.div`
    padding-top: 16px;
    border-top: 1px solid #dcdfec;
  `,
  PrimaryButton: styled(Button)`
    height: 40px;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 800;
    line-height: 20px;
    border-radius: 12px;
  `,
  SecondaryButton: styled(Button)`
    height: 40px;
    margin-bottom: 8px;
    background-color: #f0f2fa;
    color: ${Color.DANGER};
    font-size: 14px;
    font-weight: 800;
    line-height: 20px;
    border-radius: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  `,
  Settings: styled.ul`
    border: 1px solid #dcdfec;
    border-bottom: none;
  `,
  SettingItem: styled.li`
    position: relative;

    & label {
      position: absolute;
      right: 16px;
      top: 20px;
    }
  `,
  SettingsTitle: styled.h2`
    margin: 32px auto 20px;
    font-size: 18px;
    font-weight: 800;
    line-height: 23px;
  `,
  Setting: styled.details`
    &[open] summary::after {
      background-image: url(${minusIcon});
    }

    &:last-child span {
      flex-basis: 164px;
    }
  `,
  SettingName: styled.summary`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 64px;
    padding: 12px 16px 12px 41px;
    font-size: 14px;
    font-weight: 800;
    line-height: 20px;
    border-bottom: 1px solid #dcdfec;

    &::-webkit-details-marker {
      display: none;
    }

    & span {
      flex-basis: 195px;
    }

    &::after {
      content: '';
      width: 20px;
      height: 20px;
      background-image: url(${plusIcon});
      background-size: contain;
      position: absolute;
      left: 16px;
      top: 50%;
      transform: translateY(-50%);
    }
  `,
  SettingDetails: styled.p`
    padding: 16px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    background-color: #f9fafb;
    color: ${Color.LIGHT_TEXT};
    border-bottom: 1px solid #dcdfec;
  `,
  ActiveLabel: styled.strong`
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    color: ${Color.DANGER};
  `,
  Divider: styled.div`
    width: 100%;
    height: 1px;
    margin-top: 8px;
    background-color: #dcdfec;
  `,
  Switch: styled(Switch)`
    width: 44px;
    height: 24px;

    input {
      &:checked ~ div {
        background: linear-gradient(180deg, #141529 58.59%, #2c2f54 100%),
          #f0f2fa;
        border: 1px solid transparent;
      }
    }

    span {
      top: 2px;
      left: 2px;
      width: 20px;
      height: 20px;
    }
  `,
}
