import styled from 'styled-components'

import checkIcon from 'assets/images/check.svg'
import bgImage from 'assets/images/summary-heart-score-bg.png'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color, HEADER_HEIGHT } from 'root-constants/common'

export const StyledSummaryPressureAnalyses = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Content: styled.div`
    ${baseColumnStyles};
    min-height: calc(100dvh - ${HEADER_HEIGHT}px);
    padding-top: 24px;
  `,
  Title: styled.h1`
    margin-bottom: 24px;
    font-size: 25px;
    font-weight: 900;
    line-height: 25px;

    strong {
      font-weight: 900;
      color: ${({ theme }) => theme.colors.highlightedText};
    }
  `,
  Card: styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    padding: 16px 16px 9px 16px;
    border-radius: 16px;
    background-color: ${Color.WHITE};
    background-image: url(${bgImage});
    background-size: calc(100% - 32px);
    background-repeat: no-repeat;
    background-position: center 36px;
    box-shadow: 0 8px 12px 0 rgba(152, 159, 187, 0.08);

    &::before {
      content: '';
      position: absolute;
      top: 36px;
      left: 50%;
      transform: translateX(-50%);
      width: calc(100% - 16px);
      height: 130px;
      background: linear-gradient(
        180deg,
        ${Color.WHITE} 0%,
        rgba(255, 255, 255, 0.7) 100%
      );
    }
  `,
  CardTitle: styled.h3`
    margin-bottom: 4px;
    font-size: 16px;
    font-weight: 900;
    line-height: 24px;
    z-index: 1;
  `,
  PressureWrapper: styled.div`
    display: flex;
    align-items: flex-end;
    gap: 8px;
    margin-bottom: 4px;
    z-index: 1;
  `,
  PressureValue: styled.span`
    font-size: 32px;
    font-weight: 900;
    line-height: 40px;
  `,
  PressureUnits: styled.span`
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  `,
  Date: styled.p`
    color: ${Color.LIGHT_TEXT};
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    z-index: 1;
    text-transform: capitalize;
  `,
  ImageContainer: styled.div`
    width: 100%;
    aspect-ratio: 295/86;
    z-index: 1;
  `,
  ListTitle: styled.h3`
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 900;
    line-height: 24px;
  `,
  List: styled.ul`
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  `,
  ListItem: styled.li`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
  `,
  CheckIcon: styled.div`
    position: relative;
    width: 20px;
    height: 20px;
    background: linear-gradient(180deg, #7bc27e 0%, #55bc5a 100%);
    border-radius: 50%;
    flex-shrink: 0;

    &::before {
      content: '';
      width: 20px;
      height: 20px;
      position: absolute;
      top: 0;
      left: 0;
      background-image: url(${checkIcon});
      background-position: center;
      background-size: 10px;
      background-repeat: no-repeat;
    }
  `,
}
