import React from 'react'
import { useTranslation } from 'react-i18next'

import { ISelectPlanItemProps } from 'modules/subscriptions/types'

import {
  CURRENCY_SYMBOLS,
  INTRO_OFFER_PERIODS,
  ProductKey,
  TRIAL_PERIOD_NAME_TO_MARKUP_MAP,
} from 'root-constants/common'

import { StyledPlanItemVariant2 as S } from './PaidTrialItemVariant2.styles'

export const PaidTrialItemVariant2: React.FC<ISelectPlanItemProps> = ({
  subscription: {
    isDefault,
    currency,
    product,
    mainPrices: { fullPrice },
    trialPrices: { fullPrice: trialPrice, durationDays, daily, oldPrices },
  },
  isSelected,
}) => {
  const { t } = useTranslation()

  return (
    <S.PlanItem
      data-is-selected={isSelected}
      data-is-default={isDefault}
      data-text={t`subscriptions.mostPopular`}
    >
      <S.Container>
        <S.PlanTitle>
          {t('subscriptions.trialPlanPeriod', {
            periodName: t(TRIAL_PERIOD_NAME_TO_MARKUP_MAP[durationDays], {
              count: INTRO_OFFER_PERIODS[durationDays],
            }),
            periodQuantity: INTRO_OFFER_PERIODS[durationDays],
          })}
        </S.PlanTitle>
        <S.PricesWrapper>
          <S.OldPrice>
            {CURRENCY_SYMBOLS[currency]}
            {(product === ProductKey.ONE_WEEK
              ? oldPrices.beforeCustomDiscount.fullPrice
              : fullPrice
            ).toFixed(2)}
          </S.OldPrice>
          <S.NewPrice>
            {CURRENCY_SYMBOLS[currency]}
            {trialPrice.toFixed(2)}
          </S.NewPrice>
        </S.PricesWrapper>
      </S.Container>
      {!!daily && (
        <S.Container>
          <S.CustomPrice
            data-is-selected={isSelected}
            data-currency-symbol-length={CURRENCY_SYMBOLS[currency]?.length}
          >
            <S.Price data-is-selected={isSelected}>
              {CURRENCY_SYMBOLS[currency]}
              {daily.toFixed(2)}
            </S.Price>
            <S.Period data-is-selected={isSelected}>
              {t`subscriptions.perDay`}
            </S.Period>
          </S.CustomPrice>
        </S.Container>
      )}
    </S.PlanItem>
  )
}
