import { ProductKey } from 'modules/subscriptions/constants'

import smartAi from 'assets/images/smart-ai.png'
import weeklyReports from 'assets/images/weekly-reports.png'

export const PLAN_ITEM_TITLES = {
  [ProductKey.UPSELL_BUNDLE]: 'upsell.allInOne',
  [ProductKey.AI_HELPER]: 'upsell.smartAi',
  [ProductKey.WEEKLY_REPORTS]: 'upsell.weeklyReports',
}

export const PLAN_ITEM_FONT_WEIGHT = {
  [ProductKey.UPSELL_BUNDLE]: 700,
  [ProductKey.AI_HELPER]: 800,
  [ProductKey.WEEKLY_REPORTS]: 800,
}
export const DESCRIPTION_DATA = {
  [ProductKey.AI_HELPER]: {
    img: smartAi,
    text: 'upsell.personalizedTips',
  },
  [ProductKey.WEEKLY_REPORTS]: {
    img: weeklyReports,
    text: 'upsell.stayOnTop',
  },
}
