import React from 'react'

import { HeaderV3 } from 'components/HeaderV3'

import { Cohort } from 'root-constants/common'

export const WHITE_BACKGROUND_COHORTS: string[] = [
  Cohort.CARDIMATE_20,
  Cohort.CARDIMATE_21,
]

export const HEADERS: Record<string, React.FC> = {
  [Cohort.CARDIMATE_20]: HeaderV3,
  [Cohort.CARDIMATE_21]: HeaderV3,
}
