import { useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import {
  selectAmplitudeApiKey,
  selectCurrentVariantCohort,
  selectLanguage,
  selectStripeAccountId,
  selectStripeAccountName,
} from 'root-redux/selects/common'
import {
  selectUUID,
  selectUserStatus,
  selectUtmTags,
} from 'root-redux/selects/user'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'
import { useGetPageInfo } from 'hooks/useGetPageInfo'

import { getEnvironment } from 'helpers/getEnvironment'

import { Amplitude } from 'services/amplitude.service'
import { eventLogger } from 'services/eventLogger.service'

import {
  EventLoggerInstanceName,
  Language,
  TEST_ENV_QUERY_PARAM,
} from 'root-constants/common'

export const useAmplitudeInitialization = (): void => {
  const { search } = useLocation()
  const cohort = useSelector(selectCurrentVariantCohort)
  const uuid = useSelector(selectUUID)
  const amplitudeApiKey = useSelector(selectAmplitudeApiKey)
  const stripeAccountId = useSelector(selectStripeAccountId)
  const stripeAccountName = useSelector(selectStripeAccountName)
  const utmTags = useSelector(selectUtmTags)
  const userStatus = useSelector(selectUserStatus)
  const language = useSelector(selectLanguage)

  const [amplitudeInstance, setAmplitudeInstance] = useState<Amplitude | null>(
    null,
  )

  const URLParams = new URLSearchParams(search)
  const isTestEnvironment = URLParams.has(TEST_ENV_QUERY_PARAM)
  const utmTagsFromUrl = Object.fromEntries(URLParams.entries())
  const { isProdEnvironment } = getEnvironment()

  const { isPaymentPage } = useGetPageInfo()
  const { hasAnalyticsAndPerformanceCookie, isEUUser } =
    useCookieConsentAnswer()

  const shouldAmplitudeBePaused = useMemo(
    () =>
      isEUUser && !hasAnalyticsAndPerformanceCookie && language === Language.EN,
    [hasAnalyticsAndPerformanceCookie, isEUUser, language],
  )

  const hasAmplitude = useMemo(
    () => isPaymentPage || userStatus?.hasSubscription,
    [isPaymentPage, userStatus?.hasSubscription],
  )

  useLayoutEffect(() => {
    if (eventLogger.isAmplitudeActive || shouldAmplitudeBePaused) return

    if (uuid && amplitudeApiKey && hasAmplitude) {
      const amplitudeService = new Amplitude({
        apiKey: amplitudeApiKey,
        instanceName: EventLoggerInstanceName.AMPLITUDE,
        userId: uuid,
        utmTags: utmTagsFromUrl.utm_source ? utmTagsFromUrl : utmTags,
        isTestEnvironment,
        isDevEnvironment: !isProdEnvironment,
      })

      amplitudeService.configure({ cohort, language })
      eventLogger.init(amplitudeService)
      setAmplitudeInstance(amplitudeService)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    amplitudeApiKey,
    cohort,
    isPaymentPage,
    isProdEnvironment,
    isTestEnvironment,
    search,
    userStatus?.hasSubscription,
    utmTags,
    uuid,
    shouldAmplitudeBePaused,
  ])

  useEffect(() => {
    if (!stripeAccountId || !stripeAccountName) return

    amplitudeInstance?.updateUserProperties(stripeAccountId, stripeAccountName)
  }, [amplitudeInstance, stripeAccountId, stripeAccountName])
}
