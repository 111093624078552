import React from 'react'
import { useTranslation } from 'react-i18next'

import { usePurchaseStore } from 'hooks/usePurchaseStore'
import { useVatInfo } from 'hooks/useVatInfo'

import { CURRENCY_SYMBOLS } from 'root-constants/common'

import { StyledDescription as S } from './Description.styles'

export const Description: React.FC = () => {
  const { t } = useTranslation()

  const { currentPrice, trialPriceId, periodQuantity, currency } =
    usePurchaseStore()

  const hasIncludedVAT = useVatInfo()

  return trialPriceId ? (
    <ul>
      <S.DescriptionItem>
        {t`payment.description.followInstructions`}
      </S.DescriptionItem>
      <S.DescriptionItem>
        {t('payment.description.trialPeriod', {
          periodQuantity,
          price: currentPrice.toFixed(2),
          currencySymbol: CURRENCY_SYMBOLS[currency],
          count: periodQuantity,
          minimumFractionDigits: 2,
        })}
      </S.DescriptionItem>
    </ul>
  ) : (
    <ul>
      <S.DescriptionItem>
        {t`payment.description.followInstructions`}
      </S.DescriptionItem>
      <S.DescriptionItem>{t`payment.description.autoRenew`}</S.DescriptionItem>
      <S.DescriptionItem>
        {t('payment.description.period', {
          periodQuantity,
          price: currentPrice.toFixed(2),
          currencySymbol: CURRENCY_SYMBOLS[currency],
          count: periodQuantity,
          minimumFractionDigits: 2,
          ...(hasIncludedVAT && { context: 'vat' }),
        })}
      </S.DescriptionItem>
    </ul>
  )
}
