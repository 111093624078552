import React, { Suspense } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Route, Routes } from 'react-router-dom'

import { GrowthBookProvider } from '@growthbook/growthbook-react'
import { PageInfoProvider } from 'providers/PageInfoProvider'
import { polyfill as smoothScrollPolyfill } from 'smoothscroll-polyfill'
import 'swiper/css'
import 'swiper/css/bundle'

import { selectConfig, selectCurrentVariant } from 'root-redux/selects/common'
import { selectUserStatus } from 'root-redux/selects/user'

import { useFacebookPixelInitialization } from 'hooks/useFacebookPixelInitialization'
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics'
import { growthbook, useGrowthBook } from 'hooks/useGrowthBook'
import { useInContextInitialization } from 'hooks/useInContextInitialization'
import { useInitAppConfig } from 'hooks/useInitAppConfig'
import { useHotJarInitialization } from 'hooks/useInitHotJar'
import { useLanguage } from 'hooks/useLanguage'
import { useScrollFix } from 'hooks/useScrollFix'
import { useSetUserStatus } from 'hooks/useSetUserStatus'
import { useSetUuid } from 'hooks/useSetUuid'
import { useSetVariant } from 'hooks/useSetVariant'

import { setViewportFullHeight } from 'helpers/setViewportFullHeight'

import { LayoutProvider } from 'components/LayoutProvider'
import { Spinner } from 'components/Spinner'

import { browserHistory } from 'browser-history'
import { CookiePolicy, PrivacyPolicy, TermsOfUse } from 'pages'
import { TOU_PP_COOKIE_LINKS } from 'root-constants/common'

import { CustomRouter } from './CustomRouter'

setViewportFullHeight()
smoothScrollPolyfill()

export const App: React.FC = () => {
  const userStatus = useSelector(selectUserStatus, shallowEqual)
  const variant = useSelector(selectCurrentVariant, shallowEqual)
  const config = useSelector(selectConfig, shallowEqual)

  useInitAppConfig()
  useGoogleAnalytics()
  useFacebookPixelInitialization()
  useInContextInitialization()
  useSetVariant()
  useLanguage()
  useSetUuid()
  useSetUserStatus()
  useScrollFix()
  useGrowthBook()
  useHotJarInitialization()

  return (
    <CustomRouter history={browserHistory}>
      <Suspense fallback={<Spinner />}>
        {TOU_PP_COOKIE_LINKS.includes(window.location.pathname) ? (
          <Routes>
            <Route path="terms-of-use" element={<TermsOfUse />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="cookie-policy" element={<CookiePolicy />} />
          </Routes>
        ) : (
          <GrowthBookProvider growthbook={growthbook}>
            {userStatus && variant && config ? (
              <PageInfoProvider>
                <LayoutProvider />
              </PageInfoProvider>
            ) : (
              <Spinner />
            )}
          </GrowthBookProvider>
        )}
      </Suspense>
    </CustomRouter>
  )
}
