import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { setAnswersAction } from 'root-redux/actions/common'
import { sendUserAnswersAction } from 'root-redux/actions/user'

import { useDelayedContinue } from 'hooks/useDelayedContinue'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'

import { goTo } from 'browser-history'
import { BLOOD_PRESSURE_OPTION_VALUES, Language } from 'root-constants/common'
import { CustomPageId } from 'root-constants/pages'

import { StyledBloodPressureVariant4 as S } from './BloodPressureVariant4.styles'
import { PAGE_NAME } from './constants'

export const BloodPressureVariant4: React.FC<TPageProps> = ({
  nextPagePath,
  alternativePagePath,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = (answer) => {
    dispatch(
      setAnswersAction({
        answers: answer,
        pageId: CustomPageId.BLOOD_PRESSURE,
      }),
    )

    dispatch(sendUserAnswersAction())

    eventLogger.logQuestion({
      question: t('onboarding.bloodPressure.question3', { lng: Language.EN }),
      answers: answer,
      pageName: PAGE_NAME,
    })

    if (answer === BLOOD_PRESSURE_OPTION_VALUES.BETWEEN_120_129_AND_LESS_80) {
      goTo(alternativePagePath)
      return
    }
    goTo(nextPagePath)
  }

  const optionProps = {
    name: CustomPageId.BLOOD_PRESSURE,
    type: OptionType.RADIO,
    onChange: (value: string) => {
      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
    },
  }

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.bloodPressure.question3`}</S.Title>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={BLOOD_PRESSURE_OPTION_VALUES.LESS_120_LESS_80}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>
              {BLOOD_PRESSURE_OPTION_VALUES.LESS_120_LESS_80}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={BLOOD_PRESSURE_OPTION_VALUES.BETWEEN_120_129_AND_LESS_80}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>
              {BLOOD_PRESSURE_OPTION_VALUES.BETWEEN_120_129_AND_LESS_80}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={BLOOD_PRESSURE_OPTION_VALUES.BETWEEN_130_139_AND_80_89}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>
              {BLOOD_PRESSURE_OPTION_VALUES.BETWEEN_130_139_AND_80_89}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={BLOOD_PRESSURE_OPTION_VALUES.MORE_140_MORE_90}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>
              {BLOOD_PRESSURE_OPTION_VALUES.MORE_140_MORE_90}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={BLOOD_PRESSURE_OPTION_VALUES.DONT_KNOW}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`actions.doNotKnow`}</QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </S.Wrapper>
  )
}
