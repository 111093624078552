import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { selectTaxAmount } from 'root-redux/selects/common'

import { getPriceWithoutTaxes } from 'helpers/getPriceWithoutTaxes'

import { ISelectPlanItemProps } from 'modules/subscriptions/types'

import {
  CURRENCY_SYMBOLS,
  PERIOD_NAME_TO_MARKUP_MAP,
} from 'root-constants/common'

import { StyledPlanItemVariant1 as S } from './PlanItemVariant1.styles'

export const VatPlanItemVariant1: React.FC<ISelectPlanItemProps> = ({
  subscription: {
    isDefault,
    currency,
    mainPrices: { periodQuantity, periodName, monthly },
  },
  isSelected,
}) => {
  const { t } = useTranslation()
  const taxAmount = useSelector(selectTaxAmount)
  const hasAlternativeVariant = useFeatureIsOn('web_432_vat_test')

  const pricePerMonthWithoutTaxes = useMemo(
    () => getPriceWithoutTaxes({ price: monthly, taxAmount }),
    [monthly, taxAmount],
  )

  const pricePerPeriod = useMemo(
    () => (hasAlternativeVariant ? monthly : pricePerMonthWithoutTaxes),
    [hasAlternativeVariant, monthly, pricePerMonthWithoutTaxes],
  )

  return (
    <S.PlanItem
      data-is-selected={isSelected}
      data-is-default={isDefault}
      data-text={t`subscriptions.specialOffer`}
    >
      <S.Container>
        <S.PlanTitle>
          {t('subscriptions.planPeriodV2', {
            periodQuantity,
            periodName: t(PERIOD_NAME_TO_MARKUP_MAP[periodName], {
              count: periodQuantity,
            }),
          })}
        </S.PlanTitle>
        <S.PaymentInformation>
          {t('subscriptions.billingCycle', {
            periodName: t(PERIOD_NAME_TO_MARKUP_MAP[periodName], {
              count: periodQuantity,
            }),
            count: periodQuantity,
          })}
        </S.PaymentInformation>
      </S.Container>
      <S.Container>
        <S.PlanPrice>
          {CURRENCY_SYMBOLS[currency]}
          {pricePerPeriod}
        </S.PlanPrice>
        <S.PricePeriod>{t`subscriptions.perMonth`}</S.PricePeriod>
      </S.Container>
    </S.PlanItem>
  )
}
