import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { getVariantAction } from 'root-redux/actions/common'

import { getIsHeartMonitorDomain } from 'helpers/getIsHeartMonitorDomain'
import { getIsMyCardimateDomain } from 'helpers/getIsMyCardimateDomain'

import { Cohort } from 'root-constants/common'

export const useSetVariant = (): void => {
  const isMyCardimateDomain = getIsMyCardimateDomain()
  const isHeartMonitorDomain = getIsHeartMonitorDomain()

  const dispatch = useDispatch()
  const URLParams = new URLSearchParams(document.location.search)
  const cohortFromUrl = URLParams.get('cohort')

  const defaultCohort = useMemo(() => {
    if (isHeartMonitorDomain) {
      return Cohort.HEART_MONITOR_16
    }
    if (isMyCardimateDomain) {
      return Cohort.CARDIMATE_16_NP
    }
    return Cohort.CARDIMATE_14
  }, [isHeartMonitorDomain, isMyCardimateDomain])

  useEffect(() => {
    dispatch(getVariantAction({ cohort: cohortFromUrl || defaultCohort }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
