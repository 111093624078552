import icon3 from 'assets/images/customized-reports-icon.png'
import icon2 from 'assets/images/pulse-hrv-icon.png'
import icon1 from 'assets/images/tracking-system-icon.png'

import { CDN_FOLDER_LINK, Images } from 'root-constants/common'

export const ACCESS_DATA = [
  {
    image: `${CDN_FOLDER_LINK}${Images.TRACKING_SYSTEM_PHONE}`,
    icon: icon1,
    title: 'subscriptions.access.trackingSystem',
    text: 'subscriptions.access.logPressure',
  },
  {
    image: `${CDN_FOLDER_LINK}${Images.PULSE_HRV_PHONE}`,
    icon: icon2,
    title: 'subscriptions.access.pulseHrv',
    text: 'subscriptions.access.measurePulse',
  },
  {
    image: `${CDN_FOLDER_LINK}${Images.CUSTOMIZED_REPORTS_PHONE}`,
    icon: icon3,
    title: 'subscriptions.access.customizedReports',
    text: 'subscriptions.access.monitorTrends',
  },
]
export const ACCESS_DATA_V2 = [
  {
    image: `${CDN_FOLDER_LINK}${Images.TRACKING_SYSTEM_PHONE}`,
    icon: icon1,
    title: 'subscriptions.access.trackingSystem',
    text: 'subscriptions.access.logHeartRate',
  },
  {
    image: `${CDN_FOLDER_LINK}${Images.PULSE_HRV_PHONE}`,
    icon: icon2,
    title: 'subscriptions.access.pulseHrv',
    text: 'subscriptions.access.measurePulse',
  },
  {
    image: `${CDN_FOLDER_LINK}${Images.CUSTOMIZED_REPORTS_PHONE}`,
    icon: icon3,
    title: 'subscriptions.access.customizedReports',
    text: 'subscriptions.access.monitorTrends',
  },
]
