import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

const EVENTS = [
  'mousemove',
  'keydown',
  'scroll',
  'click',
  'touchstart',
  'touchmove',
  'touchend',
]

const IDLE_TIME_QUERY_PARAM = 'idle_time'

export const useIdleTimer = ({
  idleTime,
  idleCallback,
  shouldBePaused = false,
}: {
  idleTime: number
  idleCallback: () => void
  shouldBePaused?: boolean
}) => {
  const { search } = useLocation()
  const intervalIdRef = useRef<number | null>(null)
  const lastActiveTimeRef = useRef<number>(Date.now())

  const searchParams = new URLSearchParams(search)
  const idleTimeFromUrl = searchParams.get(IDLE_TIME_QUERY_PARAM) || ''
  const currentIdleTime = +idleTimeFromUrl || idleTime

  useEffect(() => {
    const handleUserActivity = () => {
      lastActiveTimeRef.current = Date.now()
    }

    const checkIdleTime = () => {
      if (
        lastActiveTimeRef?.current &&
        Date.now() - lastActiveTimeRef.current > currentIdleTime
      ) {
        intervalIdRef.current && clearInterval(intervalIdRef.current)
        idleCallback()
      }
    }

    if (shouldBePaused) {
      EVENTS.forEach((event) =>
        window.removeEventListener(event, handleUserActivity),
      )
      intervalIdRef.current && clearInterval(intervalIdRef.current)
      lastActiveTimeRef.current = 0

      return () => {}
    }

    EVENTS.forEach((event) =>
      window.addEventListener(event, handleUserActivity),
    )

    const intervalId = setInterval(checkIdleTime, 1000) as unknown as number
    intervalIdRef.current = intervalId
    lastActiveTimeRef.current = Date.now()

    return () => {
      EVENTS.forEach((event) =>
        window.removeEventListener(event, handleUserActivity),
      )
      clearInterval(intervalId)
    }
  }, [idleCallback, currentIdleTime, shouldBePaused])
}
