import styled from 'styled-components'

import bgImage from 'assets/images/summary-heart-score-bg.png'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color, HEADER_HEIGHT } from 'root-constants/common'

export const StyledSummaryHeartScore = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Content: styled.div`
    ${baseColumnStyles};
    min-height: calc(100dvh - ${HEADER_HEIGHT}px);
    padding-top: 24px;
  `,
  Title: styled.h1`
    margin-bottom: 24px;
    font-size: 25px;
    font-weight: 900;
    line-height: 25px;

    strong {
      font-weight: 900;
      color: ${({ theme }) => theme.colors.highlightedText};
    }
  `,
  Card: styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;
    padding: 16px;
    border-radius: 16px;
    background-color: ${Color.WHITE};
    background-image: url(${bgImage});
    background-size: calc(100% - 32px);
    background-repeat: no-repeat;
    background-position: center 85px;
    box-shadow: 0 8px 12px 0 rgba(152, 159, 187, 0.08);

    &::before {
      content: '';
      position: absolute;
      top: 40px;
      left: 50%;
      transform: translateX(-50%);
      width: calc(100% - 16px);
      height: 247px;
      background: linear-gradient(
        180deg,
        ${Color.WHITE} 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  `,
  CardTitle: styled.h3`
    margin-bottom: 4px;
    text-align: center;
    font-size: 18px;
    font-weight: 900;
    line-height: 24px;

    strong {
      font-weight: 900;
      color: #ffc600;
    }
  `,
  CardSubtitle: styled.p`
    color: ${Color.LIGHT_TEXT};
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    z-index: 1;
  `,
  ImageContainer: styled.div`
    width: 100%;
    max-width: 220px;
    aspect-ratio: 220/220;
    z-index: 1;
  `,
  CardText: styled.p`
    margin-top: -8px;
    padding: 8px 12px;
    border-radius: 20px;
    background-color: #f7f9fc;
    color: ${Color.LIGHT_TEXT};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    strong {
      color: ${Color.DANGER};
      font-weight: 800;
    }
  `,
  Disclaimer: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 8px 12px;
    border-radius: 20px;
    background-color: ${Color.WHITE};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    svg {
      flex-shrink: 0;
    }

    strong {
      font-weight: 800;
    }
  `,
}
