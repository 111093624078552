import React, { useEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { usePageInfo } from 'providers/PageInfoProvider'

import { selectUserHeartAge } from 'root-redux/selects/common'

import { useHeartMonitorDomain } from 'hooks/ui/useHeartMonitorDomain'
import { useUserData } from 'hooks/user/useUserData'
import { useUserSymptoms } from 'hooks/user/useUserSymptoms'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { Button } from 'components/Button'

import activityIcon from 'assets/images/activity-icon.png'
import arrowIcon from 'assets/images/arrow-repeate.svg'
import goalIcon from 'assets/images/goal-icon.png'
import heartProgress from 'assets/images/heart-progress.png'
import heartRateIcon from 'assets/images/heart-rate.svg'
import presentationIcon from 'assets/images/presentation.svg'
import symptomsIcon from 'assets/images/symptoms-icon.png'

import { goTo } from 'browser-history'

import { StyledSummaryHeartPlan as S } from './SummaryHeartPlan.styles'
import {
  ACTIVITY_TO_MARKUP,
  GOAL_TO_MARKUP,
  MAX_SYMPTOMS_SIZE,
} from './constants'

export const SummaryHeartPlan: React.FC<TPageProps> = ({ pageId }) => {
  const { search } = useLocation()
  const { t } = useTranslation()
  const userHeartAge = useSelector(selectUserHeartAge)

  const { currentSubscriptionPageId } = usePageInfo()
  const { goal, activity, symptoms } = useUserData()
  const userSymptoms = useUserSymptoms(MAX_SYMPTOMS_SIZE)
  const { productName } = useHeartMonitorDomain()

  const formattedUserSymptoms = useMemo(() => {
    if (!symptoms) return []
    return symptoms?.length <= MAX_SYMPTOMS_SIZE
      ? userSymptoms
      : [...userSymptoms, t`commonComponents.more`]
  }, [userSymptoms, symptoms, t])

  useEffect(() => {
    eventLogger.logPresalePageShown({
      pageName: pageId,
    })
  }, [pageId])

  const handleContinue = () => {
    eventLogger.logPresalePageCompleted({
      pageName: pageId,
    })
    goTo({ pathname: currentSubscriptionPageId, search })
  }

  return (
    <S.Wrapper>
      <S.Content>
        <S.Title>
          <Trans i18nKey="summary.summaryHeartPlan.title" />
        </S.Title>
        <S.BenefitsWrapper>
          <S.BenefitsContentWrapper>
            <S.BenefitsIcon src={goalIcon} alt="icon" />
            <S.BenefitsContent>
              <S.BenefitsTitle>{t`summary.summaryHeartPlan.goal`}</S.BenefitsTitle>
              <S.BenefitsText>
                {t(GOAL_TO_MARKUP[goal as string])}
              </S.BenefitsText>
            </S.BenefitsContent>
          </S.BenefitsContentWrapper>
          {!!userSymptoms.length && (
            <S.BenefitsContentWrapper>
              <S.BenefitsIcon src={symptomsIcon} alt="icon" />
              <S.BenefitsContent>
                <S.BenefitsTitle>{t`summary.summaryHeartPlan.symptoms`}</S.BenefitsTitle>
                <S.BenefitsText>
                  <Trans
                    i18nKey="subscriptions.subscription3.goals.symptoms"
                    values={{
                      symptoms: formattedUserSymptoms,
                      style: 'long',
                      type: 'conjunction',
                    }}
                  />
                </S.BenefitsText>
              </S.BenefitsContent>
            </S.BenefitsContentWrapper>
          )}
          <S.BenefitsContentWrapper>
            <S.BenefitsIcon src={activityIcon} alt="icon" />
            <S.BenefitsContent>
              <S.BenefitsTitle>{t`summary.summaryHeartPlan.activityLevel`}</S.BenefitsTitle>
              <S.BenefitsText>{t(ACTIVITY_TO_MARKUP[activity])}</S.BenefitsText>
            </S.BenefitsContent>
          </S.BenefitsContentWrapper>
        </S.BenefitsWrapper>
        <S.Title>{t`summary.summaryHeartPlan.heartHealthSummary`}</S.Title>
        <S.SummaryWrapper>
          <S.SummaryContentWrapper>
            <S.SummaryIcon src={heartRateIcon} alt="icon" />
            <S.SummaryText>
              <Trans i18nKey="summary.summaryHeartPlan.heartRate" />
            </S.SummaryText>
          </S.SummaryContentWrapper>
          <S.SummaryContentWrapper>
            <S.SummaryIcon src={arrowIcon} alt="icon" />
            <S.SummaryText>
              <Trans
                i18nKey="summary.summaryHeartPlan.heartAge"
                values={{ age: userHeartAge }}
              />
            </S.SummaryText>
          </S.SummaryContentWrapper>
          <S.SummaryContentWrapper>
            <S.SummaryIcon src={presentationIcon} alt="icon" />
            <S.SummaryText>
              <Trans i18nKey="summary.summaryHeartPlan.cardicDisease" />
            </S.SummaryText>
          </S.SummaryContentWrapper>
        </S.SummaryWrapper>
        <S.UsersWrapper>
          <S.HeartImage src={heartProgress} alt="icon" />
          <S.UsersContent>
            <S.UsersPercentage>{t`summary.summaryHeartPlan.82`}</S.UsersPercentage>
            <S.UsersText>
              {t('summary.summaryHeartPlan.cardiMate', { productName })}
            </S.UsersText>
          </S.UsersContent>
        </S.UsersWrapper>
        <Button type="button" onClick={handleContinue}>
          {t`actions.continue`}
        </Button>
      </S.Content>
    </S.Wrapper>
  )
}
