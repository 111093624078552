import React from 'react'
import { useTranslation } from 'react-i18next'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useIsCurrentCohort } from 'hooks/useIsCurrentCohort'
import { useNextStep } from 'hooks/useNextStep'
import { useUserData } from 'hooks/useUserData'

import { TPageProps } from 'models/common.model'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'

import { Cohort, Language } from 'root-constants/common'

import { StyledDailyWaterVariant1 as S } from './DailyWaterVariant1.styles'
import { DYNAMIC_TITLE_OPTIONS, OPTION_VALUES } from './constants'

export const DailyWaterVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const { weightDescription } = useUserData()
  const isRendered = useIsCurrentCohort(Cohort.CARDIMATE_19_FEED)

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: t('onboarding.dailyWater.title', {
      lng: Language.EN,
    }),
    nextPagePath,
  })

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    onChange: (value: string) => {
      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
    },
  }

  return (
    <S.Wrapper>
      <S.Column>
        {isRendered && (
          <S.Title>{t(DYNAMIC_TITLE_OPTIONS[weightDescription])}</S.Title>
        )}
        <S.Title>{t`onboarding.dailyWater.title`}</S.Title>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.LESS_THAN_4}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`onboarding.dailyWater.lessThan4`}</QuestionButton>
          </Option>

          <Option
            {...optionProps}
            value={OPTION_VALUES.FROM_4_TO_8}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`onboarding.dailyWater.from4to8`}</QuestionButton>
          </Option>

          <Option
            {...optionProps}
            value={OPTION_VALUES.MORE_THAN_8}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`onboarding.dailyWater.moreThan8`}</QuestionButton>
          </Option>

          <Option
            {...optionProps}
            value={OPTION_VALUES.DONT_KNOW}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`onboarding.dailyWater.dontKnow`}</QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </S.Wrapper>
  )
}
