import React, { useEffect, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'
import { useGetPrices } from 'hooks/useGetPrices'
import { usePurchaseStore } from 'hooks/usePurchaseStore'
import { useUserData } from 'hooks/useUserData'

import { createProductId } from 'helpers/createProductId'

import { TPageProps } from 'models/common.model'

import { ScreenName, eventLogger } from 'services/eventLogger.service'

import { MoneyBackGuarantee } from 'modules/subscriptions/components/common/MoneyBackGuarantee'
import { SubscriptionDescription } from 'modules/subscriptions/components/common/SubscriptionDescription'
import { usePaywall } from 'modules/subscriptions/hooks/usePaywall'

import { HeaderWithButton } from 'components/HeaderWithButton'
import {
  ReviewsSlider,
  ReviewsSliderAlternative,
} from 'components/ReviewSlider'
import { Spinner } from 'components/Spinner'

import scoresImg from 'assets/images/scores.webp'
import heartRateImg from 'assets/images/subscriptions-heart-rate.jpg'

import { Goal, SubscriptionTag } from 'root-constants/common'

import { SelectPlanBlockVariant1 } from '../../components/common/SelectPlanBlockVariant1'
import { SubheaderWithTimer } from '../../components/common/SubheaderWithTimer'
import {
  COMMON_BENEFITS,
  PLAN_ITEMS,
  REDUCE_STRESS_BENEFITS,
} from '../../constants'
import { StyledPaidTrialSubscriptions as S } from './PaidTrialSubscriptions.styles'

export const PaidTrialSubscriptions: React.FC<TPageProps> = ({
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const startPlanButtonRef = useRef<HTMLButtonElement>(null)

  const { subscriptions } = usePurchaseStore()
  const { goal } = useUserData()
  const { hasPrices, handleSelectSubscription, handleShowPayment } =
    usePaywall(nextPagePath)

  useGetPrices({
    tags: SubscriptionTag.NO_TAX,
  })
  const { subscriptionBlockType } = useDynamicPaywallConfig()

  useEffect(() => {
    if (!subscriptions.length) return

    eventLogger.logSalePageShown({
      productIds: subscriptions.map(
        ({ mainPrices: { periodName, periodQuantity, fullPrice } }) =>
          createProductId({
            periodName,
            periodQuantity,
            price: fullPrice,
          }),
      ),
      screenName: ScreenName.ONBOARDING,
      goal,
    })
  }, [goal, subscriptions])

  const handleScroll = () => {
    startPlanButtonRef.current?.scrollIntoView({
      block: 'end',
    })
  }

  return !hasPrices ? (
    <Spinner />
  ) : (
    <S.Wrapper>
      <HeaderWithButton onClick={handleScroll} />
      <S.Content>
        <SubheaderWithTimer discount={50} />
        {goal === Goal.REDUCE_STRESS ? (
          <>
            <S.Title>
              <Trans i18nKey="subscriptions.takeControl" />
            </S.Title>
            <S.Subtitle>
              <Trans i18nKey="subscriptions.stressFeedback" />
            </S.Subtitle>
            <S.List>
              <ul>
                {REDUCE_STRESS_BENEFITS.map((path) => (
                  <li key={path}>{t(path)}</li>
                ))}
              </ul>
              <img src={scoresImg} alt="scores on mobile phone" width={295} />
            </S.List>
          </>
        ) : (
          <>
            <S.Congratulations>{t`subscriptions.congrats`}</S.Congratulations>
            <S.Title>{t`subscriptions.rightTrack`}</S.Title>
            <S.Disclaimer>{t`subscriptions.trackingTools`}</S.Disclaimer>

            <S.List>
              <ul>
                {COMMON_BENEFITS.map((path) => (
                  <li key={path}>{t(path)}</li>
                ))}
              </ul>
              <img
                src={heartRateImg}
                alt="scores on mobile phone"
                width={295}
              />
            </S.List>
          </>
        )}

        <S.SubscriptionBlockTitle>
          {t`subscriptions.investInHealth`}
        </S.SubscriptionBlockTitle>
        <SelectPlanBlockVariant1
          onSelect={handleSelectSubscription}
          SelectPlanItem={PLAN_ITEMS[subscriptionBlockType]}
        />

        <S.Button onClick={handleShowPayment} buttonRef={startPlanButtonRef}>
          {t`actions.getMyPlan`}
        </S.Button>
        <SubscriptionDescription />

        {goal === Goal.REDUCE_STRESS ? (
          <ReviewsSliderAlternative
            titlePath="subscriptions.customersLove"
            titleBottomMargin={48}
            marginBottom={40}
          />
        ) : (
          <ReviewsSlider
            titlePath="subscriptions.customersLove"
            titleBottomMargin={48}
            marginBottom={40}
          />
        )}
        <MoneyBackGuarantee />
      </S.Content>
    </S.Wrapper>
  )
}
