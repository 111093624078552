import styled, { css } from 'styled-components'

import {
  baseColumnStyles,
  basePageStyles,
  baseTitleStyles,
} from 'common-styles'
import { Color, HEADER_HEIGHT } from 'root-constants/common'

const baseSystemButtonsWrapperStyles = css`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  background-color: ${({ theme }) => theme.colors.measurementSystemBackground};
  border-radius: 12px;
  margin-bottom: 8px;
`

const baseSystemButtonStyles = css`
  width: calc(50% - 4px);
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;
  text-transform: uppercase;
  color: ${Color.LIGHT_TEXT};
  border-radius: 10px;
  border: none;
  padding: 8px 0;
`

export const StyledWeight = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding: 46px 0;
  `,
  Title: styled.h1`
    ${baseTitleStyles};
    margin-bottom: 12px;
  `,
  ButtonsWrapper: styled.div`
    ${baseSystemButtonsWrapperStyles};
    gap: 4px;
  `,
  SystemButton: styled.button`
    ${baseSystemButtonStyles};
    background-color: ${Color.WHITE};
  `,
  SystemWrapper: styled.div`
    width: 100%;
    margin-bottom: 4px;
  `,
}
