import React from 'react'
import { useTranslation } from 'react-i18next'

import { getCurrentEnv } from 'helpers/getCurrentEnv'
import { getIsHeartMonitorDomain } from 'helpers/getIsHeartMonitorDomain'

import { eventLogger } from 'services/eventLogger.service'

import { CurrentEnvironment, SUPPORT_EMAIL_LINK } from 'root-constants/common'

type TProps = {
  text?: string
  className?: string
}

export const CONTACT_FORM_LINKS = {
  [CurrentEnvironment.DEV]: 'https://account-cardimate.gidev.xyz/contact-form',
  [CurrentEnvironment.PROD]: 'https://account.cardimate.com/contact-form',
  [CurrentEnvironment.STAGE]:
    'https://account-cardimate.gistage.com/contact-form',
}

export const SupportLink: React.FC<TProps> = ({
  text = 'commonComponents.contactSupport',
  className,
}) => {
  const currentEnv = getCurrentEnv()
  const { t } = useTranslation()
  const isHeartMonitorDomain = getIsHeartMonitorDomain()

  const link = isHeartMonitorDomain
    ? `mailto:${SUPPORT_EMAIL_LINK}`
    : CONTACT_FORM_LINKS[currentEnv || CurrentEnvironment.STAGE]

  const handleClick = () => eventLogger.logNeedHelpClicked()

  return (
    <a
      href={link}
      className={className}
      target="_blank"
      rel="noreferrer"
      onClick={handleClick}
    >
      {t(text)}
    </a>
  )
}
