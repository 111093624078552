import styled, { keyframes } from 'styled-components'

import { Color, MediaBreakpoint } from 'root-constants/common'

type TWrapperProps = {
  backgroundColor: string
  marginTop?: number
}

const GAP_VALUE = 4

const scroll = keyframes`
    to {
        transform: translateX(calc(-100% - ${GAP_VALUE}px));
    }
`

export const StyledDynamicDiscountBanner = {
  Wrapper: styled.div<TWrapperProps>`
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    width: 100%;
    min-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${GAP_VALUE}px;
    background: ${({ backgroundColor }) => backgroundColor};
    overflow: hidden;
    margin-top: ${({ marginTop }) => marginTop || 0}px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  Container: styled.ul`
    min-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    gap: ${GAP_VALUE}px;
    list-style: none;
    user-select: none;
    animation: ${scroll} 50s linear infinite;
  `,
  Sale: styled.li`
    display: flex;
    align-items: center;

    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    color: ${Color.WHITE};
    text-transform: uppercase;
    position: relative;

    & svg {
      width: 24px;
      height: 24px;
      margin: 0 4px 0 0;
    }
  `,
}
