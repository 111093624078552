import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { usePageInfo } from 'providers/PageInfoProvider'

import { useUserData } from 'hooks/useUserData'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { HeartStats } from 'modules/summary/components/HeartStats'

import beforeAfterGraphic from 'assets/images/before-after-graphic.png'

import { goTo } from 'browser-history'

import { StyledSummaryTestResults as S } from './SummaryTestResults.styles'

export const SummaryTestResults: React.FC<TPageProps> = ({ pageId }) => {
  const { t } = useTranslation()

  const { currentSubscriptionPageId } = usePageInfo()
  const { search } = useLocation()
  const { name } = useUserData()

  useEffect(() => {
    eventLogger.logPresalePageShown({
      pageName: pageId,
    })
  }, [pageId])

  const handleContinue = () => {
    eventLogger.logPresalePageCompleted({
      pageName: pageId,
    })
    goTo({ pathname: currentSubscriptionPageId, search })
  }

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>
          {t('summary.summaryTestResults.title', {
            name: name ? `${name},` : '',
          })}
        </S.Title>
        <HeartStats />
        <S.Subtitle>{t`summary.summaryTestResults.subtitle`}</S.Subtitle>
        <S.ImageContainer>
          <img src={beforeAfterGraphic} alt="graphic" />
        </S.ImageContainer>
        <S.Note>
          <Trans i18nKey="summary.summaryTestResults.note" />
        </S.Note>
        <S.Button type="button" onClick={handleContinue}>
          {t`actions.getMyPlan`}
        </S.Button>
      </S.Column>
    </S.Wrapper>
  )
}
