import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { setMultipleAnswerAction } from 'root-redux/actions/common'
import { sendUserAnswersAction } from 'root-redux/actions/user'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useDynamicPage } from 'hooks/useDynamicPage'
import { useFirstPageFinish } from 'hooks/useFirstPageFinish'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { QUESTION } from 'pages/age-range/constants'

import { OptionType } from 'components/Option'
import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { SvgImage } from 'components/SvgImage'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import arrowIcon from 'assets/images/sprite/arrow-right.svg'

import { goTo } from 'browser-history'
import { STATIC_HEART_AGE } from 'root-constants/common'
import { CustomPageId } from 'root-constants/pages'

import { StyledDynamicRange as S } from './DynamicRange.styles'

export const DynamicRange: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const {
    title,
    subtitle,
    pageTexts: [firstText, secondText],
    optionData,
    customPageId,
  } = useDynamicPage(pageId, nextPagePath)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { handleChange, isAnswersDisabled, setIsAnswersDisabled } =
    useDelayedContinue()
  const handleFirstPageFinish = useFirstPageFinish()

  const handleContinue = (age) => {
    dispatch(
      setMultipleAnswerAction({
        answers: {
          [CustomPageId.AGE]: age,
          heart_age: +age + STATIC_HEART_AGE,
        },
      }),
    )
    dispatch(sendUserAnswersAction())

    eventLogger.logQuestion({
      question: title || QUESTION,
      answers: age,
      pageName: customPageId || CustomPageId.AGE,
    })

    goTo(nextPagePath)
  }

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    onChange: (value: string) => {
      handleFirstPageFinish()
      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
    },
  }

  return (
    <S.Wrapper>
      <S.Column>
        {!!title && <S.Title>{title}</S.Title>}
        {!!subtitle && <S.Subtitle>{subtitle}</S.Subtitle>}
        {!!firstText && <S.Text>{firstText}</S.Text>}
        <S.OptionsContainer>
          {optionData?.map(({ value, imageUrl, text }) => (
            <S.Option
              {...optionProps}
              key={value}
              disabled={isAnswersDisabled}
              value={value}
            >
              <S.Action>
                <S.RangeImage src={imageUrl} alt="range" />
                <S.Answer />
                <S.Range>
                  {!!text && text}
                  <SvgImage svg={arrowIcon} height={10} />
                </S.Range>
              </S.Action>
            </S.Option>
          ))}
        </S.OptionsContainer>
        <S.AgreementText>{t`onboarding.intro.byContinuing`}</S.AgreementText>
        <S.PoliciesLinksWrapper>
          <TermsOfUseLink />
          <PrivacyPolicyLink />
        </S.PoliciesLinksWrapper>
        <S.Description>
          {secondText || t`commonComponents.description`}
        </S.Description>
      </S.Column>
    </S.Wrapper>
  )
}
