import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { usePageInfo } from 'providers/PageInfoProvider'

import {
  selectCurrentVariantCohortToUse,
  selectCurrentVariantSteps,
} from 'root-redux/selects/common'

import { useUserData } from 'hooks/useUserData'

import { IStep } from 'models/variant.model'

import { Cohort } from 'root-constants/common'

import { StyledProgressBarV2 as S } from './ProgressBarV3.styles'
import {
  FEMALE_OPTIONAL_PAGES,
  MALE_OPTIONAL_PAGES,
  ONBOARDING_END,
  ONBOARDING_START,
  PROFILE_BREAKPOINTS,
  PROFILE_TEXTS,
} from './constants'

export const ProgressBarV3: React.FC = () => {
  const { t } = useTranslation()
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)
  const steps = useSelector(selectCurrentVariantSteps)
  const { isMale } = useUserData()

  const isBloodPressureCohort =
    cohortToUse === Cohort.CARDIMATE_17 || cohortToUse === Cohort.CARDIMATE_19

  const { currentPageId, hasProgressbar } = usePageInfo()

  const visibleSteps = useMemo(() => {
    if (!isBloodPressureCohort) {
      return steps
    }
    return steps.filter(({ id }) =>
      isMale
        ? !FEMALE_OPTIONAL_PAGES.includes(id)
        : !MALE_OPTIONAL_PAGES.includes(id),
    )
  }, [steps, isBloodPressureCohort, isMale])

  const questionPages = visibleSteps.filter(({ isQuestion }) => isQuestion)

  const currentPageIndex = questionPages.findIndex(
    ({ id }) => id === currentPageId,
  )

  const segmentedSteps: IStep[][] = useMemo(
    () =>
      visibleSteps.reduce(
        (stepsWithSegments: IStep[][], currentStep: IStep) => {
          const breakPointIndex = PROFILE_BREAKPOINTS[cohortToUse]?.findIndex(
            (item) => item === currentStep.id,
          )

          if (breakPointIndex >= 0) {
            stepsWithSegments[breakPointIndex] = [currentStep]
          } else {
            stepsWithSegments[stepsWithSegments.length - 1]?.push(currentStep)
          }
          return stepsWithSegments
        },
        [],
      ),
    [cohortToUse, visibleSteps],
  )

  const segmentedOnboardingSteps = segmentedSteps.slice(
    ONBOARDING_START,
    ONBOARDING_END,
  )

  const [currentSectionIndex, currentStepIndex] = useMemo(() => {
    const sectionIndex = segmentedSteps.findIndex(
      (block) => !!block?.find((step) => step.id === currentPageId),
    )
    const stepIndex = segmentedSteps[sectionIndex]?.findIndex(
      (step) => step.id === currentPageId,
    )
    return [sectionIndex, stepIndex]
  }, [currentPageId, segmentedSteps])

  return !hasProgressbar ? null : (
    <S.Wrapper>
      <S.TitleWrapper>
        <S.Title>{t(PROFILE_TEXTS[cohortToUse][currentSectionIndex])}</S.Title>
        <S.PagesCounter>
          {currentPageIndex + 1} / {questionPages.length}
        </S.PagesCounter>
      </S.TitleWrapper>
      <S.ProgressContainer>
        {segmentedOnboardingSteps.map((stepsBlock, index) => {
          const currentBlockProgressValue =
            currentSectionIndex === index ? currentStepIndex + 1 : 0

          const progressValue =
            currentSectionIndex > index
              ? stepsBlock.length
              : currentBlockProgressValue

          return (
            <S.Progress
              key={stepsBlock[0].id}
              max={stepsBlock.length}
              value={progressValue}
            />
          )
        })}
      </S.ProgressContainer>
    </S.Wrapper>
  )
}
