import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { Button } from 'components/Button'
import { StickyButtonContainer } from 'components/StickyButtonContainer'
import { SvgImage } from 'components/SvgImage'

import doneIcon from 'assets/images/sprite/done-green-icon.svg'

import { goTo } from 'browser-history'
import { PageId } from 'root-constants/pages'

import { StyledSummaryBloodPressurePlan as S } from './SummaryBloodPressurePlan.styles'

export const SummaryBloodPressurePlan: React.FC<TPageProps> = ({ pageId }) => {
  const { t } = useTranslation()
  const { search } = useLocation()

  useEffect(() => {
    eventLogger.logPresalePageShown({
      pageName: pageId,
    })
  }, [pageId])

  const handleContinue = () => {
    eventLogger.logPresalePageCompleted({
      pageName: pageId,
    })
    goTo({ pathname: PageId.SUMMARY_PRESSURE_ANALYSES, search })
  }

  return (
    <S.Wrapper>
      <S.Content>
        <S.Title>
          <Trans
            i18nKey="summary.summaryBloodPressurePlan.title"
            components={{
              image: <SvgImage svg={doneIcon} width={56} />,
            }}
          />
        </S.Title>
        <S.Subtitle>{t`summary.summaryBloodPressurePlan.subtitle`}</S.Subtitle>
        <ul>
          {(
            t('summary.summaryBloodPressurePlan.planData', {
              returnObjects: true,
            }) as string[]
          ).map((text) => (
            <S.PlanDescription key={text}>{text}</S.PlanDescription>
          ))}
        </ul>
        <StickyButtonContainer>
          <Button type="button" onClick={handleContinue}>
            {t`actions.continue`}
          </Button>
        </StickyButtonContainer>
      </S.Content>
    </S.Wrapper>
  )
}
