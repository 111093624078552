import styled from 'styled-components'

import { basePageStyles } from 'common-styles'
import { Color, HEADER_HEIGHT, MediaBreakpoint } from 'root-constants/common'

export const StyledUsersAroundTheWorldVariant1 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Content: styled.div`
    width: 100%;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    height: calc(100dvh - ${HEADER_HEIGHT}px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 40px;
    background: radial-gradient(
      119.61% 100% at 47.47% 100%,
      rgba(240, 242, 250, 0.4) 0%,
      #f0f2fa 39.73%,
      #afccff 100%
    );

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  ImageContainer: styled.div`
    width: 100%;
    aspect-ratio: 375/320;
    margin-bottom: 60px;
  `,
  Title: styled.h2`
    margin-bottom: 16px;
    text-align: center;
    font-size: 22px;
    font-weight: 900;
    line-height: 30px;
  `,
  Text: styled.p`
    flex-grow: 1;
    margin-bottom: 50px;
    color: ${Color.LIGHT_TEXT};
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    max-width: 375px;
  `,
}
