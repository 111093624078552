import React from 'react'
import { useSelector } from 'react-redux'

import { usePageInfo } from 'providers/PageInfoProvider'

import { selectCurrentVariantCohortToUse } from 'root-redux/selects/common'

import { useHeartMonitorDomain } from 'hooks/ui/useHeartMonitorDomain'

import logoImg from 'assets/images/app-name-logo.png'
import heartMonitorLogo from 'assets/images/heart-monitor-logo.png'

import {
  COHORTS_WITH_BURGER_MENU,
  Cohort,
  PAGES_WITH_BURGER_MENU,
} from 'root-constants/common'

import { BurgerMenu } from '../BurgerMenu'
import { ProgressBar } from '../ProgressBar'
import { StyledHeader as S } from './Header.styles'

export const Header: React.FC = () => {
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)
  const { isHeartMonitorDomain } = useHeartMonitorDomain()

  const { currentPageId, hasHeader } = usePageInfo()

  const hasBurgerMenu =
    COHORTS_WITH_BURGER_MENU.includes(cohortToUse) &&
    PAGES_WITH_BURGER_MENU.includes(currentPageId)

  const hasAlternativeProgressBar =
    cohortToUse === Cohort.CARDIMATE_15 ||
    cohortToUse === Cohort.CARDIMATE_17 ||
    cohortToUse === Cohort.CARDIMATE_19

  return !hasHeader ? null : (
    <S.Wrapper>
      <S.Header>
        <img
          height="28"
          src={isHeartMonitorDomain ? heartMonitorLogo : logoImg}
          alt="logo"
        />
      </S.Header>
      {hasBurgerMenu && <BurgerMenu />}
      {!hasAlternativeProgressBar && <ProgressBar />}
    </S.Wrapper>
  )
}
