import { StripeError } from '@stripe/stripe-js'

import { IRequestOptions, IResponseResult } from 'models/api.model'
import {
  IBaseSubscriptionInfoRaw,
  IPrimerClientSessionResponseRaw,
  IPrimerConfirmPaymentResponseRaw,
  IPrimerPaymentResponseRaw,
  IPurchaseResponseRaw,
  IPurchaseRetrySubscriptionResponseRaw,
} from 'models/payment.model'

import { ApiService } from 'services/api.service'

import { APP_NAME, RequestMethod } from 'root-constants/common'

export class PaymentApi {
  private readonly baseUrl = 'v2/purchases'
  private api: ApiService

  constructor(api: ApiService) {
    this.api = api
  }

  createSubscription({
    uuid,
    planId,
    trialPeriodDays,
    promoCode,
    cohort,
  }: {
    uuid: string
    promoCode?: string
    planId: number
    trialPeriodDays: number
    cohort: string
  }): Promise<IResponseResult<IPurchaseResponseRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        plan_id: planId,
        promo_code: promoCode,
        trial_duration: trialPeriodDays,
        cohort,
      },
    }

    return this.api.makeRequest<IPurchaseResponseRaw>(
      `${this.baseUrl}/stripe/${uuid}/${APP_NAME}`,
      options,
    )
  }

  retryPayment({
    uuid,
    stripeError,
  }: {
    uuid: string
    stripeError: StripeError
  }): Promise<IResponseResult<IPurchaseRetrySubscriptionResponseRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        payment_error: stripeError,
      },
    }
    return this.api.makeRequest<IPurchaseRetrySubscriptionResponseRaw>(
      `${this.baseUrl}/stripe/${uuid}/${APP_NAME}/retry`,
      options,
    )
  }

  createPaypalSubscription({
    uuid,
    paypalPlanId,
    cohort,
  }: {
    uuid: string
    paypalPlanId: string | null
    cohort: string
  }): Promise<IResponseResult<never>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        paypal_plan_id: paypalPlanId,
        cohort,
      },
    }
    return this.api.makeRequest<never>(
      `${this.baseUrl}/paypal/${uuid}/${APP_NAME}`,
      options,
    )
  }

  makeUpsell({
    uuid,
    planId,
    productId,
    promoCode,
  }: {
    uuid: string
    planId: number
    productId: string
    promoCode?: string
  }): Promise<IResponseResult<IPurchaseResponseRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        plan_id: planId,
        description: productId,
        promo_code: promoCode,
      },
    }

    return this.api.makeRequest<IPurchaseResponseRaw>(
      `${this.baseUrl}/stripe/${uuid}/${APP_NAME}/upsell`,
      options,
    )
  }

  makePrimerUpsell({
    uuid,
    planId,
    productId,
    promoCode,
  }: {
    uuid: string
    planId: number
    productId: string
    promoCode?: string
  }): Promise<IResponseResult<IBaseSubscriptionInfoRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        description: productId,
        promo_code: promoCode,
        plan_id: planId,
      },
    }

    return this.api.makeRequest<IBaseSubscriptionInfoRaw>(
      `${this.baseUrl}/${uuid}/${APP_NAME}/upsell`,
      options,
    )
  }

  getPrimerClientSessionToken({
    uuid,
    cohort,
    planId,
    trialPeriodDays,
  }: {
    uuid: string
    cohort: string
    trialPeriodDays?: number
    planId: number
  }): Promise<IResponseResult<IPrimerClientSessionResponseRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        cohort,
        plan_id: planId,
        trial_duration: trialPeriodDays,
      },
    }

    return this.api.makeRequest<IPrimerClientSessionResponseRaw>(
      `${this.baseUrl}/primer/manual/${uuid}/${APP_NAME}/token`,
      options,
    )
  }

  updatePrimerClientSession({
    uuid,
    paymentId,
    clientToken,
  }: {
    uuid: string
    paymentId?: string
    clientToken: string
  }): Promise<IResponseResult<IPrimerClientSessionResponseRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.PATCH,
      body: {
        client_token: clientToken,
        ...(paymentId && { payment_id: paymentId }),
      },
    }
    return this.api.makeRequest<IPrimerClientSessionResponseRaw>(
      `${this.baseUrl}/primer/manual/${uuid}/${APP_NAME}/token`,
      options,
    )
  }

  resumePrimerSubscription({
    uuid,
    paymentId,
    resumeToken,
  }: {
    uuid: string
    paymentId: string
    resumeToken: string
  }): Promise<IResponseResult<IPrimerPaymentResponseRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        payment_id: paymentId,
        resume_token: resumeToken,
      },
    }
    return this.api.makeRequest<IPrimerPaymentResponseRaw>(
      `${this.baseUrl}/primer/manual/${uuid}/${APP_NAME}/payment/resume`,
      options,
    )
  }

  createPrimerSubscription({
    uuid,
    token,
  }: {
    uuid: string
    token: string
  }): Promise<IResponseResult<IPrimerPaymentResponseRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        payment_method_token: token,
      },
    }
    return this.api.makeRequest<IPrimerPaymentResponseRaw>(
      `${this.baseUrl}/primer/manual/${uuid}/${APP_NAME}/payment`,
      options,
    )
  }

  confirmPrimerSubscription({
    uuid,
    paymentId,
  }: {
    uuid: string
    paymentId: string
  }): Promise<IResponseResult<IPrimerConfirmPaymentResponseRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        payment_id: paymentId,
      },
    }
    return this.api.makeRequest<IPrimerConfirmPaymentResponseRaw>(
      `${this.baseUrl}/primer/manual/${uuid}/${APP_NAME}/confirm`,
      options,
    )
  }
}
