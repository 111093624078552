import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  selectCurrentVariantCohortToUse,
  selectUserHeartAge,
} from 'root-redux/selects/common'

import { useUserData } from 'hooks/useUserData'

import hearts from 'assets/images/subscription-hearts-age.jpg'

import { Cohort } from 'root-constants/common'

import { StyledHeartAgeVariant1 as S } from './HeartAgeVariant1.styles'
import { STATIC_HEART_AGE } from './constants'

export const HeartAgeVariant1: React.FC = ({ ...props }) => {
  const { t } = useTranslation()

  const userHeartAge = useSelector(selectUserHeartAge)
  const cohort = useSelector(selectCurrentVariantCohortToUse)
  const { age } = useUserData()

  const youngHeartAge = age - STATIC_HEART_AGE

  const isSeniorCohort =
    cohort === Cohort.CARDIMATE_20 || cohort === Cohort.CARDIMATE_21

  return (
    <S.Wrapper {...props}>
      <S.ImageContainer hasAlternativeStyles={isSeniorCohort}>
        <img src={hearts} alt="hearts" />
      </S.ImageContainer>
      <S.HeartAgeDataWrapper>
        <S.OldHeartAge>
          <S.Title>{t`subscriptions.heartAge.heartAge`}</S.Title>
          <S.Text>{userHeartAge}</S.Text>
        </S.OldHeartAge>
        <S.NewHeartAge>
          <S.Title>{t`subscriptions.heartAge.heartAge`}</S.Title>
          <S.Text>{youngHeartAge}</S.Text>
        </S.NewHeartAge>
        <S.OldHeartRisk>
          <S.Title>{t`subscriptions.heartAge.cardiacRisk`}</S.Title>
          <S.Text>{t`subscriptions.heartAge.high`}</S.Text>
        </S.OldHeartRisk>
        <S.NewHeartRisk>
          <S.Title>{t`subscriptions.heartAge.cardiacRisk`}</S.Title>
          <S.Text>{t`subscriptions.heartAge.lower`}</S.Text>
        </S.NewHeartRisk>
      </S.HeartAgeDataWrapper>
    </S.Wrapper>
  )
}
