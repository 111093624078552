import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  selectCurrency,
  selectSubscriptionFullPrice,
  selectSubscriptionPeriodName,
  selectSubscriptionPeriodQuantity,
} from 'modules/payment/redux/selects'

import {
  CURRENCY_SYMBOLS,
  PERIOD_NAME_TO_MARKUP_MAP,
} from 'root-constants/common'

import { StyledTotalAmountVAT as S } from '../TotalAmountVAT/TotalAmountVAT.styles'

export const TotalAmount: React.FC = () => {
  const { t } = useTranslation()
  const currentPrice = useSelector(selectSubscriptionFullPrice)
  const periodName = useSelector(selectSubscriptionPeriodName)
  const periodQuantity = useSelector(selectSubscriptionPeriodQuantity)
  const currency = useSelector(selectCurrency)

  return (
    <S.Container>
      <Trans
        i18nKey="payment.totalAmount"
        values={{
          currencySymbol: CURRENCY_SYMBOLS[currency],
          price: currentPrice.toFixed(2),
          periodQuantity,
          minimumFractionDigits: 2,
        }}
        components={{
          periodName: (
            <S.PeriodName>
              {t(PERIOD_NAME_TO_MARKUP_MAP[periodName], {
                count: periodQuantity,
              })}
            </S.PeriodName>
          ),
        }}
      />
    </S.Container>
  )
}
