import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectCurrentVariantCohortToUse } from 'root-redux/selects/common'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'

import { Cohort, Gender } from 'root-constants/common'

import { StyledGenderVariant3 as S } from './GenderVariant3.styles'

export const GenderVariant3: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const cohort = useSelector(selectCurrentVariantCohortToUse)

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: t('onboarding.gender.title', { lng: 'en' }),
    nextPagePath,
  })

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    onChange: (value: string) => {
      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
    },
  }

  const hasSubtitle =
    cohort === Cohort.CARDIMATE_17 || cohort === Cohort.CARDIMATE_19

  const isNineteenCohort = cohort === Cohort.CARDIMATE_19

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title isNineteenCohort={isNineteenCohort}>
          {t`onboarding.gender.title`}
        </S.Title>
        {!hasSubtitle && (
          <S.Subtitle>{t`onboarding.gender.subtitle`}</S.Subtitle>
        )}

        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={Gender.FEMALE}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`actions.female`}</QuestionButton>
          </Option>

          <Option
            {...optionProps}
            value={Gender.MALE}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`actions.male`}</QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </S.Wrapper>
  )
}
