import styled, { css } from 'styled-components'

import { Popover } from 'components/Popover'

import {
  baseColumnStyles,
  basePageStyles,
  baseTitleStyles,
} from 'common-styles'
import { Color, HEADER_HEIGHT } from 'root-constants/common'

const inputWrapperStyles = css`
  width: 100%;
  margin-bottom: 4px;
`
export const StyledHeight = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding: 46px 0;
  `,
  Title: styled.h1`
    ${baseTitleStyles};
    margin-bottom: 12px;
  `,
  ButtonsWrapper: styled.div`
    width: 100%;
    display: flex;
    gap: 4px;
    padding: 8px;
    background-color: ${({ theme }) =>
      theme.colors.measurementSystemBackground};
    border-radius: 12px;
    margin-bottom: 8px;
  `,
  SystemButton: styled.button`
    width: calc(50% - 4px);
    font-weight: 700;
    font-size: 12px;
    line-height: 22px;
    text-transform: uppercase;
    color: #565976;
    border-radius: 10px;
    border: none;
    padding: 8px 0;
    background-color: inherit;

    &[data-is-active='true'] {
      background-color: ${Color.WHITE};
    }
  `,
  MetricSystemWrapper: styled.div`
    ${inputWrapperStyles};
  `,
  ImperialSystemWrapper: styled.div`
    ${inputWrapperStyles};
    display: flex;
    gap: 4px;
  `,
  Popover: styled(Popover)`
    width: calc(50% - 4px);
  `,
}
