import styled from 'styled-components'

import { Color } from 'root-constants/common'

export const StyledGuides = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
  `,
  Title: styled.h3`
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 8px;
    color: ${Color.GRAY_100};

    strong {
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      color: ${Color.RED};
    }
  `,
  Subtitle: styled.p`
    font-size: 17px;
    font-weight: 600;
    line-height: 24px;
    color: ${Color.GRAY_80};
    margin-bottom: 24px;
  `,
  GuideContainer: styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    border-radius: 16px;
    border: 1px solid #e9e9e9e9;
    background-color: ${Color.WHITE};
    margin-bottom: 12px;

    img {
      max-width: 72px;
      height: 104px;
      border-bottom-left-radius: 16px;
      border-top-left-radius: 16px;
    }
  `,
  TextWrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
  `,
  PriceWrapper: styled.div`
    display: flex;
    gap: 4px;
    align-items: center;
  `,
  OldPrice: styled.p`
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-decoration-line: line-through;
    color: ${Color.RED};
  `,
  CurrentPrice: styled.p`
    border-radius: 4px;
    background-color: ${Color.RED};
    padding: 2px 6px;
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: ${Color.WHITE};
    text-transform: uppercase;
    display: inline-block;
  `,
  Text: styled.p`
    font-size: 17px;
    font-weight: 600;
    line-height: 24px;
    color: ${Color.GRAY_100};
  `,
  Plan: styled.p`
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin: 32px 0 36px;
    color: ${Color.GRAY_100};
  `,
}
