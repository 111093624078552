/* eslint-disable max-lines */
import { TIdToPageMap } from 'models/common.model'

import { Account } from 'modules/account/Account'
import {
  EmailVariant1,
  EmailVariant2,
  EmailVariant3,
} from 'modules/email/pages'
import { PaymentVariant1, PaymentVariant2 } from 'modules/payment/pages'
import {
  CancelOfferDiscount,
  CancelOfferVariant2,
  PaidTrialSubscriptions,
  SubscriptionsVariant1,
  SubscriptionsVariant2,
  SubscriptionsVariant6,
  SubscriptionsVariant9,
  SubscriptionsVariant10,
  UpsellVariant1,
} from 'modules/subscriptions/pages'
import {
  SummaryBloodPressurePlan,
  SummaryHealthMetrics,
  SummaryHealthProfile,
  SummaryHeartPlan,
  SummaryHeartScore,
  SummaryPressureAnalyses,
  SummaryTestResults,
  SummaryVariant2,
} from 'modules/summary/pages'

import {
  AdaptingProfileVariant1,
  AdaptingProfileVariant2,
  AdditionalGoalsVariant1,
  AdditionalGoalsVariant2,
  AgeRangeVariant1,
  AgeRangeVariant3,
  AgeRangeVariant4,
  AgeVariant1,
  AlcoholVariant2,
  AlcoholVariant3,
  BetterHeartLifeVariant1,
  BetterHeartLifeVariant2,
  BloodPressureGraphVariant1,
  BloodPressureGraphVariant2,
  BloodPressureGraphVariant3,
  BloodPressureGraphVariant4,
  BloodPressureGraphVariant5,
  BloodPressureVariant1,
  BloodPressureVariant4,
  BloodPressureVariant5,
  BloodPressureVariant6,
  CardiacRiskVariant1,
  CardiacRiskVariant2,
  CheckUpVariant1,
  ConsumptionVariant1,
  ConsumptionVariant2,
  CovidVariant1,
  DailyMealsVariant1,
  DailyWaterVariant1,
  DailyWaterVariant2,
  DidYouKnowVariant1,
  DownloadApp,
  DynamicLeftToRight,
  DynamicLeftToRightIconVariant1,
  DynamicLeftToRightVariant2,
  DynamicRange,
  DynamicSocialProof,
  DynamicStatement,
  EasyToUseVariant2,
  EatingHabitsVariant1,
  EthnicityVariant1,
  FamilyDiseaseVariant1,
  FoodsVariant1,
  GenderVariant1,
  GenderVariant3,
  GenderVariant4,
  HealthConditionVariant2,
  HeartCompanionVariant1,
  HeartCompanionVariant2,
  HeartCompanionVariant3,
  HeartDiseaseVariant1,
  HeartDiseaseVariant2,
  HeartDiseaseVariant3,
  HeartDiseaseVariant4,
  HeartRateVariant1,
  HeightVariant1,
  HormonalMedsVariant1,
  HormonalMedsVariant2,
  IntroVariant4,
  IntroVariant5,
  MainGoalVariant1,
  MainGoalVariant2,
  MainGoalVariant4,
  MainGoalVariant5,
  MainGoalVariant6,
  MealTimeVariant1,
  MedicineVariant1,
  MenopauseVariant1,
  MenopauseVariant2,
  MonitorBloodPressureVariant1,
  MonitorBloodPressureVariant2,
  MonitorBloodPressureVariant3,
  MonitorBloodPressureVariant4,
  NameVariant1,
  NameVariant2,
  OverallConditionVariant1,
  OverallConditionVariant2,
  PainDuringActivityVariant1,
  PainDuringActivityVariant2,
  PhysicalActivityVariant1,
  PhysicalActivityVariant2,
  PhysicalActivityVariant3,
  PhysicalActivityVariant4,
  PhysicalActivityVariant5,
  PlanProcessingVariant1,
  PlanProcessingVariant5,
  PlanProcessingVariant6,
  PlanProcessingVariant7,
  PreparingPlanNameVariant1,
  PreparingPlanTextVariant1,
  SleepVariant2,
  SleepVariant3,
  SmokingVariant2,
  SmokingVariant3,
  SocialProofConsumptionVariant1,
  SocialProofDehydrationVariant1,
  SocialProofDoctorVariant1,
  SocialProofDoctorVariant2,
  SocialProofGainHealthVariant1,
  SocialProofHealthRisksVariant1,
  SocialProofHealthRisksVariant2,
  SocialProofPressureVariant1,
  SocialProofRiskVariant1,
  SocialProofVariant1,
  SocialProofVariant2,
  SocialProofVariant3,
  SocialProofVariant4,
  SocialProofVariant5,
  SocialProofVariant6,
  SocialProofVariant7,
  StatementVariant2,
  StatementVariant3,
  SymptomsVariant1,
  SymptomsVariant2,
  SymptomsVariant3,
  SymptomsVariant4,
  SymptomsVariant5,
  SymptomsVariant6,
  TestosteroneVariant1,
  TestosteroneVariant2,
  UsersAroundTheWorldVariant1,
  UsersAroundTheWorldVariant2,
  WeightVariant1,
  WeightVariant3,
} from 'pages'

export const enum CustomPageId {
  NAME = 'name',
  AGE = 'age',
  MAIN_GOAL = 'main_goal',
  BLOOD_PRESSURE = 'blood_pressure',
  SYMPTOMS = 'symptoms',
}

export const enum PageId {
  INTRO_4 = 'intro_4',
  INTRO_5 = 'intro_5',
  HEART_DISEASE_1 = 'heart_disease_1',
  HEART_DISEASE_2 = 'heart_disease_2',
  HEART_DISEASE_3 = 'heart_disease_3',
  HEART_DISEASE_4 = 'heart_disease_4',
  KIT_HEART_DISEASE_1 = 'kit_heart_disease_1',
  PHYSICAL_ACTIVITY_1 = 'physical_activity_1',
  PHYSICAL_ACTIVITY_2 = 'physical_activity_2',
  PHYSICAL_ACTIVITY_3 = 'physical_activity_3',
  PHYSICAL_ACTIVITY_4 = 'physical_activity_4',
  KIT_PHYSICAL_ACTIVITY_1 = 'kit_physical_activity_1',
  ETHNICITY_1 = 'ethnicity_1',
  AGE_1 = 'age_1',
  AGE_RANGE_1 = 'age_range_1',
  AGE_RANGE_3 = 'age_range_3',
  AGE_RANGE_4 = 'age_range_4',
  HEIGHT_1 = 'height_1',
  WEIGHT_1 = 'weight_1',
  WEIGHT_3 = 'weight_3',
  GENDER_1 = 'gender_1',
  GENDER_3 = 'gender_3',
  KIT_GENDER_1 = 'kit_gender_1',
  EASY_TO_USE_2 = 'easy_to_use_2',
  HEALTH_CONDITION_2 = 'health_condition_2',
  BLOOD_PRESSURE_1 = 'blood_pressure_1',
  BLOOD_PRESSURE_4 = 'blood_pressure_4',
  BLOOD_PRESSURE_5 = 'blood_pressure_5',
  KIT_BLOOD_PRESSURE_1 = 'kit_blood_pressure_1',
  BLOOD_PRESSURE_GRAPH_1 = 'blood_pressure_graph_1',
  BLOOD_PRESSURE_GRAPH_2 = 'blood_pressure_graph_2',
  BLOOD_PRESSURE_GRAPH_3 = 'blood_pressure_graph_3',
  KIT_BLOOD_PRESSURE_GRAPH_1 = 'kit_blood_pressure_graph_1',
  MONITOR_BLOOD_PRESSURE_1 = 'monitor_blood_pressure_1',
  MONITOR_BLOOD_PRESSURE_2 = 'monitor_blood_pressure_2',
  MONITOR_BLOOD_PRESSURE_3 = 'monitor_blood_pressure_3',
  KIT_MONITOR_BLOOD_PRESSURE_1 = 'kit_monitor_blood_pressure_1',
  SLEEP_2 = 'sleep_2',
  KIT_SLEEP_1 = 'kit_sleep_1',
  SMOKING_2 = 'smoking_2',
  KIT_SMOKING_1 = 'kit_smoking_1',
  ALCOHOL_2 = 'alcohol_2',
  KIT_ALCOHOL_1 = 'kit_alcohol_1',
  HEART_RATE_1 = 'heart_rate_1',
  OVERALL_CONDITION_1 = 'overall_condition_1',
  OVERALL_CONDITION_2 = 'overall_condition_2',
  SYMPTOMS_1 = 'symptoms_1',
  SYMPTOMS_2 = 'symptoms_2',
  SYMPTOMS_3 = 'symptoms_3',
  SYMPTOMS_4 = 'symptoms_4',
  KIT_SYMPTOMS_1 = 'kit_symptoms_1',
  KIT_SYMPTOMS_2 = 'kit_symptoms_2',
  MEDICINE_1 = 'medicine_1',
  DID_YOU_KNOW_1 = 'did_you_know_1',
  MAIN_GOAL_1 = 'main_goal_1',
  MAIN_GOAL_2 = 'main_goal_2',
  MAIN_GOAL_4 = 'main_goal_4',
  MAIN_GOAL_5 = 'main_goal_5',
  KIT_MAIN_GOAL_1 = 'kit_main_goal_1',
  SOCIAL_PROOF_DEHYDRATION_1 = 'social_proof_dehydration_1',
  SOCIAL_PROOF_DOCTOR_1 = 'social_proof_doctor_1',
  SOCIAL_PROOF_DOCTOR_2 = 'social_proof_doctor_2',
  SOCIAL_PROOF_PRESSURE_1 = 'social_proof_pressure_1',
  SOCIAL_PROOF_HEALTH_RISKS_1 = 'social_proof_health_risks_1',
  SOCIAL_PROOF_HEALTH_RISKS_2 = 'social_proof_health_risks_2',
  SOCIAL_PROOF_GAIN_HEALTH_1 = 'social_proof_gain_health_1',
  SOCIAL_PROOF_CONSUMPTION_1 = 'social_proof_consumption_1',
  SOCIAL_PROOF_RISK_1 = 'social_proof_risk_1',
  KIT_SOCIAL_PROOF_1 = 'kit_social_proof_1',
  KIT_SOCIAL_PROOF_2 = 'kit_social_proof_2',
  KIT_SOCIAL_PROOF_3 = 'kit_social_proof_3',
  KIT_SOCIAL_PROOF_4 = 'kit_social_proof_4',
  KIT_SOCIAL_PROOF_5 = 'kit_social_proof_5',
  KIT_SOCIAL_PROOF_6 = 'kit_social_proof_6',
  KIT_SOCIAL_PROOF_7 = 'kit_social_proof_7',
  KIT_SOCIAL_PROOF_8 = 'kit_social_proof_8',
  KIT_SOCIAL_PROOF_9 = 'kit_social_proof_9',
  SOCIAL_ACTIVITIES = 'social_activities',
  FAMILY_SUPPORT = 'family_support',
  HOBBIES = 'hobbies',
  SOCIALIZE_FREQUENCY = 'socialize_frequency',
  NEEDED_RELY = 'needed_rely',
  ENJOY_FREQUENCY = 'enjoy_frequency',
  USERS_AROUND_THE_WORLD_1 = 'users_around_the_world_1',
  USERS_AROUND_THE_WORLD_2 = 'users_around_the_world_2',
  PAIN_DURING_ACTIVITY_1 = 'pain_during_activity_1',
  PAIN_DURING_ACTIVITY_2 = 'pain_during_activity_2',
  ADDITIONAL_GOALS_1 = 'additional_goals_1',
  ADDITIONAL_GOALS_2 = 'additional_goals_2',
  BETTER_HEART_LIFE_1 = 'better_heart_life_1',
  BETTER_HEART_LIFE_2 = 'better_heart_life_2',
  CHECK_UP_1 = 'check_up_1',
  HEART_COMPANION_1 = 'heart_companion_1',
  HEART_COMPANION_2 = 'heart_companion_2',
  KIT_HEART_COMPANION_1 = 'kit_heart_companion_1',
  PROCESSING_1 = 'processing_1',
  PROCESSING_5 = 'processing_5',
  PROCESSING_6 = 'processing_6',
  KIT_PROCESSING_1 = 'kit_processing_1',
  DYNAMIC_LEFT_TO_RIGHT = 'dynamic_left_to_right',
  KIT_DYNAMIC_LEFT_TO_RIGHT = 'kit_dynamic_left_to_right',
  KIT_DYNAMIC_LEFT_TO_RIGHT_ICON = 'kit_dynamic_left_to_right_icon',
  DYNAMIC_RANGE = 'dynamic_range',
  DYNAMIC_STATEMENT = 'dynamic_statement',
  KIT_DYNAMIC_SOCIAL_PROOF = 'kit_dynamic_social_proof',
  PREPARING_PLAN_NAME = 'preparing_plan_name',
  PREPARING_PLAN_TEXT = 'preparing_plan_text',
  ADAPTING_PROFILE_1 = 'adapting_profile_1',
  ADAPTING_PROFILE_2 = 'adapting_profile_2',
  NAME_1 = 'name_1',
  KIT_NAME_1 = 'kit_name_1',
  DAILY_WATER_1 = 'daily_water_1',
  KIT_DAILY_WATER_1 = 'kit_daily_water_1',
  CONSUMPTION_1 = 'consumption_1',
  KIT_CONSUMPTION_1 = 'kit_consumption_1',
  CARDIAC_RISK_1 = 'cardiac_risk_1',
  KIT_CARDIAC_RISK_1 = 'kit_cardiac_risk_1',
  MENOPAUSE_1 = 'menopause_1',
  KIT_MENOPAUSE_1 = 'kit_menopause_1',
  HORMONAL_MEDS_1 = 'hormonal_meds_1',
  KIT_HORMONAL_MEDS_1 = 'kit_hormonal_meds_1',
  TESTOSTERONE_1 = 'testosterone_1',
  KIT_TESTOSTERONE_1 = 'kit_testosterone_1',
  KIT_COVID_1 = 'kit_covid_1',
  KIT_EATING_HABITS_1 = 'kit_eating_habits_1',
  KIT_FOODS_1 = 'kit_foods_1',
  KIT_DAILY_MEALS_1 = 'kit_daily_meals_1',
  KIT_MEAL_TIME_1 = 'kit_meal_time_1',
  EMAIL_1 = 'email_1',
  EMAIL_2 = 'email_2',
  KIT_EMAIL_1 = 'kit_email_1',
  SUMMARY_2 = 'summary_2',
  SUMMARY_HEART_PLAN = 'summary_heart_plan',
  SUMMARY_TEST_RESULTS = 'summary_test_results',
  SUMMARY_HEALTH_PROFILE = 'summary_health_profile',
  SUMMARY_BLOOD_PRESSURE_PLAN = 'summary_blood_pressure_plan',
  SUMMARY_PRESSURE_ANALYSES = 'summary_pressure_analyses',
  SUMMARY_HEART_SCORE = 'summary_heart_score',
  SUMMARY_HEALTH_METRICS = 'summary_health_metrics',
  SUBSCRIPTIONS_1 = 'subscriptions_1',
  SUBSCRIPTIONS_2 = 'subscriptions_2',
  SUBSCRIPTIONS_6 = 'subscriptions_6',
  SUBSCRIPTIONS_9 = 'subscriptions_9',
  KIT_SUBSCRIPTIONS_1 = 'kit_subscriptions_1',
  PAID_TRIAL_SUBSCRIPTIONS_2 = 'paid_trial_subscriptions_2',
  CANCEL_OFFER_SUBSCRIPTIONS_2 = 'cancel_offer_subscriptions_2',
  CANCEL_OFFER_DISCOUNT_1 = 'cancel_offer_discount_1',
  UPSELL_1 = 'upsell_1',
  PAYMENT_1 = 'payment_1',
  PAYMENT_2 = 'payment_2',
  ACCOUNT = 'account',
  DOWNLOAD = 'download',
  PRIVACY_POLICY = 'privacy-policy',
  TERMS_OF_USE = 'terms-of-use',
  COOKIE_POLICY = 'cookie-policy',
}

export const ID_TO_PAGE_MAP: TIdToPageMap = {
  intro_4: IntroVariant4,
  intro_5: IntroVariant5,
  heart_disease_1: FamilyDiseaseVariant1,
  heart_disease_2: HeartDiseaseVariant1,
  heart_disease_3: HeartDiseaseVariant2,
  heart_disease_4: HeartDiseaseVariant3,
  kit_heart_disease_1: HeartDiseaseVariant4,
  physical_activity_1: PhysicalActivityVariant1,
  physical_activity_2: PhysicalActivityVariant2,
  physical_activity_3: PhysicalActivityVariant3,
  physical_activity_4: PhysicalActivityVariant4,
  kit_physical_activity_1: PhysicalActivityVariant5,
  ethnicity_1: EthnicityVariant1,
  age_1: AgeVariant1,
  age_range_1: AgeRangeVariant1,
  age_range_3: AgeRangeVariant3,
  age_range_4: AgeRangeVariant4,
  height_1: HeightVariant1,
  weight_1: WeightVariant1,
  weight_3: WeightVariant3,
  gender_1: GenderVariant1,
  gender_3: GenderVariant3,
  kit_gender_1: GenderVariant4,
  easy_to_use_2: EasyToUseVariant2,
  health_condition_2: HealthConditionVariant2,
  blood_pressure_1: BloodPressureVariant1,
  blood_pressure_4: BloodPressureVariant4,
  blood_pressure_5: BloodPressureVariant5,
  kit_blood_pressure_1: BloodPressureVariant6,
  blood_pressure_graph_1: BloodPressureGraphVariant1,
  blood_pressure_graph_2: BloodPressureGraphVariant2,
  blood_pressure_graph_3: BloodPressureGraphVariant3,
  kit_blood_pressure_graph_1: BloodPressureGraphVariant4,
  kit_blood_pressure_graph_2: BloodPressureGraphVariant5,
  monitor_blood_pressure_1: MonitorBloodPressureVariant1,
  monitor_blood_pressure_2: MonitorBloodPressureVariant2,
  monitor_blood_pressure_3: MonitorBloodPressureVariant3,
  kit_monitor_blood_pressure_1: MonitorBloodPressureVariant4,
  sleep_2: SleepVariant2,
  kit_sleep_1: SleepVariant3,
  smoking_2: SmokingVariant2,
  kit_smoking_1: SmokingVariant3,
  alcohol_2: AlcoholVariant2,
  kit_alcohol_1: AlcoholVariant3,
  heart_rate_1: HeartRateVariant1,
  overall_condition_1: OverallConditionVariant1,
  overall_condition_2: OverallConditionVariant2,
  symptoms_1: SymptomsVariant1,
  symptoms_2: SymptomsVariant2,
  symptoms_3: SymptomsVariant3,
  symptoms_4: SymptomsVariant4,
  kit_symptoms_1: SymptomsVariant5,
  kit_symptoms_2: SymptomsVariant6,
  medicine_1: MedicineVariant1,
  did_you_know_1: DidYouKnowVariant1,
  main_goal_1: MainGoalVariant1,
  main_goal_2: MainGoalVariant2,
  main_goal_4: MainGoalVariant4,
  main_goal_5: MainGoalVariant5,
  kit_main_goal_1: MainGoalVariant6,
  processing_1: PlanProcessingVariant1,
  processing_5: PlanProcessingVariant5,
  processing_6: PlanProcessingVariant6,
  kit_processing_1: PlanProcessingVariant7,
  social_proof_dehydration_1: SocialProofDehydrationVariant1,
  social_proof_doctor_1: SocialProofDoctorVariant1,
  social_proof_doctor_2: SocialProofDoctorVariant2,
  social_proof_pressure_1: SocialProofPressureVariant1,
  social_proof_health_risks_1: SocialProofHealthRisksVariant1,
  social_proof_health_risks_2: SocialProofHealthRisksVariant2,
  social_proof_gain_health_1: SocialProofGainHealthVariant1,
  social_proof_consumption_1: SocialProofConsumptionVariant1,
  social_proof_risk_1: SocialProofRiskVariant1,
  kit_social_proof_1: SocialProofVariant1,
  kit_social_proof_2: SocialProofVariant2,
  kit_social_proof_3: SocialProofVariant3,
  kit_social_proof_4: SocialProofVariant4,
  kit_social_proof_5: SocialProofVariant5,
  kit_social_proof_6: SocialProofVariant6,
  kit_social_proof_7: SocialProofVariant7,
  kit_social_proof_8: SocialProofVariant3,
  kit_social_proof_9: SocialProofVariant3,
  social_activities: StatementVariant2,
  family_support: StatementVariant2,
  hobbies: StatementVariant2,
  socialize_frequency: StatementVariant3,
  needed_rely: StatementVariant3,
  enjoy_frequency: StatementVariant3,
  dynamic_left_to_right: DynamicLeftToRight,
  kit_dynamic_left_to_right: DynamicLeftToRightVariant2,
  kit_dynamic_left_to_right_icon: DynamicLeftToRightIconVariant1,
  dynamic_statement: DynamicStatement,
  dynamic_range: DynamicRange,
  kit_dynamic_social_proof: DynamicSocialProof,
  users_around_the_world_1: UsersAroundTheWorldVariant1,
  users_around_the_world_2: UsersAroundTheWorldVariant2,
  pain_during_activity_1: PainDuringActivityVariant1,
  pain_during_activity_2: PainDuringActivityVariant2,
  additional_goals_1: AdditionalGoalsVariant1,
  additional_goals_2: AdditionalGoalsVariant2,
  better_heart_life_1: BetterHeartLifeVariant1,
  better_heart_life_2: BetterHeartLifeVariant2,
  check_up_1: CheckUpVariant1,
  heart_companion_1: HeartCompanionVariant1,
  heart_companion_2: HeartCompanionVariant2,
  kit_heart_companion_1: HeartCompanionVariant3,
  name_1: NameVariant1,
  kit_name_1: NameVariant2,
  daily_water_1: DailyWaterVariant1,
  kit_daily_water_1: DailyWaterVariant2,
  preparing_plan_name: PreparingPlanNameVariant1,
  preparing_plan_text: PreparingPlanTextVariant1,
  adapting_profile_1: AdaptingProfileVariant1,
  adapting_profile_2: AdaptingProfileVariant2,
  consumption_1: ConsumptionVariant1,
  kit_consumption_1: ConsumptionVariant2,
  cardiac_risk_1: CardiacRiskVariant1,
  kit_cardiac_risk_1: CardiacRiskVariant2,
  menopause_1: MenopauseVariant1,
  kit_menopause_1: MenopauseVariant2,
  hormonal_meds_1: HormonalMedsVariant1,
  kit_hormonal_meds_1: HormonalMedsVariant2,
  testosterone_1: TestosteroneVariant1,
  kit_testosterone_1: TestosteroneVariant2,
  kit_covid_1: CovidVariant1,
  kit_eating_habits_1: EatingHabitsVariant1,
  kit_foods_1: FoodsVariant1,
  kit_daily_meals_1: DailyMealsVariant1,
  kit_meal_time_1: MealTimeVariant1,
  email_1: EmailVariant1,
  email_2: EmailVariant2,
  kit_email_1: EmailVariant3,
  summary_2: SummaryVariant2,
  summary_heart_plan: SummaryHeartPlan,
  summary_test_results: SummaryTestResults,
  summary_health_profile: SummaryHealthProfile,
  summary_blood_pressure_plan: SummaryBloodPressurePlan,
  summary_pressure_analyses: SummaryPressureAnalyses,
  summary_heart_score: SummaryHeartScore,
  summary_health_metrics: SummaryHealthMetrics,
  subscriptions_1: SubscriptionsVariant1,
  subscriptions_2: SubscriptionsVariant2,
  subscriptions_6: SubscriptionsVariant6,
  subscriptions_9: SubscriptionsVariant9,
  kit_subscriptions_1: SubscriptionsVariant10,
  paid_trial_subscriptions_2: PaidTrialSubscriptions,
  cancel_offer_subscriptions_2: CancelOfferVariant2,
  cancel_offer_discount_1: CancelOfferDiscount,
  upsell_1: UpsellVariant1,
  payment_1: PaymentVariant1,
  payment_2: PaymentVariant2,
  account: Account,
  download: DownloadApp,
}
