import { PageId } from 'root-constants/pages'

export const STATEMENT_V2_DATA = {
  [PageId.SOCIAL_ACTIVITIES]: {
    title: 'onboarding.statement2.titles.socialActivities',
    subtitle: 'onboarding.statement2.subtitles.socialActivities',
    lowestScaleText: 'onboarding.statement2.rarely',
    highestScaleText: 'onboarding.statement2.often',
  },
  [PageId.FAMILY_SUPPORT]: {
    title: 'onboarding.statement2.titles.familySupport',
    subtitle: '',
    lowestScaleText: 'onboarding.statement2.notReally',
    highestScaleText: 'onboarding.statement2.totally',
  },
  [PageId.HOBBIES]: {
    title: 'onboarding.statement2.titles.hobbies',
    subtitle: 'onboarding.statement2.subtitles.hobbies',
    lowestScaleText: 'onboarding.statement2.never',
    highestScaleText: 'onboarding.statement2.daily',
  },
}
export const STATEMENT_V3_DATA = {
  [PageId.SOCIALIZE_FREQUENCY]: {
    title: 'onboarding.statement3.titles.socialActivities',
    subtitle: 'onboarding.statement3.subtitles.socialActivities',
    lowestScaleText: 'onboarding.statement2.rarely',
    highestScaleText: 'onboarding.statement2.often',
  },
  [PageId.NEEDED_RELY]: {
    title: 'onboarding.statement3.titles.familySupport',
    subtitle: '',
    lowestScaleText: 'onboarding.statement2.rarely',
    highestScaleText: 'onboarding.statement2.often',
  },
  [PageId.ENJOY_FREQUENCY]: {
    title: 'onboarding.statement3.titles.hobbies',
    subtitle: 'onboarding.statement2.subtitles.hobbies',
    lowestScaleText: 'onboarding.statement2.rarely',
    highestScaleText: 'onboarding.statement2.often',
  },
}

export const OPTIONS_NUMBER = 5
