import React, { useState } from 'react'

import { useHeartMonitorDomain } from 'hooks/ui/useHeartMonitorDomain'

import { SupportLink } from 'components/SupportLink'

import { PrivacyPolicyLink } from '../PrivacyPolicyLink'
import { TermsOfUseLink } from '../TermsOfUseLink'
import { StyledBurgerMenu as S } from './BurgerMenu.styles'

export const BurgerMenu: React.FC = (props) => {
  const [isMenuVisible, setIsMenuVisible] = useState(false)

  const toggleMenuVisibility = () => {
    setIsMenuVisible(!isMenuVisible)
  }

  const { isHeartMonitorDomain } = useHeartMonitorDomain()

  return (
    <S.Wrapper {...props}>
      <S.BurgerButton type="button" onClick={toggleMenuVisibility} />
      <S.Backdrop
        isMenuVisible={isMenuVisible}
        onClick={toggleMenuVisibility}
      />
      <S.Menu isMenuVisible={isMenuVisible}>
        <S.MenuCloseButton type="button" onClick={toggleMenuVisibility} />
        <TermsOfUseLink />
        <PrivacyPolicyLink />
        {!isHeartMonitorDomain && <SupportLink text="actions.needHelp" />}
      </S.Menu>
    </S.Wrapper>
  )
}
