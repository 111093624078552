import React, { HTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'

import { useHeartMonitorDomain } from 'hooks/ui/useHeartMonitorDomain'

import logoImg from 'assets/images/app-name-logo.png'
import heartMonitorLogo from 'assets/images/heart-monitor-logo.png'

import { StyledHeaderWithButton as S } from './HeaderWithButton.styles'

export interface IProps extends HTMLAttributes<HTMLDivElement> {
  onClick: () => void
  logo?: string
  height?: number
}

export const HeaderWithButton: React.FC<IProps> = ({
  onClick,
  logo = logoImg,
  height = 28,
  ...props
}) => {
  const { t } = useTranslation()
  const { isHeartMonitorDomain } = useHeartMonitorDomain()

  return (
    <S.HeaderWrapper {...props}>
      <S.Header>
        <img
          height={height}
          src={isHeartMonitorDomain ? heartMonitorLogo : logo}
          alt="cardi mate logo"
        />
        <S.Button onClick={onClick}>{t`actions.getStarted`}</S.Button>
      </S.Header>
    </S.HeaderWrapper>
  )
}
