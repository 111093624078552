import priceDangerBgImg from 'assets/images/price-bg-danger.svg'
import priceRedBgImg from 'assets/images/price-bg-red.svg'

import { Cohort } from 'root-constants/common'

export const PRICE_BACKGROUND = {
  [Cohort.CARDIMATE_14]: priceRedBgImg,
  [Cohort.CARDIMATE_15]: priceRedBgImg,
  [Cohort.CARDIMATE_17]: priceRedBgImg,
  [Cohort.CARDIMATE_19]: priceRedBgImg,
  [Cohort.CARDIMATE_20]: priceDangerBgImg,
  [Cohort.CARDIMATE_21]: priceDangerBgImg,
}
