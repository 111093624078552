import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'

import { usePageInfo } from 'providers/PageInfoProvider'

import { useUserData } from 'hooks/useUserData'

import { eventLogger } from 'services/eventLogger.service'

import { HeaderWithButton } from 'components/HeaderWithButton'
import { ReviewsSlider } from 'components/ReviewSlider'

import logoImg from 'assets/images/app-name-logo.png'

import { goTo } from 'browser-history'
import { Goal } from 'root-constants/common'

import { HeartHealth } from '../../components/HeartHealth'
import { PersonalSummary } from '../../components/PersonalSummary'
import { ReduceStress } from '../../components/ReduceStress'
import { StyledSummaryVariant2 as S } from './SummaryVariant2.styles'

const GOAL_TO_SCREEN_NAME_MAP = {
  [Goal.HEART_HEALTH]: 'Heart Health Improvement Plan',
  [Goal.REDUCE_STRESS]: 'Stress Relief Plan',
}

export const SummaryVariant2: React.FC = () => {
  const { t } = useTranslation()

  const { goal } = useUserData()
  const { currentSubscriptionPageId } = usePageInfo()
  const { search } = useLocation()

  const pageName = GOAL_TO_SCREEN_NAME_MAP[goal as string] || ''

  useEffect(() => {
    eventLogger.logPresalePageShown({
      pageName,
    })
  }, [pageName])

  const handleContinue = () => {
    eventLogger.logPresalePageCompleted({
      pageName,
    })
    goTo({ pathname: currentSubscriptionPageId, search })
  }

  return (
    <S.Wrapper>
      <HeaderWithButton onClick={handleContinue} />
      <S.Content>
        <S.PersonalSummaryTitle>{t`summary.yourSummary`}</S.PersonalSummaryTitle>
        <PersonalSummary />

        {goal === Goal.HEART_HEALTH && (
          <HeartHealth handleButtonClick={handleContinue} />
        )}
        {goal === Goal.REDUCE_STRESS && (
          <ReduceStress handleButtonClick={handleContinue} />
        )}

        <ReviewsSlider
          titlePath="summary.successStories"
          titleBottomMargin={48}
          marginBottom={42}
        />

        <S.Button onClick={handleContinue}>{t`actions.startNow`}</S.Button>
        <S.Logo height={28} src={logoImg} alt="cardi mate logo" />
        <S.Description>{t`commonComponents.description`}</S.Description>
      </S.Content>
    </S.Wrapper>
  )
}
