import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { usePageInfo } from 'providers/PageInfoProvider'

import { selectLanguage } from 'root-redux/selects/common'

export const useDynamicPaywallConfig = () => {
  const language = useSelector(selectLanguage)
  const { currentPageIndex, steps } = usePageInfo()

  return useMemo(() => {
    const dynamicPaywallConfig = steps[currentPageIndex].paywallDynamicConfig
    return {
      title: dynamicPaywallConfig?.[language]?.title,
      subtitle: dynamicPaywallConfig?.[language]?.subtitle,
      buttonText: dynamicPaywallConfig?.[language]?.buttonText,
      subscriptionBlockTitle:
        dynamicPaywallConfig?.[language]?.subscriptionBlockTitle,
      subscriptionBlockType:
        dynamicPaywallConfig?.[language]?.subscriptionBlockType ||
        'plan_item_variant_1',
      benefitsBlockTitle: dynamicPaywallConfig?.[language]?.benefitsBlockTitle,
      benefits: dynamicPaywallConfig?.[language]?.benefits,
      customerReviewsTitle:
        dynamicPaywallConfig?.[language]?.customerReviewsTitle,
      customerReviews: dynamicPaywallConfig?.[language]?.customerReviews,
      personalization: dynamicPaywallConfig?.[language]?.personalization,
    }
  }, [currentPageIndex, language, steps])
}
