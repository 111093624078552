import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Answer, answerTheme } from 'storybook-ui'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'

import { Color, SENIOR_FLOW_HEADER_HEIGHT } from 'root-constants/common'

import { StyledRadioOptionPage as S } from './KitRadioOptionPage.styles'

type TRadioPageProps = {
  pageName?: string
  options: { value: string; text: string }[]
  titlePath: string
  titleFontSize?: number
  titleLineHeight?: number
  titleColor?: string
  titleMarginBottom?: number
  subtitlePath?: string
  wrapperPaddingTop?: number
  columnPaddingTop?: number
} & TPageProps

export const KitRadioOptionPage: React.FC<TRadioPageProps> = ({
  pageName,
  options,
  titlePath,
  titleFontSize,
  titleLineHeight,
  titleColor = Color.GRAY_100,
  titleMarginBottom,
  subtitlePath,
  pageId,
  nextPagePath,
  wrapperPaddingTop = SENIOR_FLOW_HEADER_HEIGHT,
  columnPaddingTop,
}) => {
  const { t } = useTranslation()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    pageName: pageName || pageId,
    question: t(titlePath, { lng: 'en' }),
    nextPagePath,
  })

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    onChange: (value: string) => {
      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
    },
  }

  return (
    <S.Wrapper wrapperPaddingTop={wrapperPaddingTop}>
      <S.Column columnPaddingTop={columnPaddingTop}>
        <S.Title
          titleFontSize={titleFontSize}
          titleLineHeight={titleLineHeight}
          titleColor={titleColor}
          marginBottom={titleMarginBottom}
        >
          {t(titlePath)}
        </S.Title>
        {!!subtitlePath && <S.Subtitle>{t(subtitlePath)}</S.Subtitle>}
        <StyledOption.OptionsContainer>
          {options.map(({ value, text }) => (
            <Answer
              {...optionProps}
              theme={answerTheme.CARDIMATE}
              key={value}
              value={value}
              disabled={isAnswersDisabled}
              margin="0 0 12px"
            >
              <Trans i18nKey={text} components={{ span: <span /> }} />
            </Answer>
          ))}
        </StyledOption.OptionsContainer>
      </S.Column>
    </S.Wrapper>
  )
}
