import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { GrowthBook } from '@growthbook/growthbook-react'

import {
  selectCurrentVariantCohort,
  selectGrowthBookExperiments,
} from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'

import { getCurrentEnv } from 'helpers/getCurrentEnv'

import { eventLogger } from 'services/eventLogger.service'

import { CurrentEnvironment } from 'root-constants/common'

const PROD_GROWTHBOOK_CLIENT_KEY = 'sdk-wTnMfmy0QAVpKUd'
const DEV_GROWTHBOOK_CLIENT_KEY = 'sdk-hYaaGKy0MP0YGdtd'

const clientKey =
  getCurrentEnv() === CurrentEnvironment.PROD
    ? PROD_GROWTHBOOK_CLIENT_KEY
    : DEV_GROWTHBOOK_CLIENT_KEY

export const growthbook = new GrowthBook({
  apiHost: 'https://cdn.growthbook.io',
  enableDevMode: true,
  clientKey,
})

export const useGrowthBook = (): void => {
  const uuid = useSelector(selectUUID)
  const cohort = useSelector(selectCurrentVariantCohort)
  const experimentsMap = useSelector(selectGrowthBookExperiments)

  useEffect(() => {
    growthbook.init({ streaming: true })
  }, [])

  useEffect(() => {
    growthbook.setAttributes({
      id: uuid,
      cohort,
    })
  }, [cohort, uuid])

  useEffect(() => {
    growthbook.subscribe((experiment, result) => {
      const experimentKey = experiment.key || ''
      const variantId = String(result.variationId)

      if (
        !experimentsMap ||
        !experimentsMap[experimentKey] ||
        !result.hashUsed
      ) {
        return
      }

      eventLogger.logGrowthbookAbSegmentName({
        variantId,
        experimentKey,
        variantName: experimentsMap[experimentKey][variantId],
      })
    })
  }, [experimentsMap])
}
