import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectLanguage } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'
import { useUserData } from 'hooks/useUserData'
import { useUserSymptoms } from 'hooks/useUserSymptoms'

import { TPageProps } from 'models/common.model'

import { Button } from 'components/Button'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { CDN_FOLDER_LINK, Images, Language } from 'root-constants/common'

import { StyledBetterHeartLife as S } from './BetterHeartLife.styles'
import {
  ADDITIONAL_GOALS_TO_MARKUP,
  MAX_ADDITIONAL_GOALS_SIZE,
  MAX_SYMPTOMS_SIZE,
} from './constants'

export const BetterHeartLifeVariant2: React.FC<TPageProps> = ({
  nextPagePath,
  pageId,
}) => {
  const { t } = useTranslation()
  const { additionalGoals } = useUserData()
  const language = useSelector(selectLanguage)

  const userSymptoms = useUserSymptoms(MAX_SYMPTOMS_SIZE)

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: t('onboarding.betterHeartLife.title', { lng: Language.EN }),
    nextPagePath,
  })

  const formattedGoals = useMemo(
    () =>
      additionalGoals
        .slice(0, MAX_ADDITIONAL_GOALS_SIZE)
        .map((goal) => t(ADDITIONAL_GOALS_TO_MARKUP[goal]).toLowerCase()),
    [additionalGoals, t],
  )

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.betterHeartLife.title2`}</S.Title>
        <S.BenefitsWrapper>
          <S.BenefitsList>
            <li>{t`onboarding.betterHeartLife.advancedTracking`}</li>
            <li>
              <Trans
                i18nKey="onboarding.betterHeartLife.additionalGoals"
                values={{
                  goals: formattedGoals,
                  style: 'long',
                  type: 'conjunction',
                }}
              />
            </li>
            {!!userSymptoms.length && (
              <li>
                <Trans
                  i18nKey="onboarding.betterHeartLife.minimize2"
                  values={{
                    symptoms: userSymptoms,
                    style: 'long',
                    type: 'conjunction',
                  }}
                />
              </li>
            )}
          </S.BenefitsList>
        </S.BenefitsWrapper>
        <S.Image
          src={`${CDN_FOLDER_LINK}${Images.NUMBER_ONE}_${language}.png`}
          alt="number_one_app"
          marginBottom={16}
        />
        <StickyButtonContainer>
          <Button type="button" onClick={() => handleContinue('')}>
            {t`actions.continue`}
          </Button>
        </StickyButtonContainer>
      </S.Column>
    </S.Wrapper>
  )
}
