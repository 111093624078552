import styled from 'styled-components'

import {
  baseColumnStyles,
  basePageStyles,
  baseTitleStyles,
} from 'common-styles'
import { HEADER_HEIGHT } from 'root-constants/common'

type TTitleProps = {
  marginBottom?: number
}

export const StyledBloodPressure = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding: 46px 0;
  `,
  Title: styled.h1<TTitleProps>`
    ${baseTitleStyles};
    margin-bottom: ${({ marginBottom }) => `${marginBottom || 40}px`};
  `,
}
