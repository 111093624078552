import React, { useEffect, useLayoutEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { usePageInfo } from 'providers/PageInfoProvider'

import { useProductId } from 'hooks/useProductId'
import { usePurchaseStore } from 'hooks/usePurchaseStore'
import { useUserData } from 'hooks/useUserData'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import {
  MixedPaymentFlows,
  NoPaypalPaymentFlows,
} from 'modules/payment/components/PaymentFlows'
import { NO_PAYPAL_COHORTS } from 'modules/payment/constants'
import { useIsCheckoutReady } from 'modules/payment/hooks/useIsCheckoutReady'

import { Spinner } from 'components/Spinner'
import { StripePaymentProcessing } from 'components/StripePaymentProcessing'

import { replaceHistory } from 'browser-history'
import { PageId } from 'root-constants/pages'

import { StyledPayment as S } from './Payment.styles'

export const PaymentVariant2: React.FC<TPageProps> = () => {
  const { search } = useLocation()

  const {
    threeDSecureIframeUrl,
    screenName,
    selectedSubscriptionId,
    stripeAccountName,
    stripeAccountId,
    cohort,
  } = usePurchaseStore()
  const productId = useProductId()
  const { goal } = useUserData()
  const { currentSubscriptionPageId } = usePageInfo()
  const isCheckoutReady = useIsCheckoutReady()

  const hasPaypal = !NO_PAYPAL_COHORTS.includes(cohort)

  useLayoutEffect(() => {
    if (selectedSubscriptionId) return

    replaceHistory({
      pathname: currentSubscriptionPageId as PageId,
      search,
    })
  }, [currentSubscriptionPageId, search, selectedSubscriptionId])

  useEffect(() => {
    if (productId) {
      eventLogger.logPurchaseShown({
        productId,
        goal,
        screenName,
        stripeAccountName,
        stripeAccountId,
      })
    }
  }, [goal, productId, screenName, stripeAccountId, stripeAccountName])

  return (
    <S.Wrapper>
      {threeDSecureIframeUrl ? (
        <S.ThreeDSecureIframe title="3DSecure" src={threeDSecureIframeUrl} />
      ) : (
        <>
          <StripePaymentProcessing />
          <S.Content>
            {hasPaypal ? <MixedPaymentFlows /> : <NoPaypalPaymentFlows />}
          </S.Content>
        </>
      )}
      {!isCheckoutReady && <Spinner />}
    </S.Wrapper>
  )
}
