import styled from 'styled-components'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { HEADER_HEIGHT } from 'root-constants/common'

export const StyledSummaryHealthMetrics = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Content: styled.div`
    ${baseColumnStyles};
    min-height: calc(100dvh - ${HEADER_HEIGHT}px);
    padding-top: 16px;
  `,
  Title: styled.h1`
    font-size: 24px;
    font-weight: 900;
    line-height: 32px;

    strong {
      font-weight: 900;
      color: ${({ theme }) => theme.colors.highlightedText};
    }
  `,
  ImageContainer: styled.div`
    max-width: 327px;
    width: 100%;
    aspect-ratio: 327/431;
    flex-grow: 1;
  `,
}
