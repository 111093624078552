import bloodSugar from 'assets/images/blood-sugar-guide.png'
import sweetSimple from 'assets/images/sweet-simple-guide.png'

export const GUIDES_DATA = [
  {
    img: bloodSugar,
    oldPrice: 21.99,
    currentPrice: 'subscriptions.guides.currentPrice',
    text: 'subscriptions.guides.bloodSugar',
  },
  {
    img: sweetSimple,
    oldPrice: 21.99,
    currentPrice: 'subscriptions.guides.currentPrice',
    text: 'subscriptions.guides.sugarFree',
  },
]
