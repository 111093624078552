import React, {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import {
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import {
  StripeCardCvcElement,
  StripeCardExpiryElement,
  StripeCardNumberElement,
} from '@stripe/stripe-js'

import { resetErrorAction } from 'root-redux/actions/common'
import {
  selectError,
  selectIsFetching,
  selectScreenName,
} from 'root-redux/selects/common'

import { useGetPageInfo } from 'hooks/useGetPageInfo'
import { useUserData } from 'hooks/useUserData'

import { eventLogger } from 'services/eventLogger.service'

import { getDefaultPaymentErrorsState } from 'modules/payment/helpers/getDefaultPaymentErrorsState'
import { getPaymentErrorState } from 'modules/payment/helpers/getPaymentErrorState'

import { PaymentMethod, StripeFieldName } from 'root-constants/common'

import {
  EMPTY_FIELD_ERROR,
  THREEDS_REDIRECT_SEARCH_PARAM,
} from '../../constants'
import {
  check3DSecure,
  purchaseAction,
  setPaymentMethodAction,
} from '../../redux/actions/common'
import { select3DSecureIframeUrl } from '../../redux/selects'
import { TCreditCardField, TPaymentErrorState } from '../../types'
import {
  StyledStripePaymentForm as S,
  stripeElementStyle,
} from './StripePaymentForm.styles'

export const StripePaymentForm: React.FC = (props) => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const stripe = useStripe()
  const elements = useElements()
  const dispatch = useDispatch()

  const threeDSecureIframeUrl = useSelector(select3DSecureIframeUrl)
  const screenName = useSelector(selectScreenName)
  const isFetching = useSelector(selectIsFetching)
  const requestError = useSelector(selectError)

  const cardNumberElemRef = useRef<StripeCardNumberElement | null>(null)
  const cardExpiryElemRef = useRef<StripeCardExpiryElement | null>(null)
  const cvcElemRef = useRef<StripeCardCvcElement | null>(null)
  const cardholderNameElemRef = useRef<HTMLInputElement>(null)

  const [errors, setErrors] = useState<TPaymentErrorState>(() =>
    getDefaultPaymentErrorsState(),
  )
  const [name, setName] = useState('')

  const { goal } = useUserData()
  const { currentPageId } = useGetPageInfo()

  const hasErrors = useMemo(
    () => Object.values(errors).some((error) => error.isShown && error.error),
    [errors],
  )
  const hasUntouchedFields = useMemo(
    () =>
      Object.values(errors).some((error) => error.isShown && !error.isTouched),
    [errors],
  )
  const hasUncompletedFields = useMemo(
    () => Object.values(errors).some((field) => !field.isComplete),
    [errors],
  )
  const isFormValid = useMemo(
    () => !hasErrors && !hasUntouchedFields && !hasUncompletedFields,
    [hasErrors, hasUncompletedFields, hasUntouchedFields],
  )

  useEffect(() => {
    const URLParams = new URLSearchParams(search)
    const isSuccess = URLParams.has(THREEDS_REDIRECT_SEARCH_PARAM)

    if (!isSuccess || !threeDSecureIframeUrl || !stripe) return

    dispatch(check3DSecure({ stripe, goal }))
  }, [dispatch, search, stripe, threeDSecureIframeUrl, goal, screenName])

  const handleChange = useCallback(
    ({
      fieldName,
      isEmpty,
      hasError,
      isComplete,
      nextElemRef,
    }: {
      fieldName: StripeFieldName
      isEmpty: boolean
      hasError: boolean
      isComplete: boolean
      nextElemRef?: RefObject<TCreditCardField>
    }) => {
      dispatch(resetErrorAction())

      let error = ''

      if (hasError) {
        error = 'is invalid'
      }

      if (isEmpty) {
        error = EMPTY_FIELD_ERROR
      }

      if (nextElemRef && isComplete) {
        nextElemRef.current?.focus()
      }

      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: { isTouched: true, error, isComplete },
      }))
    },
    [dispatch],
  )

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault()
      dispatch(resetErrorAction())

      if (hasUntouchedFields) {
        setErrors(getPaymentErrorState(errors))
        return
      }

      if (hasErrors || isFetching || requestError) return

      const card = elements?.getElement(CardNumberElement)

      if (!stripe || !card) return

      eventLogger.logPaymentMethodSelected({
        paymentMethod: PaymentMethod.CREDIT_CARD,
      })
      cardholderNameElemRef.current?.blur()
      dispatch(setPaymentMethodAction(PaymentMethod.CREDIT_CARD))
      dispatch(
        purchaseAction({
          stripe,
          card,
          name,
          paymentPageId: currentPageId,
          goal,
        }),
      )
    },
    [
      dispatch,
      hasUntouchedFields,
      hasErrors,
      elements,
      stripe,
      name,
      currentPageId,
      errors,
      goal,
      isFetching,
      requestError,
    ],
  )

  return (
    <S.Form onSubmit={handleSubmit} {...props}>
      <S.Label gridArea="cardNumberLabel">{t`payment.stripe.card`}</S.Label>
      <S.CardNumberElement
        onReady={(elem) => {
          elem.focus()
          cardNumberElemRef.current = elem
        }}
        options={{
          showIcon: false,
          placeholder: '1234 1234 1234 1234',
          style: stripeElementStyle,
        }}
        onChange={({ empty, error, complete }) => {
          handleChange({
            fieldName: StripeFieldName.NUMBER,
            isEmpty: empty,
            hasError: !!error,
            isComplete: complete,
            nextElemRef: cardExpiryElemRef,
          })
        }}
      />

      <S.Label gridArea="cardExpiryLabel">{t`payment.stripe.date`}</S.Label>
      <S.CardExpiryElement
        onReady={(elem) => {
          cardExpiryElemRef.current = elem
        }}
        options={{
          placeholder: t`payment.stripe.datePlaceholder`,
          style: stripeElementStyle,
        }}
        onChange={({ empty, error, complete }) => {
          handleChange({
            fieldName: StripeFieldName.EXPIRY,
            isEmpty: empty,
            hasError: !!error,
            isComplete: complete,
            nextElemRef: cvcElemRef,
          })
        }}
      />

      <S.Label gridArea="cardCvcLabel">{t`payment.stripe.cvv`}</S.Label>
      <S.CardCvcElement
        onReady={(elem) => {
          cvcElemRef.current = elem
        }}
        options={{
          placeholder: '111',
          style: stripeElementStyle,
        }}
        onChange={({ empty, error, complete }) => {
          handleChange({
            fieldName: StripeFieldName.CVC,
            isEmpty: empty,
            hasError: !!error,
            isComplete: complete,
            nextElemRef: cardholderNameElemRef,
          })
        }}
      />

      <S.Label gridArea="cardholderLabel">{t`payment.stripe.name`}</S.Label>
      <S.CardholderInput
        ref={cardholderNameElemRef}
        type="text"
        placeholder={t`payment.stripe.placeholder`}
        data-invalid={!!errors[StripeFieldName.NAME].error}
        onChange={(e) => {
          const value = e.target.value.trim()
          setName(value)

          handleChange({
            fieldName: StripeFieldName.NAME,
            isEmpty: false,
            hasError: false,
            isComplete: true,
          })
        }}
      />
      <S.SubmitButton
        type="submit"
        disabled={!stripe || !isFormValid || isFetching || requestError}
      >
        {t`actions.continue`}
      </S.SubmitButton>
    </S.Form>
  )
}
