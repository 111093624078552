import React from 'react'
import { useTranslation } from 'react-i18next'

import { usePurchaseStore } from 'hooks/common/usePurchaseStore'

import { getPriceBeforeDiscount } from 'helpers/getPriceBeforeDiscount'

import { PriceValue } from 'components/PriceValue'

import {
  DEFAULT_DYNAMIC_DISCOUNT_AMOUNT,
  Language,
  TRIAL_DESCRIPTION_CONTEXT,
} from 'root-constants/common'

import { StyledTrialAmountWithDynamicDiscount as S } from './TrialAmountWithDynamicDiscount.styles'

export const TrialAmountWithDynamicDiscount: React.FC = () => {
  const { t } = useTranslation()
  const { language, trialPrice, trialPeriodDays, currency, dynamicDiscount } =
    usePurchaseStore()

  const oldPrice = getPriceBeforeDiscount(
    dynamicDiscount?.amount || DEFAULT_DYNAMIC_DISCOUNT_AMOUNT,
    trialPrice,
  )

  const priceWithDiscount = Number(oldPrice) - trialPrice

  return (
    <S.IntroOfferContainer>
      <S.Title fontSize={language === Language.ES ? 22 : 24}>
        {t`payment.paymentFlow.getPlan`}
      </S.Title>

      <S.DescriptionWrapper>
        <S.Description>
          {t('payment.trialWithoutDiscountAmount.amount', {
            context: TRIAL_DESCRIPTION_CONTEXT[trialPeriodDays],
          })}
        </S.Description>
        <S.Price>
          <PriceValue value={oldPrice} currency={currency} />
        </S.Price>
      </S.DescriptionWrapper>
      <S.DescriptionWrapper>
        <S.Description>
          {dynamicDiscount?.amount}% {dynamicDiscount?.checkout}
        </S.Description>
        <S.Price color="#FD417F">
          -
          <PriceValue value={priceWithDiscount} currency={currency} />
        </S.Price>
      </S.DescriptionWrapper>
      <S.DescriptionWrapper>
        <S.Description fontWeight={900}>
          {t`payment.paymentSummary.total`}
        </S.Description>
        <S.Price fontWeight={900}>
          <PriceValue value={trialPrice} currency={currency} />
        </S.Price>
      </S.DescriptionWrapper>
    </S.IntroOfferContainer>
  )
}
