import React from 'react'
import { Helmet } from 'react-helmet'

import { useHeartMonitorDomain } from 'hooks/ui/useHeartMonitorDomain'

import heartMonitorFavicon from 'assets/images/heart-monitor-logo.webp'
import cardiMateFavicon from 'assets/images/logo.png'

export const MetaInfo: React.FC = () => {
  const { isHeartMonitorDomain } = useHeartMonitorDomain()

  return isHeartMonitorDomain ? (
    <Helmet>
      <title>Heart Monitor</title>
      <link rel="icon" href={heartMonitorFavicon} />
    </Helmet>
  ) : (
    <Helmet>
      <title>Cardi Mate</title>
      <link rel="icon" href={cardiMateFavicon} />
    </Helmet>
  )
}
