import React, {
  useDeferredValue,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { usePageInfo } from 'providers/PageInfoProvider'

import { resetErrorAction } from 'root-redux/actions/common'
import {
  GET_STATUS,
  SEND_USER_EMAIL,
  sendUserEmailAction,
} from 'root-redux/actions/user'
import {
  selectActionList,
  selectCurrentVariantCohortToUse,
  selectError,
} from 'root-redux/selects/common'
import { selectUserStatus } from 'root-redux/selects/user'

import { useCookieConsentAnswer } from 'hooks/common/useCookieConsentAnswer'
import { useDynamicOBConfig } from 'hooks/common/useDynamicOBConfig'
import { useEmailInputField } from 'hooks/common/useEmailInputField'
import { useHeartMonitorDomain } from 'hooks/ui/useHeartMonitorDomain'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { getFilteredEmailDomains } from 'modules/email/helpers'

import { Button } from 'components/Button'
import { Modal } from 'components/Modal'
import { Popover } from 'components/Popover'

import { goTo } from 'browser-history'
import { Cohort } from 'root-constants/common'

import { StyledEmailVariant2 as S } from './EmailVariant2.styles'

export const EmailVariant2: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const dispatch = useDispatch()
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)
  const error = useSelector(selectError)
  const fetchingActionsList = useSelector(selectActionList)
  const userStatus = useSelector(selectUserStatus)

  const [areEmailTipsVisible, setAreEmailTipsVisible] = useState(false)
  const [isErrorModalShown, setIsErrorModalShown] = useState(false)

  const [email, , validateEmail] = useEmailInputField()
  const deferredEmail = useDeferredValue(email.value)
  const { currentSubscriptionPageId } = usePageInfo()
  const { title, subtitle, buttonText } = useDynamicOBConfig()
  const { isPersonalDataAllowed } = useCookieConsentAnswer()
  const { productName } = useHeartMonitorDomain()

  const isStatusFetching =
    fetchingActionsList?.includes(SEND_USER_EMAIL) ||
    fetchingActionsList?.includes(GET_STATUS)

  const isButtonDisabled = !email.value || !email.isValid || isStatusFetching

  const filteredEmailDomains = getFilteredEmailDomains(deferredEmail)

  const isBloodPressureCohort =
    cohortToUse === Cohort.CARDIMATE_17 || cohortToUse === Cohort.CARDIMATE_19

  useLayoutEffect(() => {
    if (userStatus?.email.hasEmail) {
      goTo(nextPagePath || `${currentSubscriptionPageId}${search}`)
    }
  }, [
    currentSubscriptionPageId,
    nextPagePath,
    search,
    userStatus?.email.hasEmail,
  ])

  useEffect(() => {
    eventLogger.logEmailPageShown()
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!email.isValid || isStatusFetching) return

    dispatch(
      sendUserEmailAction({
        email: email.value.toLowerCase(),
        unsuccessCallback: () => setIsErrorModalShown(true),
        nextPagePath,
        isPersonalDataAllowed,
      }),
    )
  }

  const handleChange = ({ target: { value } }) => {
    validateEmail(value)
    setAreEmailTipsVisible(true)
  }

  const handlePrefilledEmail = ({ currentTarget: { value } }) => {
    validateEmail(value)
    setAreEmailTipsVisible(false)
  }

  return (
    <>
      <S.Wrapper>
        <form onSubmit={handleSubmit}>
          <S.Content>
            <S.Title>
              {title || (
                <Trans
                  i18nKey={
                    isBloodPressureCohort ? 'email.title4' : 'email.title3'
                  }
                />
              )}
            </S.Title>
            <S.Subtitle>
              {subtitle || <Trans i18nKey="email.subtitle3" />}
            </S.Subtitle>

            <S.InputWrapper>
              <Popover
                isShown={!email.isValid}
                position="center"
                text={email.validationText}
              >
                <S.Input
                  value={email.value}
                  isValid={email.isValid}
                  placeholder={t`email.emailPlaceholder`}
                  autoComplete="off"
                  onChange={handleChange}
                />
              </Popover>
              {areEmailTipsVisible && !!filteredEmailDomains.length && (
                <S.AutoCompleteWrapper>
                  {filteredEmailDomains.map((value) => (
                    <S.AutoCompleteButton
                      type="button"
                      key={value}
                      value={value}
                      onMouseDown={handlePrefilledEmail}
                    >
                      <S.AutoCompleteButtonTextWrapper>
                        {value}
                      </S.AutoCompleteButtonTextWrapper>
                    </S.AutoCompleteButton>
                  ))}
                </S.AutoCompleteWrapper>
              )}
            </S.InputWrapper>

            <S.Disclaimer>
              {t('email.disclaimer', { productName })}
            </S.Disclaimer>
            <Button type="submit" disabled={isButtonDisabled}>
              {buttonText || t`actions.continue`}
            </Button>
          </S.Content>
        </form>
      </S.Wrapper>
      <Modal
        onClose={() => {
          dispatch(resetErrorAction())
          setIsErrorModalShown(false)
        }}
        isShown={isErrorModalShown}
      >
        {error}
      </Modal>
    </>
  )
}
