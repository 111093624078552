import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import {
  getUserStatusAction,
  sendUserConfigAction,
} from 'root-redux/actions/user'
import { selectUUID } from 'root-redux/selects/user'

import { eventLogger } from 'services/eventLogger.service'

import { CookiePolicyLink } from 'components/CookiePolicyLink'
import { SvgImage } from 'components/SvgImage'

import logoImg from 'assets/images/app-name-logo.png'
import closeIcon from 'assets/images/sprite/close-icon.svg'

import { CookieConsentOption } from 'root-constants/common'

import { StyledCookieConsent as S } from './CookieConsent.styles'
import { ALL_COOKIE_CONSENT_TYPES, Source } from './constants'

export const CookieConsent = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const uuid = useSelector(selectUUID)

  const [isCookieConsentVisible, setIsCookieConsentVisible] = useState(true)
  const [areSettingsOn, setAreSettingsOn] = useState(false)
  const [cookieConsentTypes, setCookieConsentTypes] = useState<string[]>([])

  useEffect(() => {
    isCookieConsentVisible && eventLogger.logCookiesConsentShown()
  }, [isCookieConsentVisible])

  const toggleSettingsVisibility = () => {
    areSettingsOn
      ? eventLogger.logCookiesConsentSettingsScreenClose()
      : eventLogger.logCookiesConsentSettingsTap()
    setAreSettingsOn((prev) => !prev)
  }

  const handleSubmit = async (userAnswer: string[]) => {
    setIsCookieConsentVisible(false)
    await dispatch(
      sendUserConfigAction({
        cookie_consent: userAnswer,
      }),
    )
    dispatch(getUserStatusAction(uuid))
  }

  const handleChange = ({ target: { value } }) => {
    if (cookieConsentTypes.includes(value)) {
      eventLogger.logCookiesConsentToggleDisable(value)
      setCookieConsentTypes((prev) => prev.filter((item) => item !== value))
      return
    }

    eventLogger.logCookiesConsentToggleEnable(value)
    setCookieConsentTypes((prev) => [...prev, value])
  }

  if (!isCookieConsentVisible) {
    return null
  }

  return (
    <S.Wrapper fullHeight={areSettingsOn}>
      {areSettingsOn && (
        <S.Header>
          <img height="28" src={logoImg} alt="cardi mate logo" />
          <S.CloseButton onClick={toggleSettingsVisibility}>
            <SvgImage svg={closeIcon} width={24} />
          </S.CloseButton>
        </S.Header>
      )}
      {areSettingsOn && (
        <>
          <S.Container>
            <S.Title marginBottom={12}>
              {t('cookieConsent.privacyCenterTitle')}
            </S.Title>
            <S.Description>
              {t('cookieConsent.privacyCenterDescription')}
            </S.Description>
            <S.PrimaryButton
              onClick={() => {
                eventLogger.logCookiesConsentAcceptAllTap(Source.SETTINGS)
                handleSubmit(ALL_COOKIE_CONSENT_TYPES)
              }}
            >
              {t('cookieConsent.allowAll')}
            </S.PrimaryButton>
            <S.SettingsTitle>
              {t('cookieConsent.consentManagement')}
            </S.SettingsTitle>
            <S.Settings>
              <S.SettingItem>
                <S.Setting
                  onClick={(event) => {
                    if (event.currentTarget.hasAttribute('open')) return

                    eventLogger.logCookiesConsentExpandOptionTap(
                      CookieConsentOption.ADVERTISING_AND_TARGETING,
                    )
                  }}
                >
                  <S.SettingName>
                    <span>{t('cookieConsent.advertisingCookies')}</span>
                  </S.SettingName>
                  <S.SettingDetails>
                    {t('cookieConsent.advertisingCookiesDetails')}
                  </S.SettingDetails>
                </S.Setting>
                <S.Switch
                  value={CookieConsentOption.ADVERTISING_AND_TARGETING}
                  onChange={handleChange}
                  isChecked={cookieConsentTypes.includes(
                    CookieConsentOption.ADVERTISING_AND_TARGETING,
                  )}
                />
              </S.SettingItem>
              <S.SettingItem>
                <S.Setting
                  onClick={(event) => {
                    if (event.currentTarget.hasAttribute('open')) return

                    eventLogger.logCookiesConsentExpandOptionTap(
                      CookieConsentOption.FUNCTIONAL_COOKIES,
                    )
                  }}
                >
                  <S.SettingName>
                    <span>{t('cookieConsent.functionalCookies')}</span>
                  </S.SettingName>
                  <S.SettingDetails>
                    {t('cookieConsent.functionalCookiesDetails')}
                  </S.SettingDetails>
                </S.Setting>
                <S.Switch
                  value={CookieConsentOption.FUNCTIONAL_COOKIES}
                  onChange={handleChange}
                  isChecked={cookieConsentTypes.includes(
                    CookieConsentOption.FUNCTIONAL_COOKIES,
                  )}
                />
              </S.SettingItem>
              <S.SettingItem>
                <S.Setting
                  onClick={(event) => {
                    if (event.currentTarget.hasAttribute('open')) return

                    eventLogger.logCookiesConsentExpandOptionTap(
                      CookieConsentOption.ANALYTICS_AND_PERFORMANCE,
                    )
                  }}
                >
                  <S.SettingName>
                    <span>{t('cookieConsent.analyticalCookies')}</span>
                  </S.SettingName>
                  <S.SettingDetails>
                    {t('cookieConsent.analyticalCookiesDetails')}
                  </S.SettingDetails>
                </S.Setting>
                <S.Switch
                  value={CookieConsentOption.ANALYTICS_AND_PERFORMANCE}
                  onChange={handleChange}
                  isChecked={cookieConsentTypes.includes(
                    CookieConsentOption.ANALYTICS_AND_PERFORMANCE,
                  )}
                />
              </S.SettingItem>
              <S.SettingItem>
                <S.Setting>
                  <S.SettingName>
                    <span>{t('cookieConsent.necessaryCookies')}</span>
                    <S.ActiveLabel>
                      {t('cookieConsent.activeLabel')}
                    </S.ActiveLabel>
                  </S.SettingName>
                  <S.SettingDetails>
                    {t('cookieConsent.necessaryCookiesDetails')}
                  </S.SettingDetails>
                </S.Setting>
              </S.SettingItem>
            </S.Settings>
          </S.Container>
          <S.Divider />
        </>
      )}

      <S.Container>
        {!areSettingsOn && (
          <>
            <S.Title>{t('cookieConsent.title')}</S.Title>
            <S.Description>
              {t('cookieConsent.description')}{' '}
              <CookiePolicyLink title="cookieConsent.cookieNotice" />
            </S.Description>
            <S.PrimaryButton
              onClick={() => {
                eventLogger.logCookiesConsentAcceptAllTap(Source.BANNER)
                handleSubmit(ALL_COOKIE_CONSENT_TYPES)
              }}
            >
              {t('cookieConsent.acceptAll')}
            </S.PrimaryButton>
            <S.SecondaryButton onClick={toggleSettingsVisibility}>
              {t('cookieConsent.settings')}
            </S.SecondaryButton>
          </>
        )}

        {areSettingsOn && (
          <>
            <S.PrimaryButton
              onClick={() => {
                eventLogger.logCookiesConsentAcceptAllTap(Source.SETTINGS)
                handleSubmit(ALL_COOKIE_CONSENT_TYPES)
              }}
            >
              {t('cookieConsent.allowAll')}
            </S.PrimaryButton>

            <S.SecondaryButton
              onClick={() => {
                eventLogger.logCookiesConsentSettingsConfirmChoice(
                  `${
                    cookieConsentTypes.toString() ||
                    CookieConsentOption.REJECT_ALL
                  }`,
                )
                handleSubmit(cookieConsentTypes)
              }}
            >
              {t('cookieConsent.confirmChoice')}
            </S.SecondaryButton>
          </>
        )}
        <S.SecondaryButton
          onClick={() => {
            handleSubmit([])
            eventLogger.logCookiesConsentRejectAllTap(
              areSettingsOn ? Source.SETTINGS : Source.BANNER,
            )
          }}
        >
          {t('cookieConsent.rejectAll')}
        </S.SecondaryButton>
      </S.Container>
    </S.Wrapper>
  )
}
