import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { selectIsFetching } from 'root-redux/selects/common'
import { selectEmail } from 'root-redux/selects/user'

import { useConfirmationPasswordInputField } from 'hooks/common/useConfirmationPasswordInputField'
import { useEmailInputField } from 'hooks/common/useEmailInputField'
import { usePasswordInputField } from 'hooks/common/usePasswordInputField'
import { useHeartMonitorDomain } from 'hooks/ui/useHeartMonitorDomain'

import { Button } from 'components/Button'
import { GlobalStatusBar } from 'components/GlobalStatusBar'
import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import {
  registerWithEmailFirebaseAction,
  resetEmailErrorMessageAction,
} from '../../redux/actions'
import { selectEmailErrorMessage } from '../../redux/selects'
import { StyledEmailAccount as S } from './EmailAccount.styles'

export const EmailAccount: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isFetching = useSelector(selectIsFetching)
  const emailErrorMessage = useSelector(selectEmailErrorMessage)
  const userOnboardingEmail = useSelector(selectEmail)

  const [email, setEmail, validateEmail] = useEmailInputField(
    userOnboardingEmail,
    emailErrorMessage,
    () => dispatch(resetEmailErrorMessageAction()),
  )
  const [password, setPassword, validatePassword] = usePasswordInputField()

  const [
    confirmationPassword,
    setConfirmationPassword,
    validateConfirmationPassword,
  ] = useConfirmationPasswordInputField(password)

  const { productName } = useHeartMonitorDomain()

  const isComplete = useMemo(
    () =>
      email.isValid &&
      email.value !== '' &&
      password.isValid &&
      password.value !== '' &&
      confirmationPassword.isValid &&
      confirmationPassword.value !== '',
    [
      confirmationPassword.isValid,
      confirmationPassword.value,
      email.isValid,
      email.value,
      password.isValid,
      password.value,
    ],
  )

  return (
    <S.Wrapper>
      <GlobalStatusBar completedStageNumber={3} />
      <S.Title>
        <Trans i18nKey="login.title" values={{ productName }} />
      </S.Title>
      <S.Text>{t`login.subtitle`}</S.Text>

      <S.Input
        value={email.value}
        isValid={email.isValid}
        validationText={email.validationText}
        placeholder={t`login.emailPlaceholder`}
        onChange={({ target: { value } }) => {
          setEmail((prevState) => ({
            ...prevState,
            value: value.toLowerCase().trim(),
            isValid: true,
            validationText: '',
          }))
        }}
        onBlur={({ target: { value } }) => validateEmail(value)}
      />

      <S.Input
        value={password.value}
        isValid={password.isValid}
        validationText={password.validationText}
        placeholder={t`login.passwordPlaceholder`}
        hasVisibilityControl
        onChange={({ target: { value } }) => {
          setPassword((prevState) => ({
            ...prevState,
            value,
            isValid: true,
            validationText: '',
          }))
        }}
        onBlur={({ target: { value } }) => validatePassword(value)}
      />

      <S.Input
        value={confirmationPassword.value}
        isValid={confirmationPassword.isValid}
        validationText={confirmationPassword.validationText}
        hasVisibilityControl
        placeholder={t`login.confirmPasswordPlaceholder`}
        onChange={({ target: { value } }) => {
          setConfirmationPassword((prevState) => ({
            ...prevState,
            value,
            isValid: true,
            validationText: '',
          }))
        }}
        onBlur={({ target: { value } }) => validateConfirmationPassword(value)}
      />

      <S.Footer>
        <S.Description>
          <Trans
            i18nKey="login.disclaimer"
            components={{
              privacyPolicy: <PrivacyPolicyLink />,
              termsOfUse: <TermsOfUseLink />,
            }}
          />
        </S.Description>
        <Button
          disabled={!isComplete || isFetching}
          onClick={() => {
            dispatch(
              registerWithEmailFirebaseAction(
                email.value.toLowerCase(),
                password.value,
              ),
            )
          }}
        >
          {t`login.signUp`}
        </Button>
      </S.Footer>
    </S.Wrapper>
  )
}
