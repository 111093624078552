import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectCurrency } from 'modules/payment/redux/selects'
import { GUIDES_DATA } from 'modules/subscriptions/components/common/Guides/constants'

import { CURRENCY_SYMBOLS } from 'root-constants/common'

import { StyledGuides as S } from './Guides.styles'

export const Guides: React.FC = () => {
  const { t } = useTranslation()
  const currency = useSelector(selectCurrency)

  return (
    <S.Wrapper>
      <S.Title>
        <Trans
          i18nKey="subscriptions.guides.title"
          values={{
            price: 43.98,
            currencySymbol: CURRENCY_SYMBOLS[currency],
            minimumFractionDigits: 2,
          }}
        />
      </S.Title>
      <S.Subtitle>{t`subscriptions.guides.subtitle`}</S.Subtitle>
      {GUIDES_DATA.map(({ img, oldPrice, currentPrice, text }) => (
        <S.GuideContainer key={text}>
          <img src={img} alt="text" />
          <S.TextWrapper>
            <S.PriceWrapper>
              <S.OldPrice>
                <Trans
                  i18nKey="subscriptions.guides.oldPrice"
                  values={{
                    price: oldPrice,
                    currencySymbol: CURRENCY_SYMBOLS[currency],
                    minimumFractionDigits: 2,
                  }}
                />
              </S.OldPrice>
              <S.CurrentPrice>{t(currentPrice)}</S.CurrentPrice>
            </S.PriceWrapper>
            <S.Text>{t(text)}</S.Text>
          </S.TextWrapper>
        </S.GuideContainer>
      ))}
      <S.Plan>{t`subscriptions.guides.choosePlan`}</S.Plan>
    </S.Wrapper>
  )
}
