import styled from 'styled-components'

import checkIcon from 'assets/images/check-circle-green-white.svg'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color, HEADER_HEIGHT } from 'root-constants/common'

export const StyledSummaryBloodPressurePlan = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Content: styled.div`
    ${baseColumnStyles};
    padding-top: 12px;
    min-height: calc(100dvh - ${HEADER_HEIGHT}px);

    ul {
      flex-grow: 1;
    }
  `,
  Title: styled.h1`
    font-size: 32px;
    font-weight: 900;
    line-height: 40px;
    margin: 0 -5px 16px;

    strong {
      font-weight: 900;
      color: ${({ theme }) => theme.colors.highlightedText};
    }

    svg {
      height: 56px;
      position: relative;
      top: 13px;
    }
  `,
  Subtitle: styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: ${Color.LIGHT_TEXT};
    margin-bottom: 24px;
  `,
  PlanDescription: styled.li`
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    padding: 0 0 0 30px;
    background-image: url(${checkIcon});
    background-size: 20px 20px;
    background-position: left center;
    background-repeat: no-repeat;

    :not(:last-child) {
      margin-bottom: 8px;
    }
  `,
}
