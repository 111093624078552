import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/common/useNextStep'
import { useHeartMonitorDomain } from 'hooks/ui/useHeartMonitorDomain'
import { useUserData } from 'hooks/user/useUserData'

import { TPageProps } from 'models/common.model'

import { Button } from 'components/Button'

import img from 'assets/images/social-proof-doctor.jpg'

import { StyledSocialProofDoctorVariant1 as S } from './SocialProofDoctorVariant1.styles'

export const SocialProofDoctorVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const { age, userGender } = useUserData()
  const { productName } = useHeartMonitorDomain()

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: '',
    nextPagePath,
  })

  return (
    <S.Wrapper>
      <S.Img src={img} alt="doctor" />
      <S.Column>
        <S.Title>{t`onboarding.didYouKnow.title`}</S.Title>
        <S.Text>
          {t('onboarding.socialProofDoctor.highBlood', {
            context: userGender,
            age,
          })}
        </S.Text>
        <S.Text>
          <Trans
            i18nKey="onboarding.socialProofDoctor.cardiMate"
            values={{ productName }}
          />
        </S.Text>
        <S.StickyButtonContainer>
          <Button onClick={() => handleContinue('')}>
            {t`actions.continue`}
          </Button>
        </S.StickyButtonContainer>
      </S.Column>
    </S.Wrapper>
  )
}
