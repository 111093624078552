/* eslint-disable max-lines */
import { getCurrentExperimentId } from 'helpers/getCurrentExperimentId'
import { getCurrentOnboardingVariant } from 'helpers/getCurrentOnboardingVariant'
import { getMobileOperatingSystem } from 'helpers/getMobileOperatingSystem'

import { TAnswer } from 'models/common.model'
import { IEvent, IEventLogger, TQuestionPageParams } from 'models/events.model'
import { TProductId } from 'models/subscriptions.model'

import {
  EventLoggerInstanceName,
  LoginMethod,
  NO_NAME_AB_SEGMENT,
  PaymentMethod,
  PaymentSystem,
} from 'root-constants/common'

export const enum ScreenName {
  ONBOARDING = 'onboarding',
  CANCEL_OFFER = 'cancel_offer',
  UPSELL_OFFER = 'upsell_offer',
  CANCEL_OFFER_DISCOUNT = 'cancel_offer_discount',
}

export const enum Source {
  INITIAL = 'initial',
  CANCEL_OFFER = 'cancel_offer',
  EMAIL = 'email',
}

export const enum Events {
  SESSION_STARTED = 'session_start',
  AB_SEGMENT = 'ab_segment',
  QUESTION_COMPLETED = 'question_page_completed',
  PRESALE_PAGE_COMPLETED = 'presale_page_completed',
  PRESALE_PAGE_SHOW = 'presale_page_show',
  EMAIL_PAGE_SHOW = 'email_page_show',
  EMAIL_PAGE_COMPLETED = 'email_page_completed',
  EMAIL_PAGE_ERROR = 'email_page_error',
  PAYMENT_METHOD_SELECTED = 'payment_method_selected',
  SALE_SCREEN_SHOW = 'plans_page_show',
  CANCEL_OFFER_SCREEN_SHOW = 'cancel_offer_page_show',
  TERMS_OF_USE = 'terms_of_use_tap',
  PRIVACY_POLICY = 'privacy_policy_tap',
  NEED_HELP = 'need_help_tap',
  PURCHASE_SHOW = 'subs_purchase_show',
  PURCHASE_STARTED = 'subs_purchase_started',
  PURCHASE_STARTED_PAYPAL = 'subs_purchase_started_paypal',
  PURCHASE_COMPLETED = 'subs_purchase_completed',
  PURCHASE_FAILED = 'subs_purchase_failed',
  PURCHASE_SCREEN_CLOSE = 'subs_purchase_screen_close',
  FORTY_FIVE_SEC_INACTION_ON = '45sec_inaction_on',
  UPSELL_PURCHASE_SHOW = 'upsell_purchase_show',
  UPSELL_PURCHASE_CLOSE = 'upsell_purchase_close',
  CREATE_ACCOUNT_SHOW = 'create_account_page',
  ACCOUNT_CREATED = 'account_created',
  ACCOUNT_CREATION_FAILED = 'account_creation_failed',
  FINISH_ACCOUNT_SCREEN_VIEW = 'finish_account_screen_view',
  DOWNLOAD_BTN_PRESSED = 'download_button_press',
  COOKIES_BANNER_SHOW = 'cookies_banner_show',
  COOKIES_BANNER_SETTINGS_TAP = 'cookies_banner_settings_tap',
  COOKIES_ACCEPT_ALL_TAP = 'cookies_accept_all_tap',
  COOKIES_REJECT_ALL_TAP = 'cookies_reject_all_tap',
  COOKIES_SETTINGS_TOGGLE_ENABLE = 'cookies_settings_toggle_enable',
  COOKIES_SETTINGS_TOGGLE_DISABLE = 'cookies_settings_toggle_disable',
  COOKIES_SETTING_CONFIRM_CHOICES_TAP = 'cookies_setting_confirm_choices_tap',
  COOKIES_SETTINGS_SCREEN_CLOSE = 'cookies_settings_screen_close',
  COOKIES_SETTINGS_EXPAND_OPTION_TAP = 'cookies_settings_expand_option_tap',
  COOKIE_POLICY_TAP = 'cookies_policy_button_tap',
}

class EventLoggerService {
  private loggers?: Map<EventLoggerInstanceName, IEventLogger>
  private eventsQueue: IEvent[] = []

  get isAmplitudeActive() {
    return this.loggers?.has(EventLoggerInstanceName.AMPLITUDE)
  }

  get getIsGiaActive() {
    return this.loggers?.has(EventLoggerInstanceName.GIA)
  }

  init(...loggers: IEventLogger[]): void {
    const entriesArr = loggers.map(
      (logger) =>
        [logger.name, logger] as [EventLoggerInstanceName, IEventLogger],
    )

    if (!this.loggers) {
      this.loggers = new Map(entriesArr)
      this.notifyInitFinished()
      return
    }

    if (this.loggers) {
      loggers.map((logger) =>
        this.loggers?.set(
          logger.name as EventLoggerInstanceName,
          logger as IEventLogger,
        ),
      )
    }

    this.notifyInitFinished()
  }

  logSessionStarted = (): void => {
    this.logEventOrPushToQueue({
      event: Events.SESSION_STARTED,
      eventProperty: {
        device_type: getMobileOperatingSystem(),
      },
      isAmplitudeEvent: false,
    })
  }

  logGrowthbookAbSegmentName({
    variantId,
    experimentKey,
    variantName,
  }: {
    experimentKey: string
    variantId: string
    variantName: string
  }): void {
    this.logEventOrPushToQueue({
      event: Events.AB_SEGMENT,
      eventProperty: {
        growthbook_feature_key: getCurrentExperimentId(experimentKey),
        ab_variant: getCurrentOnboardingVariant(variantId),
        ab_segment_name: variantName || NO_NAME_AB_SEGMENT,
      },
      isAmplitudeEvent: false,
    })
  }

  logQuestion({ question, answers, pageName }: TQuestionPageParams): void {
    this.logEventOrPushToQueue({
      event: Events.QUESTION_COMPLETED,
      eventProperty: {
        question,
        answer: Array.isArray(answers) ? answers.join(',') : answers,
        screen_name: pageName,
      },
    })
  }

  // EmailWrapper Page Events
  logEmailPageShown(): void {
    this.logEventOrPushToQueue({
      event: Events.EMAIL_PAGE_SHOW,
      isAmplitudeEvent: false,
    })
  }

  logEmailPageCompleted(eventProperty: { email: string }): void {
    this.logEventOrPushToQueue({
      event: Events.EMAIL_PAGE_COMPLETED,
      eventProperty,
      isAmplitudeEvent: false,
    })
  }

  logEmailPageError(eventProperty: { error: string }): void {
    this.logEventOrPushToQueue({
      event: Events.EMAIL_PAGE_ERROR,
      eventProperty,
    })
  }

  // Presale Page Events
  logPresalePageShown({ pageName }: { pageName: string }): void {
    this.logEventOrPushToQueue({
      event: Events.PRESALE_PAGE_SHOW,
      eventProperty: {
        screen_name: pageName,
      },
      isAmplitudeEvent: false,
    })
  }

  logPresalePageCompleted({ pageName }: { pageName: string }): void {
    this.logEventOrPushToQueue({
      event: Events.PRESALE_PAGE_COMPLETED,
      eventProperty: {
        screen_name: pageName,
      },
      isAmplitudeEvent: false,
    })
  }

  // Sale Page Events
  logSalePageShown({
    productIds,
    screenName,
    onboardingId,
    goal,
  }: {
    productIds: string[]
    screenName: ScreenName
    onboardingId?: string
    goal?: TAnswer
  }): void {
    this.logEventOrPushToQueue({
      event:
        screenName === ScreenName.CANCEL_OFFER ||
        screenName === ScreenName.CANCEL_OFFER_DISCOUNT
          ? Events.CANCEL_OFFER_SCREEN_SHOW
          : Events.SALE_SCREEN_SHOW,
      eventProperty: {
        product_id: productIds.join(','),
        screen_name: screenName,
        onboarding_id: onboardingId || '',
        ...(goal && { goal }),
      },
      isAmplitudeEvent:
        screenName === ScreenName.CANCEL_OFFER ||
        screenName === ScreenName.CANCEL_OFFER_DISCOUNT,
    })
  }

  logTermsOfUseClicked(): void {
    this.logEventOrPushToQueue({ event: Events.TERMS_OF_USE })
  }

  logPrivacyPolicyClicked(): void {
    this.logEventOrPushToQueue({ event: Events.PRIVACY_POLICY })
  }

  logNeedHelpClicked(): void {
    this.logEventOrPushToQueue({ event: Events.NEED_HELP })
  }

  // Account Page events
  logCreateAccountShown(): void {
    this.logEventOrPushToQueue({ event: Events.CREATE_ACCOUNT_SHOW })
  }

  logAccountCreated(eventProperty: { method: LoginMethod | null }): void {
    this.logEventOrPushToQueue({
      event: Events.ACCOUNT_CREATED,
      eventProperty,
    })
  }

  logAccountCreationFailed({ error }: { error: string }): void {
    this.logEventOrPushToQueue({
      event: Events.ACCOUNT_CREATION_FAILED,
      eventProperty: {
        error_reason: error,
      },
    })
  }

  // Getting App Page Events
  logGettingAppShown(): void {
    this.logEventOrPushToQueue({ event: Events.FINISH_ACCOUNT_SCREEN_VIEW })
  }

  logDownloadClicked(callback: () => void): void {
    this.logEventOrPushToQueue({
      event: Events.DOWNLOAD_BTN_PRESSED,
      callback,
    })
  }

  // Payment
  logPaymentMethodSelected({
    paymentMethod,
  }: {
    paymentMethod: PaymentMethod
  }): void {
    this.logEventOrPushToQueue({
      event: Events.PAYMENT_METHOD_SELECTED,
      eventProperty: {
        payment_method: paymentMethod,
      },
    })
  }

  logPurchaseShown({
    productId,
    screenName,
    goal,
    stripeAccountId,
    stripeAccountName,
    source = Source.INITIAL,
  }: {
    productId: TProductId
    screenName: ScreenName
    goal: TAnswer
    stripeAccountId: string
    stripeAccountName: string
    source?: Source
  }): void {
    this.logEventOrPushToQueue({
      event: Events.PURCHASE_SHOW,
      eventProperty: {
        onboarding_id: '',
        product_id: productId,
        screen_name: screenName,
        goal,
        stripe_account_name: stripeAccountName,
        stripe_account_id: stripeAccountId,
        source,
      },
    })
  }

  logPurchaseStarted({
    eventName = Events.PURCHASE_STARTED,
    productId,
    productKey,
    priceDetails: { price, trial = false, currency = 'USD' },
    paymentMethod,
    email,
    source = Source.INITIAL,
    screenName,
    paymentSystem,
    stripeAccountId,
    stripeAccountName,
    goal,
    isPersonalDataAllowed,
  }: {
    eventName?: Events
    productId: TProductId
    productKey?: string
    priceDetails: { price: number; trial?: boolean; currency?: string }
    paymentMethod: PaymentMethod
    email: string
    source?: Source
    screenName: ScreenName
    paymentSystem: PaymentSystem
    stripeAccountId?: string
    stripeAccountName?: string
    goal: TAnswer
    isPersonalDataAllowed: boolean
  }): void {
    this.logEventOrPushToQueue({
      event: eventName,
      eventProperty: {
        email,
        source,
        onboarding_id: '',
        trial,
        price,
        currency,
        product_id: productId,
        ...(productKey && { product_key: productKey }),
        screen_name: screenName,
        goal,
        payment_system: paymentSystem,
        ...(stripeAccountName && { stripe_account_name: stripeAccountName }),
        ...(stripeAccountId && { stripe_account_id: stripeAccountId }),
        payment_method: paymentMethod || PaymentMethod.CREDIT_CARD,
      },
      personalDataProperties: { email: isPersonalDataAllowed ? email : '' },
    })
  }

  logPurchaseCompleted({
    productId,
    productKey,
    priceDetails: { price, trial = false, currency = 'USD' },
    paymentMethod,
    paymentSystem,
    discountApplied,
    transactionId,
    email,
    source = Source.INITIAL,
    screenName,
    stripeAccountName,
    stripeAccountId,
    goal,
    isPersonalDataAllowed,
  }: {
    productId: TProductId
    productKey?: string
    priceDetails: { price: number; trial?: boolean; currency?: string }
    email: string
    paymentMethod?: PaymentMethod
    paymentSystem: PaymentSystem
    discountApplied?: string
    transactionId?: string
    source?: Source
    screenName: ScreenName
    stripeAccountName?: string
    stripeAccountId?: string
    goal: TAnswer
    isPersonalDataAllowed: boolean
  }): void {
    this.logEventOrPushToQueue({
      event: Events.PURCHASE_COMPLETED,
      eventProperty: {
        email,
        onboarding_id: '',
        source,
        trial,
        price,
        currency,
        product_id: productId,
        ...(productKey && { product_key: productKey }),
        screen_name: screenName,
        goal,
        payment_method: paymentMethod || PaymentMethod.CREDIT_CARD,
        payment_system: paymentSystem,
        ...(transactionId && { transaction_id: transactionId }),
        ...(stripeAccountName && { stripe_account_name: stripeAccountName }),
        ...(stripeAccountId && { stripe_account_id: stripeAccountId }),
        ...(discountApplied && { discount_applied: discountApplied }),
      },
      personalDataProperties: { email: isPersonalDataAllowed ? email : '' },
    })
  }

  logPurchaseFailed({
    productId,
    productKey,
    priceDetails: { price, trial = false, currency = 'USD' },
    error: { description, type, code },
    paymentMethod,
    paymentSystem,
    stripeAccountId,
    stripeAccountName,
    source = Source.INITIAL,
    email,
    screenName,
    goal,
    isPersonalDataAllowed,
  }: {
    productId: TProductId
    productKey?: string
    priceDetails: { price: number; trial?: boolean; currency?: string }
    error: { type: string; description?: string; code?: string }
    paymentMethod?: PaymentMethod
    paymentSystem: PaymentSystem
    stripeAccountId?: string
    stripeAccountName?: string
    source?: Source
    email: string
    screenName: ScreenName
    goal: TAnswer
    isPersonalDataAllowed: boolean
  }): void {
    this.logEventOrPushToQueue({
      event: Events.PURCHASE_FAILED,
      eventProperty: {
        email,
        onboarding_id: '',
        source,
        trial,
        price,
        currency,
        error_type: type,
        payment_system: paymentSystem,
        ...(stripeAccountName && { stripe_account_name: stripeAccountName }),
        ...(stripeAccountId && { stripe_account_id: stripeAccountId }),
        ...(description && { error_description: description }),
        ...(code && { error_code: code }),
        product_id: productId,
        ...(productKey && { product_key: productKey }),
        screen_name: screenName,
        goal,
        payment_method: paymentMethod || PaymentMethod.CREDIT_CARD,
      },
      personalDataProperties: { email: isPersonalDataAllowed ? email : '' },
    })
  }

  logPurchaseScreenClosed({
    productId,
    goal,
    screenName,
  }: {
    productId: TProductId
    goal: TAnswer
    screenName: ScreenName
  }): void {
    this.logEventOrPushToQueue({
      event: Events.PURCHASE_SCREEN_CLOSE,
      eventProperty: {
        product_id: productId,
        screen_name: screenName,
        goal,
      },
    })
  }

  logFortyFiveSecInaction({
    productId,
    goal,
    screenName,
  }: {
    productId: TProductId
    goal: TAnswer
    screenName: ScreenName
  }): void {
    this.logEventOrPushToQueue({
      event: Events.FORTY_FIVE_SEC_INACTION_ON,
      eventProperty: {
        product_id: productId,
        screen_name: screenName,
        goal,
      },
    })
  }

  logUpsellPurchaseShown(): void {
    this.logEventOrPushToQueue({
      event: Events.UPSELL_PURCHASE_SHOW,
      eventProperty: {
        screen_name: ScreenName.UPSELL_OFFER,
      },
    })
  }

  logUpsellPurchaseClose(): void {
    this.logEventOrPushToQueue({
      event: Events.UPSELL_PURCHASE_CLOSE,
      eventProperty: {
        screen_name: ScreenName.UPSELL_OFFER,
      },
    })
  }

  logCookiesConsentShown(): void {
    this.logEventOrPushToQueue({
      event: Events.COOKIES_BANNER_SHOW,
      isAmplitudeEvent: false,
    })
  }

  logCookiesConsentSettingsTap(): void {
    this.logEventOrPushToQueue({
      event: Events.COOKIES_BANNER_SETTINGS_TAP,
      isAmplitudeEvent: false,
    })
  }

  logCookiesConsentAcceptAllTap(source: string): void {
    this.logEventOrPushToQueue({
      event: Events.COOKIES_ACCEPT_ALL_TAP,
      eventProperty: { source },
      isAmplitudeEvent: false,
    })
  }

  logCookiesConsentRejectAllTap(source: string): void {
    this.logEventOrPushToQueue({
      event: Events.COOKIES_REJECT_ALL_TAP,
      eventProperty: { source },
      isAmplitudeEvent: false,
    })
  }

  logCookiesConsentToggleEnable(option: string): void {
    this.logEventOrPushToQueue({
      event: Events.COOKIES_SETTINGS_TOGGLE_ENABLE,
      eventProperty: { option },
      isAmplitudeEvent: false,
    })
  }

  logCookiesConsentToggleDisable(option: string): void {
    this.logEventOrPushToQueue({
      event: Events.COOKIES_SETTINGS_TOGGLE_DISABLE,
      eventProperty: { option },
      isAmplitudeEvent: false,
    })
  }

  logCookiesConsentSettingsConfirmChoice(options: string): void {
    this.logEventOrPushToQueue({
      event: Events.COOKIES_SETTING_CONFIRM_CHOICES_TAP,
      eventProperty: { allowed_options: options },
      isAmplitudeEvent: false,
    })
  }

  logCookiesConsentSettingsScreenClose(): void {
    this.logEventOrPushToQueue({
      event: Events.COOKIES_SETTINGS_SCREEN_CLOSE,
      isAmplitudeEvent: false,
    })
  }

  logCookiesConsentExpandOptionTap(option: string): void {
    this.logEventOrPushToQueue({
      event: Events.COOKIES_SETTINGS_EXPAND_OPTION_TAP,
      eventProperty: { option },
      isAmplitudeEvent: false,
    })
  }

  logCookiePolicyClicked(): void {
    this.logEventOrPushToQueue({ event: Events.COOKIE_POLICY_TAP })
  }

  private logEventOrPushToQueue({
    event,
    eventProperty,
    personalDataProperties,
    isAmplitudeEvent = true,
    callback,
  }: {
    event: Events
    eventProperty?: Record<string, any>
    personalDataProperties?: Record<string, any>
    isAmplitudeEvent?: boolean
    callback?: () => void
  }): void {
    if (this.getIsGiaActive) {
      this.logEvent({
        event,
        eventProperty,
        personalDataProperties,
        isAmplitudeEvent,
        callback,
      })
    } else {
      this.eventsQueue.push({
        event,
        eventProperty,
        personalDataProperties,
        isAmplitudeEvent,
        callback,
      })
    }
  }

  private notifyInitFinished() {
    if (this.eventsQueue.length) {
      this.eventsQueue.forEach(
        ({
          event,
          eventProperty,
          personalDataProperties,
          isAmplitudeEvent,
          callback,
        }) =>
          this.logEvent({
            event,
            eventProperty,
            personalDataProperties,
            isAmplitudeEvent,
            callback,
          }),
      )
      this.eventsQueue = []
    }
  }

  private logEvent({
    event,
    eventProperty,
    personalDataProperties = {},
    isAmplitudeEvent = true,
    callback,
  }: {
    event: Events
    eventProperty?: Record<string, any>
    personalDataProperties?: Record<string, any>
    isAmplitudeEvent?: boolean
    callback?: () => void
  }): void {
    if (!isAmplitudeEvent) {
      this.loggers
        ?.get(EventLoggerInstanceName.GIA)
        ?.log(event, eventProperty, callback)

      this.loggers
        ?.get(EventLoggerInstanceName.USER_FLOW_TRACKER)
        ?.log(event, eventProperty, callback)
      return
    }

    if (this.loggers) {
      const eventLoggers = [...this.loggers.values()]
      eventLoggers.forEach((logger, index, array) => {
        const personalData =
          logger.name === EventLoggerInstanceName.AMPLITUDE
            ? personalDataProperties
            : {}

        index === array.length - 1
          ? logger.log(event, { ...eventProperty, ...personalData }, callback)
          : logger.log(event, { ...eventProperty, ...personalData })
      })
    }
  }
}
export const eventLogger = new EventLoggerService()
