import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { FeaturesReady } from '@growthbook/growthbook-react'

import { resetErrorAction } from 'root-redux/actions/common'
import {
  selectCurrentVariantCohort,
  selectScreenName,
} from 'root-redux/selects/common'

import { useProductId } from 'hooks/useProductId'
import { useUserData } from 'hooks/useUserData'
import { useVatInfo } from 'hooks/useVatInfo'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { TotalAmount } from 'modules/payment/components/TotalAmount'

import paymentServicesImage from 'assets/images/payment-services.png'

import { goBack } from 'browser-history'

import { Description } from '../Description'
import { PayPalContainer } from '../PayPalContainer'
import { PaymentRequestButton } from '../PaymentRequestButton'
import { StripePaymentForm } from '../StripePaymentForm'
import { TotalAmountVAT } from '../TotalAmountVAT'
import { StyledPaymentFlows as S } from './PaymentFlows.styles'

export const PaymentFlows2: React.FC = () => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const cohort = useSelector(selectCurrentVariantCohort)
  const screenName = useSelector(selectScreenName)

  const { goal } = useUserData()
  const productId = useProductId()
  const hasIncludedVAT = useVatInfo()

  const handleBack = useCallback(() => {
    dispatch(resetErrorAction())
    googleAnalyticsLogger.logPageView(`${pathname}/${cohort}`)
    eventLogger.logPurchaseScreenClosed({
      productId,
      goal,
      screenName,
    })

    goBack()
  }, [cohort, dispatch, goal, pathname, productId, screenName])

  return (
    <>
      <FeaturesReady timeout={0} fallback={<div />}>
        <S.Title>{t`payment.paymentFlow.getPlan`}</S.Title>
        {hasIncludedVAT ? <TotalAmountVAT /> : <TotalAmount />}
      </FeaturesReady>
      <S.Form>
        <StripePaymentForm />
      </S.Form>
      <S.Divider>
        <S.DividerText>{t`payment.paymentFlow.or`}</S.DividerText>
      </S.Divider>
      <PayPalContainer />
      <PaymentRequestButton />
      <S.PaymentServiceImage
        width="256px"
        src={paymentServicesImage}
        decoding="async"
        alt="payments services"
      />
      <S.Guarantee>{t`payment.paymentFlow.moneyBack`}</S.Guarantee>
      <S.BackButton onClick={handleBack}>
        {t`payment.paymentFlow.back`}
      </S.BackButton>
      <Description />
    </>
  )
}
