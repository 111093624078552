import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectHasUpsellOffer, selectLanguage } from 'root-redux/selects/common'
import { selectUserStatus } from 'root-redux/selects/user'

import { Language } from 'root-constants/common'

import { StyledGlobalStatusBar as S } from './GlobalStatusBar.styles'

type TProps = {
  completedStageNumber: number
  marginTop?: number
} & React.HTMLAttributes<HTMLDivElement>

export const GlobalStatusBar: React.FC<TProps> = ({
  completedStageNumber,
  ...props
}) => {
  const { t } = useTranslation()
  const userStatus = useSelector(selectUserStatus)
  const hasUpsellOffer = useSelector(selectHasUpsellOffer)
  const language = useSelector(selectLanguage)

  const isUpsellShown = useMemo(
    () =>
      hasUpsellOffer &&
      language === Language.EN &&
      userStatus?.upsell.isUpsellAvailable &&
      !userStatus?.config.isCancelOfferPurchased &&
      !userStatus?.config.isCancelOfferTried,

    [
      hasUpsellOffer,
      language,
      userStatus?.upsell.isUpsellAvailable,
      userStatus?.config.isCancelOfferPurchased,
      userStatus?.config.isCancelOfferTried,
    ],
  )

  return (
    <S.Wrapper {...props}>
      <S.Item data-is-complete={completedStageNumber >= 1}>
        <S.Label>1</S.Label>
        <S.Title>{t`commonComponents.globalProgressbar.selectPlan`}</S.Title>
      </S.Item>
      <S.Item data-is-complete={completedStageNumber >= 2}>
        <S.Label>2</S.Label>
        <S.Title>{t`commonComponents.globalProgressbar.payment`}</S.Title>
      </S.Item>
      {isUpsellShown && (
        <S.Item data-is-complete={completedStageNumber >= 3}>
          <S.Label>3</S.Label>
          <S.Title>{t`commonComponents.globalProgressbar.premiumCombo`}</S.Title>
        </S.Item>
      )}
      <S.Item data-is-complete={completedStageNumber >= 4}>
        <S.Label>{isUpsellShown ? 4 : 3}</S.Label>
        <S.Title>{t`commonComponents.globalProgressbar.account`}</S.Title>
      </S.Item>
    </S.Wrapper>
  )
}
