import { useTranslation } from 'react-i18next'

import { getIsHeartMonitorDomain } from 'helpers/getIsHeartMonitorDomain'

export const useHeartMonitorDomain = () => {
  const isHeartMonitorDomain = getIsHeartMonitorDomain()
  const { t } = useTranslation()
  const productName = isHeartMonitorDomain
    ? t`actions.heartMonitor`
    : t`actions.cardiMate`

  return {
    productName,
    isHeartMonitorDomain,
  }
}
