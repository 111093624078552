import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectAnswers } from 'root-redux/selects/common'

import { TAnswer } from 'models/common.model'

import {
  CheckboxCustomValue,
  GENDER_PAGES,
  Gender,
  MAIN_GOAL_OPTION_VALUES,
} from 'root-constants/common'
import { CustomPageId, PageId } from 'root-constants/pages'

export const useUserData = (): {
  age: number
  weight: number
  weightUnit: TAnswer
  height: string | string[]
  userGender: string
  isMale: boolean
  ethnicity: string
  goal: string
  prioritizedUserGoal: string
  symptoms: string[] | null
  additionalGoals: string[]
  activity: string
  name: string
  bloodPressureFrequency: string
  userGoals: string[]
} => {
  const answers = useSelector(selectAnswers)

  const age = useMemo(() => answers?.[CustomPageId.AGE] || 0, [answers])

  const userGender = useMemo(() => {
    const genderPageId = GENDER_PAGES.find((page) => answers?.[page]) || ''
    return answers?.[genderPageId] as string
  }, [answers])

  const isMale = useMemo(() => userGender === Gender.MALE, [userGender])

  const weight = useMemo(() => answers?.[PageId.WEIGHT_1] || 0, [answers])

  const weightUnit = useMemo(() => answers?.weightUnit || '', [answers])

  const height = useMemo(() => answers?.[PageId.HEIGHT_1] || '', [answers])

  const ethnicity = useMemo(
    () => (answers?.[PageId.ETHNICITY_1] as string) || '',
    [answers],
  )

  const goal = useMemo(
    () => answers?.[CustomPageId.MAIN_GOAL] as string,
    [answers],
  )

  const userGoals = useMemo(
    () => (answers?.[CustomPageId.MAIN_GOAL] as string[]) || [],
    [answers],
  )

  const formattedUserGoals = useMemo(() => {
    if (
      userGoals.length === 1 &&
      userGoals.includes(MAIN_GOAL_OPTION_VALUES.OTHER)
    ) {
      return MAIN_GOAL_OPTION_VALUES.CONTROL_HEART_HEALTH
    }
    if (
      userGoals.includes(MAIN_GOAL_OPTION_VALUES.OTHER) &&
      userGoals.includes(MAIN_GOAL_OPTION_VALUES.CONTROL_HEART_HEALTH)
    ) {
      return MAIN_GOAL_OPTION_VALUES.CONTROL_HEART_HEALTH
    }
    return userGoals
  }, [userGoals])

  const [prioritizedUserGoal] = useMemo(() => {
    return Object.values(MAIN_GOAL_OPTION_VALUES).filter((item) =>
      formattedUserGoals.includes(item),
    )
  }, [formattedUserGoals])

  const symptoms = useMemo(() => {
    const userSymptoms = (answers?.[CustomPageId.SYMPTOMS] as string[]) || []
    if (userSymptoms.includes(CheckboxCustomValue.NONE_OF_THE_ABOVE)) {
      return null
    }
    return userSymptoms
  }, [answers])

  const additionalGoals = useMemo(
    () =>
      ((answers?.[PageId.ADDITIONAL_GOALS_1] ||
        answers?.[PageId.ADDITIONAL_GOALS_2]) as string[]) || [],
    [answers],
  )

  const activity = useMemo(
    () => (answers?.[PageId.PHYSICAL_ACTIVITY_2] as string) || '',
    [answers],
  )

  const name = useMemo(
    () => (answers?.[CustomPageId.NAME] as string) || '',
    [answers],
  )

  const bloodPressureFrequency = useMemo(
    () => (answers?.[PageId.MONITOR_BLOOD_PRESSURE_3] as string) || '',
    [answers],
  )

  return {
    age: +age,
    userGender,
    isMale,
    weight: +weight,
    weightUnit,
    height,
    ethnicity,
    goal,
    prioritizedUserGoal,
    symptoms,
    additionalGoals,
    activity,
    name,
    bloodPressureFrequency,
    userGoals,
  }
}
