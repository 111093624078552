import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { usePageInfo } from 'providers/PageInfoProvider'

import {
  selectCurrentVariantSteps,
  selectHasUpsellOffer,
  selectLanguage,
} from 'root-redux/selects/common'
import { selectUserStatus } from 'root-redux/selects/user'

import { Language } from 'root-constants/common'
import { PageId } from 'root-constants/pages'

export const useUserStatus = (): string => {
  const steps = useSelector(selectCurrentVariantSteps)
  const userStatus = useSelector(selectUserStatus)
  const hasUpsellOffer = useSelector(selectHasUpsellOffer)
  const language = useSelector(selectLanguage)

  const { currentSubscriptionPageId, currentUpsellPageId } = usePageInfo()

  return useMemo(() => {
    if (userStatus?.account.hasAccount) {
      return PageId.DOWNLOAD
    }

    if (
      userStatus?.hasSubscription &&
      hasUpsellOffer &&
      userStatus.upsell.isUpsellAvailable &&
      !userStatus.upsell.isUpsellPurchased &&
      !userStatus?.config.isCancelOfferPurchased &&
      !userStatus?.config.isCancelOfferTried &&
      language === Language.EN
    ) {
      return currentUpsellPageId
    }

    if (userStatus?.hasSubscription) {
      return PageId.ACCOUNT
    }

    if (userStatus?.email.hasEmail) {
      return currentSubscriptionPageId || steps[0].id
    }

    return ''
  }, [
    userStatus,
    hasUpsellOffer,
    currentSubscriptionPageId,
    steps,
    language,
    currentUpsellPageId,
  ])
}
