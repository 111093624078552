import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectLanguage } from 'root-redux/selects/common'

import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import oldLogo from 'assets/images/app-name-logo.png'
import guaranteeStamp from 'assets/images/sprite/guarantee-stamp.svg'
import guaranteeStampES from 'assets/images/sprite/guarantee-stamp_es.svg'

import { Language } from 'root-constants/common'

import { StyledMoneyBackGuarantee as S } from './MoneyBackGuarantee.styles'

type TProps = {
  logo?: string
  width?: number
  height?: number
  guaranteeImg?: string
}

export const MoneyBackGuarantee: React.FC<TProps> = ({
  logo = oldLogo,
  width = 121,
  height = 28,
  guaranteeImg = guaranteeStamp,
  ...props
}) => {
  const { t } = useTranslation()
  const language = useSelector(selectLanguage)

  return (
    <S.Wrapper {...props}>
      <S.Image
        svg={language === Language.ES ? guaranteeStampES : guaranteeImg}
      />
      <S.Logo src={logo} alt="logo" width={width} height={height} />
      <S.LinksWrapper>
        <TermsOfUseLink />
        <PrivacyPolicyLink />
      </S.LinksWrapper>
      <p>{t`commonComponents.description`}</p>
    </S.Wrapper>
  )
}
