import React, { useEffect, useLayoutEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { usePageInfo } from 'providers/PageInfoProvider'

import { useProductId } from 'hooks/useProductId'
import { usePurchaseStore } from 'hooks/usePurchaseStore'
import { useUserData } from 'hooks/useUserData'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { PaymentFlows2 } from 'modules/payment/components/PaymentFlows'
import { NO_TRIAL_NO_DISCOUNT_COHORTS } from 'modules/payment/constants'

import { Spinner } from 'components/Spinner'
import { StripePaymentProcessing } from 'components/StripePaymentProcessing'

import { replaceHistory } from 'browser-history'
import { PageId } from 'root-constants/pages'

import { PaymentFlows } from '../components/PaymentFlows'
import { StyledPayment as S } from './Payment.styles'

export const PaymentVariant1: React.FC<TPageProps> = () => {
  const { search } = useLocation()

  const {
    threeDSecureIframeUrl,
    isPaymentFlowsShown,
    screenName,
    selectedSubscriptionId,
    stripeAccountName,
    stripeAccountId,
    cohortToUse,
  } = usePurchaseStore()
  const productId = useProductId()
  const { goal } = useUserData()
  const { currentSubscriptionPageId } = usePageInfo()

  const hasTrialOrDiscountInfo =
    !NO_TRIAL_NO_DISCOUNT_COHORTS.includes(cohortToUse)

  useLayoutEffect(() => {
    if (selectedSubscriptionId) return

    replaceHistory({
      pathname: currentSubscriptionPageId as PageId,
      search,
    })
  }, [currentSubscriptionPageId, search, selectedSubscriptionId])

  useEffect(() => {
    if (productId) {
      eventLogger.logPurchaseShown({
        productId,
        goal,
        screenName,
        stripeAccountName,
        stripeAccountId,
      })
    }
  }, [goal, productId, screenName, stripeAccountId, stripeAccountName])

  return (
    <S.Wrapper>
      {threeDSecureIframeUrl ? (
        <S.ThreeDSecureIframe title="3DSecure" src={threeDSecureIframeUrl} />
      ) : (
        <>
          <StripePaymentProcessing />
          <S.Content>
            {hasTrialOrDiscountInfo ? <PaymentFlows /> : <PaymentFlows2 />}
          </S.Content>
        </>
      )}
      {!isPaymentFlowsShown && <Spinner />}
    </S.Wrapper>
  )
}
