import React, {
  useCallback,
  useDeferredValue,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { resetErrorAction } from 'root-redux/actions/common'
import {
  GET_STATUS,
  SEND_USER_EMAIL,
  sendUserEmailAction,
} from 'root-redux/actions/user'
import { selectActionList, selectError } from 'root-redux/selects/common'
import { selectUserStatus } from 'root-redux/selects/user'

import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useEmailInputField } from 'hooks/useEmailInputField'
import { useGetPageInfo } from 'hooks/useGetPageInfo'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { Button } from 'components/Button'
import { Modal } from 'components/Modal'
import { Popover } from 'components/Popover'

import { goTo } from 'browser-history'

import { getFilteredEmailDomains } from '../../helpers'
import { StyledEmailVariant1 as S } from './EmailVariant1.styles'

export const EmailVariant1: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const dispatch = useDispatch()
  const error = useSelector(selectError)
  const fetchingActionsList = useSelector(selectActionList)
  const userStatus = useSelector(selectUserStatus)

  const [areEmailTipsVisible, setAreEmailTipsVisible] = useState(false)
  const [isErrorModalShown, setIsErrorModalShown] = useState(false)

  const [email, , validateEmail] = useEmailInputField()
  const deferredEmail = useDeferredValue(email.value)
  const { currentSubscriptionPageId } = useGetPageInfo()
  const { title, subtitle, buttonText } = useDynamicOBConfig()

  const isStatusFetching = useMemo(
    () =>
      fetchingActionsList?.includes(SEND_USER_EMAIL) ||
      fetchingActionsList?.includes(GET_STATUS),
    [fetchingActionsList],
  )

  const isButtonDisabled = useMemo(
    () => !email.value || !email.isValid || isStatusFetching,
    [email.isValid, email.value, isStatusFetching],
  )

  const filteredEmailDomains = useMemo(
    () => getFilteredEmailDomains(deferredEmail),
    [deferredEmail],
  )

  useLayoutEffect(() => {
    if (userStatus?.email.hasEmail) {
      goTo(nextPagePath || `${currentSubscriptionPageId}${search}`)
    }
  }, [
    currentSubscriptionPageId,
    nextPagePath,
    search,
    userStatus?.email.hasEmail,
  ])

  useEffect(() => {
    eventLogger.logEmailPageShown()
  }, [])

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault()
      if (!email.isValid || isStatusFetching) return

      dispatch(
        sendUserEmailAction({
          email: email.value.toLowerCase(),
          unsuccessCallback: () => setIsErrorModalShown(true),
          nextPagePath,
        }),
      )
    },
    [dispatch, email.isValid, email.value, isStatusFetching, nextPagePath],
  )

  const handleChange = useCallback(
    ({ target: { value } }) => {
      validateEmail(value)
      setAreEmailTipsVisible(true)
    },
    [validateEmail],
  )

  const handlePrefilledEmail = useCallback(
    ({ currentTarget: { value } }) => {
      validateEmail(value)
      setAreEmailTipsVisible(false)
    },
    [validateEmail],
  )

  return (
    <>
      <S.Wrapper>
        <form onSubmit={handleSubmit}>
          <S.Content>
            <S.Title>{title || <Trans i18nKey="email.title" />}</S.Title>
            <S.Subtitle>
              {subtitle || <Trans i18nKey="email.subtitle" />}
            </S.Subtitle>

            <S.InputWrapper>
              <Popover
                isShown={!email.isValid}
                position="center"
                text={email.validationText}
              >
                <S.Input
                  value={email.value}
                  isValid={email.isValid}
                  placeholder={t`email.emailPlaceholder`}
                  autoComplete="off"
                  onChange={handleChange}
                />
              </Popover>
              {areEmailTipsVisible && !!filteredEmailDomains.length && (
                <S.AutoCompleteWrapper>
                  {filteredEmailDomains.map((value) => (
                    <S.AutoCompleteButton
                      type="button"
                      key={value}
                      value={value}
                      onMouseDown={handlePrefilledEmail}
                    >
                      <S.AutoCompleteButtonTextWrapper>
                        {value}
                      </S.AutoCompleteButtonTextWrapper>
                    </S.AutoCompleteButton>
                  ))}
                </S.AutoCompleteWrapper>
              )}
            </S.InputWrapper>

            <Button type="submit" disabled={isButtonDisabled}>
              {buttonText || t`actions.continue`}
            </Button>
          </S.Content>
        </form>
      </S.Wrapper>
      <Modal
        onClose={() => {
          dispatch(resetErrorAction())
          setIsErrorModalShown(false)
        }}
        isShown={isErrorModalShown}
      >
        {error}
      </Modal>
    </>
  )
}
