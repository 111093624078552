import React, { useEffect, useLayoutEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import {
  selectCurrentVariantCohortToUse,
  selectLanguage,
} from 'root-redux/selects/common'
import { selectUUID, selectUserCountryCode } from 'root-redux/selects/user'

import { useAmplitudeInitialization } from 'hooks/useAmplitudeInitialization'
import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'
import { useDynamicDiscount } from 'hooks/useDynamicDiscount'
import { useGAPageTracker } from 'hooks/useGAPageTracker'
import { useGiaFlowTrackerInitialization } from 'hooks/useGiaFlowTrackerInitialization'
import { growthbook } from 'hooks/useGrowthBook'
import { useScrollToTop } from 'hooks/useScrollToTop'
import { useStartSession } from 'hooks/useStartSession'
import { useUtmTags } from 'hooks/useUtmTags'

import { getEnvironment } from 'helpers/getEnvironment'

import { CookieConsent } from 'components/CookieConsent'
import { Header } from 'components/Header'
import { InContextControlPanel } from 'components/InContextControlPanel'
import { LanguageSelector } from 'components/LanguageSelector'
import { Spinner } from 'components/Spinner'
import { Theme } from 'components/Theme'

import { Cohort, Language } from 'root-constants/common'

import { RouteList } from '../../RouteList'
import { ProgressBarV3 } from '../ProgressBarV3'
import {
  BackgroundGlobalStyles,
  FontFamilyGlobalStyles,
} from './LayoutProvider.styles'
import { HEADERS, WHITE_BACKGROUND_COHORTS } from './constants'

export const LayoutProvider: React.FC = () => {
  const { i18n } = useTranslation()
  const { pathname, search } = useLocation()
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)
  const userCountryCode = useSelector(selectUserCountryCode)
  const language = useSelector(selectLanguage)
  const uuid = useSelector(selectUUID)

  const { isDevEnvironment, isStageEnvironment, isTestEnvironment } =
    getEnvironment()
  const isLanguageSelectorShown = isStageEnvironment || isTestEnvironment

  useUtmTags()
  useGAPageTracker()
  useGiaFlowTrackerInitialization()
  useAmplitudeInitialization()
  useStartSession()
  useScrollToTop()
  useDynamicDiscount()

  const shouldBeRedirected = useFeatureIsOn('web_car962_long_pw')

  useLayoutEffect(() => {
    if (!shouldBeRedirected) return

    const originUrl = window.location.origin
    const searchParams = new URLSearchParams(search)
    searchParams.set('cohort', Cohort.CARDIMATE_19_UPDATE)
    searchParams.set('uuid', uuid)
    window.location.href = `${originUrl}?${searchParams.toString()}`
  }, [search, shouldBeRedirected, uuid])

  const { isEUUser, cookieConsent } = useCookieConsentAnswer()

  const isCookieConsentAvailable = useMemo(
    () => !cookieConsent && isEUUser && language === Language.EN,
    [cookieConsent, isEUUser, language],
  )

  const hasWhiteBackground = useMemo(
    () => WHITE_BACKGROUND_COHORTS.includes(cohortToUse),
    [cohortToUse],
  )

  const hasOpenSansFontFamily = useMemo(
    () =>
      cohortToUse === Cohort.CARDIMATE_20 ||
      cohortToUse === Cohort.CARDIMATE_21,
    [cohortToUse],
  )

  const hasAlternativeProgressBar = useMemo(
    () =>
      cohortToUse === Cohort.CARDIMATE_15 ||
      cohortToUse === Cohort.CARDIMATE_17 ||
      cohortToUse === Cohort.CARDIMATE_19,
    [cohortToUse],
  )

  useEffect(() => {
    i18n.changeLanguage(language)
  }, [i18n, language])

  useEffect(() => {
    growthbook.setAttributes({
      ...growthbook.getAttributes(),
      url: `${pathname}${search}`,
      country: userCountryCode,
      language,
    })
  }, [language, pathname, search, userCountryCode])

  const CurrentHeader = HEADERS[cohortToUse] || Header

  return shouldBeRedirected ? (
    <Spinner />
  ) : (
    <Theme>
      {isDevEnvironment && <InContextControlPanel />}
      {isLanguageSelectorShown && <LanguageSelector />}
      {hasWhiteBackground && <BackgroundGlobalStyles />}
      {hasOpenSansFontFamily && <FontFamilyGlobalStyles />}

      <CurrentHeader />
      {hasAlternativeProgressBar && <ProgressBarV3 />}
      {isCookieConsentAvailable && <CookieConsent />}
      <RouteList />
    </Theme>
  )
}
