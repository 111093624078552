import React, { useEffect, useMemo, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { FeaturesReady, useFeatureIsOn } from '@growthbook/growthbook-react'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'
import { useGetPrices } from 'hooks/useGetPrices'
import { usePurchaseStore } from 'hooks/usePurchaseStore'
import { useUserData } from 'hooks/useUserData'
import { useVatInfo } from 'hooks/useVatInfo'

import { createProductId } from 'helpers/createProductId'

import { TPageProps } from 'models/common.model'

import { ScreenName, eventLogger } from 'services/eventLogger.service'

import { GettingHealthy } from 'modules/subscriptions/components/common/GettingHealthy'
import { MoneyBackGuarantee } from 'modules/subscriptions/components/common/MoneyBackGuarantee'
import { PlanItemLong } from 'modules/subscriptions/components/common/PlanItemLong'
import { VatPlanItemVariant1 } from 'modules/subscriptions/components/common/PlanItemVariant1'
import { SelectPlanBlockVariant1 } from 'modules/subscriptions/components/common/SelectPlanBlockVariant1'
import { SubheaderWithTimer } from 'modules/subscriptions/components/common/SubheaderWithTimer'
import { SubscriptionDescription } from 'modules/subscriptions/components/common/SubscriptionDescription'
import {
  COMMON_BENEFITS,
  PLAN_ITEMS,
  REDUCE_STRESS_BENEFITS,
  TRIAL_PLAN_ITEMS,
} from 'modules/subscriptions/constants'
import { usePaywall } from 'modules/subscriptions/hooks/usePaywall'

import { HeaderWithButton } from 'components/HeaderWithButton'
import {
  ReviewsSlider,
  ReviewsSliderAlternative,
} from 'components/ReviewSlider'
import { Spinner } from 'components/Spinner'

import scoresImg from 'assets/images/scores.webp'
import heartRateImg from 'assets/images/subscriptions-heart-rate.jpg'

import { Goal, SubscriptionTag } from 'root-constants/common'

import { StyledSubscriptionsVariant2 as S } from './SubscriptionsVariant2.styles'

export const SubscriptionsVariant2: React.FC<TPageProps> = ({
  alternativePagePath,
}) => {
  const { t } = useTranslation()
  const selectPlanBlockRef = useRef<HTMLDivElement>(null)
  const isAlternativeVariant = useFeatureIsOn('web_436_redirect_test')
  const isAlternativePlanVariant = useFeatureIsOn('car_184_paywall_test')

  const { subscriptions } = usePurchaseStore()
  const { goal } = useUserData()
  const hasIncludedVAT = useVatInfo()
  const { hasPrices, handleSelectSubscription, handleShowPayment } =
    usePaywall(alternativePagePath)

  useGetPrices({
    tags: hasIncludedVAT ? SubscriptionTag.TAX : SubscriptionTag.NO_TAX,
  })

  const { subscriptionBlockType } = useDynamicPaywallConfig()

  const planItem = useMemo(() => {
    if (hasIncludedVAT && !isAlternativePlanVariant) {
      return VatPlanItemVariant1
    }
    if (isAlternativePlanVariant) {
      return PlanItemLong
    }
    return PLAN_ITEMS[subscriptionBlockType]
  }, [hasIncludedVAT, isAlternativePlanVariant, subscriptionBlockType])

  const isTrialPlan = TRIAL_PLAN_ITEMS.includes(subscriptionBlockType)

  useEffect(() => {
    if (!subscriptions.length) return

    eventLogger.logSalePageShown({
      productIds: subscriptions.map(
        ({ mainPrices: { periodName, periodQuantity, fullPrice } }) =>
          createProductId({
            periodName,
            periodQuantity,
            price: fullPrice,
          }),
      ),
      screenName: ScreenName.ONBOARDING,
      goal,
    })
  }, [goal, subscriptions])

  const handleScroll = () => {
    selectPlanBlockRef.current?.scrollIntoView({
      block: 'center',
    })
  }

  return !hasPrices ? (
    <Spinner />
  ) : (
    <S.Wrapper>
      <FeaturesReady timeout={0} fallback={<div />}>
        {isAlternativeVariant ? (
          <HeaderWithButton onClick={handleShowPayment} />
        ) : (
          <HeaderWithButton onClick={handleScroll} />
        )}
      </FeaturesReady>

      <S.Content>
        <SubheaderWithTimer discount={isTrialPlan ? 50 : 75} />
        {goal === Goal.REDUCE_STRESS ? (
          <>
            <S.Title>
              <Trans i18nKey="subscriptions.takeControl" />
            </S.Title>
            <S.Subtitle>
              <Trans i18nKey="subscriptions.stressFeedback" />
            </S.Subtitle>

            <S.List>
              <ul>
                {REDUCE_STRESS_BENEFITS.map((path) => (
                  <li key={path}>{t(path)}</li>
                ))}
              </ul>
              <S.ImageContainer>
                <img src={scoresImg} alt="scores on mobile phone" />
              </S.ImageContainer>
            </S.List>
          </>
        ) : (
          <>
            <S.Congratulations>{t`subscriptions.congrats`}</S.Congratulations>
            <S.Title>{t`subscriptions.rightTrack`}</S.Title>
            <S.Disclaimer>{t`subscriptions.trackingTools`}</S.Disclaimer>

            <S.List>
              <ul>
                {COMMON_BENEFITS.map((path) => (
                  <li key={path}>{t(path)}</li>
                ))}
              </ul>
              <S.ImageContainer>
                <img src={heartRateImg} alt="scores on mobile phone" />
              </S.ImageContainer>
            </S.List>
          </>
        )}

        <FeaturesReady timeout={0} fallback={<div />}>
          <SelectPlanBlockVariant1
            ref={selectPlanBlockRef}
            onSelect={handleSelectSubscription}
            SelectPlanItem={planItem}
            scrollMargin={isAlternativePlanVariant ? 200 : 0}
          />
        </FeaturesReady>

        <S.Button onClick={handleShowPayment}>{t`actions.getCardi`}</S.Button>
        {isTrialPlan ? (
          <SubscriptionDescription />
        ) : (
          <S.Disclaimer>{t`subscriptions.disclaimer`}</S.Disclaimer>
        )}
        <GettingHealthy />
        {goal === Goal.REDUCE_STRESS ? (
          <ReviewsSliderAlternative
            titlePath="subscriptions.customersLove"
            titleBottomMargin={48}
            marginBottom={40}
          />
        ) : (
          <ReviewsSlider
            titlePath="subscriptions.customersLove"
            titleBottomMargin={48}
            marginBottom={40}
          />
        )}
        <MoneyBackGuarantee />
      </S.Content>
    </S.Wrapper>
  )
}
