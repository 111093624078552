import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Outlet, useLocation } from 'react-router-dom'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantSteps,
  selectLanguage,
} from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'

import { getPathFromPageId } from 'helpers/getPathFromPageId'

import { goTo } from 'browser-history'

export const SubscribeFlow: React.FC = () => {
  const { search } = useLocation()
  const steps = useSelector(selectCurrentVariantSteps)
  const cohort = useSelector(selectCurrentVariantCohort)
  const uuid = useSelector(selectUUID)
  const language = useSelector(selectLanguage)

  const firstPagePath = useMemo(
    () =>
      getPathFromPageId({
        pageId: steps[0].id,
        cohort,
        uuid,
        language,
        currentSearch: search,
      }),
    [cohort, language, steps, search, uuid],
  )

  useEffect(() => {
    const URLParams = new URLSearchParams(search)
    const uuidFromUrl = URLParams.get('uuid')

    if (!uuidFromUrl) {
      goTo(firstPagePath)
    }
  }, [search, firstPagePath])

  return <Outlet />
}
