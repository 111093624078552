import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { resetErrorAction } from 'root-redux/actions/common'
import { getUserStatusAction } from 'root-redux/actions/user'
import { selectActionList, selectError } from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'

import {
  CHECK_3D_SECURE,
  PURCHASE,
  setIsPaymentProcessingPopupActiveAction,
} from 'modules/payment/redux/actions/common'
import { PURCHASE_PRIMER } from 'modules/payment/redux/actions/primer'
import { MAKE_UPSELL } from 'modules/payment/redux/actions/upsell'

import { Spinner } from 'components/Spinner'

import errorIcon from 'assets/images/error.svg'

import { StyledPaymentStatus as S } from 'common-styles'

import { PaymentSuccess } from '../PaymentSuccess'

export const StripePaymentProcessing: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const uuid = useSelector(selectUUID)
  const fetchingActionsList = useSelector(selectActionList)
  const requestError = useSelector(selectError)
  const [isShown, setIsShown] = useState(false)

  const isPurchaseInProcess = useMemo(
    () =>
      fetchingActionsList.some((action) =>
        [PURCHASE, PURCHASE_PRIMER, CHECK_3D_SECURE, MAKE_UPSELL].includes(
          action,
        ),
      ),
    [fetchingActionsList],
  )

  useEffect(() => {
    if (isPurchaseInProcess) {
      setIsShown(true)
      dispatch(setIsPaymentProcessingPopupActiveAction(true))
    }
  }, [isPurchaseInProcess, dispatch])

  const handleResetError = () => {
    if (!window.navigator.onLine) return

    dispatch(getUserStatusAction(uuid))
    setIsShown(false)
    dispatch(setIsPaymentProcessingPopupActiveAction(false))
    dispatch(resetErrorAction())
  }

  return (
    <>
      {isPurchaseInProcess && (
        <S.Wrapper>
          <S.Container>
            <Spinner isFullScreen={false} />
            <S.Title>{t`payment.stripe.titleProcessing`}</S.Title>
            <S.Subtitle>{t`payment.stripe.subtitleProcessing`}</S.Subtitle>
          </S.Container>
        </S.Wrapper>
      )}
      {!isPurchaseInProcess && !requestError && isShown && <PaymentSuccess />}
      {!isPurchaseInProcess && requestError && isShown && (
        <S.Wrapper>
          <S.Container>
            <S.Image src={errorIcon} width="72" alt="Error" />
            <S.Title>{t`payment.stripe.error`}</S.Title>
            <S.Subtitle>
              {requestError?.description ||
                requestError ||
                t`payment.stripe.paymentInfoError`}
            </S.Subtitle>
            <S.Button onClick={handleResetError}>
              {t`payment.stripe.tryAgain`}
            </S.Button>
          </S.Container>
        </S.Wrapper>
      )}
    </>
  )
}
