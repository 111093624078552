import React from 'react'
import { useTranslation } from 'react-i18next'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'

import { Language, MAIN_GOAL_OPTION_VALUES } from 'root-constants/common'
import { CustomPageId } from 'root-constants/pages'

import { StyledMainGoal as S } from './MainGoal.styles'
import { OPTION_VALUE_TO_GOAL_MAP, PAGE_NAME } from './constants'

export const MainGoalVariant2: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId: CustomPageId.MAIN_GOAL,
    pageName: PAGE_NAME,
    question: t('onboarding.mainGoal.title', { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = {
    name: CustomPageId.MAIN_GOAL,
    type: OptionType.RADIO,
    onChange: (value: string) => {
      handleChange(OPTION_VALUE_TO_GOAL_MAP[value], handleContinue)
      setIsAnswersDisabled(true)
    },
  }

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.mainGoal.title`}</S.Title>
        <S.Text>{t`onboarding.mainGoal.subtitle`}</S.Text>

        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={MAIN_GOAL_OPTION_VALUES.CONTROL_BLOOD_PRESSURE}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>
              {t`onboarding.mainGoal.controlBloodPressure`}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={MAIN_GOAL_OPTION_VALUES.CONTROL_HEART_HEALTH}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`onboarding.mainGoal.controlHeartRate`}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={MAIN_GOAL_OPTION_VALUES.REDUCE_STRESS}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`onboarding.mainGoal.reduceStress`}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={MAIN_GOAL_OPTION_VALUES.SLEEP_BETTER}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`onboarding.mainGoal.sleepBetter`}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={MAIN_GOAL_OPTION_VALUES.REDUCE_MEDICATION_INTAKE}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>
              {t`onboarding.mainGoal.reduceMedicationIntake`}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={MAIN_GOAL_OPTION_VALUES.OTHER}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`onboarding.mainGoal.other`}</QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </S.Wrapper>
  )
}
