import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { setMultipleAnswerAction } from 'root-redux/actions/common'
import { sendUserAnswersAction } from 'root-redux/actions/user'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useFirstPageFinish } from 'hooks/useFirstPageFinish'
import { useURLTitle } from 'hooks/useURLTitle'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { DynamicDiscountBanner } from 'components/DynamicDiscountBanner'
import { OptionType } from 'components/Option'
import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { SvgImage } from 'components/SvgImage'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import arrowIcon from 'assets/images/sprite/arrow-right.svg'

import { goTo } from 'browser-history'
import { STATIC_HEART_AGE } from 'root-constants/common'
import { CustomPageId } from 'root-constants/pages'

import { StyledAgeRangeVariant3 as S } from './AgeRangeVariant3.styles'
import { QUESTION, RANGES } from './constants'

export const AgeRangeVariant3: React.FC<TPageProps> = ({ nextPagePath }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { handleChange, isAnswersDisabled, setIsAnswersDisabled } =
    useDelayedContinue()
  const handleFirstPageFinish = useFirstPageFinish()
  const urlTitle = useURLTitle()

  const handleContinue = (age) => {
    dispatch(
      setMultipleAnswerAction({
        answers: {
          [CustomPageId.AGE]: age,
          heart_age: +age + STATIC_HEART_AGE,
        },
      }),
    )
    dispatch(sendUserAnswersAction())

    eventLogger.logQuestion({
      question: QUESTION,
      answers: age,
      pageName: CustomPageId.AGE,
    })

    goTo(nextPagePath)
  }

  const optionProps = {
    name: CustomPageId.AGE,
    type: OptionType.RADIO,
    onChange: (value: string) => {
      handleFirstPageFinish()
      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
    },
  }

  return (
    <S.Wrapper>
      <DynamicDiscountBanner />
      <S.Column>
        <S.Subtitle>{t`onboarding.ageRange.subtitle3`}</S.Subtitle>
        <S.Title>
          {urlTitle || <Trans i18nKey="onboarding.ageRange.title2" />}
        </S.Title>
        <S.Text>{t`onboarding.ageRange.quiz`}</S.Text>
        <S.OptionsContainer>
          {RANGES.map(({ value, img, text }) => (
            <S.Option
              {...optionProps}
              key={value}
              disabled={isAnswersDisabled}
              value={value}
            >
              <S.Action>
                <S.RangeImage src={img} alt="range" />
                <S.Answer />
                <S.Range>
                  {text}
                  <SvgImage svg={arrowIcon} height={10} />
                </S.Range>
              </S.Action>
            </S.Option>
          ))}
        </S.OptionsContainer>
        <S.AgreementText>{t`onboarding.intro.byContinuing`}</S.AgreementText>
        <S.PoliciesLinksWrapper>
          <TermsOfUseLink />
          <PrivacyPolicyLink />
        </S.PoliciesLinksWrapper>
        <S.Description>{t`commonComponents.description`}</S.Description>
      </S.Column>
    </S.Wrapper>
  )
}
