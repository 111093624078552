import { COMMON_OPTION_VALUES } from 'root-constants/common'

export const OPTIONS_DATA = [
  { value: COMMON_OPTION_VALUES.YES, text: 'actions.yes' },
  { value: COMMON_OPTION_VALUES.NO, text: 'actions.no' },
  {
    value: COMMON_OPTION_VALUES.PREFER_NOT_TO_ANSWER,
    text: 'actions.notToAnswer',
  },
]
export const DYNAMIC_TITLE_OPTIONS = {
  yes: 'onboarding.hormonalMeds.yes',
  no: 'onboarding.hormonalMeds.no',
  post_menopause: 'onboarding.hormonalMeds.postMenopause',
  prefer_not_to_answer: 'onboarding.hormonalMeds.preferNotTo',
}
