import { createGlobalStyle } from 'styled-components'

import { Color } from 'root-constants/common'

export const GlobalStyleForPrivacyPolicy = createGlobalStyle`
  * {
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: initial;
    -moz-osx-font-smoothing: initial;
  }
  
   body {
     line-height: 1.5;
     min-width: 320px;
     overflow-x: hidden;
     background-color: ${Color.WHITE};
     color: #000;
   }

  a {
    text-decoration: underline;
    word-break: break-word;
  }

  ul,
  ol {
    list-style: initial;
  }

   .container {
     margin: 0 auto;
   }

   .header {
     background-color: #f0f2fa;
   }

   .header__container {
     align-items: center;
     column-gap: 20px;
     display: flex;
     max-width: 980px;
     padding: 10px 0;
     width: calc(100% - 10px - 10px);
   }

   .logo {
     display: flex;
     overflow: hidden;
     width: 48px;
   }

   .logo__img {
     max-width: 100%;
   }

   .header-title {
     color: #191919;
     font-size: 22px;
     font-weight: bold;
     font-family: 'Raleway', sans-serif;
   }

   .article {
     width: calc(100% - 10px - 10px);
     max-width: 800px;
     padding: 35px 0 20px;
   }

   .article__title {
     font-size: 24px;
     font-weight: 700;
   }

   .article__date {
     font-size: 24px;
     font-weight: 700;
     margin: 0 0 30px;
   }

   .article section:not(:last-of-type) {
     margin-bottom: 30px;
   }

   .article section {
     font-weight: 300;
   }

   .article h2 {
     font-size: 24px;
     margin-bottom: 30px;
   }

   .article h3 {
     font-size: 18px;
     font-weight: 700;
   }

   .article p,
   .article li {
     font-size: 18px;
   }

   .article a {
     color: inherit;
     font: inherit;
   }

   .article ol,
   .article ul {
     padding-left: 32px;
   }
   .article .mb-10 {
     margin-bottom: 10px;
   }

   .article .mb-20 {
     margin-bottom: 20px;
   }

   .article .mb-30 {
     margin-bottom: 30px;
   }

   .article__customList {
     padding-left: 50px;
     margin-bottom: 20px;
   }

   .footer {
     background-color: #f0f2fa;
     padding: 10px 0;
     text-align: center;
   }

   .footer__content {
     color: #191919;
     font-size: 14px;
     font-weight: 400;
   }

   @media only screen and (min-width: 1024px) {
     .header__container {
       padding: 25px 10px;
     }

     .logo {
       width: 64px;
     }

     .header-title {
       font-size: 22px;
     }

     .article {
       padding: 70px 0 40px;
     }

     .footer {
       padding: 25px 0;
     }
   }
   
`
